import FuseScrollbars from '@fuse/core/FuseScrollbars';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import UserNavbarHeader from 'app/fuse-layouts/shared-components/UserNavbarHeader';
import clsx from 'clsx';
import { memo } from 'react';
import Button from '@material-ui/core/Button';
import 'intro.js/introjs.css';
import introJs from 'intro.js';
import { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
// import {
// 	enableDashboardTour
// } from '../../store/dashboardTourSlice';
import {
	enableDashboardTour,
	enableExploreTour,
	enableCBTour,
	enableKPIBuilderTour,
	enableTrackEventTour,
	enablePinnedDashboardTour,
	enableSharedDashboardTour,
	enableFunnelTour,
	enableDITour
} from '../../store/productTourSlice';

const useStyles = makeStyles(theme => ({
	root: {
		// backgroundColor: theme.palette.background.default,
		backgroundColor: theme.palette.primary[100],
		color: theme.palette.text.primary,
		'& ::-webkit-scrollbar-thumb': {
			boxShadow: `inset 0 0 0 20px ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
				}`
		},
		'& ::-webkit-scrollbar-thumb:active': {
			boxShadow: `inset 0 0 0 20px ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
				}`
		}
	},
	content: {
		overscrollBehavior: 'contain',
		overflowX: 'hidden',
		overflowY: 'auto',
		'-webkit-overflow-scrolling': 'touch',
		background:
			'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 40px, 100% 10px',
		backgroundAttachment: 'local, scroll'
	}
}));

function NavbarStyle1Content(props) {
	const classes = useStyles();
	const theme = useTheme();
	const intro = introJs();
	const dispatch = useDispatch();
	const [startTour, setStartTour] = useState(false);
	
	const productTour = (e) => {
		let pageTitle = window.location.href.split('/')[3];
		console.log(pageTitle);
		if (pageTitle === 'dashboard') {
			dispatch(enableDashboardTour(true))
		}
		else if(pageTitle === 'explore')
		 {
			console.log(" noinside id");
			dispatch(enableExploreTour(true))
		}
		else if(pageTitle==='chartbuilder')
		{
			dispatch(enableCBTour());
		}
		else if(pageTitle==='kpibuilder')
		{
			dispatch(enableKPIBuilderTour());
		}
		else if(pageTitle==='track-events') {
			dispatch(enableTrackEventTour());
		}
		else if(pageTitle==='pinned-dashboard') {
			dispatch(enablePinnedDashboardTour());
		}
		else if(pageTitle==='shared-dashboard') {
			dispatch(enableSharedDashboardTour());
		}
		else if(pageTitle==='behaviour-analysis') {
			if(window.location.href.split('/')[4]==='funnel')
			dispatch(enableFunnelTour());
		}
		else if(pageTitle==='dynamic-insights') {
			
			dispatch(enableDITour());
		}
	};
	
	return (
		<div className={clsx('flex flex-auto flex-col overflow-hidden h-full', classes.root, props.className)} >
			<AppBar

				style={{ background: '#FAFAFA' }}
				position="static"
				className="flex flex-row items-center flex-shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0"
			>
				<div className="flex flex-1 mx-4" >
					<Logo />
				</div>

				<NavbarToggleButton className="w-40 h-40 p-0" />
			</AppBar>

			<FuseScrollbars
				className={clsx(classes.content)}
				option={{ suppressScrollX: true, wheelPropagation: false }}
			>
				{/* <UserNavbarHeader /> */}

				<Navigation layout="vertical" />
				<AppBar
					style={{ background: '#FAFAFA', width: 'inherit', position: 'fixed', bottom: '0' }}
					position="static"
					className="flex flex-row items-center flex-shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0"
				>
					<div className="flex flex-1 mx-4" >
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className="mx-auto mt-16"
							onClick={productTour}
						>
							Take a tour
						</Button>
					</div>

				</AppBar>
			</FuseScrollbars>

		</div>
	);
}

export default memo(NavbarStyle1Content);
