import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import {
  Typography,
  Divider,
  Grid,
  List,
  ListItemAvatar,
  ListItem,
  Box,
  Link as ButtonLink,
} from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { logoutUser } from "app/auth/store/userSlice";
import Logout from "@material-ui/icons/ExitToApp";

import { clear_state } from "app/main/app/dashboard/store/kpisSlice";
import { Link, useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { v1 as uuid } from "uuid";
import { setSwitchAccount } from "app/auth/store/userSlice";

function UserMenu(props) {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const history = useHistory();
  const [userMenu, setUserMenu] = useState(null);
  const location = useLocation();
  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };
  localStorage.setItem("lac-user-details", JSON.stringify(user.data));
  const userMenuClose = () => {
    setUserMenu(null);
  };

  const addNewAccount = () => {
    localStorage.setItem("lac-source-account-setup", "ADD_NEW_PRODUCT");
    history.push("/account-setup");
  };

  const switchAccount = () => {
    dispatch(setSwitchAccount());
    //history.go(0);
    localStorage.setItem("reload-menu-items", "true");
    history.push("/dashboard/" + uuid());
    // history.push('/dashboard');
  };

  const logout = () => {
    // dispatch(clear_state(true));
    
    localStorage.clear();
    history.push("/login");
    
  };
  const { user_role, organization_type } = user.data;
  
  const userStepControllerHandler = (item) => {
    if (item.product_register_progress === "Step 2") {
      localStorage.setItem("lac-user-active-client_id", item.client_id);
      localStorage.setItem("lac-source-account-setup", "STEP_2");
      history.push("/account-setup");
    } else if (item.product_register_progress === "Completed") {
    localStorage.setItem("lac-user-active-client_id", item.client_id);
      switchAccount(item.client_id);
    } else {
      history.push("/account-setup");
    }
  };
  return (
    <>
      <Button
        className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
        onClick={userMenuClick}
      >
        <div className="hidden md:flex flex-col mx-4 items-end">
          <Typography component="span" className="font-semibold flex">
            {/* {user.data.display_name} */}
            {`${user.data?.full_name} /`}{" "}
            <Box style={{ fontWeight: "800" }}>
              {user.data?.currentProductName}
            </Box>
          </Typography>
          <Typography
            className="text-11 font-medium capitalize"
            color="textSecondary"
          >
            {user?.role?.toString()}
            {(!user.role ||
              (Array.isArray(user.role) && user.role.length === 0)) &&
              user.data[0]?.user_role}
          </Typography>
        </div>

        {user.data.photoURL ? (
          <Avatar
            className="md:mx-4"
            alt="user photo"
            src={user.data.photoURL}
          />
        ) : (
          // <Avatar className="md:mx-4">{user.data?.display_name?.[0]}</Avatar>
          <Avatar className="md:mx-4">{user.data[0]?.full_name[0]}</Avatar>
        )}
      </Button>

      <Popover
        open={Boolean(userMenu)}
        anchorEl={userMenu}
        onClose={userMenuClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        classes={{
          paper: "py-8",
        }}
      >
        {!user.role || user.role.length === 0 ? (
          <>
            <MenuItem>
              <Grid container direction="column">
                <Grid container direction="row" justify="flex-end">
                  <Link to="/profile">
                    {" "}
                    <ButtonLink component="button" variant="body2" style={{color:"black"}}>
                      Edit Profile
                    </ButtonLink>
                  </Link>
                </Grid>
                <Grid container direction="row" justify="center">
                  <Avatar
                    className="md:mx-4"
                    style={{ width: "56px", height: "56px" }}
                  >
                    {user.data?.display_name?.[0]}
                  </Avatar>
                </Grid>
                <Grid container direction="row" justify="center">
                  <Typography variant="subtitle2">
                    {user.data?.currentProductName}
                  </Typography>
                </Grid>
                <Grid container direction="row" justify="center">
                  <div>Hi , {user.data?.display_name}</div>
                </Grid>
              </Grid>
            </MenuItem>
            {user_role === "Admin" ? (
              <MenuItem
                component={Link}
                to="/manage-users"
                role="button"
                className="mb-20"
              >
                <Grid container direction="column" justify="center">
                  <Button
                    type="upload"
                    variant="outlined"
                    size="medium"
                    component="label"
                  >
                    <Icon size="small">group_add</Icon>
                    <Typography style={{ paddingLeft: "3px" }}>
                      Manage Users
                    </Typography>
                  </Button>
                </Grid>
              </MenuItem>
            ) : null}
            <Divider />

            {user.data?.availableProducts &&
              user.data?.availableProducts.length > 1 && (
                <List>
                  {user.data?.availableProducts.map((item) => (
                    <MenuItem
                      // component={Link}
                      // to={`/dashboard/${item.client_id}`}
                      onClick={() => {
                        userStepControllerHandler(item);
                      }}
                      role="button"
                    >
                      <ListItem alignItems="flex-start">
                        <ListItemAvatar>
                          <Avatar
                            alt="Remy Sharp"
                            src="/material-ui-static/images/avatar/1.jpg"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Grid container direction="column">
                              <Typography>Switch to</Typography>
                              <Typography>{item.company_name}</Typography>
                            </Grid>
                          }
                        />
                      </ListItem>
                    </MenuItem>
                  ))}
                </List>
              )}

            <Divider />
            {user_role == "Admin" && organization_type == "Marketing Agency" ? (
              <MenuItem
                // component={Link}
                // to="/account-setup"
                onClick={addNewAccount}
                role="button"
                style={{ height: "65px" }}
              >
                <ListItemIcon className="min-w-40">
                  <Icon>business</Icon>
                </ListItemIcon>
                <ListItemText primary="Add Website / Client" />
              </MenuItem>
            ) : null}
            <Divider />
            <MenuItem
              onClick={logout}
              role="button"
              style={{ justifyContent: "center" }}
            >
              <Grid
                container
                direction="row"
                justify="center"
                style={{ width: "auto" }}
              >
                <ListItemIcon className="min-w-20">
                  <Logout />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </Grid>
            </MenuItem>
            {/* <MenuItem component={Link} to="/register" role="button">
              <ListItemIcon className="min-w-40">
                <Icon>person_add</Icon>
              </ListItemIcon>
              <ListItemText primary="Register" />
            </MenuItem> */}
          </>
        ) : (
          <>
            <MenuItem
              component={Link}
              to="/profile"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>account_circle</Icon>
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </MenuItem>
            <MenuItem
              component={Link}
              to="/apps/mail"
              onClick={userMenuClose}
              role="button"
            >
              <ListItemIcon className="min-w-40">
                <Icon>mail</Icon>
              </ListItemIcon>
              <ListItemText primary="Inbox" />
            </MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(logoutUser());
                userMenuClose();
              }}
            >
              <ListItemIcon className="min-w-40">
                <Icon>exit_to_app</Icon>
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </MenuItem>
          </>
        )}
      </Popover>
    </>
  );
}

export default UserMenu;
