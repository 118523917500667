import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
// import Auth0RegisterTab from './tabs/Auth0RegisterTab';
// import FirebaseRegisterTab from './tabs/FirebaseRegisterTab';
import JWTRegisterTab from './tabs/JWTRegisterTab';
import withReducer from 'app/store/withReducer';

import reducer from './store';

const useStyles = makeStyles((theme) => ({
  root: {
    // background: `linear-gradient(to right, ${
    //   theme.palette.primary.dark
    // } 0%, ${darken(theme.palette.primary.dark, 0.5)} 100%)`,
    background: '#FFFFFF',
    color: theme.palette.primary.contrastText,
  },
  leftSection: {},
  rightSection: {
    background: `linear-gradient(to right, ${
      theme.palette.primary.main
    } 0%, ${darken(theme.palette.primary.main, 0.5)} 100%)`,
    color: theme.palette.primary.contrastText,
  },
}));

function Register() {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        classes.root,
        'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'
      )}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
        className="flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
      >
        <Card
          className={clsx(
            classes.leftSection,
            'flex flex-col w-full max-w-sm items-center justify-center shadow-0'
          )}
          square
        >
          <CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
            >
              <div className="flex items-center justif-center mb-32">
                <img
                  className="logo-icon w-48"
                  src="assets/images/logos/flableLogo.png"
                  alt="logo"
                />
                {/* <div className="border-l-1 mr-4 w-1 h-40" /> */}
                <div>
                  <Typography
                    className="text-24 font-semibold logo-text"
                    color="inherit"
                  >
                    FLABLE
                  </Typography>
                  <Typography
                    className="text-16 tracking-widest -mt-8 font-700"
                    color="textSecondary"
                  >
                    CAS GLOBAL
                  </Typography>
                </div>
              </div>
            </motion.div>

            <div className="flex flex-col items-center justify-center pb-32">
              <div>
                <span className="font-normal mr-8">
                  Already have an account?
                </span>
                <Link className="font-normal" to="/login" id="signinLink">
                  Sign In
                </Link>
              </div>
              {/* <Link className="font-normal mt-8" to="/">
							Back to Dashboard
						</Link> */}
            </div>

            {/* <Tabs
							value={selectedTab}
							onChange={handleTabChange}
							variant="fullWidth"
							className="w-full mb-32"
						>
							<Tab
								icon={
									<img
										className="h-40 p-4 bg-black rounded-12"
										src="assets/images/logos/jwt.svg"
										alt="firebase"
									/>
								}
								className="min-w-0"
								label="JWT"
							/>
							<Tab
								icon={<img className="h-40" src="assets/images/logos/firebase.svg" alt="firebase" />}
								className="min-w-0"
								label="Firebase"
							/>
							<Tab
								icon={<img className="h-40" src="assets/images/logos/auth0.svg" alt="auth0" />}
								className="min-w-0"
								label="Auth0"
							/>
						</Tabs> */}

            {/* {selectedTab === 0 &&  */}
            <JWTRegisterTab />
            {/* } */}
            {/* {selectedTab === 1 && <FirebaseRegisterTab />}
						{selectedTab === 2 && <Auth0RegisterTab />} */}
          </CardContent>
        </Card>

        <div
          className={clsx(
            classes.rightSection,
            'hidden md:flex flex-1 items-center justify-center p-64'
          )}
        >
          <div className="max-w-320">
            <motion.div
              initial={{ opacity: 0, y: 40 }}
              animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
            >
              <Typography
                variant="h3"
                color="inherit"
                className="font-semibold leading-tight"
              >
                Welcome <br />
                to the <br /> Flable!
              </Typography>
            </motion.div>

            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            >
              <Typography variant="subtitle1" color="inherit" className="mt-32">
                Powerful and professional admin template for Web Applications,
                CRM, CMS, Admin Panels and more.
              </Typography>
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default withReducer('register', reducer)(Register);
