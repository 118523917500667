import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';

i18next.addResourceBundle('en', 'examplePage', en);
i18next.addResourceBundle('tr', 'examplePage', tr);
i18next.addResourceBundle('ar', 'examplePage', ar);

/**
 * Lazy load Example
 */

import React from 'react';

const SocialListeningConfig = {
   settings: {
      layout: {
         config: {},
      },
   },
   routes: [
      {
         path: '/social-listening',
         component: React.lazy(() => import('./SocialListening')),
      },
      {
         path: '/integrations',
         component: React.lazy(() => import('./integrations')),
      },
      {
         path: '/woocommerce',
         component: React.lazy(() => import('./integrations/WoocommerceForm')),
      },
      {
         path: '/shopify',
         component: React.lazy(() => import('./integrations/ShopifyForm')),
      },
   ],
};

export default SocialListeningConfig;
