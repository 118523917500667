import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FilterRowContext } from './Context';
import _ from '@lodash';
import Autocomplete from '@mui/material/Autocomplete';
import {
  TextField,
  Input,
  Select,
  MenuItem,
  FormControl
} from '@material-ui/core';
import { maxHeight } from '@mui/system';

const useStyles = makeStyles({
  layoutRoot: {},
  inputRoot: {
    height: '20px',
    'min-height': '20px'
  },
  input: {
    margin: '0',
    'font-size': '12px',
    'padding-top': '4px',
    'padding-left': '6px'
  },
  endAdornment: {
    'margin-top': '4px'
  },
  listbox: {
    'font-size': '12px',
    'font-family': 'Nunito Sans ,sans-serif'
  },
  noOptions: {
    'font-size': '12px',
    'font-family': 'Nunito Sans ,sans-serif'
  }
});

const FilterCell = (props) => {
  const classes = useStyles();
  const context = useContext(FilterRowContext);
  const { fieldValues } = props;
  const updateState = (values) => {
    if (context?.onFieldValueChange) {
      context.onFieldValueChange(values);
    }
  };

  const updateValues = (cellValues) => {
    updateState(cellValues);
  };

  const handleTextFieldChange = (event) => {
    const label = event.target.value;
    updateValues([{ value: label, label: label }]);
  };

  const handleAutoCompleteFieldChange = (event, newValue) => {
    if (newValue) {
      updateValues([{ value: newValue, label: newValue }]);
    } else {
      updateValues([]);
    }
  };

  const renderField = (fieldType, operator) => {
    if (fieldValues && props.value in fieldValues && (operator == 'EQ' || operator == 'NE')) {
      return <FormControl>
        <Autocomplete
          id={`filter_${context.cardIndex}_row_${context.rowIndex}_fieldValue`}
          className="text-11 chart-builder-value-select"
          classes={{ inputRoot: classes.inputRoot, input: classes.input, endAdornment: classes.endAdornment, listbox: classes.listbox, noOptions: classes.noOptions }}
          fullWidth
          value={props.values?.[0]?.value || ''}
          // value={props.values?.[0]?.value || fieldValues[props.value]?.[0]}
          options={fieldValues[props.value]}
          onChange={handleAutoCompleteFieldChange}
          renderInput={(params) => <TextField {...params} placeholder="--Select--" variant="outlined" />}>
        </Autocomplete>
      </FormControl>
    }
    switch (fieldType) {
      case 'text': {
        return (
          <TextField
            id={`filter_${context.cardIndex}_row_${context.rowIndex}_fieldValue`}
            name={`filter_${context.cardIndex}_row_${context.rowIndex}_fieldValue`}
            style={{ width: 50 }}
            disabled={props.disabled}
            value={props.values?.[0]?.value || ''}
            onChange={handleTextFieldChange}
          />
        );
      }
      case 'number': {
        return (
          <TextField
            id={`filter_${context.cardIndex}_row_${context.rowIndex}_fieldValue`}
            name={`filter_${context.cardIndex}_row_${context.rowIndex}_fieldValue`}
            disabled={props.disabled}
            style={{ width: 50, textAlign: 'right' }}
            value={props.values?.[0]?.value || ''}
            onChange={handleTextFieldChange}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      }
      case 'date': {
        return (
          <TextField
            id={`filter_${context.cardIndex}_row_${context.rowIndex}_fieldValue`}
            name={`filter_${context.cardIndex}_row_${context.rowIndex}_fieldValue`}
            style={{ width: 50 }}
            disabled={props.disabled}
            value={props.values?.[0]?.value || ''}
            onChange={handleTextFieldChange}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderCell = () => {
    const { fieldType, operatorOptions, operator } = props;

    // check operator has field Type, if yes then render the value component based on operator field type
    const currentOperator = operatorOptions?.find((option) => {
      return option.value == operator;
    });

    switch (operator) {
      case 'EQ':
      case 'NE': {
        return renderField(currentOperator?.fieldType || fieldType, operator);
      }
      default: {
        return renderField(currentOperator?.fieldType || fieldType, operator);
      }
    }
  };

  return renderCell();
};

export default FilterCell;
