import {
  IconButton,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions
} from "@material-ui/core";
import InfoRounded from "@material-ui/icons/InfoRounded";
import { closeDialog } from "app/store/fuse/dialogSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const ContinueRegistrationPopup = (props) => {
  const { callback } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    setTimeout(() => {
      dispatch(closeDialog());
      callback();
    }, 1500);
  }, []);
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            color="primary"
            aria-label="delete role"
            component="span"
            size="small"
          >
            <InfoRounded fontSize="large" />
          </IconButton>
          <div>Complete Registration</div>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">
          You are in the process of registration. You will be redirected to
          Account Setup screen.
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", padding: "22px",height:'20px' }}></DialogActions>
    </>
  );
};
