import { combineReducers } from '@reduxjs/toolkit';
import dateRange from './dateRangeSlice';
import toggleFlagToolBar from './toolBarSlice'

const reducer = combineReducers({
  dateRange,
  toggleFlagToolBar

});

export default reducer;
