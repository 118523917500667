import OverviewConfig from "./behaviour-analysis/overview/OverviewConfig";
import UserJourneyConfig from "./behaviour-analysis/user-journey/UserJourneyConfig";
import ExploreConfig from "./explore/ExploreConfig";
import ChartBuilderConfig from "./chart-builder/ChartBuilderConfig";
import KpiBuilderConfig from "./kpi-builder/KpiBuilderConfig";
import DashboardConfig from "./dashboard/DashboardConfig";
import HeatMapConfig from "./behaviour-analysis/heatmap/HeatMapConfig";
import PinnedDashboardConfig from "./pinned-dashboard/PinnedDashboardConfig";
import SharedDashboardConfig from "./shared-dashboard/SharedDashboardConfig";
import FunnelAnalysisConfig from "./behaviour-analysis/funnel/FunnelAnalysisConfig";
import SocialListeningConfig from "./social-listening/SocialListeningConfig";
import CustomizeConfig from "./customize/CustomizeConfig";
import TrackEventsConfig from "./track-events/TrackEventsConfig";
import CustomEventMappingConfig from "./custom-event/CustomEventMappingConfig";
import DynamicInsightConfig from "./dynamic-insight/DynamicInsightConfig";
import DigitalAssitantConfig from './telegram-integration/DigitalAssitantConfig';

const appsConfigs = [
  //explore
  ExploreConfig,
  ChartBuilderConfig,
  KpiBuilderConfig,
  // dashboard
  DashboardConfig,
  PinnedDashboardConfig,
  SharedDashboardConfig,
  CustomizeConfig,
  // behaviour-analysis
  UserJourneyConfig,
  FunnelAnalysisConfig,
  // OverviewConfig,
  HeatMapConfig,
  // social-listening
   SocialListeningConfig,
  DynamicInsightConfig,
  CustomEventMappingConfig,
  TrackEventsConfig,
  DigitalAssitantConfig
];

export default appsConfigs;
