import withReducer from 'app/store/withReducer';
import reducer from './store';
import AppBar from '@material-ui/core/AppBar';
import { Grid, Button } from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import {
  fetchKpis,
  updateUserKpis,
} from 'app/main/app/dashboard/store/kpisSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import {
  fetchCharts
} from '../../../main/app/dashboard/store/chartsSlice';
import {
  setKPIOrderChanged,
  setChartsOrderChanged,
} from '../../../main/app/dashboard/store/dashboardSlice';
import SearchBar from 'material-ui-search-bar';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
  },
  btn: {
    margin: `2px ${theme.spacing(2)}px`,
  },
  searchInput: {
    width: '40%',
    left: '20px',
  },
  newButton: {
    position: 'absolute',
    right: '60px',
  },
  button: {
    position: 'absolute',
    right: '100px',
    padding: '20px',
  },
  power: {
    position: 'absolute',
    right: '5px',
  },
  headHeader: {
    border :'1px solid #EBEBEB',
  //  boxShadow: '0px 2px 5px #ccc',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  largeBtn: {
    width: '140px',
    height: '50px',
    marginLeft: '20px',
  },
  formroot: {
    '& .MuiFormControl-root': {
      width: '80%',
      margin: theme.spacing(1),
    },
  },
  selectedButton: {
    backgroundColor: ' #25303E',
    color: 'white',
    '&:hover': {
      backgroundColor: ' #25303E', // "#D3D3D3"
      color: 'white',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ToolbarLayout1(props) {
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const navbar = useSelector(({ fuse }) => fuse.navbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const dispatch = useDispatch();
  const title = props.title;
  const classes = useStyles();

  let pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 7);
  const [open, setOpen] = useState(false);
  const [createChartflag, setCreateChartflag] = useState(false);
  const [age, setAge] = useState('');

  const [charttitle, setChartTitle] = useState('');
  const [chartType, setChartType] = useState('');
  const dashboardPageState = useSelector((state) => state.dashboard.page);
  const user = useSelector((state) => state.auth.user);
  const [snakBaropen, setSnakBarOpen] = useState(false);
  const today = new Date();
  const todayForm = today.toISOString().substring(0, 10);
  const [value, setValue] = useState('female');

  const [toDate, setToDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayForm = yesterday.toISOString().substring(0, 10);
  let dayBefore = new Date();
  dayBefore.setDate(dayBefore.getDate() - 1);

  let week = new Date();
  week.setDate(week.getDate() - 7);
  const weekForm = week.toISOString().substring(0, 10);

  const [selectedDateStart, setSelectedStartDate] = useState(
    'Thu Apr 22 2021 19:39:00 GMT+0200 (Central European Summer Time)'
  );
  const [selectedDateEnd, setSelectedEndDate] = useState(today);
  const [selectedTodayButtonFlag, setselectedTodayButtonFlag] = useState(false);
  const [
    selectedYesterdayButtonFlag,
    setselectedYesterdayButtonFlag,
  ] = useState(false);
  const [selectedWeekButtonFlag, setselectedWeekButtonFlag] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleStartDateChange = (e) => {
    setselectedWeekButtonFlag(false);
    setselectedYesterdayButtonFlag(false);
    setselectedTodayButtonFlag(false);
    setFromDate(e.toISOString().substring(0, 10));
    setSelectedStartDate(e);
    console.log('Date format' + e);
    console.log('fromDate :' + fromDate);
    dispatch(
      fetchKpis({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate: e.toISOString().substring(0, 10),
        toDate,
      })
    );
    dispatch(
      fetchCharts({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate: e.toISOString().substring(0, 10),
        toDate,
      })
    );
  };

  const handleEndDateChange = (e) => {
    setToDate(e.toISOString().substring(0, 10));
    setSelectedEndDate(e);
    console.log(toDate);
    dispatch(
      fetchKpis({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate,
        toDate: e.toISOString().substring(0, 10),
      })
    );
    dispatch(
      fetchCharts({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate,
        toDate: e.toISOString().substring(0, 10),
      })
    );
  };

  const changeToday = () => {
    setselectedTodayButtonFlag(true);
    setselectedYesterdayButtonFlag(false);
    setselectedWeekButtonFlag(false);
    dispatch(
      fetchKpis({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate: todayForm,
        toDate: todayForm,
      })
    );
    dispatch(
      fetchCharts({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate: todayForm,
        toDate: todayForm,
      })
    );
  };
  const changeYesterday = () => {
    setselectedYesterdayButtonFlag(true);
    setselectedTodayButtonFlag(false);
    setselectedWeekButtonFlag(false);
    dispatch(
      fetchKpis({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate: yesterdayForm,
        toDate: yesterdayForm,
      })
    );
    dispatch(
      fetchCharts({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate: yesterdayForm,
        toDate: yesterdayForm,
      })
    );
  };
  const changeWeek = () => {
    setselectedWeekButtonFlag(true);
    setselectedYesterdayButtonFlag(false);
    setselectedTodayButtonFlag(false);
    dispatch(
      fetchKpis({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate: weekForm,
        toDate: todayForm,
      })
    );
    dispatch(
      fetchCharts({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        fromDate: weekForm,
        toDate: todayForm,
      })
    );
  };
  const handleChangeRadio = (event) => {
    setValue(event.target.value);
  };
  const handleChartCreation = () => {
    setCreateChartflag(true);
    //handleChartDataCreation;
  };

  const handleSavePreferences = () => {
    if (dashboardPageState.hasKPIOrderChanged) {
      dispatch(updateUserKpis(dashboardPageState.updatedKPIList))
        .then((unwrapResult) => {
          dispatch(setKPIOrderChanged({ flag: false, list: [] }));
          setSnakBarOpen(true);
        })
        .catch(() => {});
    }
    if (dashboardPageState.hasChartsOrderChanged) {
      // TODO: uncomment this code after implement the sevice api
      /*
      dispatch(updateUserCharts(dashboardPageState.updatedChartList))
        .then((unwrapResult) => {
          dispatch(setChartsOrderChanged({ flag: false, list: [] }));
          setSnakBarOpen(true);
        })
        .catch(() => { });
        */

      // comment below lines after service api implementation
      dispatch(setChartsOrderChanged({ flag: false, list: [] }));
      setSnakBarOpen(true);
    }
  };

  const handleChartDataCreation = {
    series: [
      {
        name: 'Desktops',
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: chartType,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: charttitle,
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
        ],
      },
    },
  };

  const handleSnakBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnakBarOpen(false);
  };

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx(
          classes.root,
          'flex relative z-20 shadow-md',
          props.className
        )}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
        position="static"
      >
        <Toolbar
          className={clsx(classes.headHeader, 'p-0 min-h-48 md:min-h-64')}
        >
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden mdDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">
           <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden mdDown>
                {!navbar.open && (
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                )}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>

        {title === 'Footprint Map' ? (
          <Toolbar className="p-0 min-h-48 md:min-h-48">
            <Grid container>
              <Grid item className=" flex w-full sm:w-2/3 p-20">
                <h3>{title}</h3>
              </Grid>
              <Grid className="flex p-10">
                <SearchBar placeholder="Search Url" />
                <div>
                  <Button
                    className={classes.largeBtn}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<FontAwesomeIcon icon={faFilter} />}
                    onClick={handleClickOpen}
                  >
                    <h4>Filter</h4>
                  </Button>
                </div>
              </Grid>

              <Dialog
                fullWidth={true}
                className="w-full"
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Add Filters</DialogTitle>
                <DialogContent>
                  <div className="w-2/5 flex flex flex-col">
                    <div dialogwidth>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={value}
                          onChange={handleChangeRadio}
                        >
                          <FormControlLabel
                            value="Today"
                            control={<Radio />}
                            label="Today"
                          />
                          <FormControlLabel
                            value="Yesterday"
                            control={<Radio />}
                            label="Yesterday"
                          />
                          <FormControlLabel
                            value="Last 7 days"
                            control={<Radio />}
                            label="Last 7 days"
                          />
                        </RadioGroup>
                        <br></br>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <span>
                            <b>
                              <h4>Select Dates</h4>
                            </b>
                          </span>
                          <br></br>
                          <span>From</span>
                          <br></br>
                          <DatePicker
                            value={selectedDateStart}
                            onChange={handleStartDateChange}
                          />
                          <br></br>
                          <span> To </span>
                          <br></br>
                          <DatePicker
                            value={selectedDateEnd}
                            onChange={handleEndDateChange}
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </div>

                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={handleClose} color="primary">
                    Save
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Toolbar>
        ) : (
          title && (
            <Toolbar className="p-0 min-h-48 md:min-h-48">
              <Grid container alignItems="center">
                <Grid item className="p-20 flex w-full sm:w-1/4 p-16">
                  <h3>{title}</h3>
                </Grid>

                <Grid item className="p-10 flex w-full">
                  <Button
                    className={`${
                      selectedTodayButtonFlag
                        ? classes.selectedButton
                        : classes.btn
                    }`}
                    onClick={changeToday}
                  >
                    Today
                  </Button>
                  <Button
                    className={`${
                      selectedYesterdayButtonFlag
                        ? classes.selectedButton
                        : classes.btn
                    }`}
                    onClick={changeYesterday}
                  >
                    Yesterday
                  </Button>
                  <Button
                    className={`${
                      selectedWeekButtonFlag
                        ? classes.selectedButton
                        : classes.btn
                    }`}
                    onClick={changeWeek}
                  >
                    Last 7 Days
                  </Button>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={selectedDateStart}
                      onChange={handleStartDateChange}
                      className="p-10"
                      maxDate={today}
                    />
                    <span className="p-20"> to </span>
                    <DatePicker
                      value={selectedDateEnd}
                      onChange={handleEndDateChange}
                      className="p-10"
                      maxDate={today}
                    />
                  </MuiPickersUtilsProvider>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<SaveIcon />}
                    onClick={handleSavePreferences}
                    disabled={
                      !dashboardPageState.hasKPIOrderChanged &&
                      !dashboardPageState.hasChartsOrderChanged
                    }
                  >
                    Save Preferences
                  </Button>
                </Grid>
              </Grid>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snakBaropen}
                autoHideDuration={1500}
                onClose={handleSnakBarClose}
              >
                <Alert onClose={handleSnakBarClose} severity="success">
                  Preferences Save Successfully.
                </Alert>
              </Snackbar>
            </Toolbar>
          )
        )}
      </AppBar>
    </ThemeProvider>
  );
}

export default withReducer('toolbarlayout1', reducer)(ToolbarLayout1);
