//import Card from '@material-ui/core/Card';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { memo } from 'react';
import _ from '@lodash';
import ReactApexChart from 'react-apexcharts';
import getChartConfig from './ChartHelper';
import FunnelChart from '../behaviour-analysis/funnel/components/FunnelChart';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
});

function ChartContent(props) {
  const theme = useTheme();
  const data = _.merge({}, props.data);
  const state = getChartConfig(data, props.type, props.groupThenBy, props.multipleSegment, props.dateRange);
  /*state.config.options.legend = {
    formatter: function (val, opts) {
      return (
        state.config.series[opts.seriesIndex] +
        ' - ' +
        opts.w.globals.series[opts.seriesIndex]
      );
    },
  };*/

  return (
    <>
      {props.type != 'funnel' ? <ReactApexChart
        options={state.config.options}
        series={state.config.series}
        type={state.type}
        height={props.height || 380}
        width={props.width}
      /> :
        <FunnelChart
          funnelData={props.data.series}
          handleBar={() => {}}
          isUser2={props.data.thenBy || false}
          showEvent={props.data.groupBy || []}
          days={props.data.dateRange}
          filters={props.data.filters || []}
          isPin={true}
          stacks={[]}
          stackBy={props.data.compareBy || {}}
          isStacked={Object.entries(props.data.compareBy ||  {}).filter((val) => props.data.compareBy[val[0]]).length !== 0}

        />
      }
    </>
  );
}

export default ChartContent;
