import { useTheme } from '@material-ui/core/styles';
import _ from '@lodash';
import { memo, useState } from 'react';
import KPIContent from './KPIContent';
import { Grid, makeStyles, Collapse, CardContent } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import Chart from '../../../Charts/Chart';
import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 345,
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	avatar: {
		backgroundColor: red[500],
	},
}));

const options = [
	'Unpin',
	'Show in %',
];



const cardData = [
	{
		title: "Overall Uniques",
		value: "435K",
		date: "Since Jul 28",
	},
	{
		title: " ",
		value: "128 K",
		date: "yesterday",
	},
	{
		title: "Current Values",
		value: "< 0.1%",
		date: "from Aug 25"
	}, {
		title: " ",
		value: "< 0.1%",
		date: "from Jul 28"
	}
]

function KPIWidget(props) {
	const theme = useTheme();
	const [expanded, setExpanded] = useState(false);
	const classes = useStyles();
	const style = {
		"backgroundColor": "#FFFFFF",
		"borderRadius": "5px soild #000000",
		"width": "345%",
		"zIndex": "444",
		"position": "absolute",
		"left": "2rem",
		"right": "1rem",
		"top": "43rem"
	}
	const kpidata = _.merge({}, props.kpidata);
	const handleExpandClick = () => {
		// setExpanded(!expanded);
	};
	return (
		<>
			<KPIContent kpidata={kpidata} handleExpandClick={handleExpandClick} expanded={expanded} deleteKPI= {props.deleteKPI} ></KPIContent>
			<div style={style} >
				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<CardContent>
						<Grid container>
							<br />
							{
								cardData.map((card, index) => {
									return (
										<Grid xs={1} key={index} stle={{ "marginLeft": "1rem" }}>
											<p>{card.title}</p>
											<h3>{card.value}</h3>
											<p>{card.date}</p>
										</Grid>
									)
								})
							}
						</Grid>
						{
							<Chart type={kpidata.data.type} data={kpidata.data}></Chart>
						}
					</CardContent>
				</Collapse>
			</div>
		</>
	);
}

export default memo(KPIWidget);