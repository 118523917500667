import { Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  p: (props) => {
    const { isActive } = props;
    return {
      textDecoration: isActive ? "underline" : "",
      cursor: isActive ? "" : "pointer",
    };
  },
}));

export const StyledTypography = (props) => {
  const { isActive, name, itemKey, onChange } = props;
  const classes = useStyles({ isActive });
  return (
    <Typography
      className={classes.p}
      color={isActive ? "textPrimary" : "textSecondary"}
      onClick={() => {
        onChange(itemKey);
      }}
    >
      {name}
    </Typography>
  );
};
