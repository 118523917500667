
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { memo } from 'react';
import _ from '@lodash';
import { Grid, makeStyles } from '@material-ui/core';
import TrendUPIcon from '@material-ui/icons/TrendingUp';
import TrendDownIcon from '@material-ui/icons/TrendingDown';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardActions from '@material-ui/core/CardActions';
import clsx from 'clsx';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core'
import Icon from '@material-ui/core/Icon';
import Input from '@material-ui/core/Input';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { selectKpisEntities } from 'app/main/app/dashboard/store/kpisSlice';
import { useDispatch, useSelector } from 'react-redux';
import ReactApexChart from 'react-apexcharts';

import { red } from '@material-ui/core/colors';

import Chart from '../../../Charts/Chart';

import CallMadeRoundedIcon from '@material-ui/icons/CallMadeRounded';
import CallReceivedRoundedIcon from '@material-ui/icons/CallReceivedRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const options = [
  {
    label: 'Edit',
    value: 1
  },
  {
    label: 'Delete',
    value: 2
  }
];

function KPIContent(props) {
  const { expanded, deleteKPI } = props;
  const classes = useStyles();
  // const theme = useTheme();
  const kpidata = _.merge({}, props.kpidata);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const history = useHistory();


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (label, dashboardId, chartId) => {
    handleClose();
    if (label === 'Edit') {
      localStorage.setItem(
        'lac-kpi-dashboardInfo',
        JSON.stringify(kpidata.dashboardInfo)
      );
      localStorage.setItem(
        'lac-kpi-chartsDetails',
        JSON.stringify(kpidata.chartsDetails)
      );
      history.push('/kpibuilder/' + dashboardId + '/' + chartId);
    }
    else if (label === 'Delete') {
      if (deleteKPI) {
        deleteKPI(dashboardId, chartId);
      }
    }
  };

  const ITEM_HEIGHT = 48;

  // when previousRecordCount is 0 and filteredRecordCount is not 0 then display as 100% else display as 0%
  const percentage =  kpidata?.data?.previousRecordCount === 0 ? ( kpidata?.data?.filteredRecordCount === 0 ? 0 : 100) : Math.round(( kpidata?.data?.filteredRecordCount -  kpidata?.data?.previousRecordCount) * 100 /  kpidata?.data?.previousRecordCount);

  // const percentage = kpidata?.data?.previousRecordCount === 0 ? 0 : Math.round((kpidata?.data?.filteredRecordCount - kpidata?.data?.previousRecordCount) * 100 / kpidata?.data?.previousRecordCount);

  return (
    <>
      <div>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >
          {options.map((el, i) => (
            <MenuItem key={i} onClick={e => handleMenuItemClick(el.label, kpidata.dashboardId, kpidata.data.chartId)} value={el.value}>{el.label}</MenuItem>
          ))}
        </Menu>

        <Card className={classes.root} style={{ "borderRadius": "5px" }}>
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon onClick={handleClick} />
              </IconButton>
            }
            title={kpidata?.data?.filteredRecordCount}
            style={{ "paddingBottom": "0", "paddingTop": "5px" }}
          />
          <CardActions>
            <span style={{ "fontSize": "15px" }}>
              {kpidata?.data?.chartTitle}
            </span>
            <span style={{ "fontSize": "15px", "color": `${percentage > 0 ? "green" : "red"}`, "marginLeft": "auto" }}>
              {percentage}%
            </span>
            <span
              style={{ "border": "1px soild" }}
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={props.handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              { percentage > 0 &&
                <CallMadeRoundedIcon fontSize="small" style={{ "color": "green" }} />
              }
              { percentage <= 0 &&
                <CallReceivedRoundedIcon fontSize="small" style={{ "color": "red" }} />
              }
            </span>
          </CardActions>
          <CardContent style={{ "padding": "0" }}>
            {
              <Chart type={kpidata.data.type} data={kpidata.data} height={60} width={"100%"}></Chart>
            }
          </CardContent>
        </Card>
      </div>
    </>
  );
}

export default memo(KPIContent);