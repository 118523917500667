import {
  RadioGroupFormsy,
  SelectFormsy,
  TextFieldFormsy,
} from "@fuse/core/formsy";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  FormControlLabel,
  FormControl,
  MenuItem,
  Radio,
  Grid,
  Button,
  // TextField,
  // Dialog,
} from "@material-ui/core";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import { useSelector} from "react-redux"
import Formsy from "formsy-react";
import { useEffect, useRef, useState } from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import 'intro.js/introjs.css';
import introJs from 'intro.js';

const useAccountSetupStyles = makeStyles({
  root: {
    width: "75%",
  },
});

const MARKETING_AGENCY = "Marketing Agency";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function CompanyDetails(props) {
  const {
    Country,
    // Role,
    Traffic,
    Platform,
    onFormSubmission,
    accountSetupData,
    accountSetupDisabled,
  } = props;
  const history = useHistory();
  const BusinessType = props["Business Type"];
  const OrganizationType = props["Organization Type"];
  const [organisationType, setOrganizationType] = useState(
    accountSetupData?.organisationType
      ? accountSetupData.organisationType
      : MARKETING_AGENCY
  );
  const [isFormValid, setIsFormValid] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [url, setUrl] = useState();
  const [validateUrl, setValidateUrl] = useState(false);
  const [open, setOpen] = useState(false);
  const [adminUser, setAdminUser] = useState(null)
  const formRef = useRef(null);
  const intro = introJs();
  const productTour = (e) => {
    intro.setOptions({ steps });
    intro.start();
  };
  const steps = [
    {
      element: '#companyDetailsForm',
      intro: 'Add in your company\'s information to setup your account ',
      position: 'top',
    },
    {
      element: '#organisationTypeSelection',
      intro: 'Select your organistation type ',
      position: 'top',
    },
    {
      element: '#companyNameTxt',
      intro: ' Enter your company Name. It is a mandatory field',
      position: 'top',
    },
    {
      element: '#companyUrlTxt',
      intro: 'Please ensure that the URL provided is accurate as it cannot be changed once registration is complete. It\'s essential to double-check  the URL before proceeding with the registration.',
      position: 'top',
    },
    {
      element: '#businessTypeSelection',
      intro: ' Select your business field or industry from the available options. This step allows you to specify the area in which your business operates,ensuring a more tailored experience ',
      position: 'top',
    },
    {
      element: '#continueButton',
      intro: 'After entering the required information, validate your URL and click  on the "Continue" button.',
      position: 'top',
    }
  ];

  useEffect(() => {
    const user = JSON.parse(
      localStorage.getItem("lac-user-details")
    );
    if (user?.user_role === "Admin") {
      setAdminUser(user?.user_role)
    }
  }, [])

  const [msgOpen, setMsgOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('Error');
  const classes = useAccountSetupStyles();
  useEffect(() => {
    if (accountSetupData?.organisationType) {
      setOrganizationType(accountSetupData.organisationType);
      setIsDisabled(true);
    }
  }, [accountSetupData]);
  function disableButton() {
    setIsFormValid(false);
  }

  function enableButton() {
    // setIsFormValid(true);
  }
  const handleDialogClose = () => {
    setOpen(false);
  };
  const handleDialogConfirm = () => {
    setOpen(false);
    setIsFormValid(true);
  }
  const handleMsgClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMsgOpen(false);
  };
  const handleUrlValidation = () => {
    // console.log("inside url validation");
    if (/^(http(s?):\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g.test(url)) {
      setOpen(true);
    } else {
      setMsgOpen(true);
      setAlertMessage('The entered company url is not valid.Please enter a correct url');
      setOpen(false);
    }
  }

  return (
    <div>
      <>
        <Dialog
          fullWidth={true}
          className="w-full"
          open={open}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Confirmation Required
          </DialogTitle>
          <DialogContent>
            <div className="flex flex flex-col">
              <div dialogwidth>
                <FormControl component="fieldset">
                  <label>
                    Please click the entered URL and confirm if the entered URL is
                    valid.
                  </label>
                  <br />
                  <div>
                    <a
                      rel="noreferrer"
                      // href={protocol + '://' + url}
                      href={url}
                      target="_blank" style={{ width: "auto" }}
                    >
                      <span
                        style={{
                          color: "#22d3ee",
                          textDecoration: "none",
                          backgroundColor: "white"
                        }}
                      >
                        {url}
                      </span>
                    </a>
                  </div>
                </FormControl>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={handleDialogConfirm} variant="contained">
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
      <Formsy
        onValidSubmit={onFormSubmission}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="flex flex-col justify-center"
        disabled={accountSetupDisabled}
        id="companyDetailsForm"
        onChange={(currentValues) => {
          setOrganizationType(currentValues.organisationType);
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <p className="ml-10">What best describes your organisation?</p>
            <RadioGroupFormsy
              className={`m-10`}
              name="organisationType"
              // label="What best describes your organisation?"
              value={organisationType}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="flex-start"
                id="organisationTypeSelection"
              >
                {OrganizationType &&
                  OrganizationType.map((item) => (
                    <FormControlLabel
                      value={item}
                      control={<Radio color="primary" />}
                      label={item}
                      disabled={isDisabled}
                    />
                  ))}
              </Grid>
            </RadioGroupFormsy>
          </Grid>
          <Grid item xs={12} md={6}>
            {adminUser === "Admin" ? (
              <TextFieldFormsy
                className={classes.root}
                type="text"
                name="role "
                disabled
                label="Admin"
                value={adminUser}
              />
            ) : (
              <TextFieldFormsy
                className={classes.root}
                type="text"
                name="role "
                disabled
                label="Admin"
                value="Admin"
              />
            )}
          </Grid>
          {organisationType == MARKETING_AGENCY && (
            <>
              <Grid item xs={12} md={6}>
                <TextFieldFormsy
                  className={`m-10 ${classes.root}`}
                  type="text"
                  name="agencyName"
                  label="Agency Name"
                  disabled={accountSetupData?.agencyName ? true : false}
                  value={accountSetupData?.agencyName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextFieldFormsy
                  className={`m-10 ${classes.root}`}
                  type="text"
                  name="agencyUrl"
                  label="Agency URL"
                  disabled={accountSetupData?.agencyUrl ? true : false}
                  value={accountSetupData?.agencyUrl}
                />
              </Grid>
            </>
          )}

          <>
            <Grid item xs={12} md={6}>
              <TextFieldFormsy
                className={`m-10 ${classes.root}`}
                type="text"
                name="companyName"
                id="companyNameTxt"
                value={accountSetupData?.companyName}
                required
                label="Company Name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldFormsy
                className={`m-10 ${classes.root}`}
                type="text"
                id="companyUrlTxt"
                name="companyUrl"
                label="Company URL"
                required
                value={accountSetupData?.companyUrl}
                // validationError="Please add https or htpps://www as prefix "
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">https://</InputAdornment>
                //   ),
                // }}
                placeholder="https://"
                onChange={(e) => {
                  setUrl(e.target.value);
                  if (e.target.value.length > 0) {
                    setValidateUrl(true);
                    // setIsFormValid(true)
                  }
                }}
              />
              {/* <FormHelperText style={{fontSize:"10px",paddingLeft:"1rem"}}> Please add https or htpps://www as prefix</FormHelperText> */}
              <Button
                variant="contained"
                size="medium"
                style={{ marginTop: "2rem" }}
                disabled={!validateUrl}
                // onClick={() => {
                //   setOpen(true);
                // }}
                onClick={handleUrlValidation}
              >
                validate
              </Button>
              <Snackbar open={msgOpen} autoHideDuration={6000} onClose={handleMsgClose}>
                <Alert onClose={handleMsgClose} severity={alertType}>
                  {alertMessage}
                </Alert>
              </Snackbar>
            </Grid>
          </>

          <Grid item xs={12} md={6} id="businessTypeSelection">
            <SelectFormsy
              className={`m-10 ${classes.root}`}
              name="businessType"
              label="Business Type"
              value={accountSetupData?.businessType}
            >
              {BusinessType &&
                BusinessType.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
            </SelectFormsy>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectFormsy
              className={`m-10 ${classes.root}`}
              name="platform"
              label="Which Platform do you use?"
              id="platformSelection"
              value={accountSetupData?.platform}
            >
              {Platform &&
                Platform.map((item) => (
                  <MenuItem value={item}>{item}</MenuItem>
                ))}
            </SelectFormsy>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectFormsy
              name="traffic"
              label="Traffic"
              value={accountSetupData?.traffic}
              className={`m-10 ${classes.root}`}
            >
              {Traffic &&
                Traffic?.slice()
                  ?.sort((a, b) => {
                    const firstValue = parseInt(a?.split("-")[0]);
                    const nextValue = parseInt(b?.split("-")[0]);
                    return firstValue - nextValue;
                  })
                  ?.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
            </SelectFormsy>
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectFormsy
              name="country"
              label="Country"
              value={accountSetupData?.country}
              className={`m-10 ${classes.root}`}
            >
              {Country &&
                Country.map((item) => <MenuItem value={item}>{item}</MenuItem>)}
            </SelectFormsy>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            style={{
              marginTop: "20px",
              marginLeft: "12px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="submit"
              id="continueButton"
              disabled={!isFormValid}
            >
              Continue
            </Button>
            <Button
              className="ml-10"
              variant="outlined"
              size="lg"
              onClick={() => {
                history.push("/dashboard/default");
              }}
            >
              Discard
            </Button>
            <Button
              className="ml-10"
              variant="contained"
              color="primary"
              onClick={productTour}
            >
              Take a tour
            </Button>
          </Grid>
        </Grid>

      </Formsy>

    </div>
  );
}

export default CompanyDetails;
