import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { bounceIn } from '@amcharts/amcharts4/.internal/core/utils/Ease';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import { makeStyles } from "@material-ui/core/styles";
import Box from '@mui/material/Box';

    am4core.useTheme(am4themes_animated);
    const Item = styled(Paper)(({ theme }) => ({
      ...theme.typography.body2,
      padding: theme.spacing(1),
      // textAlign: 'center',
      color: theme.palette.text.secondary,
    }));
    const useStyles = makeStyles((theme) => ({
      box: {
        height: 100,
        display: "flex",
      },
      spreadBox: {
        justifyContent: "space-around",
        alignItems: "center"
      }
    }));
    
    export default function UserJourneySankey() {
      const classes = useStyles();
        const [startNode, setStartNode] = React.useState('');
        let [newData,setNewData]= React.useState(['']);
        // const [chartType, setChartType] = React.useState('');
        // const [showResults, setShowResults] = React.useState(false)
        
    
    //sankey
        let chart = am4core.create("chartdiv", am4charts.SankeyDiagram);
        let data = [
          {id:1,"from": "Home Page", "to": "Search", "value": 10, "value2": 5},
          {id:2,"from": "Home Page", "to": "Sign Up", "value": 8, "value2": 5},
          {id:3,"from": "Home Page", "to": "New Deals" , "value": 2 , "value2": 5},
          {id:4,"from": "Home Page", "to": null, "value": 1, "value2": 1},
          {id:5,"from": "Search", "to": "Product List", "value": 4, "value2": 2},
          {id:6,"from": "Search", "to": "View Product", "value": 3, "value2": 5},
          {id:7,"from": "Search","to": null, "value": 3, "value2": 1 },
          {id:8,"from": "Sign Up", "to": "View Product", "value": 5, "value2": 6},
          {id:9,"from": "Sign Up", "to": null, "value": 3, "value2": 1},
          {id:10,"from": "Product List", "to": "Similar Product", "value": 3, "value2": 7},
          {id:11,"from": "Product List", "to": null, "value": 1, "value2": 1 },
          {id:12,"from": "View Product", "to": "Read Review", "value": 3, "value2": 5},
          {id:13,"from": "View Product", "to": "Add to Cart", "value": 3 , "value2": 5},
          {id:14,"from":"Read Review" ,"to": "Purchase", "value": 5, "value2": 8},
          {id:15,"from": "Add to Cart", "to": "Purchase", "value": 3 , "value2": 5},
          {id:16,"from": "Product List", "to": "Bestseller", "value": 1 , "value2": 5}
          ];
          const handleChange1 = (event) => {
          //   setChartType(event.target.value)
          //   //setShowResults(true)
          //   if(event.target.value==="Top Path"){
          //     console.log(event.target.value)
          //     setNewData([''])
          //     setShowResults(true)
          //     let chart1 = am4core.create("chartdiv1", am4charts.XYChart);
          //     // Add data
          //     chart1.data = [{
          //       "category": "Home",
          //       "value": 10
          //     }, {
          //       "category": "Search",
          //       "value": 8
          //     }, {
          //       "category": "View Product",
          //       "value": 3
          //     }, {
          //       "category": "Read Review",
          //       "value": 3
          //     }, {
          //       "category": "Purchase",
          //       "value": 3
          //     }];
    
          // var categoryAxis = chart1.xAxes.push(new am4charts.CategoryAxis());
          // categoryAxis.dataFields.category = "category";
          // categoryAxis.renderer.line.strokeOpacity = 1;
          // categoryAxis.renderer.line.strokeWidth = 2;
          // categoryAxis.renderer.line.stroke = am4core.color("#3787ac");
    
          // // Create value axis
          // var valueAxis = chart1.yAxes.push(new am4charts.ValueAxis());
          // valueAxis.renderer.line.strokeOpacity = 1;
          // valueAxis.renderer.line.strokeWidth = 2;
          // valueAxis.renderer.line.stroke = am4core.color("#3787ac");
    
          // // Create series
          // var series = chart1.series.push(new am4charts.ColumnSeries());
          // series.dataFields.valueY = "value";
          // series.dataFields.categoryX = "category";
          // series.name = "Sales";
          //    }
          //   else{
          //     setShowResults(false)
          //   }
             
          }
        const handleChange = (event) => {
          setStartNode(event.target.value);
          //setChartType("User Flow")
          let filterData = data.filter(data=>data.from===event.target.value);
          // filterData.forEach((obj) =>{
          //   if(obj.to===null){
          //   const newRow = {...obj, "linkColor": "#f04545","to":"Drop-off"} 
          //   filterData.push(newRow);
          //   }
          // });
          // let uniqueData= [...filterData.reduce((map,obj) => map.set(obj.id,obj),new Map()).values()];
          // console.log(uniqueData);
         setNewData(filterData);
         //setChartType(va)
          
        };
        chart.data = newData;
        chart.sortBy= "value"
        chart.align="right"
        chart.dataFields.fromName = "from";
        chart.dataFields.toName = "to";
        chart.dataFields.value = "value";
        chart.tooltip.label.fontSize = 15;
        const nodeTemplate = chart.nodes.template;
        nodeTemplate.nameLabel.label.hideOversized = false;
        nodeTemplate.color="#458df0";
        nodeTemplate.width= 30;
        nodeTemplate.nameLabel.label.fontWeight = "400";
        nodeTemplate.propertyFields.hidden = "disabled";
        nodeTemplate.totalOutgoing="total";
        nodeTemplate.level="level";
        nodeTemplate.nameLabel.label.text = "{name}({total})";
        const linkTemplate = chart.links.template;
        linkTemplate.reverseOrder= true;
        linkTemplate.colorMode = "solid";
        linkTemplate.propertyFields.fill = "linkColor";
        linkTemplate.propertyFields.fillOpacity = "linkOpacity";
        linkTemplate.propertyFields.id = "id";
        linkTemplate.propertyFields.showTooltipOn="showTooltipOn"
        linkTemplate.tooltipText="{fromName} → {toName}: [bold]{value}[/] People\n People who [bold]{toName}  \n contributes to [bold]{value2} %[/] convertion rate"
        linkTemplate.maxWidth = 1;
        var labelBullet = chart.links.template.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.propertyFields.text = "labelText";
        labelBullet.propertyFields.locationX = "labelLocationX";
        labelBullet.propertyFields.rotation = "labelRotation";
        labelBullet.label.horizontalCenter = "left";
        labelBullet.label.textAlign = "start";
        labelBullet.label.dy = 40;
        const showNextStep=()=>{
          let chartData= chart.data;
          let uniqueData=[];
          chartData.forEach((nextNode)=>{
            let a = data.filter(data=>data.from===nextNode.to)
            //  if (nextNode.value<=2) {
            //   const newRow = {...nextNode,  "disabled": "true"}
            // //                   
            //                    a.push(newRow);
            //                   console.log(a);
            // }
             if(nextNode.to==null){
              const newRow = {...nextNode, "tooltipText": "{fromName} drop-off:{value}","labelText":"drop-off:{value}"}
              
                               a.push(newRow);
                              console.log(a);
            }
            newData = newData.concat(a)
            console.log(newData);
            uniqueData= [...newData.reduce((map,obj) => map.set(obj.id,obj),new Map()).values()];
           console.log(uniqueData);
          });
          setNewData(uniqueData);
        }
        nodeTemplate.nameLabel.adapter.add("locationX", function(location, target) {
          switch (target.parent.level) {
            case 0:
              return 1;
            case 1:
              return -3;
            case 2:
              return -1;
              case 3:
                return -3;
            default:
              return -2;
          }
        });
        // chart.chartContainer.handleChildAdded=()=>{
        //   console.log('child added');
        // }
          return (
            <div className="App">
              <Grid container   spacing={2}>
                <Grid item xs={12}>
                  <Item>
                  <Box component="span"
                    m={1}
                    className={`${classes.spreadBox} ${classes.box}`}>
                    <FormControl  sx={{ m: 1, minWidth: 300 }}>
                          <InputLabel id="demo-simple-select-helper-label">Start Node</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={startNode}
                            label="Start Node"
                            onChange={handleChange}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"Home Page"}>Home Page</MenuItem>
                            <MenuItem value={"Search"}>Search</MenuItem>
                            <MenuItem value={"Sign Up"}>Sign Up</MenuItem>
                          </Select>
                        </FormControl>
                        <Button justify="right" variant="outlined" onClick={showNextStep} >Show next step</Button>
                  </Box> 
                    </Item>
                  </Grid>
                <Grid item xs={12}>
                  <Item>
                    <Box component="span"
                    m={1}
                    className={`${classes.spreadBox} ${classes.box}`}>
                <ButtonGroup variant="outlined" aria-label="outlined button group">
                  {/* <Button>Custom</Button> */}
                  <Button>Today</Button>
                  <Button>7D</Button>
                  <Button>30D</Button>
                  <Button>3M</Button>
                  {/* <Button>12M</Button> */}
                </ButtonGroup>
                  {/* <FormControl  sx={{ m: 1, minWidth: 300 }}>
                          <InputLabel id="demo-simple-select-helper-label">Choose Type</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={chartType}
                            label="Start Node"
                            onChange={handleChange1}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={"User Flow"}>User Flow</MenuItem>
                            <MenuItem value={"Top Path"}>Top Path</MenuItem>
                          </Select>
                        </FormControl> */}
                
                </Box>
                {/* { showResults ? <div id="chartdiv1"  style={{ width: "80%", height: "400px", margin: "2rem" }}></div> : <div id="chartdiv"  style={{ width: "80%", height: "400px", margin: "2rem" }}></div>} */}
                <div id="chartdiv"  style={{ width: "80%", height: "400px", margin: "2rem" }}></div>
                </Item>
              </Grid>
            </Grid>
              </div>
          );
        }
   
       
    //sec trial
    // import './App.css';
    // import React, { Component } from 'react';
    // import { useRef, useLayoutEffect } from 'react';
    // import * as am4core from "@amcharts/amcharts4/core";
    // import * as am4charts from "@amcharts/amcharts4/charts";
    // import am4themes_animated from "@amcharts/amcharts4/themes/animated";
    // import InputLabel from '@mui/material/InputLabel';
    // import MenuItem from '@mui/material/MenuItem';
    // import FormControl from '@mui/material/FormControl';
    // import Select from '@mui/material/Select';
    // import Button from '@mui/material/Button';
    // am4core.useTheme(am4themes_animated);
    // export default function App() {
    //   const [startNode, setStartNode] = React.useState('');
    //    // Create root and chart
       
    //    let chart = am4core.create("chartdiv", am4charts.SankeyDiagram);
    //     let data = {
    //       "homePage":[
    //     { "from": "Home Page", "to": "Search", "value": 10},
    //     { "from": "Home Page", "to": "Sign Up", "value": 8},
    //     { "from": "Home Page", "to": "New Deals" , "value": 2 },
    //     { "from": "Home Page", "to": null , "value": 1}],
    //     "search":[
    //     { "from": "Search", "to": "Product List", "value": 4},
    //     { "from": "Search", "to": "View Product", "value": 3},
    //     { "from": "Search","to": null, "value": 3 }],
    //     "signUp":[
    //     { "from": "Sign Up", "to": "View Product", "value": 5},
    //     { "from": "Sign Up", "to": null, "value": 3}],
    //     "productList":[
    //     { "from": "Product List", "to": "Similar Product", "value": 3,"value2":""},
    //     { "from": "Product List", "to": null, "value": 1,"value2":"" }],
    //       "viewProduct":[
    //     { "from": "View Product", "to": "Read Review", "value": 3,"value2":"" },
    //     { "from": "View Product", "to": "Add to Cart", "value": 2,"value2":"" }],
        
    //   };
    //   const handleChange = (event) => {
    //     setStartNode(event.target.value);
    //     getNextTo();
    //   };
    //   const getNextTo=()=>{ 
    //   }
    //   chart.data= data[startNode];
    //   chart.sortBy= "value"
    //   chart.align="right"
    //   chart.dataFields.fromName = "from";
    //   chart.dataFields.toName = "to";
    //   chart.dataFields.value = "value";
    //   chart.tooltip.label.fontSize = 12;
      
      
    
    //   const nodeTemplate = chart.nodes.template;
    //   nodeTemplate.nameLabel.adapter.add("locationX", function(location, target) {
    //     switch (target.parent.level) {
    //       case 1:
    //         return -10;
    //       case 2:
    //         return -11;
    //       default:
    //         return 1;
    //     }
    //   });
    //   nodeTemplate.level="level";
    //   nodeTemplate.nameLabel.label.hideOversized = false;
     
    //   nodeTemplate.color="#458df0";
    //   nodeTemplate.nameLabel.label.fontWeight = "400";
    //   nodeTemplate.propertyFields.hidden = "disabled";
    //   nodeTemplate.totalOutgoing="total";
    //   nodeTemplate.nameLabel.label.text = "{name}({totalOutgoing}){level}";
    
    //   const linkTemplate = chart.links.template;
    //   linkTemplate.reverseOrder= true;
    //   linkTemplate.colorMode = "solid";
    //   linkTemplate.propertyFields.fill = 'linkColor';
    //   linkTemplate.propertyFields.fillOpacity = "linkOpacity";
    //   linkTemplate.propertyFields.id = "id";
    //   linkTemplate.propertyFields.showTooltipOn="showTooltipOn"
    //   linkTemplate.propertyFields.tooltipText="tooltipText"
    //   linkTemplate.maxWidth = 1;
    
    //   var labelBullet = chart.links.template.bullets.push(new am4charts.LabelBullet());
    //   labelBullet.label.propertyFields.text = "labelText";
    //   labelBullet.label.horizontalCenter = "left";
    //   labelBullet.label.textAlign = "start";
    //   labelBullet.label.dx = -90;
    //     return (
    //       <div className="App">
    //       <FormControl sx={{ m: 1, minWidth: 190 }}>
    //         <InputLabel id="demo-simple-select-helper-label">Select Start Node</InputLabel>
    //         <Select
    //           labelId="demo-simple-select-helper-label"
    //           id="demo-simple-select-helper"
    //           value={startNode}
    //           label="Start Node"
    //           onChange={handleChange}
    //         >
    //           <MenuItem value="">
    //             <em>None</em>
    //           </MenuItem>
    //           <MenuItem value={"homePage"}>Home Page</MenuItem>
    //           <MenuItem value={"search"}>Search</MenuItem>
    //           <MenuItem value={"signUp"}>Sign Up</MenuItem>
    //         </Select>
            
    //       </FormControl>
    //         <Button variant="outlined" onClick={() => {
    //           let nextData =data.search;
    //           let oldData=data[startNode];
    //           console.log(oldData);
    //           console.log(nextData);
    //           let newData= oldData.concat(nextData)
    //           console.log(newData);
    //           chart.data= newData;
    //   }} >Add Step</Button>
    //         <div id="chartdiv"  style={{ width: "80%", height: "400px", margin: "5rem" }}></div>
    //         </div>
    //     );
    //   }
    
    
    
    
    //1st trial
    
    // import InputLabel from '@mui/material/InputLabel';
    // import MenuItem from '@mui/material/MenuItem';
    // import FormControl from '@mui/material/FormControl';
    // import Select from '@mui/material/Select';
    // am4core.useTheme(am4themes_animated);
    // export default function App() {
    //   const [startNode, setStartNode] = React.useState('');
    //   let newData=[]
    //   const handleChange = (event) => {
    //     setStartNode(event.target.value);
        
    //     let i = inputData.findIndex(obj => obj.from === event.target.value);
    //     console.log(i);
    //   //   chart.events.on("beforedatavalidated", function(ev) {
    //   //   for( i ; i < inputData.length; i++) {
          
    //   //            if (inputData.to=== null) {
    //   //             const newRow = {...inputData, "linkColor": "#f04545"} 
    //   //             newData.push(newRow);
    //   //         }
    //   //         else if(inputData.value <= 2) {
    //   //           const newRow = {...inputData, "linkColor": "#938D88", disabled: true}
    //   //           newData.push(newRow);
    //   //         }
    //   //            else{
    //   //             const newRow = {...inputData, "linkColor": "#938D88"}
    //   //             newData.push(newRow);
    //   //            }
    //   //         }
    //   //         ev.target.data = newData;
    //   //  });
    //   };
      
    //   //const chart = useRef(null);
    //   // const [age, setAge] = React.useState('');
    
    //   // const handleChange = (event) => {
    //   //   setAge(event.target.value);
    //   // };
    //   let inputData= [
    //     { "from": "Home Page", "to": "Search", "value": 10},
    //           { "from": "Home Page", "to": "Sign Up", "value": 8},
    //           { "from": "Home Page", "to": "New Deals" , "value": 2, },
    //           { "from": "Home Page", "to": null , "value": 1,},
              
    //           { "from": "Search", "to": "Product List", "value": 4},
    //           { "from": "Search", "to": "View Product", "value": 3},
    //           { "from": "Search","to": null, "value": 3},
          
    //           { "from": "Sign Up", "to": "View Product", "value": 5},
    //           { "from": "Sign Up", "to": null, "value": 3},
          
    //           { "from": "Product List", "to": "Similar Product", "value": 3},
    //           { "from": "Product List", "to": null, "value": 1},
          
    //           { "from": "View Product", "to": "Read Review", "value": 3},
    //           { "from": "View Product", "to": "Add to Cart", "value": 2},
              
    //           { "from": "View Product", "to": "Bestseller", "value": 3},
    //           { "from": "View Product", "to": null, "value": 2 },
          
    //           { "from":"Read Review" ,"to": "Purchase", "value": 5},
    //           { "from": "Add to Cart", "to": "Purchase", "value": 3 },
    //           { "from": "Product List", "to": "Bestseller", "value": 1 },
    //   ]
    //   useLayoutEffect(() => {
    //     let chart = am4core.create("chartdiv", am4charts.SankeyDiagram);
      
        
    //         chart.data= newData;
    //         chart.dataFields.fromName = "from";
    //         chart.dataFields.toName = "to";
    //         chart.dataFields.value = "value";
            
           
    //     return () => {
    //       chart.dispose();
    //     };
    //   }, []);
    
      // When the paddingRight prop changes it will update the chart
      // useLayoutEffect(() => {
      //     chart.current.paddingRight = props.paddingRight;
      // }, [props.paddingRight]);
      
      
    //   return (
    //     <>
    //     <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
    //         <InputLabel id="demo-simple-select-standard-label">Start Node</InputLabel>
    //         <Select
    //           labelId="demo-simple-select-standard-label"
    //           id="demo-simple-select-standard"
    //           value={startNode||" "}
    //           onChange={handleChange}
    //           label="Start Node"
    //         >
    //           <MenuItem value="">
    //             <em>None</em>
    //           </MenuItem>
    //           <MenuItem value={"Home Page"}>Home Page</MenuItem>
    //           <MenuItem value={"Search"}>Search</MenuItem>
    //           <MenuItem value={"Sign Up"}>Sign Up</MenuItem>
    //         </Select>
    //       </FormControl>
    //       <div id="chartdiv" style={{ width: "70%", height: "400px", margin: "5rem" }}></div>
    //       </>
    //   );
    // }
    
