import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';

i18next.addResourceBundle('en', 'overviewPage', en);
i18next.addResourceBundle('tr', 'overviewPage', tr);
i18next.addResourceBundle('ar', 'overviewPage', ar);

/**
 * Lazy load Example
 */

import React from 'react';

const OverviewConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes: [
        {
            path : '/behaviour-analysis/overview',
            component: React.lazy(() => import('./Overview'))
        }
    ]
};

export default OverviewConfig;


