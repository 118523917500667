import React, { useEffect, useState } from "react";
import "./trafficcount.css"
import { Typography, ListItem, ListItemIcon, Collapse, ListItemText, List, Grid } from "@material-ui/core";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PersonIcon from '@material-ui/icons/Person';
const CheckNullValueCountry=(value)=> {
    if (value == null || value == undefined || value == "") {
        return "Not Set";
    } else {
        return value;
    }
}
function Trafficcount(props) {
    const [open, setOpen] = useState(false);
    const [CollapseIndex, setCollapseIndex] = useState(0);
    const [traffic_count, setTraffic_count] = useState([]);




    useEffect(() => {
        if (props?.realtimeData?.length > 0) {
           let filteredData = props?.realtimeData?.filter((item, index) => {
                return props?.realtimeData?.findIndex(obj => obj.user_id === item.user_id) === index;
            }
            )
            let newArray = []

            filteredData?.map((item) => {
                let country = item.traffic_count.countryList.name;
                let state = item.traffic_count.countryList.co_region;
                let co_region_count = item.traffic_count.countryList.co_region_count;
                let flagImage = item.traffic_count.countryList.flagImage;
                let flagUnicode = item.traffic_count.countryList.flagUnicode;
                let flagSVG = item.traffic_count.countryList.flagSVG;
                let co_region = {
                    "co_region": state,
                    "co_region_count": co_region_count
                }
                let countryObj = {
                    "name": country,
                    "flagImage": flagImage,
                    "flagUnicode": flagUnicode,
                    "flagSVG": flagSVG,
                    "co_region_count": co_region_count,
                    "state": [co_region]
                }




                let index = newArray.findIndex((item) => item.name === country);
                if (index === -1) {
                    newArray.push(countryObj);
                } else {
                    let index2 = newArray[index].state.findIndex((item) => item.co_region === state);
                    if (index2 === -1) {
                        newArray[index].state.push(co_region);
                    } else {
                        newArray[index].state[index2].co_region_count += co_region_count;
                    }
                }
            }

            )
            newArray.map((item) => {
                let count = 0;
                item.state.map((item2) => {
                    count += item2.co_region_count;
                })
                item.co_region_count = count;
            }
            )
            setTraffic_count(newArray);

        }
    }, [props?.realtimeData])

    const handleOpen = (e,index) => {
    // if index is same as open then close it
            setOpen(!open);
            setCollapseIndex(index);
    }

    return (
        <div className="trafficCount">
            <Typography className="trafficCountTitle">Traffic details</Typography>
            <div className='flex flex-row items-center justify-between'>
                <Typography className='font-400 text-5' variant='p'>
                    Country
                </Typography>
                <Typography className='font-400 text-5' variant='p'>
                    Users
                </Typography>

            </div>
            <div className=' mb-4'>

                {
                    traffic_count?.length > 0 && traffic_count.map((item, index) => {
                        return (
                            <List key={index} >
                                <ListItem button onClick={(e) => {
                                    handleOpen(e,index)
                                }} >
                                    <Grid container spacing={1}>
                                        <Grid item xs={6} className="
                                        flex flex-row items-start justify-start
                                        "  >
                                            <ListItemIcon>
                                                <img src={item.flagSVG} width="3%" /> <Typography className='font-500 text-5 pl-2' variant="span"> {item.name == null ? CheckNullValueCountry(item.name) : item.name}</Typography>
                                            </ListItemIcon>
                                        </Grid>
                                        <Grid item xs={6}
                                            className="
                                        
                                        flex flex-row items-end justify-end"
                                        >
                                            <ListItemText primary="" />
                                            {open && (index === CollapseIndex) && (item.name !== null) ? (<> <PersonIcon />{item.co_region_count}<ExpandLess /></>) : (<> <PersonIcon />{item.co_region_count}<ExpandMore /></>)}
                                        </Grid>
                                    </Grid>
                                </ListItem>
                                <div className='trafficScrollContain mb-4'>

                                <Collapse in={open} timeout="auto" unmountOnExit style={
                                    {
                                        height:"100px",
                                        overflow:"auto !important",
                                    }
                                }>
                                        <List component="div" disablePadding>
                                            {
                                                item.state.map((item2, inx) => {
                                                    return (

                                                        <ListItem button key={inx}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={6} className="
                                        flex flex-row items-start justify-start
                                        "   >
                                                                    <ListItemIcon >
                                                                        <Typography className='font-400 text-5' variant='p'>
                                                                            {`${inx + 1} ${item2.co_region}`}
                                                                        </Typography>

                                                                    </ListItemIcon>
                                                                </Grid>
                                                                <Grid item xs={6}
                                                                    className="
                                        
                                                                 flex flex-row items-end justify-end"
                                                                >
                                                                    <ListItemText primary="" />
                                                                    <Typography className='font-400 text-5' variant='p'>
                                                                        <PersonIcon />  {item2.co_region_count}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </ListItem>
                                                    )
                                                })
                                            }
                                        </List>

                                </Collapse>
                                </div>

                            </List>

                        )
                    }
                    )
                }
            </div>

        </div>

    )

}

export default Trafficcount;
