import { DataTable } from '../data-table/DataTable';
import { makeStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Typography,
  TextField
} from '@material-ui/core';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme) => ({
  td: {
    borderBottom: 'none',
  },
}));

export const BusinessInfoList = (props) => {
  const {
    businessAccountInfo,
    roleList,
    getAddedBusiness,
    businessListOptions,
  } = props;
  const [businessList, setBusinessList] = useState([]);
  const classes = useStyles();

  const handleChange = (value, rowIndex) => {
    let newBusinessList = [...businessList];
    if (newBusinessList[rowIndex]) {
      newBusinessList[rowIndex].access_role = value == 'None' ? '' : value;
    }
    setBusinessList(newBusinessList);
    getAddedBusiness(newBusinessList);
  };

  useEffect(() => {
    let businessAccountInfoMap = businessAccountInfo?.reduce((acc, item) => {
      acc[item.current_business] = item.access_role;
      return acc;
    }, {});
    let newBusinessList = businessListOptions?.reduce((acc, item) => {
      let obj = { ...item };
      obj.client_id = item.client_id;
      if (businessAccountInfoMap && !businessAccountInfoMap[item.url]) {
        obj.current_business = item.url;
        obj.access_role = 'None';
      } else {
        obj.current_business = item.url;
        obj.access_role =
          businessAccountInfoMap && businessAccountInfoMap[item.url];
      }
      acc.push(obj);

      return acc;
    }, []);

    if (newBusinessList && businessList) {
      setBusinessList([...businessList, ...newBusinessList]);
      getAddedBusiness(newBusinessList);
    }

    return () => {
      setBusinessList([]);
    };
  }, []);
   
  const getDisabled = val => {
    return{ disabled: val} 
    
  };
  const columns = [
    {
      label: 'Current Business',
      accessor: 'current_business',
      render: (col, rowData, rowIndex) => {
        return (<div>{col}</div>);
      },
    },
    {
      label: 'Access Role',
      accessor: `${props.editProfile ===true ? "user_role" :"access_role"}`,
      render: (col, rowData, rowIndex) => {
  
        return (
          <FormControl variant="standard" style={{ width: '100%' }}>
            {!col && (
              <InputLabel id="demo-simple-select-standard-label">
                Choose Role
              </InputLabel>
            )}
          {col !== "Admin"  ? <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={col ? col : ''}
            {...getDisabled(props.editProfile)}
            onChange={(e) => handleChange(e.target.value, rowIndex)}
          >
            <MenuItem value="none">
              <em>None</em>
            </MenuItem>
         <MenuItem  value={roleList[1]}>{roleList[1]}</MenuItem>
          </Select>:<TextField value={col} disabled/>}
          </FormControl>
        );
      },
    },
  ];

  // const additionalRowRender = () => {
  //   return (
  //     <Button
  //       variant="link"
  //       size="small"
  //       startIcon={<AddIcon />}
  //       onClick={updateGridItems}
  //     >
  //       {businessList.length >= 1 ? "Add another" : "Add Business"}
  //     </Button>
  //   );
  // };

  return (
    <>
      <Typography
        variant="subtitle2"
        style={{ paddingBottom: '20px', fontSize: '12px' }}
      >
        Business
      </Typography>
      <DataTable
        classes={classes}
        columns={columns}
        //    additionalRowRender={additionalRowRender}
        data={businessList}
      />
    </>
  );
};
