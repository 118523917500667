// import i18next from 'i18next';
// import en from './i18n/en';
// import tr from './i18n/tr';
// import ar from './i18n/ar';
import { TextareaAutosize } from "@material-ui/core";

// i18next.addResourceBundle('en', 'funnelAnalysisPage', en);
// i18next.addResourceBundle('tr', 'funnelAnalysisPage', tr);
// i18next.addResourceBundle('ar', 'funnelAnalysisPage', ar);

/**
 * Lazy load Example
 */

import React from 'react';

const FunnelAnalysisConfig = {
    settings: {
        layout: {
            config: {
                toolbar: {
                    display: true,
                    style: 'fixed',
                    position: 'below',
                    title: '',
                }
            }
        },
    },
    routes: [
        {
            path: '/behaviour-analysis/funnel',
            exact: true,
            component: React.lazy(() => import('./components/App'))
        },
        {
            path: '/behaviour-analysis/funnel/:dashboardId/:chartId',
            component: React.lazy(() => import('./components/App'))
        },
    ]
};

export default FunnelAnalysisConfig;

