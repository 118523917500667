import NewPassword from './NewPassword';
import React from 'react';

const NewPasswordConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes  : [
        {
            path     : '/new-password/:token',
            component: React.lazy(() => import('./NewPassword')),
        },
    ],
};
export default NewPasswordConfig;
