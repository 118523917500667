import { Avatar, Typography, Link } from "@material-ui/core";
import { UploadButton } from "app/shared-components/buttons/UploadButton";
import { useState } from "react";
import styled from "styled-components";

const Stack = styled.div`
  display: flex;
  flex-diraction: row;
  justify-content: space-between;
  width: 41%;
`;
export const UserProfilePic = (props) => {
  const [picture, setPicture] = useState();
  const [src, setSrc] = useState();

  const handleProfilePreview = (event) => {
    let picture = event.target.files[0];
    let src = URL.createObjectURL(picture);
    setPicture(picture);
    setSrc(src);
  };

  // change to Grid
  return (
    <Stack> 
      <Typography style={{ lineHeight: "40px" }}>User profile photo</Typography>
      <Avatar alt="Remy Sharp" src={src} />
      <UploadButton onImageSelected={handleProfilePreview} />

      <Link component="button" variant="body2" onClick={()=>setSrc('')}>
        Remove Image
      </Link>
    </Stack>
  );
};
