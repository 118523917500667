import { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Filter, { FilterRows } from './Filter';
import {
  arrangeDataSetWithGroupFilterIndex,
  addNewGroupOrSet,
  editGroupOrSet,
  deleteGroupOrSet,
} from '../util/filterSetHelper';
import _ from '@lodash';
import {
  FormControl,
  Select,
  MenuItem,
  Divider,
  CardContent,
  CardMedia,
  Button,
  ButtonGroup,
  Typography,
} from '@material-ui/core';
import './filter-set.css';

const useStyles = makeStyles({
  layoutRoot: {},
});

const FilterSet = (props) => {
  const classes = useStyles();
  const [dataSet, setDataSet] = useState({
    data: [],
    isValid: false,
    validationMessage: '',
  });

  useEffect(() => {
    if (!props.dataset) {
      setDataSet({
        data: [
          {
            filterRows: null,
            rowJoinOperator: 'and',
            setJoinOperator: 'and',
            isValid: false,
            validationMessage: '',
          },
        ],
        isValid: false,
        validationMessage: '',
      });
    } else {
      setDataSet(props.dataset);
    }
  }, [props.dataset]);

  const joinOperatorOptions = [
    {
      label: 'and',
      value: 'and',
    },
    {
      label: 'or',
      value: 'or',
      isDisabled: true
    },
  ];

  const handleDataChanged = (filterIndex, filter, changeType) => {
    const dataset = { ...dataSet };
    switch (changeType) {
      case 'ADD_NEW_RULE_TO_SET': {
        addNewGroupOrSet(dataset, filterIndex, filter, {
          currentFilterIndex: 1,
        });
        break;
      }
      case 'DELETE_RULE_FROM_SET': {
        deleteGroupOrSet(dataset, filterIndex, filter, {
          currentFilterIndex: 1,
        });
        break;
      }
      case 'ADD_NEW_ROW':
      case 'DELETE_ROW':
      case 'BETWEEN_ROWS_JOIN_OPERATOR':
      case 'JOIN_OPERATOR_CHANGE':
      case 'ACTION_NAME_CHANGE':
      case 'FIELD_NAME_CHANGE':
      case 'OPERATOR_CHANGE':
      case 'FIELD_VALUE_CHANGE': {
        editGroupOrSet(dataset, filterIndex, filter, { currentFilterIndex: 1 });
        break;
      }
    }
    setDataSet(dataset);

    if (props.onChange) {
      props.onChange(dataset, changeType);
    }
  };

  return (
    <>
      <div>
        <FilterSetWrapper
          disabled={props.disabled}
          fieldNames={props.fieldNames}
          fieldValues={props.fieldValues}
          actionNames={props.actionNames}
          joinOperatorOptions={joinOperatorOptions}
          canAddSegment={props.canAddSegment}
          dataSet={dataSet}
          onChange={handleDataChanged}
        />
      </div>
    </>
  );
};

const FilterSetWrapper = (props) => {
  const dataset = props.dataSet;
  arrangeDataSetWithGroupFilterIndex(dataset, {
    currentFilterIndex: 1,
    currentGroupIndex: 1,
  });
  return (
    <>
      {dataset?.data.map((filter, idx) => {
        return (
          <Filter
            key={`filter_${idx}`}
            filterIndex={filter.filterIndex}
            disabled={props.disabled}
            fieldValues={props.fieldValues}
            fieldNames={props.fieldNames}
            actionNames={props.actionNames}
            filterRows={
              filter.filterRows?.filter(
                (item) => item.isDummy === false || item.isDummy === undefined
              ) || []
            }
            isSingleSet={dataset.data.length <= 1}
            joinOperatorOptions={props.joinOperatorOptions}
            onChange={props.onChange}
            rowJoinOperator={filter.rowJoinOperator}
            setJoinOperator={filter.setJoinOperator}
            canAddSegment={props.canAddSegment}
            renderAddSegment={idx === dataset.data.length - 1}
          ></Filter>
        );
      })}
    </>
  );
};

export default FilterSet;
