import Toolbar from "@material-ui/core/Toolbar";
import { ActionBar } from "./ActionBar";

export const EnhancedToolbar = (props) => {
  const {onSearch}=props;
  return (
    <>
      <Toolbar style={{padding:'0px'}}>
        <ActionBar content="Add users, edit, delete and assign roles to them" onSearch={onSearch} />
      </Toolbar>
    </>
  );
};
