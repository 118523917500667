import AccountSetup from './tabs/AccountSetup';
import Box from '@material-ui/core/Box';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectMasterDataEntities,
  fetchOnBoardingMasterData,
} from './store/registerSlice';

function RegisterAccount() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOnBoardingMasterData());
  }, [dispatch]);
  const masterData = useSelector(selectMasterDataEntities);
  return (
    <Box p={10} pt={5}>
      <AccountSetup {...masterData.data} />
    </Box>
  );
}

export default RegisterAccount;
