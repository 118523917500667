import React, { useEffect, useState } from "react";
// import bar chart from chart.js doughnut
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Bar, Doughnut } from "react-chartjs-2";
import Chart from "react-apexcharts";
import "./devicecount.css"
import { Tooltip, Typography } from "@material-ui/core";
import { tr } from "date-fns/locale";
import { current } from "@reduxjs/toolkit";



const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);
const DeviceCounts = (props) => {
    const [usersInLast30Minutes, setUsersInLast30Minutes] = useState();
    const [userPerMinute, setUserPerMinute] = useState()
    const [deviceCategory, setDeviceCategory] = useState({
        deviceTotal: 0,
        totalDesktop: 0,
        totalMobile: 0,
        totalTab: 0,
        desktopPrecentage: 0,
        mobilePrecentage: 0,
        tabPrecentage: 0,
    });
    const [deviceCounts, setDeviceCounts] = useState()
    // ReferenceError: realtimeData is not defined
    useEffect(() => {
        let countForUserId = []
        let filteredData = [];

        if (props?.realtimeData?.length > 0) {


            let userPerMinute = []
            filteredData = props?.realtimeData?.filter((item, index) => {
                return props?.realtimeData?.findIndex(obj => obj.user_id === item.user_id) === index;
            }
            )
            // push user_id in countForUserId
            filteredData.forEach((item) => {
                countForUserId.push(item.user_id)
            })

            filteredData.forEach((item) => {
                //    push in userPerMinute with current_time and user_id
                userPerMinute.push({
                    "user_id": item.user_id,
                    current_time: item.current_time.slice(0, -3),
                })
            })

            // when realtimeData is Change then this useEffect will call and calculate the device count
            if (props?.lastNintyMins?.length > 0) {
                let deviceTotal = 0;
                let totalDesktop = 0;
                let totalMobile = 0;
                let totalTab = 0;
                let lastNinetyMinsData = []
                lastNinetyMinsData = props?.lastNintyMins?.filter((item, index) => {
                    return props?.lastNintyMins?.findIndex(obj => obj.user_id === item.user_id) === index;
                }
                )
                // find all same userid in lastNinetyMinsData which match in countForUserId
                lastNinetyMinsData = lastNinetyMinsData.filter((item) => {
                    return countForUserId.includes(item.user_id)
                })
                for (let i = 0; i < lastNinetyMinsData?.length; i++) {
                    totalDesktop += lastNinetyMinsData[i]?.device_counts.Desktop;
                    totalMobile += lastNinetyMinsData[i]?.device_counts.Mobile;
                    totalTab += lastNinetyMinsData[i]?.device_counts.Tab;
                    deviceTotal = totalDesktop + totalMobile + totalTab;
                }
                let desktopPercentage = Math.round((totalDesktop / deviceTotal) * 100);
                let mobilePercentage = Math.round((totalMobile / deviceTotal) * 100);
                let tabPercentage = Math.round((totalTab / deviceTotal) * 100);

                setDeviceCategory({
                    deviceTotal: deviceTotal,
                    totalDesktop: totalDesktop,
                    totalMobile: totalMobile,
                    totalTab: totalTab,
                    desktopPrecentage: desktopPercentage,
                    mobilePrecentage: mobilePercentage,
                    tabPrecentage: tabPercentage,
                });
            }
            const finalJson = userPerMinute.reduce((acc, item) => {
                const found = acc.find((a) => a.current_time === item.current_time)
                if (found) {
                    found.user_count = found.user_count + 1
                } else {
                    acc.push({
                        ...item,
                        user_count: 1,
                        user_id: item.user_id
                    })
                }
                return acc
            }
                , [])

            setDeviceCounts(filteredData?.length)
            setUsersInLast30Minutes(finalJson)




        }
    }, [props.realtimeData, props?.lastNintyMins]);


    const convertDateFormat=(date)=> {
        let newDate = new Date(date)
        let year = newDate.getFullYear()
        let month = newDate.getMonth() + 1
        let day = newDate.getDate()
        let hours = newDate.getHours()
        let minutes = newDate.getMinutes()
      
        if (month < 10) {
          month = `0${month}`
        }
      
        if (day < 10) {
          day = `0${day}`
        }
      
        if (hours < 10) {
      
          hours = `0${hours}`
        }
      
        if (minutes < 10) {
      
          minutes = `0${minutes}`
        }
        //return 24 hour format
        return `${year}-${month}-${day} ${hours}:${minutes}`
      }


    useEffect(() => {
        //  if usersInLast30Minutes time match with zeroToThirty index then push that user in that index or else push 0
        let zeroToThirty = []
        // store value in array with last thirty minutes
        for (let i = 0; i < 30; i++) {
            // get current time new Date() and subtract 1 minute from it
            let d = new Date();
            d.setMinutes(d.getMinutes() - i);
            let converted = convertDateFormat(d)
            zeroToThirty?.push({
                "current_time": converted,
                "time": i

            })
        }
        let userPerMinute = []
        zeroToThirty?.forEach((item, index) => {
            //  if usersInLast30Minutes time match with zeroToThirty index then push that user in that index or else push 0
            let found = usersInLast30Minutes?.find((a) => a.current_time === item.current_time)
            if (found) {
                userPerMinute.push({
                    "user_count": found.user_count,
                    "time": item.time
                })
            }
            else {
                userPerMinute.push({
                    "user_count": 0,
                    "time": item.time
                })
            }
        })
        // set userPerMinute in opposite order
        userPerMinute = userPerMinute.reverse()
        setUserPerMinute(userPerMinute)
    }, [usersInLast30Minutes])
    return (

        <div className="device-counts-parent pl-6 pt-4 ml-6 mr-6"
        >
            {
                deviceCounts > 0 &&
                <>
                    <Typography className="device-counts-title">User in last 30 minutes</Typography>
                    <Typography className="m-6 font-500 text-2" variant="h4" > {deviceCounts}</Typography>
                    <div className="device-counts">
                        <Typography className="device-counts-value">User per minute</Typography>
                    </div>
                </>
            }
            {
                deviceCounts == 0 &&
                <>
                    <Typography className="device-counts-title">User in last 30 minutes</Typography>
                    <Typography className="m-6 font-500 text-2" variant="h4" > No user Found</Typography>
                    <div className="device-counts">
                        <Typography className="device-counts-value">User per minute</Typography>
                    </div>
                </>
            }
            <div className="device-counts-bar-chart ml-4 mr-4">
                {
                    userPerMinute?.length > 0 && userPerMinute?.map((item, index) => {
                           
                            return (
                                <LightTooltip title={
                                    <React.Fragment>
                                        <Typography color="inherit">{`${item.time} mins ago`}</Typography>
                                        <Typography color="inherit">{`${item.user_count} users`}</Typography>
                                    </React.Fragment>
                                }>
                                    {/* increse height with user */}
                                    <div className="device-counts-bar-chart-line" style={{ height: `${ item.user_count > 0 ? item.user_count * 20 : 0}px` }} title={item.user_count}>
                                    </div>
                                </LightTooltip>
                            )

                    })



                }
            </div>
            {
                deviceCategory?.deviceTotal > 0 && <div className="device-counts-dought">

                    <Chart
                        //    show desktop and mobile percentage in staight line
                        options={
                            {
                                chart: {
                                    stacked: true,
                                    stackType: "100%",
                                    toolbar: {
                                        show: false
                                    }
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: true,
                                        borderRadius: 4
                                    }
                                },
                                lebels:  //  if in charts data is 0 then it will not show in chart
                                    [
                                        deviceCategory?.desktopPrecentage > 0 ? `Desktop ${deviceCategory?.desktopPrecentage}` : "",
                                        deviceCategory?.mobilePrecentage > 0 ? `Mobile ${deviceCategory?.mobilePrecentage}` : "",
                                        deviceCategory?.tabPrecentage > 0 ? `Tab ${deviceCategory?.tabPrecentage}` : "",
                                    ],
                                colors: [
                                    deviceCategory?.desktopPrecentage > 0 ? "#FF4343" : "",
                                    deviceCategory?.mobilePrecentage > 0 ? "#671FFF" : "",
                                    deviceCategory?.tabPrecentage > 0 ? "#FFD682" : "",
                                ],
                                legend: {
                                    show: false,
                                },
                                dataLabels: {
                                    enabled: false,

                                },
                                xaxis: {
                                    categories: [""],
                                    labels: {
                                        show: false
                                    },
                                    axisBorder: {
                                        show: false
                                    },
                                    axisTicks: {
                                        show: false
                                    }
                                },
                                legend: {
                                    position: "bottom",
                                    horizontalAlign: "center",
                                    offsetY: 0,
                                    offsetX: 0,
                                    markers: {
                                        width: 5,
                                        height: 5,
                                        strokeWidth: 0,
                                        strokeColor: '#fff',
                                        fillColors: undefined,
                                        radius: 12,
                                        customHTML: undefined,
                                        onClick: undefined,
                                        offsetX: 0,
                                        offsetY: 0
                                    },
                                }
                            }
                        }
                        height={70}
                        series={[
                            {
                                data: [deviceCategory?.totalDesktop],
                                name: `${deviceCategory?.desktopPrecentage > 0 ? `Desktop ${deviceCategory?.desktopPrecentage}%` : ""}`,
                            },
                            {
                                data: [deviceCategory?.totalMobile],
                                name: `${deviceCategory?.mobilePrecentage > 0 ? `Mobile ${deviceCategory?.mobilePrecentage}%` : ""}`,
                            },
                            {
                                data: [deviceCategory?.totalTab],
                                name: `${deviceCategory?.tabPrecentage > 0 ? `Tab ${deviceCategory?.tabPrecentage}%` : ""}`,
                            },
                        ]
                        }
                        type="bar"
                    />

                </div>
            }



        </div >



    );


}


export default DeviceCounts;

