import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashboardTourFlag:false,
  exploreTourFlag:false,
  chartBuilderTourFlag:false,
  kpiBuilderTourFlag:false,
  trackEventTourFlag:false,
  pinnedDashoardTourFlag:false,
  sharedDashoardTourFlag:false,
  funnelTourFlag:false,
  DITourFlag:false
};


 const productTourSlice = createSlice({
  name: 'productTour',
  initialState,
  reducers: {
    enableDashboardTour: (state, action) => {
      return {
        ...state,
        dashboardTourFlag: true,
      };
    },
    disableDashboardTour: (state, action) => {

      return {
        ...state,
        dashboardTourFlag: false,
      };
    },
    enableTrackEventTour: (state, action) => {
      return {
        ...state,
        trackEventTourFlag: true,
      };
    },
    disableTrackEventTour: (state, action) => {

      return {
        ...state,
        trackEventTourFlag: false,
      };
    },
    enableExploreTour: (state, action) => {
      return {
        ...state,
        exploreTourFlag: true,
      };
    },
    disableExploreTour: (state, action) => {

      return {
        ...state,
        exploreTourFlag: false,
      };
    },
    enableCBTour: (state, action) => {
      return {
        ...state,
        chartBuilderTourFlag: true,
      };
    },
    disableCBTour: (state, action) => {

      return {
        ...state,
        chartBuilderTourFlag: false,
      };
    },
    enableKPIBuilderTour: (state, action) => {
      return {
        ...state,
        kpiBuilderTourFlag: true,
      };
    },
    disableKPIBuilderTour: (state, action) => {

      return {
        ...state,
        kpiBuilderTourFlag: false,
      };
    },
    enablePinnedDashboardTour:(state, action) => {
      return {
        ...state,
        pinnedDashoardTourFlag: true,
      };
    },
    disablePinnedDashboardTour: (state, action) => {

      return {
        ...state,
        pinnedDashoardTourFlag: false,
      };
    },
    enableSharedDashboardTour: (state, action) => {
      return {
        ...state,
        sharedDashoardTourFlag: true,
      };
    },
    disableSharedDashboardTour: (state, action) => {

      return {
        ...state,
        sharedDashoardTourFlag: false,
      };
    },
    enableFunnelTour: (state, action) => {
      return {
        ...state,
        funnelTourFlag: true,
      };
    },
    disableFunnelTour: (state, action) => {

      return {
        ...state,
        funnelTourFlag: false,
      };
    },
    enableDITour: (state, action) => {
      return {
        ...state,
        DITourFlag: true,
      };
    },
    disableDITour: (state, action) => {

      return {
        ...state,
        DITourFlag: false,
      };
    }
  }
});


export const { enableDashboardTour,disableDashboardTour,enableExploreTour,disableExploreTour,enableCBTour,disableCBTour,enableKPIBuilderTour,disableKPIBuilderTour,enableTrackEventTour,disableTrackEventTour,enablePinnedDashboardTour,
  disablePinnedDashboardTour,enableSharedDashboardTour,disableSharedDashboardTour,enableFunnelTour,disableFunnelTour,enableDITour,disableDITour } = productTourSlice.actions;

export default productTourSlice.reducer;
