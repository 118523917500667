import i18next from 'i18next';
import ChartBuilder from './ChartBuilder';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';

i18next.addResourceBundle('en', 'examplePage', en);
i18next.addResourceBundle('tr', 'examplePage', tr);
i18next.addResourceBundle('ar', 'examplePage', ar);

/**
 * Lazy load Example
 */

import React from 'react';

const ChartBuilderConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          display: true,
          style: 'fixed',
          position: 'below',
          title: '',
        },
      },
    },
  },

  routes: [
    {
      path: '/chartbuilder',
      exact: true,
      component: React.lazy(() => import('./ChartBuilder')),
    },
    {
      path: '/chartbuilder/:dashboardId/:chartId',
      component: React.lazy(() => import('./ChartBuilder')),
    },{
      path: '/chartbuilder/:mode',
      exact: true,
      component: React.lazy(() => import('./ChartBuilder')),
    },
  ],
};

export default ChartBuilderConfig;
