import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { AppTableBody } from "./AppTableBody";

export const DataTable = (props) => {
  const {
    classes,
    columns,
    additionalRowRender,
    data,
    isRowStyled = false,
    loading,
  } = props;
  // const {columns,data}=props;
  return (
    <TableContainer
      component={Paper}
      style={{
        width: "auto",
        border: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: "5px",
      }}
    >
      <Table style={{ minWidth: 200 }} aria-label="simple">
        <TableHead>
          <TableRow>
            {columns.map((item) => {
              return <TableCell>{item.label}</TableCell>;
            })}
          </TableRow>
        </TableHead>
        {!loading && (
          <AppTableBody
            data={data}
            additionalRowRender={additionalRowRender}
            isRowStyled={isRowStyled}
            classes={classes}
            columns={columns}
          />
        )}
      </Table>
      {loading && (
        <Box style={{ display: "flex", height: "50px" }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div style={{ height: "10px" }}></div>
            <CircularProgress size={30} />
          </Grid>
        </Box>
      )}
    </TableContainer>
  );
};
