import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { getOnBoardingMasterData } from '../services/register.service';
import { DashboardConstants } from '../../app/dashboard/constants/dashboard.constants';

const namespace = 'dashboard/kpis';
const registerAdapter = createEntityAdapter({});

const initialState = registerAdapter.getInitialState({
  loading: DashboardConstants.HTTPSTATUS.FINISHED,
  entities: {},
  currentRequestId: '',
  error: '',
});

export const fetchOnBoardingMasterData = createAsyncThunk(
  `${namespace}/getOnBoardingMasterData`,
  async (dummy, { rejectWithValue }) => {
    try {
      const list = await getOnBoardingMasterData();
      return list;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchOnBoardingMasterData.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = '';
        state.entities = payload;
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
      }
    },
    [fetchOnBoardingMasterData.pending]: (state, { meta }) => {
      state.loading = DashboardConstants.HTTPSTATUS.PENDING;
      state.currentRequestId = meta;
    },
    [fetchOnBoardingMasterData.rejected]: (state, { meta, payload, error }) => {
      if (state.currentRequestId === meta) {
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
        state.entities = payload;
        state.error = error;
        state.currentRequestId = meta;
      }
    },
  },
});

export const {
  selectEntities: selectMasterDataEntities,
} = registerAdapter.getSelectors((state) => {
  console.log('state.register.masterData', state.register.masterData);
  return state.register.masterData;
});

export default reducer;
