// react functions stateless component

import { set } from 'lodash';
import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { Typography } from '@material-ui/core';
import "./chennalCount.css"
import { last } from 'lodash';



function Chennalcounts(props) {
    const [chenalDistribution, setChenalDistribution] = useState(
        {
            direct: 0,
            socialMedia: 0,
            organicSearch: 0,
            androidApp: 0,
            other: 0,
            directPercentage: 0,
            socialMediaPercentage: 0,
            organicSearchPercentage: 0,
            androidAppPercentage: 0,
            otherPercentage: 0,


        }
    )

    useEffect(() => {
        let filteredData = [];
        let countForUserId = [];

        if (props.realtimeData?.length > 0) {
            filteredData = props?.realtimeData?.filter((item, index) => {
                return props?.realtimeData?.findIndex(obj => obj.user_id === item.user_id) === index;
            }
            )
            // push user_id in array form filtered data
            for (let i = 0; i < filteredData?.length; i++) {
                countForUserId.push(filteredData[i].user_id)
            }
            // count user_id in array
        }
        if (props?.lastNintyMins?.length > 0) {
            let chennelTotalData = []
            //  remove duplicate user_id from lastNintyMins
            chennelTotalData =  props?.lastNintyMins?.filter((item, index) => {
                return props?.lastNintyMins?.findIndex(obj => obj.user_id === item.user_id) === index;
            }
            )
            // find all same userid in lastNinetyMinsData which match in countForUserId
            chennelTotalData = chennelTotalData?.filter((item, index) => {
                return countForUserId?.includes(item.user_id)
            })


            let channelTotal = 0;
            let totalDirect = 0;
            let totalSocialMedia = 0;
            let totalOrganicSearch = 0;
            let totalAndroidApp = 0;
            let totalOther = 0;
            for (let i = 0; i < chennelTotalData?.length; i++) {
                totalDirect += chennelTotalData[i].channel_counts.Direct;
                totalSocialMedia += chennelTotalData[i].channel_counts['Social Media'];
                totalOrganicSearch += chennelTotalData[i].channel_counts['Organic Search'];
                totalAndroidApp += chennelTotalData[i].channel_counts['Android App'];
                totalOther += chennelTotalData[i].channel_counts.Other;

            }
            channelTotal = totalDirect + totalSocialMedia + totalOrganicSearch + totalAndroidApp + totalOther;


            let directPercentage = totalDirect > 0 ? Math.round((totalDirect / channelTotal) * 100) : 0;
            let socialMediaPercentage = totalSocialMedia > 0 ? Math.round((totalSocialMedia / channelTotal) * 100) : 0;
            let organicSearchPercentage = totalOrganicSearch > 0 ? Math.round((totalOrganicSearch / channelTotal) * 100) : 0;
            let androidAppPercentage = totalAndroidApp > 0 ? Math.round((totalAndroidApp / channelTotal) * 100) : 0;
            let otherPercentage = totalOther > 0 ? Math.round((totalOther / channelTotal) * 100) : 0;


            setChenalDistribution({
                direct: totalDirect,
                socialMedia: totalSocialMedia,
                organicSearch: totalOrganicSearch,
                androidApp: totalAndroidApp,
                other: totalOther,
                directPercentage: directPercentage,
                socialMediaPercentage: socialMediaPercentage,
                organicSearchPercentage: organicSearchPercentage,
                androidAppPercentage: androidAppPercentage,
                otherPercentage: otherPercentage,

            })
        }



    }, [props.realtimeData, props?.lastNintyMins])


    // if in charts data is 0 then it will not show in chart

    return (
        <>
            <div className="chennalCard">
                <Typography className='font-500 text-4' variant="h6" >
                    Channel distribution
                </Typography>
                <Chart

                    options={
                        {
                            labels:
                                //  if in charts data is 0 then it will not show in chart
                                [chenalDistribution.direct > 0 ? `Direct ${chenalDistribution.directPercentage}%` : "",
                                chenalDistribution.socialMedia > 0 ? `Social Media ${chenalDistribution.socialMediaPercentage}%` : "",
                                chenalDistribution.organicSearch > 0 ? `Organic Search ${chenalDistribution.organicSearchPercentage}%` : "",
                                chenalDistribution.androidApp > 0 ? `Android App ${chenalDistribution.androidAppPercentage}%` : "",
                                chenalDistribution.other > 0 ? `Other ${chenalDistribution.otherPercentage}%` : ""]

                            ,
                            colors:
                                //  if in charts data is 0 then it will not show in chart
                                [chenalDistribution.direct > 0 ? '#FFB946' : "",
                                chenalDistribution.socialMedia > 0 ? '#FF5C93' : "",
                                chenalDistribution.organicSearch > 0 ? '#00C48C' : "",
                                chenalDistribution.androidApp > 0 ? '#4F46E5' : "",
                                chenalDistribution.other > 0 ? '#FF5C93' : ""],
                            dataLabels: {
                                enabled: false,
                                dropShadow: {
                                    enabled: false
                                },

                            },
                            // label in bottom of the chart
                            legend: {
                                position: 'bottom',

                            },
                            // on hover chart disable lebel
                            tooltip: {
                                enabled: false,
                            },
                        }
                    }
                    series=
                    //  do'nt show data in chart 
                    {[chenalDistribution.directPercentage,
                         chenalDistribution.socialMediaPercentage, 
                         chenalDistribution.organicSearchPercentage,
                          chenalDistribution.androidAppPercentage, 
                          chenalDistribution.otherPercentage]}


                    type="donut"
                    width={280}
                    height={200}


                />
            </div>
        </>
    )
}
export default Chennalcounts;
