import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
  } from '@reduxjs/toolkit';
  import { getDetailsById } from '../services/chart-builder.service';
  import { DashboardConstants } from '../../dashboard/constants/dashboard.constants';
  
  const namespace = 'chartbuilder/filter';
  const filterAdapter = createEntityAdapter({});
  
  const initialState = filterAdapter.getInitialState({
    loading: DashboardConstants.HTTPSTATUS.FINISHED,
    entities: {},
    currentRequestId: '',
    error: '',
  });

  export const fetchKPIChartsDetailByDashboardId = async (dashboardId) => {
    try {
      const dashboardInfo = JSON.parse(
        localStorage.getItem('lac-kpi-dashboardInfo')
      );
      const chartsDetails = JSON.parse(
        localStorage.getItem('lac-kpi-chartsDetails')
      );
      console.log('***********************');
      console.log(chartsDetails);
      return chartsDetails; // TODO Add Chart ID filter
    } catch (err) {
      return null;
    }
  };
  
  export const fetchDetailsById = async (dashboardId, chartId) => {
    try {
      const dashboardInfo = JSON.parse(
        localStorage.getItem('lac-chart-dashboardInfo')
      );
      const chartsDetails = JSON.parse(
        localStorage.getItem('lac-chart-chartsDetails')
      );
      console.log('***********************');
      console.log(chartsDetails);
      return chartsDetails.find((item) => item.chartId == chartId); // TODO Add Chart ID filter
      //const { data } = await getDetailsById(id);
      // return {
      //   chartId: 1,
      //   chartTitle: 'Sudeep',
      //   chartDescription: 'My Description',
      //   exploreType: 1,
      //   filters: {
      //     data: [
      //       {
      //         filterRows: [
      //           {
      //             field: 'country',
      //             operator: 'EQ',
      //             values: [{ value: 'India', label: 'India' }],
      //             joinOperator: 'and',
      //             isPerform: false,
      //           },
      //         ],
      //         rowJoinOperator: 'and',
      //         setJoinOperator: 'and',
      //         isValid: false,
      //         validationMessage: '',
      //         filterIndex: 1,
      //       },
      //     ],
      //     isValid: false,
      //     validationMessage: '',
      //     exploreType: 1,
      //   },
      //   type: 'pie',
      //   groupBy: null,
      //   thenBy: null,
      //   compareBy: null,
      //   dateFrequency: 'hourly',
      //   dateRange: '7D',
      //   chartData: {
      //     series: [
      //       {
      //         name: 'Segment 1',
      //         data: [
      //           {
      //             xaxis: '2021-04-24',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-04-26',
      //             yaxis: 15,
      //           },
      //           {
      //             xaxis: '2021-04-27',
      //             yaxis: 11,
      //           },
      //           {
      //             xaxis: '2021-04-28',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-04-29',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-04-30',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-05-01',
      //             yaxis: 2,
      //           },
      //           {
      //             xaxis: '2021-05-03',
      //             yaxis: 10,
      //           },
      //           {
      //             xaxis: '2021-05-04',
      //             yaxis: 5,
      //           },
      //           {
      //             xaxis: '2021-05-05',
      //             yaxis: 27,
      //           },
      //           {
      //             xaxis: '2021-05-06',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-05-08',
      //             yaxis: 21,
      //           },
      //           {
      //             xaxis: '2021-05-09',
      //             yaxis: 17,
      //           },
      //           {
      //             xaxis: '2021-05-10',
      //             yaxis: 15,
      //           },
      //           {
      //             xaxis: '2021-05-11',
      //             yaxis: 16,
      //           },
      //           {
      //             xaxis: '2021-05-12',
      //             yaxis: 2,
      //           },
      //           {
      //             xaxis: '2021-05-13',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-05-14',
      //             yaxis: 15,
      //           },
      //           {
      //             xaxis: '2021-05-16',
      //             yaxis: 12,
      //           },
      //           {
      //             xaxis: '2021-05-18',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-05-19',
      //             yaxis: 19,
      //           },
      //           {
      //             xaxis: '2021-05-20',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-05-21',
      //             yaxis: 23,
      //           },
      //           {
      //             xaxis: '2021-05-23',
      //             yaxis: 3,
      //           },
      //           {
      //             xaxis: '2021-05-24',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-05-25',
      //             yaxis: 14,
      //           },
      //           {
      //             xaxis: '2021-05-27',
      //             yaxis: 6,
      //           },
      //           {
      //             xaxis: '2021-05-29',
      //             yaxis: 3,
      //           },
      //           {
      //             xaxis: '2021-05-30',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-05-31',
      //             yaxis: 11,
      //           },
      //           {
      //             xaxis: '2021-06-01',
      //             yaxis: 2,
      //           },
      //           {
      //             xaxis: '2021-06-08',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-06-12',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-06-19',
      //             yaxis: 10,
      //           },
      //           {
      //             xaxis: '2021-06-26',
      //             yaxis: 5,
      //           },
      //           {
      //             xaxis: '2021-07-02',
      //             yaxis: 5,
      //           },
      //           {
      //             xaxis: '2021-07-14',
      //             yaxis: 5,
      //           },
      //           {
      //             xaxis: '2021-07-22',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-09-23',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-09-25',
      //             yaxis: 6,
      //           },
      //           {
      //             xaxis: '2021-10-01',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-10-08',
      //             yaxis: 3,
      //           },
      //           {
      //             xaxis: '2021-10-09',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-10-20',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-10-21',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-10-23',
      //             yaxis: 12,
      //           },
      //           {
      //             xaxis: '2021-10-24',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-10-27',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-10-28',
      //             yaxis: 11,
      //           },
      //           {
      //             xaxis: '2021-10-30',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-11-01',
      //             yaxis: 11,
      //           },
      //           {
      //             xaxis: '2021-11-02',
      //             yaxis: 10,
      //           },
      //           {
      //             xaxis: '2021-11-03',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-11-05',
      //             yaxis: 11,
      //           },
      //         ],
      //       },
      //     ],
      //     name: 'anything',
      //     description: 'anything',
      //     type: 'anything',
      //   },
      // };
    } catch (err) {
      return null;
    }
  };

  export const fetchKPIDetailsById = async (dashboardId, chartId) => {
    try {
      const dashboardInfo = JSON.parse(
        localStorage.getItem('lac-kpi-dashboardInfo')
      );
      const chartsDetails = JSON.parse(
        localStorage.getItem('lac-kpi-chartsDetails')
      );
      console.log('***********************');
      console.log(chartsDetails);
      return chartsDetails.find((item) => item.chartId == chartId); // TODO Add Chart ID filter
      //const { data } = await getDetailsById(id);
      // return {
      //   chartId: 1,
      //   chartTitle: 'Sudeep',
      //   chartDescription: 'My Description',
      //   exploreType: 1,
      //   filters: {
      //     data: [
      //       {
      //         filterRows: [
      //           {
      //             field: 'country',
      //             operator: 'EQ',
      //             values: [{ value: 'India', label: 'India' }],
      //             joinOperator: 'and',
      //             isPerform: false,
      //           },
      //         ],
      //         rowJoinOperator: 'and',
      //         setJoinOperator: 'and',
      //         isValid: false,
      //         validationMessage: '',
      //         filterIndex: 1,
      //       },
      //     ],
      //     isValid: false,
      //     validationMessage: '',
      //     exploreType: 1,
      //   },
      //   type: 'pie',
      //   groupBy: null,
      //   thenBy: null,
      //   compareBy: null,
      //   dateFrequency: 'hourly',
      //   dateRange: '7D',
      //   chartData: {
      //     series: [
      //       {
      //         name: 'Segment 1',
      //         data: [
      //           {
      //             xaxis: '2021-04-24',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-04-26',
      //             yaxis: 15,
      //           },
      //           {
      //             xaxis: '2021-04-27',
      //             yaxis: 11,
      //           },
      //           {
      //             xaxis: '2021-04-28',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-04-29',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-04-30',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-05-01',
      //             yaxis: 2,
      //           },
      //           {
      //             xaxis: '2021-05-03',
      //             yaxis: 10,
      //           },
      //           {
      //             xaxis: '2021-05-04',
      //             yaxis: 5,
      //           },
      //           {
      //             xaxis: '2021-05-05',
      //             yaxis: 27,
      //           },
      //           {
      //             xaxis: '2021-05-06',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-05-08',
      //             yaxis: 21,
      //           },
      //           {
      //             xaxis: '2021-05-09',
      //             yaxis: 17,
      //           },
      //           {
      //             xaxis: '2021-05-10',
      //             yaxis: 15,
      //           },
      //           {
      //             xaxis: '2021-05-11',
      //             yaxis: 16,
      //           },
      //           {
      //             xaxis: '2021-05-12',
      //             yaxis: 2,
      //           },
      //           {
      //             xaxis: '2021-05-13',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-05-14',
      //             yaxis: 15,
      //           },
      //           {
      //             xaxis: '2021-05-16',
      //             yaxis: 12,
      //           },
      //           {
      //             xaxis: '2021-05-18',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-05-19',
      //             yaxis: 19,
      //           },
      //           {
      //             xaxis: '2021-05-20',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-05-21',
      //             yaxis: 23,
      //           },
      //           {
      //             xaxis: '2021-05-23',
      //             yaxis: 3,
      //           },
      //           {
      //             xaxis: '2021-05-24',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-05-25',
      //             yaxis: 14,
      //           },
      //           {
      //             xaxis: '2021-05-27',
      //             yaxis: 6,
      //           },
      //           {
      //             xaxis: '2021-05-29',
      //             yaxis: 3,
      //           },
      //           {
      //             xaxis: '2021-05-30',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-05-31',
      //             yaxis: 11,
      //           },
      //           {
      //             xaxis: '2021-06-01',
      //             yaxis: 2,
      //           },
      //           {
      //             xaxis: '2021-06-08',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-06-12',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-06-19',
      //             yaxis: 10,
      //           },
      //           {
      //             xaxis: '2021-06-26',
      //             yaxis: 5,
      //           },
      //           {
      //             xaxis: '2021-07-02',
      //             yaxis: 5,
      //           },
      //           {
      //             xaxis: '2021-07-14',
      //             yaxis: 5,
      //           },
      //           {
      //             xaxis: '2021-07-22',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-09-23',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-09-25',
      //             yaxis: 6,
      //           },
      //           {
      //             xaxis: '2021-10-01',
      //             yaxis: 4,
      //           },
      //           {
      //             xaxis: '2021-10-08',
      //             yaxis: 3,
      //           },
      //           {
      //             xaxis: '2021-10-09',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-10-20',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-10-21',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-10-23',
      //             yaxis: 12,
      //           },
      //           {
      //             xaxis: '2021-10-24',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-10-27',
      //             yaxis: 7,
      //           },
      //           {
      //             xaxis: '2021-10-28',
      //             yaxis: 11,
      //           },
      //           {
      //             xaxis: '2021-10-30',
      //             yaxis: 9,
      //           },
      //           {
      //             xaxis: '2021-11-01',
      //             yaxis: 11,
      //           },
      //           {
      //             xaxis: '2021-11-02',
      //             yaxis: 10,
      //           },
      //           {
      //             xaxis: '2021-11-03',
      //             yaxis: 8,
      //           },
      //           {
      //             xaxis: '2021-11-05',
      //             yaxis: 11,
      //           },
      //         ],
      //       },
      //     ],
      //     name: 'anything',
      //     description: 'anything',
      //     type: 'anything',
      //   },
      // };
    } catch (err) {
      return null;
    }
  };
  
  const { actions, reducer } = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: {
      [fetchDetailsById.fulfilled]: (state, { meta, payload }) => {
        if (meta.requestId === state.currentRequestId.requestId) {
          state.currentRequestId = '';
          state.entities = payload;
          state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
        }
      },
      [fetchDetailsById.pending]: (state, { meta }) => {
        state.loading = DashboardConstants.HTTPSTATUS.PENDING;
        state.currentRequestId = meta;
      },
      [fetchDetailsById.rejected]: (state, { meta, payload, error }) => {
        if (state.currentRequestId === meta) {
          state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
          state.entities = payload;
          state.error = error;
          state.currentRequestId = meta;
        }
      },
    },
  });
  
  export const { clear_state } = actions;
  
  export const { selectEntities: selectFiltersEntities } =
    filterAdapter.getSelectors((state) => {
      // console.log(state);
      return state.chartbuilder.details;
    });
  
  export default reducer;
  