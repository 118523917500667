import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import 'firebase/auth';
import history from '@history';
import _ from '@lodash';
import {
  setInitialSettings,
  setDefaultSettings,
} from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import auth0Service from 'app/services/auth0Service';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';

export const setUserDataAuth0 = (tokenData) => async (dispatch) => {
  const user = {
    role: ['admin'],
    from: 'auth0',
    data: {
      displayName: tokenData.username || tokenData.name,
      photoURL: tokenData.picture,
      email: tokenData.email,
      settings:
        tokenData.user_metadata && tokenData.user_metadata.settings
          ? tokenData.user_metadata.settings
          : {},
      shortcuts:
        tokenData.user_metadata && tokenData.user_metadata.shortcuts
          ? tokenData.user_metadata.shortcuts
          : [],
    },
  };

  return dispatch(setUserData(user));
};

export const setUserDataFirebase = (user, authUser) => async (dispatch) => {
  if (
    user &&
    user.data &&
    user.data.settings &&
    user.data.settings.theme &&
    user.data.settings.layout &&
    user.data.settings.layout.style
  ) {
    // Set user data but do not update
    return dispatch(setUserData(user));
  }

  // Create missing user settings
  return dispatch(createUserSettingsFirebase(authUser));
};

export const createUserSettingsFirebase =
  (authUser) => async (dispatch, getState) => {
    const guestUser = getState().auth.user;
    const fuseDefaultSettings = getState().fuse.settings.defaults;
    const { currentUser } = firebase.auth();

    /**
     * Merge with current Settings
     */
    const user = _.merge({}, guestUser, {
      uid: authUser.uid,
      from: 'firebase',
      role: ['admin'],
      data: {
        displayName: authUser.displayName,
        email: authUser.email,
        settings: { ...fuseDefaultSettings },
      },
    });
    currentUser.updateProfile(user.data);

    dispatch(updateUserData(user));

    return dispatch(setUserData(user));
  };

export const setUserData = (user) => async (dispatch, getState) => {
  /*
		You can redirect the logged-in user to a specific route depending on his role
		 */

  history.location.state = {
    redirectUrl: user.redirectUrl, // for example 'apps/academy'
  };

  /*
	Set User Settings
	 */
  dispatch(setDefaultSettings(user.data.settings));

  dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
  const oldUser = getState().auth.user;
  const user = _.merge({}, oldUser, { data: { settings } });

  dispatch(updateUserData(user));

  return dispatch(setUserData(user));
};

export const updateUserShortcuts =
  (shortcuts) => async (dispatch, getState) => {
    const { user } = getState().auth;
    const newUser = {
      ...user,
      data: {
        ...user.data,
        shortcuts,
      },
    };

    dispatch(updateUserData(user));

    return dispatch(setUserData(newUser));
  };

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState().auth;

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: '/',
  });

  switch (user.from) {
    case 'firebase': {
      firebaseService.signOut();
      break;
    }
    case 'auth0': {
      auth0Service.logout();
      break;
    }
    default: {
      jwtService.logout();
    }
  }

  dispatch(setInitialSettings());

  return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
  if (!user.role || user.role.length === 0) {
    // is guest
    return;
  }
  switch (user.from) {
    case 'firebase': {
      firebaseService
        .updateUserData(user)
        .then(() => {
          dispatch(showMessage({ message: 'User data saved to firebase' }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
    case 'auth0': {
      auth0Service
        .updateUserData({
          settings: user.data.settings,
          shortcuts: user.data.shortcuts,
        })
        .then(() => {
          dispatch(showMessage({ message: 'User data saved to auth0' }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
    default: {
      jwtService
        .updateUserData(user)
        .then(() => {
          dispatch(showMessage({ message: 'User data saved with api' }));
        })
        .catch((error) => {
          dispatch(showMessage({ message: error.message }));
        });
      break;
    }
  }
};

const initialState = {
  role: [], // guest
  token: '',
  data: {
    displayName: '',
    photoURL: '',
    email: undefined,
    shortcuts: ['calendar', 'mail', 'contacts', 'todo'],
  },
};

// const availableProducts = [
//   { productName: 'filpcart', companyURL: 'filpcart.com', clientID: 'CL0001' },
//   { productName: 'amazon', companyURL: 'amazon.com', clientID: 'CL0002' },
// ];

export const getLoggedInUser = () => {
  let userDetails = localStorage.getItem('lac-user-details');

  if (!userDetails) {
    return undefined;
  } else {
    return JSON.parse(localStorage.getItem('lac-user-details'));
  }
};

export const getLoggedInUserClientDetails = () => {
  let userClientDetails = localStorage.getItem('lac-user-client-details');

  if (!userClientDetails) {
    return undefined;
  } else {
    return JSON.parse(localStorage.getItem('lac-user-client-details'));
  }
};

export const getLoggedInUserActiveClientID = () => {
  let userClientDetails = localStorage.getItem('lac-user-active-client_id');

  if (!userClientDetails && getLoggedInUserClientDetails() != undefined) {
    localStorage.setItem(
      'lac-user-active-client_id',
      getLoggedInUserClientDetails()[0].client_id
    );
  }
  return localStorage.getItem('lac-user-active-client_id');
};

const userSlice = createSlice({
  name: 'auth/user',
  initialState: getLoggedInUser() ? { data: getLoggedInUser() } : initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    setUserDetails: (state, action) => {
      const availableClients = getLoggedInUserClientDetails();
      const activeClientDetails = availableClients?.find((item) => {
        return item.client_id === getLoggedInUserActiveClientID();
      });
      return {
        ...state,
        token: action.payload.token,
        data: Object.assign(
          {
            client_id: activeClientDetails?.client_id,
            currentProductName: activeClientDetails?.company_name,
            availableProducts: availableClients?.map((item) => {
              return {
                agency_name: item.agency_name,
                agency_url: item.agency_url,
                client_id: item.client_id,
                company_name: item.company_name,
                company_url: item.company_url,
                organization_type: item.organization_type,
                user_role: item.user_role,
                product_register_progress: item.product_register_progress,
              };
            }),
          },
          { ...state.data },
          { ...action.payload.data[0] },
          {
            displayName: action.payload.data[0].display_name,
            currentProductName: action.payload.data[0].company_name,
          }
        ),
      };
    },
    setSwitchAccount: (state, action) => {
      const availableClients = getLoggedInUserClientDetails();
      const activeClientDetails = availableClients?.find((item) => {
        return item.client_id === getLoggedInUserActiveClientID();
      });
      return {
        ...state,
        data: Object.assign(
          { ...state.data },
          {
            client_id: activeClientDetails?.client_id,
            currentProductName: activeClientDetails?.company_name,
	  	      //adding details for footprint map
            company_url:activeClientDetails?.company_url,
          }
        ),
      };
    },
    userLoggedOut: (state, action) => initialState,
  },
});

export const { setUser, setUserDetails, setSwitchAccount, userLoggedOut } =
  userSlice.actions;

export default userSlice.reducer;
