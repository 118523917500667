import { useEffect, useState } from 'react';
import { UserForm } from '../components/user-form/UserForm';
import { UserProfilePic } from '../components/profile-pic/UserProfilePic';
import { Box, Typography } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import {
  createUser,
  fetchMasterData,
  getUserById,
  deleteUser,
} from '../services/users';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
let loggedInUser;
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const AddEditUserContainer = () => {
  const params = useParams();
  const [userInfo, setUserInfo] = useState();
  const [countryList, setCountryList] = useState();
  const [languageList, setLanguageList] = useState();
  const [roleList, setRoleList] = useState();
  const [businessListOptions, setBusinessListOptions] = useState();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const pageTitle = params.userId ? 'Edit user profile' : 'Add User';

  useEffect(async () => {
    if (params.userId) {
      let email_address = '';
      if (localStorage.getItem('lac-user-details')) {
        email_address = JSON.parse(localStorage.getItem('lac-user-details'))
          .email_address;
      }
      loggedInUser = email_address;

      let result = await getUserById(params.userId, email_address);
      let businessAccountInfo = result.businessAccountInfo.map((item) => {
        return {
          current_business: item.companyUrl,
          access_role: item.role,
        };
      });
      setUserInfo({ ...result, businessAccountInfo });
    }
  }, []);

  useEffect(async () => {
    let { countryList, languageList, roleList } = await fetchMasterData();
    const businessListOptions = JSON.parse(
      localStorage.getItem('lac-user-client-details')
    ).map((item) => {
      return {
        name: item.company_name,
        url: item.company_url,
        client_id: item.client_id,
        ...item,
      };
    });
    setCountryList(countryList);
    setLanguageList(languageList);
    const filteredRoleList = roleList.filter((item) => item != 'Admin');
    setRoleList(filteredRoleList);
    setBusinessListOptions(businessListOptions);
  }, []);

  const createUserOrUpdateUser = async (userForm) => {
    console.log('access role is there',userForm);

    try {
      if(userForm.businessInfo[0].access_role === undefined 
        || userForm.businessInfo[0].access_role === '' || userForm.businessInfo[0].access_role === null || userForm.businessInfo[0].access_role === 'none' || userForm.businessInfo[0].access_role === 'None'){
        setAlertMessage('please select atleast one business role');
        setAlertType('warning');
        setOpen(true);
      }else{
        let userdata
        if(userForm.password){
           userdata =  {...userForm,pageTitle:"Add User"};

        }else{
           userdata =  {...userForm,pageTitle:"Edit User"};
        }

        let data = await createUser(userdata);
        data && history.push('/manage-users');
      }
      
    } catch (error) {
      console.log(error);
     
    
  };
}
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const dataLoaded = params.userId
    ? userInfo && countryList && roleList && languageList && businessListOptions
    : countryList && roleList && languageList && businessListOptions;

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity={alertType}>
      {alertMessage}
    </Alert>
  </Snackbar>
    <Box p={10} pt={5}>
      <Typography className="h2 mb-24">{pageTitle}</Typography>
      <UserProfilePic />
      {dataLoaded && (
        <UserForm
          userInfo={userInfo}
          onSubmit={createUserOrUpdateUser}
          countryList={countryList}
          roleList={roleList}
          languageList={languageList}
          businessListOptions={businessListOptions}
          deleteUser={deleteUser}
          loggedInUserEmail={loggedInUser}
          editProfile={false}
          pageTitle={pageTitle}
        />
      )}
    </Box>
    </>

  );
};

