import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'applications',
    title: '',
    translate: '',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'explore-component',
        title: 'Explore',
        translate: 'Explore',
        type: 'item',
        icon: 'library_books',
        url: '/explore',
      },
      {
        id: 'dashboard-component',
        title: 'Dashboard',
        translate: 'Dashboard',
        type: 'item',
        icon: 'dashboard',
        url: '/dashboard/default',
      },
      {
        id: 'track-events-component',
        title: 'Track events',
        translate: 'Track events',
        type: 'item',
        icon: 'assignment',
        url: '/track-events'
      },
      {
        id: 'pinned-dashboard-component',
        title: 'Pinned Dashboards',
        translate: 'Pinned Dashboards',
        type: 'collapse',
        icon: 'dashboard',
      },
      {
        id: 'shared-dashboard-component',
        title: 'Shared Dashboards',
        translate: 'Shared Dashboards',
        type: 'collapse',
        icon: 'dashboard',
      },
      // {
      //   id: 'Customize',
      //   title: 'Customize',
      //   translate: 'Customize',
      //   type: 'item',
      //   icon: 'addchart',
      //   url: '/Customize',
      // },
      {
        id: 'behaviour-analysis-component',
        title: 'Behaviour Analysis',
        translate: 'Behaviour Analysis',
        type: 'collapse',
        icon: 'whatshot',
        children: [
          // {
          //   id: 'overview',
          //   title: 'Overview',
          //   translate: 'Overview',
          //   type: 'item',
          //   url: '/behaviour-analysis/overview',
          // },
          // {
          //   id: 'user-journey',
          //   title: 'User Journey',
          //   translate: 'User Journey',
          //   type: 'item',
          //   url: '/behaviour-analysis/user-journey',
          // },
          {
            id: 'funnel-analysis',
            title: 'Funnel Analysis',
            translate: 'Funnel Analysis',
            type: 'item',
            url: '/behaviour-analysis/funnel',
          },
          // {
          //   id: 'Footprint Map',
          //   title: 'Footprint Map',
          //   translate: 'Footprint Map',
          //   type: 'item',
          //   url: '/behaviour-analysis/heat-map'
          // }
        ]
      },
      {
        id: 'Dynamic Insights',
        title: 'Dynamic Insights',
        translate: 'Dynamic Insights',
        type: 'item',
        icon: 'whatshot',
        url: '/dynamic-insights',
      },

      // {
      //   id: 'social-listening-component',
      //   title: 'Social Listening',
      //   translate: 'Social Listening',
      //   type: 'item',
      //   icon: 'whatshot',
      //   url: '/social-listening',
      // },
        {
          id: 'social-listening-component',
          title: 'Social Listening',
          translate: 'Social Listening',
          type: 'collapse',
          icon: 'whatshot',
          children: [
             {
                id: 'social-listenning-dashboard',
                title: 'Dashboard',
                translate: 'Dashboard',
                type: 'item',
                url: '/social-listening',
             },
            //  {
            //     id: 'social-listenning-integrations',
            //     title: 'Integrations',
            //     translate: 'Integrations',
            //     type: 'item',
            //     url: '/social-listening-integrations',
            //  },
          ],
       },
      // {
      //   id: 'digital-assitant-component',
      //   title: 'Digital Assitant',
      //   translate: 'Digital Assitant',
      //   type: 'collapse',
      //   icon: 'whatshot',
      //   children: [
      //     {
      //       id: 'integration',
      //       title: 'Integrations',
      //       translate: 'Integrations',
      //       type: 'item',
      //       url: '/digital-assitant-integration',
      //     },
      //   ],
      // },
      {
        id: 'integrations',
        title: 'Integrations',
        translate: 'Integrations',
        type: 'item',
        icon: 'library_books',
        url: '/integrations',
      },
    ],
  },
];

export default navigationConfig;
