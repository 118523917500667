import i18next from "i18next";
import en from "./i18n/en";
import tr from "./i18n/tr";
import ar from "./i18n/ar";

i18next.addResourceBundle("en", "heatMapPage", en);
i18next.addResourceBundle("tr", "heatMapPage", tr);
i18next.addResourceBundle("ar", "heatMapPage", ar);

import React from "react";
import { TextareaAutosize } from "@material-ui/core";

const HeatMapConfig = {
  settings: {
    layout: {
      style: "layout1",
      config: {
        scroll: "content",
        navbar: {
          display: true,
          folded: false,
          position: "left",
        },
        toolbar: {
          display: TextareaAutosize,
          style: "fixed",
          position: "below",
          title: "Footprint Map",
        },

        mode: "Boxed",
      },
    },
  },
  routes: [
    {
      path: ["/behaviour-analysis/heat-map"],
      component: React.lazy(() => import("./HeatMap")),
    },
  ]
};

export default HeatMapConfig;
