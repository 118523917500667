import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
  } from "@reduxjs/toolkit";
  import { DashboardConstants } from "../../dashboard/constants/dashboard.constants";
  
  
  const namespace = "realtime";
  const dynamicAdapter = createEntityAdapter({});


  const initialState = dynamicAdapter.getInitialState({
    loading:DashboardConstants.HTTPSTATUS.FINISHED,
    timeStamp:new Date(),
    allData:"",
    devices_count:{

    },
    channel_count:{

    },
    traffic_count:{

    },
    currentRequestID:"",
    error:"",
    statusCode:""
  })


  const {actions,reducer}= createSlice({
    name:namespace,
    initialState,
    reducers:{
        structureRealtime:(state,action)=>{

        },
        updateRealtime:(state,action)=>{

        }
    }
  })

  export const {selectEntities:selectDynamicEntities}=
     dynamicAdapter.getSelectors((state)=>{
        return state.dashboard.realtime;
     })

export const {
structureRealtime,
updateRealtime
} = actions;

export default reducer;