import { makeStyles } from '@material-ui/core/styles';
import React from 'react'
import Bar from './Bar';
import { useState } from 'react';
import { useEffect } from 'react';
const useStyles = makeStyles({
    main: {
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        marginLeft: "10px",
        width: "100%",
        padding: "20px",
        borderRadius: "5px",
        height: "auto"
    },
    card: {
        display: "flex",
        padding: "15px",
        marginTop: "10px",
        borderRadius: "5px",
        boxShadow: "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
        '& .barStacked button': {
            padding: '0.5rem 1rem',
            border: '1px solid #437eeb'
        }

    },
    days: {
        display: "inline-flex",
        borderRadius: "4px",
        "& button": {
            marginLeft: "-1px",
            fontSize: "1.1rem",
            minWidth: "40px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            boxSizing: "border-box",
            transition:
                "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            fontFamily: "Nunito Sans,sans-serif",
            fontWeight: 500,
            lineHeight: 1.75,
            padding: "3px 9px"
        }
    },
    refresh: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
        "& button": {
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#437eeb',
            color: 'white',
            padding: '0.3rem 0.7rem'

        }

    },
    barColors: {
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        paddingBottom: '2rem',
        paddingTop: '3rem',

        '& div:nth-child(1) .circle': {

            backgroundColor: '#437EEB',
            height: '1rem',
            width: '1rem',
            borderRadius: '50%'
        },
        '& div:nth-child(2) .circle': {
            backgroundColor: '#00b4d8',
        },
        '& div:nth-child(3) .circle': {
            backgroundColor: '#0077b6',
        },
        '& div:nth-child(4) .circle': {
            backgroundColor: '#023e8a',
        },
        '& div:nth-child(5) .circle': {
            backgroundColor: '#03045e',
        }
    }
});
function FunnelChart({
    handleBar,
    isStacked,
    isUser2,
    showEvent,
    funnelData,
    stackBy,
    days,
    filters,
    isPin,
    user2filters
}) {
    const classes = useStyles();
    const [stacks, setstacks] = useState([]);
    let data = funnelData;
    if (isPin) {
        let tempData = { 
            unfiltered: [...funnelData[0]], 
            datefiltered: [...funnelData[0]], 
            filtered: [...funnelData[0]], 
            user2filtered: funnelData[1] ? [...funnelData[1]] : [...funnelData[0]] 
          }
          data = tempData
    }
    let user1Filters = filters || [[]];
    if (isPin) {
        filters.data.forEach((filter, i) => {
            if (filter.filterRows) {
                let userFilters = [[]]
                filter.filterRows.forEach((f, j) => {
                    if(!userFilters[j]) userFilters[j] = [];
                    userFilters[j][0] = f.field;
                    userFilters[j][1] = f.values[0].value
                })
                if (i == 0) {
                   user1Filters = userFilters;
                }
                
              
            }
        })
    }
    let user2Filters = user2filters || [[]];
    if (isPin) {
        filters.data.forEach((filter, i) => {
            if (filter.filterRows) {
                let userFilters = [[]]
                filter.filterRows.forEach((f, j) => {
                    if(!userFilters[j]) userFilters[j] = [];
                    userFilters[j][0] = f.field;
                    userFilters[j][1] = f.values[0].value
                })
                if (i == 1) {
                    user2Filters = userFilters;
                }
                
              
            }
        })
    }
    useEffect(() => {
        if(showEvent.length == 0) return
        if (!isStacked || Object.entries(stackBy).filter((val) => stackBy[val[0]]).length == 0) return setstacks([]);
        if (!data.filtered) return
        let temp = isUser2 ? [...data.user2filtered] : [...data.filtered]
        let filtered = temp.filter((x) => {
            return x.event_data != null
        })
        let firstFiltered = filtered.filter(x => {
            let y = JSON.parse(x.event_data);
            let present = false;
            for (let i = 0; i < y.length; i++) {
                if (y[i].event_label == showEvent[0].label) present = true;
            }
            return present;
        })
        let tmp = [...firstFiltered];
        let stks = {}

        let compare = Object.entries(stackBy).filter((val) => stackBy[val[0]])[0][0];
        for (let obj of tmp) {
            if (compare === 'session_details.referrer') {
                if (stks[obj.session_details[0].referrer]) stks[obj.session_details[0].referrer] += 1
                else stks[obj.session_details[0].referrer] = 1
            }
            else if (compare === 'session_details.hour') {
                if (stks[obj.session_details[0].hour]) stks[obj.session_details[0].hour] += 1
                else stks[obj.session_details[0].hour] = 1
            }
            else {

                if (stks[obj[compare]]) stks[obj[compare]] += 1;
                else stks[obj[compare]] = 1
            }
        }
        stks = Object.entries(stks)
        stks.sort((a, b) => b[1] - a[1])

        let others = ['Others', 0, 0.00]
        for (let i = 0; i < stks.length; i++) {
            stks[i][2] = (stks[i][1] * 100 / tmp.length).toFixed(2);

            if (stks[i][0].includes('Other') || stks[i][0].includes('NA') || stks[i][0].includes('null')) {

                others[1] = others[1] + stks[i][1]
                others[2] = (others[2] + (stks[i][1] * 100 / tmp.length))
                stks.splice(i, 1);
                i--
            }
            if (i > 3) {
                others[1] = others[1] + stks[i][1]
                others[2] = (others[2] + (stks[i][1] * 100 / tmp.length))
            }
        }

        let top5 = stks.slice(0, 4);
        if (stks.length >= 5 || others[1] > 0) {
            others[2] = (others[2]).toFixed(2)
            top5.push(others)
        }
        let colors = ["#437EEB", '#00b4d8', '#0077b6', '#023e8a', '#03045e']
        top5.forEach((stack, i) => {
            stack[3] = colors[i]
        })
        setstacks(top5);
    }, [isStacked, stackBy, funnelData, showEvent])


  return (
    <div className={classes.card} style={{ flexDirection: 'column', minHeight: '60vh' }}>
            {!isPin &&    <div className="barStacked border-[1px] w-fit border-gray-400 rounded-md my-4 border-opacity-75" id="chartTypeBtn">

                    <button onClick={handleBar} style={{ background: `${!isStacked ? '#437eeb' : 'white'}`, color: `${!isStacked ? 'white' : 'black'}` }} className='border-r-[1px] border-gray-400 px-2 p-1 rounded-l-md'>Bar</button>
                    <button onClick={handleBar} style={{ background: `${isStacked ? '#437eeb' : 'white'}`, color: `${isStacked ? 'white' : 'black'}` }} className='border-r-[1px] border-gray-400 px-2 p-1 rounded-r-md'>Stacked</button>

                </div>}
                {showEvent.length == 0 && <h5 className='my-20'>Step 1: Select an Event to show Data.</h5>}
                {isStacked ? <section className={classes.barColors} style={{ justifyContent: stacks.length > 0 ? 'center' : 'start' }}>
                    {stacks.length > 0 && showEvent.length > 0 && stacks.map((stack, i) => {
                        return <div className='flex-col justify-center text-center items-center'>
                            <div className='flex items-center justify-center gap-4'>
                                <div className='circle' style={{ backgroundColor: stack[3] }}></div>
                                <h4 className='text-sm font-semibold'>{stack[0]}</h4>
                            </div>
                        </div>
                    }) }

                </section> : !isPin && <h5>Step 2: Select compare by</h5>}




                <div className={isUser2 && 'flex gap-1 gap-x-6'} style={{
                    justifyContent: 'space-between',
                    paddingBottom: '1rem',
                    paddingTop: '1rem',
                }}>

                    <div style={{ flexBasis: '45%' }} className='flex flex-col gap-10 justify-between items-center'>
                        {showEvent.length != 0 && isUser2 && <p>{user1Filters.filter(fil => fil[1]).map(x => x[1]).join(', ') || 'No Filters'}</p>}

                        {showEvent.map((event, i) => {
                            return <Bar
                                i={i}
                                user2={false}
                                mainStacks={stacks}
                                isStacked={isStacked}
                                stackBy={stackBy}
                                days={days}
                                data={data}
                                key={event + i}
                                filters={filters}
                                event={event}
                                showEvent={showEvent}
                            />
                        })}
                    </div>
                    {isUser2 && <div style={{ flexBasis: '45%' }} className='flex flex-col gap-10 justify-between items-center'>
                        {showEvent.length != 0 && <p>{user2Filters.filter(fil => fil[1]).map(x => x[1]).join(', ') || 'No Filters'}</p>}

                        {showEvent.map((event, i) => {
                            return <Bar
                                i={i}
                                user2={true}
                                mainStacks={stacks}
                                isStacked={isStacked}
                                stackBy={stackBy}
                                days={days}
                                data={data}
                                key={event + i}
                                filters={filters}
                                event={event}
                                showEvent={showEvent}
                            />
                        })}
                    </div>}
                </div>
            </div>
  )
}

export default FunnelChart
