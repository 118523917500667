import {
	createSelector,
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from '@reduxjs/toolkit';
import navigationConfig from 'app/fuse-configs/navigationConfig';
import { getDashboards } from '../../main/app/dashboard/services/dashboard.service';
import { DashboardConstants } from '../../main/app/dashboard/constants/dashboard.constants';
import FuseUtils from '@fuse/utils';
import i18next from 'i18next';
import _ from '@lodash';

const namespace = 'navigation';
const navigationAdapter = createEntityAdapter();
const emptyInitialState = navigationAdapter.getInitialState();
const initialState = navigationAdapter.upsertMany(
	emptyInitialState,
	navigationConfig
);

export const fetchDashboards = createAsyncThunk(
	`${namespace}/fetchDashboards`,
	async (requestParams) => {
		try {
			const { data } = await getDashboards(requestParams);
			return data;
		} catch (err) {
			return rejectWithValue([], err);
		}
	}
);

export const appendNavigationItem = (item, parentId) => (
	dispatch,
	getState
) => {
	const navigation = selectNavigationAll(getState());

	return dispatch(
		setNavigation(FuseUtils.appendNavItem(navigation, item, parentId))
	);
};

export const prependNavigationItem = (item, parentId) => (
	dispatch,
	getState
) => {
	const navigation = selectNavigationAll(getState());

	return dispatch(
		setNavigation(FuseUtils.prependNavItem(navigation, item, parentId))
	);
};

export const updateNavigationItem = (id, item) => (dispatch, getState) => {
	const navigation = selectNavigationAll(getState());

	return dispatch(setNavigation(FuseUtils.updateNavItem(navigation, id, item)));
};

export const removeNavigationItem = (id) => (dispatch, getState) => {
	const navigation = selectNavigationAll(getState());

	return dispatch(setNavigation(FuseUtils.removeNavItem(navigation, id)));
};

export const {
	selectAll: selectNavigationAll,
	selectIds: selectNavigationIds,
	selectById: selectNavigationItemById,
} = navigationAdapter.getSelectors((state) => state.fuse.navigation);

export const selectNavigation = createSelector(
	[selectNavigationAll, ({ i18n }) => i18n.language],
	(navigation, language) => {
		function setTranslationValues(data) {
			// loop through every object in the array
			return data.map((item) => {
				if (item.translate && item.title) {
					item.title = i18next.t(`navigation:${item.translate}`);
				}

				// see if there is a children node
				if (item.children) {
					// run this function recursively on the children array
					item.children = setTranslationValues(item.children);
				}
				return item;
			});
		}

		return setTranslationValues(_.merge([], navigation));
	}
);

const navigationSlice = createSlice({
	name: 'navigation',
	initialState,
	reducers: {
		setNavigation: navigationAdapter.setAll,
		resetNavigation: (state, action) => initialState,
	},
	extraReducers: {
		[fetchDashboards.fulfilled]: (state, { meta, payload }) => {
			if (meta.requestId === state.currentRequestId.requestId) {
				state.currentRequestId = '';
				// console.log(payload);
				// console.log(state.entities.applications);
				// state.entities = payload;

				state.entities.applications.children[3].children = payload.pinnedDashboards.filter(item => item.is_default === 'N').map(
					(item) => {
						return {
							id: `pinned_dashboard${item.id}`,
							title: item.title,
							translate: item.title,
							type: 'item',
							url: `/pinned-dashboard/${item.id}`,
						};
					}
				);
				state.entities.applications.children[4].children = payload.sharedDashboards.map(
					(item) => {
						return {
							id: `shared_dashboard${item.id}`,
							title: item.title,
							translate: item.title,
							type: 'item',
							url: `/shared-dashboard/${item.id}`,
						};
					}
				);
				state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
			}
		},
		[fetchDashboards.pending]: (state, { meta }) => {
			state.loading = DashboardConstants.HTTPSTATUS.PENDING;
			state.currentRequestId = meta;
		},
		[fetchDashboards.rejected]: (state, { meta, payload, error }) => {
			if (state.currentRequestId === meta) {
				state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
				state.entities = payload;
				state.error = error;
				state.currentRequestId = meta;
			}
		},
	},
});

export const { setNavigation, resetNavigation } = navigationSlice.actions;

export default navigationSlice.reducer;
