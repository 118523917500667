import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import {
  getDashboardMetaByID,
  getChartDataBySelection,
  saveChart,
} from '../services/chart-builder.service';
import { DashboardConstants } from '../../dashboard/constants/dashboard.constants';
import { getDashboards } from '../../dashboard/services/dashboard.service';
import { getUsersList } from '../../../user-management/services/users';
const namespace = 'chartbuilder/data';
const dataAdapter = createEntityAdapter({});

const initialState = dataAdapter.getInitialState({
  loading: DashboardConstants.HTTPSTATUS.FINISHED,
  entities: {},
  currentRequestId: '',
  error: '',
});

export const fetchChartDataBySelection = async (chartSelection) => {
  try {
    const { data } = await getChartDataBySelection(chartSelection);
    return data;
  } catch (err) {
    return null;
  }
};

export const fetchAllDashboardsForUser = async (requestParams) => {
  try {
    const { data } = await getDashboards(requestParams);
    return data.pinnedDashboards;
  } catch (err) {
    return null;
  }
};

export const fetchAllUsers = async (requestParams) => {
  try {
    const data = await getUsersList(requestParams.email_address);
    return data;
  } catch (err) {
    return null;
  }
};

export const saveChartBySelection = async (chartSelection) => {
  try {
    const { data } = await saveChart(chartSelection);
    return data;
  } catch (err) {
    return null;
  }
};

export const fetchDashboardMetaByID = async (chartSelection) => {
  try {
    const { data } = await getDashboardMetaByID(chartSelection);
    return data;
  } catch (err) {
    return null;
  }
};

const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchChartDataBySelection.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = '';
        state.entities = payload;
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
      }
    },
    [fetchChartDataBySelection.pending]: (state, { meta }) => {
      state.loading = DashboardConstants.HTTPSTATUS.PENDING;
      state.currentRequestId = meta;
    },
    [fetchChartDataBySelection.rejected]: (state, { meta, payload, error }) => {
      if (state.currentRequestId === meta) {
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
        state.entities = payload;
        state.error = error;
        state.currentRequestId = meta;
      }
    },
  },
});

export const { clear_state } = actions;

export const { selectEntities: selectChartDataEntities } =
  dataAdapter.getSelectors((state) => {
    return state.chartbuilder.data;
  });

export default reducer;
