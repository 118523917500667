import { useRef, useState } from "react";
import {
  Badge,
  Button,
  Box,
  Typography,
  Grid,
  TextareaAutosize,
} from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from "@material-ui/core/TextField";

import FileCopy from "@material-ui/icons/FileCopy";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export const CodeIntegrateSectionOneSummary = () => {
  
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
      id="sendSnippet"
    >
      <Typography variant="subtitle2">
        Send your snippet to a teammate.
      </Typography>
      <Typography color="textSecondary">
        Nice, tidy instructions for your favorite engineer.
      </Typography>
    </Grid>
  );
};

export const CodeIntegrateSectionOneDetails = (props) => {
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [emailAddress, setEmailAddress] = useState("");
  const handleOnchange = (e) => {
   setEmailAddress(e.target.value);
    
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleOnSubmit = (e) => {
    e.preventDefault();
    props.handleEmailMaual(emailAddress);
    setAlertMessage("Snippet sent to your email address");
      setAlertType('success');
      setOpen(true);
  };
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Typography variant="subtitle2">
        Who are you sending this snippet to?
      </Typography>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    <Alert onClose={handleClose} severity={alertType}>
      {alertMessage}
    </Alert>
  </Snackbar>
      <TextField
        className="mb-16"
        type="text"
        name="email"
        label="Email Address"
        autoComplete="off"
        required
        style={{
          width: "22%",
        }}
        value={emailAddress}
        onChange={handleOnchange}
      />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Button
          className="mb-12 mr-12"
          variant="contained"
          disableElevation
          color="primary"
          size="lg"
          onClick={handleOnSubmit}
        >
          Send your snippet
        </Button>

        {/* <Typography
          variant="caption"
          gutterBottom
          style={{ lineHeight: "40px" }}
        >
          You’ll be CC’ed on this email.
        </Typography> */}
      </Grid>
    </Grid>
  );
};

export const CodeIntegrateSectionTwoSummary = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start"
    >
      <Typography variant="subtitle2">Install snippet yourself</Typography>
      <Typography color="textSecondary">
        {/* Paste your Lacritz snippet in the head tag of your website. */}
        Paste your Flable snippet in the head tag of your website.
      </Typography>
    </Grid>
  );
};

export const CodeIntegrateSectionTwoDetails = (props) => {
  const { jsSnippet } = props;
  const [copySuccess, setCopySuccess] = useState(false);
  const textarea = useRef(null);

  const copyCodeToClipboard = () => {
    const el = textarea.current;
    el.select();
    document.execCommand("copy");
    setCopySuccess(true);
  };

  return (
    <Box p={3} pt={5.5} style={{ width: "100%", position: "relative" }}>
      <Badge
        badgeContent={
          <Button
            onClick={copyCodeToClipboard}
            variant="text"
            id="copyCodeBtn"
            style={{
              background: "white",
              right: "45px",
              color: "#636262", // covert to theme
            }}
            startIcon={<FileCopy />}
          >
            Copy code
          </Button>
        }
        style={{ width: "inherit" }}
      >
        <TextareaAutosize
          ref={textarea}
          aria-label="minimum height"
          style={{
            minHeight: "200px",
            width: "inherit",
            border: "1px solid #8C8C8C",
            borderRadius: "5px",
          }}
          value={jsSnippet}
        />
      </Badge>
    </Box>
  );
};
