import React, { useEffect, useState } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Tooltip from "@material-ui/core/Tooltip";
const useStyles = makeStyles({
    bar: {
        backgroundColor: '#437EEB',
        height: '4rem',
        borderRadius: '0.5rem',
        minWidth: '1rem !important',
        display: 'flex',
        '& div:nth-child(2)': {
            backgroundColor: '#00b4d8',
            minWidth: '0.25rem !important',
        },
        '& div:nth-child(3)': {
            backgroundColor: '#0077b6',
            minWidth: '0.25rem !important',
        },
        '& div:nth-child(4)': {
            backgroundColor: '#023e8a',
            minWidth: '0.25rem !important',
        },
        '& div:nth-child(5)': {
            backgroundColor: '#03045e',
        },
        '& div:first-child': {

            minWidth: '0.5rem !important',
            borderTopLeftRadius: "0.5rem",
            borderBottomLeftRadius: "0.5rem"
        },
        '& div:last-child': {
            minWidth: '0.5rem !important',
            borderTopRightRadius: "0.5rem",
            borderBottomRightRadius: "0.5rem"
        }


    },
    barColors: {
        display: "flex",
        flexDirection: 'column',
        gap: '2px',

        '& div:nth-child(1) .circle': {

            backgroundColor: '#437EEB',
            height: '1rem',
            width: '1rem',
            borderRadius: '50%'
        },
        '& div:nth-child(2) .circle': {
            backgroundColor: '#00b4d8',
        },
        '& div:nth-child(3) .circle': {
            backgroundColor: '#0077b6',
        },
        '& div:nth-child(4) .circle': {
            backgroundColor: '#023e8a',
        },
        '& div:nth-child(5) .circle': {
            backgroundColor: '#03045e',
        }
    }
});
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#fbfcf9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9"
    }
}))(Tooltip);
function Bar({ user2, data, event, isStacked, showEvent, stackBy, i, mainStacks }) {
    const classes = useStyles();
    let [clicks, setClicks] = useState(user2 ? [...data.user2filtered] : [...data.filtered]);
    const [firstEventData, setfirstEventData] = useState([])
    const [stacks, setstacks] = useState([])
    useEffect(() => {
        let temp = user2 ? [...data.user2filtered] : [...data.filtered]
        let filtered = temp.filter((x) => {
            return x.event_data != null
        })
        let firstFiltered = filtered.filter(x => {
            let y = JSON.parse(x.event_data);
            let present = false;
            for (let i = 0; i < y.length; i++) {
                if (y[i].event_label == showEvent[0].label) present = true;
            }
            return present;
        })
        setfirstEventData([...firstFiltered])
        filtered = filtered.filter(x => {
            let y = JSON.parse(x.event_data);
            let present = false;
            for (let i = 0; i < y.length; i++) {
                if (y[i].event_label == event.label) present = true;
            }
            return present;
        })

        if (i > 0) {
            let tmp = [];
            firstFiltered.forEach(firstEvent => {
                filtered.forEach(secondEvent => {
                    if (firstEvent.user_id == secondEvent.user_id) {
                        tmp.push(secondEvent);
                    }
                })
            })
            setClicks(tmp);

        }
        else
            setClicks([...filtered]);

    }, [data, showEvent])
    useEffect(() => {
        if (!isStacked || Object.entries(stackBy).filter((val) => stackBy[val[0]]).length == 0) return setstacks([]);
        let tmp = [...clicks];
        let stks = {}

        let compare = Object.entries(stackBy).filter((val) => stackBy[val[0]])[0][0];
        for (let obj of tmp) {
            if (compare === 'session_details.referrer') {
                if (stks[obj.session_details[0].referrer]) stks[obj.session_details[0].referrer] += 1
                else stks[obj.session_details[0].referrer] = 1
            }
            else if (compare === 'session_details.hour') {
                if (stks[obj.session_details[0].hour]) stks[obj.session_details[0].hour] += 1
                else stks[obj.session_details[0].hour] = 1
            }
            else {

                if (stks[obj[compare]]) stks[obj[compare]] += 1;
                else stks[obj[compare]] = 1
            }
        }
        stks = Object.entries(stks)
        stks.sort((a, b) => b[1] - a[1])
        let others = ['Others', 0, 0.00]
        for (let i = 0; i < stks.length; i++) {
            stks[i][2] = (stks[i][1] * 100 / tmp.length).toFixed(2);

            if (stks[i][0].includes('Other') || stks[i][0].includes('NA') || stks[i][0].includes('null')) {

                others[1] = others[1] + stks[i][1]
                others[2] = (others[2] + (stks[i][1] * 100 / tmp.length))
                stks.splice(i, 1);
                i--
            }
            if (i > 3) {
                others[1] = others[1] + stks[i][1]
                others[2] = (others[2] + (stks[i][1] * 100 / tmp.length))
            }
        }

        let top5 = stks.slice(0, 4);
        if (stks.length >= 5 || others[1] > 0) {
            others[2] = (others[2]).toFixed(2)
            top5.push(others)
        }
        for (let i = 0; i < top5.length; i++) {
            mainStacks.forEach((mainStack, j) => {
                if (top5[i][0] == mainStack[0]) top5[i][3] = mainStack[3]
            })
        }
        setstacks(top5);
    }, [clicks, isStacked, stackBy])
    const tooltipTitle = (isStacked ? <section className={ classes.barColors }>
        { stacks.length > 0 ? stacks.map((stack, i) => {
            return <div className=''>
                <div className='flex items-center gap-6'>
                    <div className='circle' style={ { backgroundColor: stack[3] } }></div>
                    <h6 className='text-sm font-semibold'>{ stack[0] }</h6>
                    <p className='text-xs'>{ `(${stack[2]}%)` }</p>
                </div>
            </div>
        }) : <p>No data</p> }

    </section> : <h6>{ event.label }</h6>)
    return (
        <>
            <section className='w-full'>

                <div className="bar flex-col items-center gap-4 justify-center w-full">
                    <div className="rounded-lg flex items-center gap-2 justify-center w-full">

                        <HtmlTooltip title={ tooltipTitle } placement='top-end' arrow>
                            <div className={ classes.bar } style={ { width: `${(clicks.length * 90 / (firstEventData.length == 0 ? 1 : firstEventData.length)).toFixed(2)}%` } }>

                                { isStacked && stacks.map((stack, i, arr) => {
                                    return <div key={ stack[0] } style={ { width: `${stack[2]}%`, height: '100%', backgroundColor: stack[3] } }  ></div>
                                }) }
                            </div>
                        </HtmlTooltip>


                    </div>
                    <div className="flex pt-4 flex-col items-center justify-center text-center text-xs">
                        <p>{ event.label }</p>
                        <p>{ clicks.length }{ `(${((clicks.length / (firstEventData.length == 0 ? 1 : firstEventData.length)) * 100).toFixed(2)}%)` }</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Bar
