import {
    createSlice,
    createAsyncThunk,
    createEntityAdapter,
  } from '@reduxjs/toolkit';
  import { getMeta } from '../services/chart-builder.service';
  import { DashboardConstants } from '../../dashboard/constants/dashboard.constants';
  
  const namespace = 'chartbuilder/meta';
  const metaAdapter = createEntityAdapter({});
  
  const initialState = metaAdapter.getInitialState({
    loading: DashboardConstants.HTTPSTATUS.FINISHED,
    entities: { fieldNames: [], actionNames: [] },
    currentRequestId: '',
    error: '',
  });
  
  export const fetchMeta = createAsyncThunk(
    `${namespace}/fetchMeta`,
    async (client_id) => {
      try {
        const { data } = await getMeta(client_id);
        return data;
      } catch (err) {
        return rejectWithValue([], err);
      }
    }
  );
  
  const { actions, reducer } = createSlice({
    name: namespace,
    initialState,
    reducers: {},
    extraReducers: {
      [fetchMeta.fulfilled]: (state, { meta, payload }) => {
        if (meta.requestId === state.currentRequestId.requestId) {
          state.currentRequestId = '';
          state.entities = payload;
          state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
        }
      },
      [fetchMeta.pending]: (state, { meta }) => {
        state.loading = DashboardConstants.HTTPSTATUS.PENDING;
        state.currentRequestId = meta;
      },
      [fetchMeta.rejected]: (state, { meta, payload, error }) => {
        if (state.currentRequestId === meta) {
          state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
          state.entities = payload;
          state.error = error;
          state.currentRequestId = meta;
        }
      },
    },
  });
  
  export const { clear_state } = actions;
  
  export const { selectEntities: selectMetaEntities } = metaAdapter.getSelectors(
    (state) => {
      // console.log(state);
      return state.chartbuilder.meta;
    }
  );
  
  export default reducer;
  