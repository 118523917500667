import { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Formsy from 'formsy-react';
import { SelectFormsy, TextFieldFormsy } from '@fuse/core/formsy';
import {
  MenuItem,
  Divider,
  Button,
  ListSubheader,
  Grid,
} from '@material-ui/core';
import '../chart-builder.css';

const useStyles = makeStyles({
  layoutRoot: {},
});

function SaveChartForm(props) {
  console.log('SaveChartForm', props);
  const { chartInfo, dashboardList, onCancel, onSubmit, dashboardId } = props;

  const classes = useStyles();
  const [isFormValid, setIsFormValid] = useState(false);
  const [dashboard_id, setDashboardId] = useState(0);
  const formRef = useRef(null);

  let initialValueProps = {
    name: {
      value: '',
    },
    description: {
      value: '',
    },
    dashboard_id: {
      value: '',
    },
    dashboard_name: {
      value: '',
    },
  };

  if (chartInfo) {
    const { name, description, dashboard_id } = chartInfo;
    initialValueProps = {
      name: {
        value: name,
      },
      description: {
        value: description,
      },
      dashboard_id: {
        value: dashboard_id,
      },
    };
  }

  const handleCreateDashboard = (event) => {
    setDashboardId(event.target.value);
  };

  const disableButton = () => {
    setIsFormValid(false);
  };

  const enableButton = () => {
    setIsFormValid(true);
  };

  const handleSubmit = (formValues) => {
    onSubmit({ ...formValues });
  };

  return (
    <>
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="w-5/5 flex flex flex-col"
      >
        <TextFieldFormsy
          className={classes.root}
          type="text"
          name="name"
          label="Name"
          required
          {...initialValueProps.name}
        />
        <TextFieldFormsy
          className={classes.root}
          type="text"
          name="description"
          label="Description"
          {...initialValueProps.description}
        />
        <SelectFormsy
          className={classes.root}
          name="dashboard_id"
          label="Add to"
          required
          onChange={handleCreateDashboard}
          {...initialValueProps.dashboard_id}
          value={dashboardId}
        >
          {/* <ListSubheader>
            <em>Existing Dasboards</em>
          </ListSubheader> */}
          {dashboardList?.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id} selected={item.id == dashboardId}>
                {item.title}
              </MenuItem>
            );
          })}
          {/* <ListSubheader>
            <Divider className="mt-10 mb-10" />
          </ListSubheader> */}
          <MenuItem value={-1}>+ Create a new dashboard</MenuItem>
        </SelectFormsy>
        {dashboard_id === -1 && (
          <TextFieldFormsy
            className={classes.root}
            type="text"
            name="dashboard_name"
            label="New Dashboard Name"
            required
            {...initialValueProps.dashboard_name}
          />
        )}
        <Grid container className="mt-20 mb-10" direction="row-reverse">
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!isFormValid}
            type="submit"
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="mr-10"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Formsy>
    </>
  );
}

export default SaveChartForm;
