import { useEffect, useState } from 'react';
import { UserForm } from 'app/main/user-management/components/user-form/UserForm';
import { UserProfilePic } from 'app/main/user-management/components/profile-pic/UserProfilePic';
import { Box, Typography } from '@material-ui/core';
import withReducer from 'app/store/withReducer';
import { useDispatch, useSelector } from 'react-redux';
import reducer from 'app/auth/store';
import { memo } from 'react';
import { useParams, useHistory } from 'react-router-dom';




import {
  createUser,
  fetchMasterData,
  getUserById,
} from '../../user-management/services/users';

// let _userInfo = {
//   fullname: "AayushVerma",
//   email: "user@mail.com",
//   country: "Germany",
//   languagePreference: "German",
//   password: "Shahbaz@95",
//   businessAccountInfo: [
//     {
//       id: "1.1",
//       assignedBusiness: "www.healthurwealth.com",
//       role: "Admin",
//     },
//     {
//       id: "1.2",
//       assignedBusiness: "www.amazon.com",
//       role: "Co Admin",
//     },
//   ],
// };

const ProfilesContainer = () => {
  const params = useParams();
  const [userInfo, setUserInfo] = useState();
  const state = useSelector(({ auth }) => {
    return auth.user.data;
  });
  let usersData = {
    fullname: state.full_name,
    email: state.email_address,
    password: state.password,
    languagePreference: state.language,
    country: state.country,
  };
  const [countryList, setCountryList] = useState();
  const [languageList, setLanguageList] = useState();
  const [roleList, setRoleList] = useState();
  const [businessListOptions, setBusinessListOptions] = useState();
  const history = useHistory();
  const pageTitle = 'Profile Settings'
  let loggedInUser
  useEffect(async() => {
    let email_address = '';
      if (localStorage.getItem('lac-user-details')) {
        email_address = JSON.parse(localStorage.getItem('lac-user-details'))
          .email_address;
      }
      loggedInUser = email_address;
      let result = await getUserById(email_address, email_address);
      let businessAccountInfo = result.businessAccountInfo.map((item) => {
        return {
          current_business: item.companyUrl,
          access_role: item.role,
        };
      });
      setUserInfo({ ...result, businessAccountInfo });
  }, []);
  useEffect(async () => {
    let { countryList, languageList, roleList } =
      await fetchMasterData();

    const businessListOptions = JSON.parse(
      localStorage.getItem('lac-user-client-details')
    ).map((item) => {
      return {
        name: item.company_name,
        url: item.company_url,
        client_id: item.client_id,
        ...item,
      };
    });
    setCountryList(countryList);
    setLanguageList(languageList);
    const filteredRoleList = roleList.filter((item) => item != 'Admin');
    setRoleList(filteredRoleList);
    setBusinessListOptions(businessListOptions);
  }, []);
  const createUserOrUpdateUser = async (userForm) => {
    try {
      let data = await createUser(userForm);
      data && history.push('/dashboard/default');
    } catch(err) {
      console.log(err);
      //alert(err.message);
      
      //error handling
    }
  };
  const dataLoaded = userInfo && countryList && roleList && languageList &&businessListOptions;
  return (
    <Box style={{ width: '100%', height: '100%' }} p={10} pt={5}>
      <Typography className="h2 mb-24">{pageTitle}</Typography>
      <UserProfilePic />
      {dataLoaded && (
        <UserForm
          userInfo={userInfo}
          countryList={countryList}
          roleList={roleList}
          languageList={languageList}
          businessListOptions={businessListOptions}
          onSubmit={createUserOrUpdateUser}
          loggedInUserEmail={loggedInUser}
          editProfile={true}
          pageTitle={pageTitle}

        />
      )}
    </Box>
  );
};

export default withReducer('auth/user', reducer)(memo(ProfilesContainer));
