// import React from 'react'
import fetchIntercept  from 'fetch-intercept'
import { useAuth0 } from '@auth0/auth0-react'
 
const AuthInterceptor =async () => {
  const { getAccessTokenSilently } = useAuth0()
  

  fetchIntercept.register({
    request: async function (url, config) {
        config.headers.Authorization = `Bearer ${ await getAccessTokenSilently() }`
        return [url, config]
    },
    requestError: function (error) {
        // Called when an error occured during another 'request' interceptor call
        return Promise.reject(error)
    },
    response: function (response) {
        // Modify the reponse object
        return response
    },
    responseError: function (error) {
        // Handle an fetch error
        return Promise.reject(error)
    }
    })

}

export default AuthInterceptor
