import withReducer from "app/store/withReducer";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//ideal react timer
import IdleTimer from "react-idle-timer";
// import { useAuth0 } from "@auth0/auth0-react";
import jwt from "jsonwebtoken";
import _ from "@lodash";
import Typography from "@material-ui/core/Typography";
import { motion } from "framer-motion";
import reducer from "./store";
import { selectWidgetsEntities, getWidgets } from "./store/widgetsSlice";
import {
  selectKpisEntities,
  fetchKpis,
  update_kpidata,
} from "./store/kpisSlice";

import { selectChartsEntities, fetchCharts } from "./store/chartsSlice";
import {
  fetchDashboardMetaByID,
  saveChartBySelection,
} from "../chart-builder/store/dataSlice";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { DragAndDropContainer } from "./DragAndDropContainer";
import "./dashboard.css";
import {
  setKPIOrderChanged,
  setChartsOrderChanged,
} from "./store/dashboardSlice";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import RealTime from "./components/realtime/RealTime";
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";
import 'intro.js/introjs.css';
import introJs from 'intro.js';
import { disableDashboardTour } from "app/fuse-layouts/layout1/components/store/productTourSlice";
//const ReactGridLayout = WidthProvider(RGL);

// const DragField = styled(ReactGridLayout)`
// margin: 16px 28px 50px 28px;
// ${props => props.isDragging ? `
//   background: url(${dragBackground});
//   background-repeat: repeat-y;
//   background-position: 0px -4px;
//   background-size: 100% 52px;
// `: ''};
// `
// useEffect(()=>{
// const token = localStorage.getItem("lac-key");
// console.log("token", token);
// const decoded = jwt.decode(token);
// console.log("decoded",decoded);
// const { email,iat,exp } = decoded;
// console.log("email",email);
// console.log("iat",iat);
// console.log("exp",exp);

// },[])

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  root1: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    minHeight: "425px",
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}));

function Dashboard() {

  const classes = useStyles();
  const dispatch = useDispatch();
  const dashboardEntities = useSelector((state) => state.dashboard.page);
  const IdleTime = 5;
  const [initState, setInitState] = useState(true);
  // const { logout } = useAuth0();
  const IdleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const startTour = useSelector((state) => state.dashboard.tourFlag.dashboardTourFlag);
  const widgets = useSelector(selectWidgetsEntities);
  const kpis = useSelector(selectKpisEntities);
  const user = useSelector((state) => state.auth.user);
  // const [dynamicInsightData, setDynamicInsightData] = useState(null);
  const [disable, setDisable] = useState(false);
  const [diTimeStamp, setDiTimeStamp] = useState("");
  const charts = useSelector(selectChartsEntities);

  const history = useHistory();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const test = "test";
  const { id } = useParams();
  // console.log("route param id", id);
  //const [kpikeys,setkpikeys] = useState(new HashMap());
  const kpilist = [];
  const onIdle = () => {
    console.log("user is idle");
    Swal.fire({
      title: "You've been idle for a while",
      text: "You will be logged out in 5 seconds",
      icon: "error",
      timer: 5000,
      allowOutsideClick: false,
      showDenyButton: true,
      denyButtonText: "Log me out",
      confirmButtonText: "Stay logged in",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("user is active");
        stayActive();
      } else {
        // logout({ returnTo: window.location.origin });
        localStorage.removeItem("lac-key");
        localStorage.clear();
        history.push("/login");
      }
    });
    sessionTimeoutRef.current = setTimeout(setInitState, 5000);
  };
  const stayActive = () => {
    console.log("user is active");
    setInitState(true);
    clearTimeout(sessionTimeoutRef.current);
  };
  function appLogout() {
    localStorage.removeItem("lac-key");
    localStorage.clear();
    history.push("/login");
    // logout({ returnTo: window.location.origin });


  }
  const intro = introJs();
  const steps = [
    {
      element: '#dateRange',
      intro: ' KPIs and interactive charts display in accordance with the chosen date range, with a default setting of seven days.',
      position: 'top',
    },
    {
      element: '#realTimeSection',
      intro: 'This section provides info about the online users.You can get idea about the channel distribution,pages visited and traffic details ',
      position: 'top',
    },
    {
      element: '#kpiSection',
      intro: 'This section presents the KPIs you\'ve created. To make a new KPI, navigate to the \"Explore\" link in the menu. You can also edit or delete existing KPIs by clicking the icon located in the upper right corner of each KPI. ',
      position: 'top',
    },
    {
      element: '#chartSection',
      intro: ' By default, six charts gets displayed on dashboard page.To download these charts,click on the icon on the right-top of each chart. ',
      position: 'top',
    },

  ];
  const productTour = (e) => {
    intro.setOptions({ steps });
    intro.start();
    dispatch(disableDashboardTour());
  };
  useEffect(() => {
    if (startTour) {
      productTour();
    }
  }, [startTour])

  useEffect(() => {
    const token = localStorage.getItem("lac-key");
    // console.log("token#################", token);
    if (token) {
      jwt.verify(token, "thisisoursecretkey", function (err, decoded) {
        if (err) {
          console.log("err", err);
          history.push("/login");
        } else {
          const { iat, exp } = decoded;
          // console.log("email",email);
          console.log("iat", iat);
          console.log("exp", exp);
          console.log("current time", Date.now());
          //date.now() is in milliseconds
          if (Date.now() > exp * 1000) {
            history.push("/login");
            localStorage.removeItem("lac-key");
            localStorage.clear();
          } else {
            history.push("/dashboard/default");
          }
          //ideal time for 30 mins set to 1800000

          // if(Date.now() > (iat+1800)*1000){
          //   // history.push("/login");
          //   Swal.fire({
          //     title: "Session Expired",
          //     text: "Please login again",
          //     icon: "warning",
          //     confirmButtonText: "OK",
          //     confirmButtonColor: "grey",
          //     // cancelButtonColor: "red",
          //     showCancelButton: false,
          //   }).then((result) => {
          //     if (result.isConfirmed) {
          //       history.push("/login");
          //       localStorage.removeItem("lac-key");
          //       localStorage.clear();
          //     }
          //   });
          // }
        }
      });
    }
  }, [localStorage.getItem("lac-key")]);
  //var map = new HashMap();
  useEffect(() => {
    dispatch(getWidgets(test));

  }, [dispatch, id]);
  useEffect(() => {
    if (kpis?.data?.dashboard_data?.length > 0 || charts?.data?.dashboard_data?.length > 0)
    {
      if (kpis?.statusCode === 200 || charts?.statusCode === 200) {

        setIsDataLoaded(true);
      }
      else {

        setIsDataLoaded(false);
      }
    }
      if (kpis?.statusCode === 400 || charts?.statusCode === 400) {
      setIsDataLoaded(true);
      }
 
  }, [kpis?.data?.dashboard_data, charts?.data?.dashboard_data,kpis.statusCode, charts.statusCode])

  useEffect(() => {
    // Get KPI List
    dispatch(
      fetchKpis({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        dateFrequency: dashboardEntities.dateFrequency,
        dateRange: dashboardEntities.dateRange,
      })
    );
  }, [
    dispatch,
    id,
    dashboardEntities.dateRange,
    dashboardEntities.dateFrequency,
  ]);

  useEffect(() => {
    // Get Interactive Charts
    dispatch(
      fetchCharts({
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        dateFrequency: dashboardEntities.dateFrequency,
        dateRange: dashboardEntities.dateRange,
      })
    );
  }, [dispatch, id, dashboardEntities.dateRange]);

  useEffect(() => {
    // TODO check if the token is expired, if yes then push to login
    if (!localStorage.getItem("lac-user")) {
      history.push("/login");
    }
  }, [history]);

  useEffect(() => {
    console.log("updated reload");
    if (
      localStorage.getItem("reload-menu-items") &&
      localStorage.getItem("reload-menu-items") === "true"
    ) {
      localStorage.setItem("reload-menu-items", "false");
      window.setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }, [dispatch, id]);



  if (_.isEmpty(widgets)) {
    return null;
  }

  const container = {
    show: {
      transition: {
        staggerChildren: 0.06,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const deleteKPI = async (dashboardId, chartId) => {
    // console.log(dashboardId, chartId);

    const saveDetails = {
      dashboard_id: dashboardId,
      email_address: user.data.email || user.data.email_address,
      client_id: user.data.client_id,
    };

    //Get the existing chart details and add at the end
    const chartSelection = {
      userId: user.data.email || user.data.email_address,
      dashboard_id: dashboardId,
      client_id: user.data.client_id,
      email_address: user.data.email || user.data.email_address,
    };

    const details = await fetchDashboardMetaByID(chartSelection);
    saveDetails.dashboard_data = JSON.parse(details.dashboard_data);
    saveDetails.dashboard_name = details.dashboard_name;
    if (chartId) {
      const objIndex = saveDetails.dashboard_data.findIndex(
        (obj) => obj.chartId == chartId
      );
      // console.log("Index matched", objIndex);
      saveDetails.dashboard_data.splice(objIndex, 1);
      const response = await saveChartBySelection(saveDetails);

      dispatch(update_kpidata({ chartId: chartId }));
    }
  };

  const getKpis = () => {
    return kpis.data && kpis.data.dashboard_data
      ? kpis.data.dashboard_data.map((item, index) => {
        return {
          type: "KPI",
          id: index + 1,
          deleteKPI: deleteKPI,
          data: {
            description: item.chartDescription,
            dashboardId: kpis.data.dashboard_id,
            dashboardInfo: {
              name: kpis.data.dashboard_name,
              description: kpis.data.dashboardDescription,
              dashboard_id: kpis.data.dashboard_id,
            },
            chartsDetails: kpis.data.dashboard_data,
            data: item,
            position: index + 1,
          },
        };
      })
      : [];
  };

  const getCharts = () => {
    return charts.data && charts.data.dashboard_data
      ? charts.data.dashboard_data.map((item, index) => {
        return {
          type: "CHARTS",
          id: index + 1,
          data: item
        };
      })
      : [];
  };

  const handleKPIDrop = (regions) => {
    const updatedKPIList = regions.map((region, index) => {
      return {
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        chartId: region.data.chartId,
        chart_position: index + 1,
      };
    });
    // console.log(updatedKPIList);
    dispatch(setKPIOrderChanged({ flag: true, list: updatedKPIList }));
  };

  const handleChartDrop = (regions) => {
    const updatedChartList = regions.map((region, index) => {
      return {
        userId: user.data.email || user.data.email_address,
        clientId: user.data.client_id,
        chartId: region.data.chartId,
        chart_position: index + 1,
      };
    });
    dispatch(setChartsOrderChanged({ flag: true, list: updatedChartList }));
  };
  const DataLoader = () => {
    return <div className={'absolute h-screen w-full text-center flex justify-center'} style={{ background: '#f3f2f1', zIndex: 10, }}>
      <h2 >Loading...</h2>
    </div>
  }
  return (
    <>
      {initState ? (isDataLoaded ? (<div className="w-full">
        <RealTime />
        {<motion.div
          className="flex flex-col md:flex-row sm:p-0 container"
          variants={container}
          initial="hidden"
          animate="show"
        >

          <div className="flex flex-1 flex-col min-w-0 pt-16">

            <motion.div
              variants={item}
              className="widget w-full p-8 pb-32 mt-12"
              id="kpiSection"
            >
              <div className="titlecontainer relative flex flex-row items-center justify-between">
                <div className="flex flex-col">
                  <Typography
                    component={motion.div}
                    variants={item}
                    className="px-16 pb-8 text-16 font-500"
                  // color="textSecondary"
                  >
                    KPI
                  </Typography>
                </div>
              </div>
              {kpis && kpis.data && (
                <DragAndDropContainer
                  regions={getKpis()}
                  onDrop={handleKPIDrop}
                />
              )}
            </motion.div>
            <motion.div
              variants={item}
              className="widget w-full p-8 pb-32 mt-12"
            >
              <div className="titlecontainer relative flex flex-row items-center justify-between" id="chartSection">
                <div className="flex flex-col">
                  <Typography
                    component={motion.div}
                    variants={item}
                    className="px-16 pb-8 text-16 font-500"
                  // color="textSecondary"
                  >
                    Interactive Charts {" "}
                    <Tooltip
                      className="pb-5"
                      title="NA represents problem in capturing user detail as the user might stayed less than 10 seconds in the website or the user would have used  VPN or incognitive mode. "
                    >
                      <InfoIcon style={{ color: "#3B6FCF" }} />
                    </Tooltip>
                  </Typography>
                </div>
              </div>
              {charts && charts.data && (
                <DragAndDropContainer
                  regions={getCharts()}
                  onDrop={handleChartDrop}
                />
              )}
            </motion.div>
          </div>
        </motion.div>}
      </div>) : <DataLoader />

      ) : (
        appLogout()
      )}
      <IdleTimer
        ref={IdleTimerRef}
        onIdle={onIdle}
        timeout={IdleTime * 60 * 1000}
      ></IdleTimer>
    </>
  );
}

export default withReducer("dashboard", reducer)(Dashboard);
