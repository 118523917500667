import React from "react";
import Icon from "@material-ui/core/Icon";
import { isMatch } from "lodash";

export const PasswordStrengthIndicator = ({ passwordCriteria, value }) => {
  return (
    <div className="password-meter text-left mb-4">
      <p className="text-dark">Password must contain:</p>
      <ul className="text-muted">
        {passwordCriteria.map((criteria, index) => {
          return (
            <PasswordStrengthIndicatorItem
              isValid={criteria.regex.test(value)}
              text={criteria.text}
            />
          );
        })}

       
      </ul>
    </div>
  );
};

const PasswordStrengthIndicatorItem = ({ isValid, text }) => {
  const highlightClass = isValid
    ? "text-green"
    : isValid !== null
    ? "text-red"
    : "";
  return (
    <li className={highlightClass}>
      {text}
      <Icon className={highlightClass}>check_circle</Icon>
    </li>
  );
};
