import axios from 'axios';
import { DashboardConstants } from '../../dashboard/constants/dashboard.constants';

const token = localStorage.getItem('lac-key');
const instance = axios.create({
  baseURL: DashboardConstants.APIURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getMeta = async (client_id) => {
  const { data } = await instance.post(
    '/chart-builder/meta',
    { client_id: client_id },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};

export const getFiltersById = async (id) => {
  const { data } = await instance.post(`/chart-builder/filters/${id}`, null, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
    },
  });
  return data;
};

export const getChartDataBySelection = async (chartSelection) => {
  const { data } = await instance.post(`/chart-builder/data`, chartSelection, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
    },
  });
  return data;
};

export const saveChart = async (chartSelection) => {
  const { data } = await instance.post(`/chart-builder/save`, chartSelection, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
    },
  });
  return data;
};

export const getDetailsById = async (id) => {
  const { data } = await instance.post(`/chart-builder/details/${id}`, null, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
    },
  });
  return data;
};

export const getDashboardMetaByID = async (chartSelection) => {
  const { data } = await instance.post(
    '/pinned-dashboard/dashboard-meta',
    chartSelection,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};

// export const getChartData = async (filters) => {
//   const { data } = await instance.post(`/chart-builder/data/`, filters, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
//     },
//   });
//   return data;
// };
