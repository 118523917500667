import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import reducer from '../chart-builder/store';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { selectMetaEntities, fetchMeta } from '../chart-builder/store/metaSlice';
import { fetchKPIDetailsById } from '../chart-builder/store/detailsSlice';
import { v1 as uuid } from "uuid";
import {
    fetchAllDashboardsForUser,
    fetchChartDataBySelection,
    fetchDashboardMetaByID,
    saveChartBySelection,
    fetchAllUsers,
} from '../chart-builder/store/dataSlice';
import { getUsers } from "../../user-management/services/users";

import FilterSet from '../chart-builder/components/FilterSet';
import Chart from '../Charts/Chart';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import SaveChartForm from './components/SaveChartForm';
import {
    FormControl,
    IconButton,
    Icon,
    Divider,
    Paper,
    Select as UISelect,
    NativeSelect,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    ButtonGroup,
    Typography,
    TextField,
    InputLabel,
    ListSubheader,
    Grid
} from '@material-ui/core';
import '../chart-builder/chart-builder.css';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from "@material-ui/core/Tooltip";
import 'intro.js/introjs.css';
import introJs from 'intro.js';
import { disableKPIBuilderTour } from "app/fuse-layouts/layout1/components/store/productTourSlice";

const useStyles = makeStyles({
    layoutRoot: {},
});

const selectStyles = {
    control: (base, state) => ({
        ...base,
        height: '20px',
        'min-height': '20px',
    }),

    valueContainer: (base, state) => ({
        ...base,
        height: '20px',
        'min-height': '20px',
        position: 'initial',
        'padding-left': '4px',
    }),
    indicatorsContainer: (base, state) => ({
        ...base,
        '& div': {
            padding: '0',
        },
    }),
    indicatorSeparator: (base, state) => ({
        ...base,
        height: '14px',
        'min-height': '14px',
        'margin-top': '2px',
        'margin-bottom': '2px',
    }),
    dropdownIndicator: (base, state) => ({
        ...base,
        padding: '0 2px',
    }),
    input: (base, state) => ({
        ...base,
        margin: '0',
        'padding-top': '0',
    }),
};

function KpiBuilder() {
    const classes = useStyles();
    const params = useParams();
    const dispatch = useDispatch();
    const [maximizeClass, setMaximizeClass] = useState('');
    const [exploreType, setExploreType] = useState(1);
    const [chartId, setChartId] = useState(-1);
    const meta = useSelector(selectMetaEntities);
    const [saveOpen, setSaveOpen] = useState(false);
    const [dataSet, setDataSet] = useState(null);
    const [groupBy, setGroupBy] = useState(null);
    const [thenBy, setThenBy] = useState(null);
    const [compareBy, setCompareBy] = useState(null);
    const [dateFrequency, setDateFrequency] = useState('daily');
    const [dashboardFullList, setdashboardFullList] = useState([]);
    const [dashboardList, setdashboardList] = useState([]);
    const [usersList, setUsersList] = useState([]);

    const [makeChartCall, setMakeChartCall] = useState(params?.chartId ? false : true);
    const [dateRange, setDateRange] = useState('45D');
    const [showChart, setShowChart] = useState(false);
    const [chartInfo, setChartInfo] = useState({
        name: 'Untitled',
        description: 'No Description',
        dashboard_id: null,
    });
    const [chartType, setChartType] = useState('area');    
    const [dataFilterType] = useState('all');
    const [chartData, setChartData] = useState({});
    const history = useHistory();
    const user = useSelector((state) => state.auth.user);
    const startTour = useSelector((state) => state.dashboard.tourFlag.kpiBuilderTourFlag);
    const intro = introJs();
    const steps = [
      {
        element: '#chart-builder-explore-type',
        intro: 'Select the explore type here—it\'s all about the number of users.',
        position: 'top',
      },
      {
        element: '#filters',
        intro: 'You can add your filters here.Based on the filters chosen,the chart will be generated',
        position: 'top',
      },
    
      {
        element: '#dateSelection',
        intro: 'you can zoom in on hours, days, weeks, or months, the chart will adapt accordingly to provide you with the perfect view of your data.',
        position: 'top',
      },
      {
        element: '#generatedChart',
        intro: 'This section holds the generated chart based on your inputs',
        position: 'top',
      },
      {
        element: '#percentDif',
        intro: 'See the difference in performance factor between previous and current record',
        position: 'top',
      },
      {
        element: '#maximizeBtn',
        intro: 'Click here to maximize the generated chart',
        position: 'top',
      },
      {
        element: '#saveBtn',
        intro: 'Click here to save generted Kpi to default dashboard',
        position: 'top',
      }
    ];
    const productTour = (e) => {
      intro.setOptions({ steps });
      intro.start();
      dispatch(disableKPIBuilderTour());
    };
    useEffect(() => {
      if (startTour) {
        productTour();
      }
    }, [startTour])

    useEffect(() => {
        // TODO check if the token is expired, if yes then push to login
        if (!localStorage.getItem('lac-user')) {
            history.push('/login');
        }
    });
    useEffect(() => {
        dispatch(fetchMeta(user.data.client_id));
    }, [dispatch]);

    const getDataSet = (details) => {
        // check if the filters are passed
        if (!details.filters) {
            const filter = {
                filterIndex: 1,
                isValid: false,
                rowJoinOperator: "and",
                setJoinOperator: "and",
                validationMessage: "",
                filterRows: []
            };
            details.filters = { data: [filter] }
        }

        // check if the filters data has value or not
        if (!details.filters.data) {
            details.filters.data = [
                {
                    filterRows: [],
                    rowJoinOperator: 'and',
                    setJoinOperator: 'and',
                    isValid: false,
                    validationMessage: '',
                    filterIndex: 1,
                },
            ];
        }

        return details.filters;
    };

    useEffect(async () => {
        console.log('params', params);
        const dashboardListdetails = await fetchAllDashboardsForUser({
            client_id: user.data.client_id,
            email_address: user.data.email || user.data.email_address,
        });

        if (dashboardListdetails) {
            setdashboardFullList(dashboardListdetails);
            setdashboardList(dashboardListdetails?.filter(dashboard => dashboard.is_default === 'Y'));
        }

        const users = await getUsers(user.data.email || user.data.email_address);
        const usersListDetails = users.map(item => { return { email_address: item.email, full_name: item.fullname } });
        console.log('usersListDetails');
        console.log(usersListDetails);
        if (usersListDetails) setUsersList(usersListDetails);

        if (params.dashboardId) {
            const details = await fetchKPIDetailsById(
                params.dashboardId,
                parseInt(params?.chartId)
            ); // TODO get the chart data by chart id from dashboard or take from cache

            if (details) {
                setChartId(parseInt(params.chartId));
                setExploreType(details.exploreType);
                setDataSet(getDataSet(details));
                setGroupBy(details.groupBy);
                setThenBy(details.thenBy);
                setCompareBy(details.compareBy);
                setChartInfo({
                    name: details.chartTitle,
                    description: details.chartDescription,
                    dashboard_id: details.dashboard_id,
                });
                setDateFrequency(details.dateFrequency);
                setDateRange(details.dateRange);
                setChartData(
                    details.chartData || { series: details.series, filteredRecordCount: details.filteredRecordCount, previousRecordCount: details.previousRecordCount}
                );
                setShowChart(true);
            }
        }

        setMakeChartCall(true);
    }, []);

    useEffect(async () => {
        if (makeChartCall) {
            renderChart();
        }
    }, [
        dateRange,
        dateFrequency,
        groupBy,
        thenBy,
        compareBy,
        chartType,
        dataFilterType,
        dataSet,
    ]);
    const data = {
        series: chartData.series,
        title: chartInfo.name,
        description: chartInfo.description,
        type: chartType,
        filteredRecordCount: chartData.filteredRecordCount
    };
    const renderChart = async () => {

        const chartSelection = {
            exploreType: exploreType,
            filters: dataSet,
            groupBy: groupBy,
            thenBy: thenBy,
            compareBy: compareBy,
            dateFrequency: dateFrequency,
            dateRange: dateRange,
            type: chartType,
            dataFilterType:dataFilterType,
            client_id: user.data.client_id,
            email_address: user.data.email || user.data.email_address,
        };

        const details = await fetchChartDataBySelection(chartSelection);

        if (details) {
            //setChartType(chartType);
            setChartData(details.chartData);
        }
        console.log('chartType')
        console.log(chartType)
        setShowChart(true);
    };

    const handleClose = () => {
        setSaveOpen(false);
    };

    const handleFilterSetChange = async (dataset, changeType) => {
        setShowChart(false);
        setDataSet(dataset);
        //renderChart();
    };

    const handleExploreTypeChange = (selectedOption) => {
        const value = selectedOption?.value;
        setShowChart(false);
        setExploreType(value);
    };

    const handleGroupByChange = (selectedOption) => {
        const value = selectedOption?.value;
        setShowChart(false);
        setChartType('bar');
        setGroupBy(value);
    };

    const handleThenByChange = (selectedOption) => {
        const value = selectedOption?.value;
        setShowChart(false);
        setChartType('bar');
        setThenBy(value);
    };

    const handleCompareByChange = (selectedOption) => {
        const value = selectedOption?.value;
        setShowChart(false);
        setChartType('bar');
        setCompareBy(value);
    };

    const handleDateFrequency = (event) => {
        setShowChart(false);
        setDateFrequency(event.target.value);
    };

    const handleDateRange = (newDateRange) => {
        setShowChart(false);
        setDateRange(newDateRange);
    };

    const generateChartId = (id, saveDetails) => {
        if (id) {
            return parseInt(id);
        }
        else if (saveDetails?.dashboard_data?.length > 0) {
            return parseInt(saveDetails.dashboard_data[saveDetails.dashboard_data.length - 1].chartId) + 1;
        }
        else {
            return 1;
        }
    };

    const handleSave = async (pchartinfo) => {
        const saveDetails = {
            dashboard_id: pchartinfo.dashboard_id,
            dashboard_name: pchartinfo.dashboard_name,
            email_address: user.data.email || user.data.email_address,
            client_id: user.data.client_id,
            is_default: 'Y'
        };
        if (saveDetails.dashboard_id == -1) {
            //New chart case
            saveDetails.dashboard_id =
                dashboardFullList && dashboardFullList.length > 0
                    ? Math.max.apply(
                        Math,
                        dashboardFullList.map(function (o) {
                            return o.id;
                        })
                    ) + 1
                    : 1;

            saveDetails.dashboard_data = [
                {
                    chartId: 1,
                    chartTitle: pchartinfo.name,
                    chartDescription: pchartinfo.description,
                    exploreType: exploreType,
                    filters: Object.assign({}, dataSet, { exploreType: exploreType }),
                    type: chartType,
                    dataFilterType: dataFilterType,
                    groupBy: groupBy,
                    thenBy: thenBy,
                    compareBy: compareBy,
                    dateFrequency: dateFrequency,
                    dateRange: dateRange,
                    is_default: 'Y'
                },
            ];
        } else {
            //Get the existing chart details and add at the end
            const chartSelection = {
                userId: user.data.email || user.data.email_address,
                dashboard_id: saveDetails.dashboard_id,
                dateRange: dateRange,
                client_id: user.data.client_id,
                email_address: user.data.email || user.data.email_address,
            };

            const details = await fetchDashboardMetaByID(chartSelection);

            console.log('fetchDashboardMetaByID');
            console.log(details, params);
            saveDetails.dashboard_data = JSON.parse(details.dashboard_data);
            saveDetails.dashboard_name = details.dashboard_name;
            const id = params.chartId || pchartinfo?.chartId;
            if (id) {
                const objIndex = saveDetails.dashboard_data.findIndex(
                    (obj) => obj.chartId == (id)
                );
                console.log('Index matched');
                saveDetails.dashboard_data[objIndex] = {
                    chartId: id
                        ? parseInt(id)
                        : saveDetails.dashboard_data.length + 1, //Should be max + 1 chart number of all existing charts in the same dashboardd
                    chartTitle: pchartinfo.name,
                    chartDescription: pchartinfo.description,
                    exploreType: exploreType,
                    filters: Object.assign({}, dataSet, { exploreType: exploreType }),
                    type: chartType,
                    dataFilterType: dataFilterType,
                    groupBy: groupBy,
                    thenBy: thenBy,
                    compareBy: compareBy,
                    dateFrequency: dateFrequency,
                    dateRange: dateRange,
                    is_default: 'Y'
                };
            } else {
                saveDetails.dashboard_data.push({
                    chartId: generateChartId(id, saveDetails),
                    chartTitle: pchartinfo.name,
                    chartDescription: pchartinfo.description,
                    exploreType: exploreType,
                    filters: Object.assign({}, dataSet, { exploreType: exploreType }),
                    type: chartType,
                    dataFilterType: dataFilterType,
                    groupBy: groupBy,
                    thenBy: thenBy,
                    compareBy: compareBy,
                    dateFrequency: dateFrequency,
                    dateRange: dateRange,
                    is_default: 'Y'
                });
            }
        }

        const response = await saveChartBySelection(saveDetails);
        setSaveOpen(false);
        localStorage.setItem("reload-menu-items", "true");
        history.push("/dashboard/" + uuid());
    };

    const openSaveForm = (e) => {
        setSaveOpen(true);
    };

    const onMaximize = (event) => {
        setMaximizeClass(maximizeClass === '' ? 'maximize' : '');
    };

    const options = [
        { value: 1, label: 'Explore Users' },
        // { value: 2, label: 'Explore Sessions', isDisabled: true },
        // { value: 3, label: 'Explore Events', isDisabled: true },
    ];

    // when previousRecordCount is 0 and filteredRecordCount is not 0 then display as 100% else display as 0%
    const percentage = chartData?.previousRecordCount === 0 ? (chartData?.filteredRecordCount === 0 ? 0 : 100) : Math.round((chartData?.filteredRecordCount - chartData?.previousRecordCount) * 100 / chartData?.previousRecordCount);    
    const tooltipinfo ="Previous RecordCount = " + chartData?.previousRecordCount +"  Current RecordCount = "+ chartData?.filteredRecordCount;
    return (
        <>
            <div className="chart-builder-main">
                <div className="chart-builder-left">
                    <div>
                        <FormControl>
                            <Select
                                id={`chart-builder-explore-type`}
                                className="text-11 chart-builder-explore-type-select"
                                label="Select Type"
                                isSearchable={false}
                                value={options?.find((o) => o.value == exploreType)}
                                options={options}
                                onChange={handleExploreTypeChange}
                                styles={selectStyles}
                            ></Select>
                        </FormControl>
                    </div>
                    <div className="chart-builder-divider">
                        <Divider />
                    </div>
                    <div className="chart-builder-users kpi-builder" id="filters">
                        <FilterSet
                            fieldNames={meta.fieldNames}
                            fieldValues={meta.fieldValues}
                            actionNames={meta.actionNames}
                            dataset={dataSet}
                            canAddSegment={true}
                            onChange={handleFilterSetChange}
                        />
                    </div>
                </div>
                <div className="chart-builder-right">
                    <div class="chart-builder-right-header">
                        <Grid container={true} justify="flex-end">
                            <div class="chart-builder-right-header-save">
                                <Button
                                    size="small"
                                    variant="contained"
                                    // color="secondary"
                                    id="saveBtn"
                                    startIcon={<SaveOutlinedIcon />}
                                    onClick={openSaveForm}
                                >
                                    Save as KPI
                                </Button>
                            </div>
                        </Grid>
                    </div>
                    <Paper elevation={3} className="chart-builder-card">
                        <div class="chart-builder-right-top" id="dateSelection">
                            <div className="mr-20">
                                <NativeSelect
                                    defaultValue="daily"
                                    value={dateFrequency}
                                    onChange={handleDateFrequency}
                                >
                                    <option value="hourly">Hourly</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                </NativeSelect>
                            </div>
                            <div className="mr-20">
                                <ButtonGroup
                                    size="small"
                                    variant="outlined"
                                    aria-label="outlined button group"
                                >
                                    <Button
                                        value="today"
                                        className={`text-11 ${dateRange === 'today' ? 'btn-primary' : ''
                                            }`}
                                        onClick={() => handleDateRange('today')}
                                    >
                                        Today
                                    </Button>
                                    <Button
                                        value="7D"
                                        className={`text-11 ${dateRange === '7D' ? 'btn-primary' : ''
                                            }`}
                                        onClick={() => handleDateRange('7D')}
                                    >
                                        7D
                                    </Button>
                                    <Button
                                        value="30D"
                                        className={`text-11 ${dateRange === '30D' ? 'btn-primary' : ''
                                            }`}
                                        onClick={() => handleDateRange('30D')}
                                    >
                                        30D
                                    </Button>
                                    <Button
                                        value="45D"
                                        className={`text-11 ${dateRange === '45D' ? 'btn-primary' : ''
                                            }`}
                                        onClick={() => handleDateRange('45D')}
                                    >
                                        45D
                                    </Button>
                                    <Button
                                        disabled={true}
                                        value="2M"
                                        className={`text-11 ${dateRange === '2M' ? 'btn-primary' : ''
                                            }`}
                                        onClick={() => handleDateRange('2M')}
                                    >
                                        2M
                                    </Button>
                                    <Button
                                        disabled={true}
                                        value="3M"
                                        className={`text-11 ${dateRange === '3M' ? 'btn-primary' : ''
                                            }`}
                                        onClick={() => handleDateRange('3M')}
                                    >
                                        3M
                                    </Button>
                                    {/* <Button
                    value="12M"
                    className={`text-11 ${
                      dateRange === '12M' ? 'btn-primary' : ''
                    }`}
                    onClick={() => handleDateRange('12M')}
                  >
                    12M
                  </Button> */}
                                </ButtonGroup>
                            </div>
                            {/* <div className="mr-20 hide">
                <NativeSelect defaultValue={10}>
                  <option value={10}>Uniques</option>
                  <option value={20}>Monthly</option>
                  <option value={30}>Yearly</option>
                </NativeSelect>
              </div> */}
                        </div>
                    </Paper>
                    <Paper elevation={3} className={`chart-builder-card flex-grow-1 ${maximizeClass}`}>
                        <div class="chart-builder-right-middle">
                            <div class="chart-builder-right-middle-header">
                                <div className="mr-auto">
                                    <div class="chart-builder-right-middle-header-count">{data?.filteredRecordCount}</div>
                                    <div class="chart-builder-right-middle-header-title">{data?.title}</div>
                                </div>
                                <div>
                                {percentage?(  <> <span id="percentDif" style={{ "fontSize": "24px", "color": `${percentage > 0 ? "green" : "red"}`, "marginLeft": "auto" }}>
                                        {percentage}%
                                        <Tooltip title= {tooltipinfo}>
                                            <InfoIcon style={{ color: "#3B6FCF" }} />
                                        </Tooltip>
                                    </span>
                                    <IconButton id="maximizeBtn" size={'small'} title="Maximize window" onClick={onMaximize}>
                                        <Icon>{maximizeClass === '' ? 'call_made' : 'call_received'}</Icon>
                                    </IconButton></>):<></>}
                                 
                                </div>
                            </div>
                            {!showChart && (
                                <div class="chart-builder-right-middle-content">
                                    <div className="chart-builder-right-middle-content-icon">
                                        <TimelineIcon />
                                    </div>
                                    <div>
                                        <Typography variant="caption" display="block" gutterBottom>
                                            {(chartType == '' || chartType == '10') ? 'Start by selecting an event' : 'Please wait while the chart data is being fetched.'}
                                        </Typography>
                                    </div>
                                </div>
                            )}
                            {showChart && data && data.series && data.series.length > 0 && data.series.some((item) => {
                                return item.data && item.data.length > 0;
                            }) && (
                                    <div class="chart-builder-right-middle-chart-content" id="generatedChart" >
                                        <Chart 
                                            type={chartType}
                                            data={data}
                                            height={'500px'}
                                            width={'100%'}
                                        ></Chart>
                                    </div>
                                )}

                            {showChart && (!data || !data.series || data.series.length == 0 || data.series.every((item) => {
                                return item.data && item.data.length == 0;
                            })
                            ) && (
                                    <Typography component="span" className="font-semibold flex">
                                        No Data Available
                                    </Typography>
                                )}

                        </div>
                    </Paper>
                </div>
            </div>
            <Dialog
                fullWidth={true}
                className="w-full"
                open={saveOpen}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Create New</DialogTitle>
                <DialogContent>
                    <SaveChartForm
                        chartInfo={chartId !== -1 ? chartInfo : null}
                        dashboardList={dashboardList}
                        chartId={params.chartId || 0}
                        dashboardId={params.dashboardId || dashboardList?.[0]?.id}
                        onCancel={handleClose}
                        onSubmit={handleSave}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
}

export default withReducer('kpibuilder', reducer)(KpiBuilder);
