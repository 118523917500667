import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import DeviceCounts from "./devicecount/DeviceCounts";
import Chennalcounts from "./chennalcounts/Chennalcounts";
import { getRealtime, getAllRealTime } from "../../services/dashboard.service";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
//  Trafficcount
import Trafficcount from "./trafficcount/Trafficcount";
import Pagecount from "./pagecount/Pagecount";
import Swal from "sweetalert2";
import InfoIcon from "@material-ui/icons/Info";
import { motion } from "framer-motion";
const item = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};
const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  media: {
    height: 140,
  },
  root1: {
    minWidth: 275,
    maxWidth: 300,
    borderRadius: "6px",
    height: 250,
  },
  root3: {
    height: 2,
    borderRadius: 5,
  },
  root4: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Image = styled("img")({
  width: "100%",
});
function RealTime() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [realtimeData, setRealTimeData] = useState();
  const [lastNintyMins, setLastNintyMins] = useState();
  const [loading, setLoading] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  // api call with websocket every i minues without page refresh
  useEffect(async () => {
    if (localStorage.getItem("lac-user-active-client_id") === null) {
      return;
    }
    let Clint_id = localStorage.getItem("lac-user-active-client_id");
    await getRealtime({
      ClientID: Clint_id,
    })
      .then((res) => {
        setRealTimeData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    await getAllRealTime({
      ClientID: Clint_id,
    })
      .then((res) => {
        setLastNintyMins(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    const interval = setInterval(async () => {
      await getRealtime({
        ClientID: Clint_id,
      })
        .then((res) => {
          setRealTimeData(res?.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          // if message is unauthorized then logout
          if (err?.response?.data?.message === "Unauthorized") {
            console.log("logout");
          } else {
            console.log(err?.response?.data?.message);
          }
        });
      await getAllRealTime({
        ClientID: Clint_id,
      })
        .then((res) => {
          setLastNintyMins(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }, 20000);
    return () => clearInterval(interval);
  }, [localStorage.getItem("lac-user-active-client_id")]);

  // console.log(lastNintyMins,"lastNintyMins")
  return (
    <div className={classes.root} id="realTimeSection">
      <motion.div
    variants={item}
    className="widget w-full p-8 pb-32 mt-12"
  >

      <div className="titlecontainer relative flex flex-row items-center justify-between">
      <Typography className="px-16 pb-8 text-16 font-500">
        Real Time{" "}
        <Tooltip
          className="pb-5"
          title="Unique users are caputured and displayed on the Real Time graph. Unique users are defined as those users who stay on the website for more than 10 seconds and are not repeated for half hour. 
           NA represents problem in capturing user detail as the user might stayed less than 10 seconds in the website or the user would have used  VPN or incognitive mode. "
        >
          <InfoIcon style={{ color: "#3B6FCF" }} />
        </Tooltip>
      </Typography>
      </div>
      </motion.div>
      <Grid container spacing={2} className="px-16 pb-8 ">
        <Grid item xs={3}>
          {/* <Card className={classes.root1} > */}
          {realtimeData?.length > 0 && lastNintyMins?.length > 0 ? (
            <DeviceCounts
              realtimeData={realtimeData}
              lastNintyMins={lastNintyMins}
            />
          ) : (
            //  ternary operator for empty data and loading
            loading  ? (
              <div
                className="flex flex-col items-center justify-center h-full"
                style={{
                  height: "15rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "1px 1px 5px 0px #ccc",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                }}
              >
                {/* materil ui loading skeleton */}
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              </div>
            ):(
              <div className="flex flex-col items-center justify-center h-full"
                            style={{
                                height: "15rem",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "1px 1px 5px 0px #ccc",
                                borderRadius: "5px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc"

                            }}>
                            <Typography className="text-20 mb-16" color="textSecondary">
                                No Data Found!
                            </Typography>
                        </div>
            )
          )}
        </Grid>
        <Grid item xs={3}>
          {realtimeData?.length > 0 ? (
            <Chennalcounts  realtimeData={realtimeData}
            lastNintyMins={lastNintyMins} loading={loading} />
          ) : (
            // materail ui card for empty data
            loading  ? (
              <div
                className="flex flex-col items-center justify-center h-full"
                style={{
                  height: "15rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "1px 1px 5px 0px #ccc",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                }}
              >
                {/* materil ui loading skeleton */}
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              </div>
            ):(
              <div className="flex flex-col items-center justify-center h-full"
                            style={{
                                height: "15rem",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "1px 1px 5px 0px #ccc",
                                borderRadius: "5px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc"

                            }}>
                            <Typography className="text-20 mb-16" color="textSecondary">
                                No Data Found!
                            </Typography>
                        </div>
            )
          )}
        </Grid>
        <Grid item xs={3}>
          {realtimeData?.length > 0 ? (
            <Pagecount realtimeData={realtimeData}  loading={loading}/>
          ) : (
            loading  ? (
              <div
                className="flex flex-col items-center justify-center h-full"
                style={{
                  height: "15rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "1px 1px 5px 0px #ccc",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                }}
              >
                {/* materil ui loading skeleton */}
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              </div>
            ):(
              <div className="flex flex-col items-center justify-center h-full"
                            style={{
                                height: "15rem",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "1px 1px 5px 0px #ccc",
                                borderRadius: "5px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc"

                            }}>
                            <Typography className="text-20 mb-16" color="textSecondary">
                                No Data Found!
                            </Typography>
                        </div>
            )
          )}
          {}
        </Grid>
        <Grid item xs={3}>
          {realtimeData?.length > 0 ? (
            <Trafficcount realtimeData={realtimeData} loading={loading} />
          ) : (
            // materail ui card for empty data
            loading  ? (
              <div
                className="flex flex-col items-center justify-center h-full"
                style={{
                  height: "15rem",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "1px 1px 5px 0px #ccc",
                  borderRadius: "5px",
                  backgroundColor: "#fff",
                  border: "1px solid #ccc",
                }}
              >
                {/* materil ui loading skeleton */}
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              </div>
            ):(
              <div className="flex flex-col items-center justify-center h-full"
                            style={{
                                height: "15rem",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                boxShadow: "1px 1px 5px 0px #ccc",
                                borderRadius: "5px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc"

                            }}>
                            <Typography className="text-20 mb-16" color="textSecondary">
                                No Data Found!
                            </Typography>
                        </div>
            )
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default RealTime;
