import { PasswordStrengthIndicator } from "./PasswordStrengthIndicator";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import TextField from '@material-ui/core/TextField';
import { useState } from "react";
import { withFormsy } from "formsy-react";
import { memo } from "react";
import _ from '@lodash';


const FormsyPassword = (props) => {

  const importedProps = _.pick(props, [
		'autoComplete',
		'autoFocus',
		'children',
		'className',
		'defaultValue',
		'disabled',
		'FormHelperTextProps',
		'fullWidth',
		'id',
		'InputLabelProps',
		'inputProps',
		'InputProps',
		'inputRef',
		'label',
		'multiline',
		'name',
		'onBlur',
		'onChange',
		'onFocus',
		'placeholder',
		'required',
		'rows',
		'rowsMax',
		'select',
		'SelectProps',
		'type',
		'variant',
		'color'
	]);


  const {
    passwordCriteria,
    showCriteriaList,
    required,
    id,
    placeholder,
    testId,
    labelName,
    errorMessage,
    value,
  } = props;
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [visibility, setVisibility] = useState("visibility_off");

  function changeValue(event) {
    props.setValue(event.currentTarget.value); // comes from withFormsy
    if (props.onChange) {
      props.onChange(event);
    }
  }

 
  return (
    <>
      <TextField
        {...importedProps}  
        onChange={changeValue}
			  value={value}
			  error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
			  helperText={errorMessage}
        type={visibility === "visibility" ? "text" : "password"}
        onFocus={() => setPasswordFocused(true)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon
                className="text-20"
                color="action"
                onClick={() => {
                  setVisibility((prevState) => {
                    if (prevState === "visibility") {
                      return "visibility_off";
                    } else {
                      return "visibility";
                    }
                  });
                }}
              >
                {visibility}
              </Icon>
            </InputAdornment>
          ),
        }}
      />
      {showCriteriaList && passwordFocused && (
        <PasswordStrengthIndicator
          passwordCriteria={passwordCriteria}
          value={value}
        />
      )}
    </>
  );
};

export default memo(withFormsy(FormsyPassword));
