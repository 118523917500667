import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import { openDialog } from "app/store/fuse/dialogSlice";
import { DeleteRoleFromOrgDialog } from "../delete-dialog/DeleteRoleFromOrgDialog";

export const GridDeleteRole = (props) => {
  const dispatch = useDispatch();
  const { email, role, deleteUserRole } = props;
  return (
    <IconButton
      color="primary"
      aria-label="delete role"
      component="span"
      size="small"
      onClick={() =>
        dispatch(
          openDialog({
            children: (
              <DeleteRoleFromOrgDialog
                email={email}
                role={role}
                deleteUserRole={deleteUserRole}
              />
            ),
          })
        )
      }
    >
      {role && role != "Admin" ? <DeleteIcon fontSize="inherit" /> : null}
    </IconButton>
  );
};
