import {
  IconButton,
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { closeDialog } from "app/store/fuse/dialogSlice";
import { useDispatch } from "react-redux";

export const DeleteRoleFromOrgDialog = (props) => {
  const { email, role, deleteUserRole } = props;
  const dispatch = useDispatch();
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            color="primary"
            aria-label="delete role"
            component="span"
            size="small"
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
          <div>Delete access role</div>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">
          Are you sure to want to delete {role} access role of
          {" " + email}
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "center", padding: "22px" }}>
        <Button
          variant="contained"
          color="primary"
          size="lg"
          disabled={false}
          onClick={() => {
            deleteUserRole?.();
            dispatch(closeDialog());
          }}
        >
          Delete
        </Button>
        <Button
          variant="outlined"
          size="medium"
          // component="label"
          disabled={false}
          onClick={() => {
            dispatch(closeDialog());
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};
