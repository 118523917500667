import { combineReducers } from '@reduxjs/toolkit';
import widgets from './widgetsSlice';
import page from './dashboardSlice';
import kpis from './kpisSlice';
import charts from './chartsSlice';
import dynamicSlice from '../../dynamic-insight/store/dynamicSlice'
import realtimeSlice from "./realtimeSlice"
import tourFlag from "../../../../fuse-layouts/layout1/components/store/productTourSlice";
const reducer = combineReducers({
	widgets,
	page,
	kpis,
	charts,
	dynamic:dynamicSlice,
	realtime:realtimeSlice,
	tourFlag
});

export default reducer;
