import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { DashboardConstants } from "../../dashboard/constants/dashboard.constants";

import { dynamicInsight } from "../services/dynamicinsight.service";

const namespace = "dynamic";
const dynamicAdapter = createEntityAdapter({});




const initialState = dynamicAdapter.getInitialState({
  loading: DashboardConstants.HTTPSTATUS.FINISHED,
  entities: {
    allData: "",
    eventsInsight: {
      data: "",
      summary: "",
      insight_time_value_in_days: "",
      business_category: "",
      filterData: ""
    },
    trafficInsight: {
      data: "",
      summary: "",
      insight_time_value_in_days: "",
      business_category: "",
      filterData: ""
    },

  },
  currentRequestId: "",
  error: "",
  statusCode: "",
});

// export const fetchDynamicInsight = createAsyncThunk(
//   `${namespace}/fetchDynamicInsight`,
//   async (client_id, { rejectWithValue }) => {
//     try {
//       const data = await dynamicInsight(client_id);
//       console.log(data.data.dynamicInsights,"json data di")

//       return data;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );
const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {
    structuredData: (state, action) => {
      state.entities.allData = action.payload.allData,
        state.entities.trafficInsight.data = action.payload.DynamicTrafficData,
        state.entities.trafficInsight.business_category = action.payload.business_categoryTraffic,
        state.entities.trafficInsight.insight_time_value_in_days = action.payload.insightTimeValueInDays,
        state.entities.eventsInsight.data = action.payload.DynamicEventData,
        state.entities.eventsInsight.business_category = action.payload.business_categoryEvent,
        state.entities.eventsInsight.insight_time_value_in_days = action.payload.insightTimeValueInDays

    },
    updateDynamicInsight: (state, action) => {
      if (action.payload.category == "traffic") {
        const objIndex = state.entities.trafficInsight.filterData.findIndex(
          (obj) => obj.insight_text == action.payload.insight_text
        )

        state.entities.trafficInsight.filterData[objIndex].user_input = action.payload.user_input
      } else if (action.payload.category == 'event') {
        const objIndex = state.entities.eventsInsight.filterData.findIndex(
          (obj) => obj.insight_text == action.payload.insight_text
        )
        state.entities.eventsInsight.filterData[objIndex].user_input = action.payload.user_input
      }
      const objIndexdata = state.entities.allData.findIndex(
        (objIndex) => objIndex.insight_text == action.payload.insight_text
      )
      state.entities.allData[objIndexdata].user_input = action.payload.insight_text
    },
    filterDynamicInsight: (state, action) => {
      if (action.payload.category == "traffic") {

        const objIndex = state.entities.trafficInsight.data.filter(
          (obj) => obj.business_category == action.payload.selectedBusinessTraffic && obj.insight_time_value_in_days == action.payload.insight_time_value_in_days && obj.insight_type != "Summary"
        );

        state.entities.trafficInsight.filterData = objIndex;
      } else if (action.payload.category == 'event') {
        const objIndex = state.entities.eventsInsight.data.filter(
          (obj) => obj.business_category == action.payload.selectedBusinessEvent && obj.insight_time_value_in_days == action.payload.insight_time_value_in_days && obj.insight_type != "Summary"
        );

        state.entities.eventsInsight.filterData = objIndex;
      }
    },
    dateFilter: (state, action) => {

    },
    paginationDynamicInsight: (state, action) => {
      //   state.entities.data["pageCount"] = action.payload.page;
      state.entities['pageCount'] = action.payload.page;
    },
  },

});

export const { selectEntities: selectDynamicEntities } =
  dynamicAdapter.getSelectors((state) => {
    return state.dashboard.dynamic;
  });

export const {
  updateDynamicInsight,
  filterDynamicInsight,
  paginationDynamicInsight,
  structuredData
} = actions;
export default reducer;
