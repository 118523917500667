import axios from "axios";
import { DashboardConstants } from "../../app/dashboard/constants/dashboard.constants";

const token = localStorage.getItem("lac-key");
const instance = axios.create({
  baseURL: DashboardConstants.APIURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
export const getUsersList = async (email) => {
  const {
    data: { data: userData },
  } = await instance.post("/users/all", {
    email,
  });
  //const { usersList } = userData;

  return userData.usersList;
};
export const getOnBoardingMasterData = async () => {
  const { data } = await instance.get("/onboarding/master-data", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
    },
  });
  return data;
};

export const getUserAccount = async (company_url) => {
  const response = await instance.post(
    "/onboarding/agency-validation",
    company_url,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
      },
    }
  );
  return response;
};
export const makeRegistrationComplete = async (data) => {
  let { email_address } = JSON.parse(localStorage.getItem("lac-user-details"));
  let payload = {
    email_address: email_address,
    organization_type: data.organisationType,
    role_in_organization: data.role, // hardocoded
    agency_name: data.agencyName,
    agency_url: data.agencyUrl,
    company_name: data.companyName,
    company_url: data.companyUrl,
    company_business_type: data.businessType,
    company_platform: data.platform,
    company_traffic: data.traffic,
    company_country: data.country,
    client_id: data.client_id,
    product_register_progress: "Completed",
    register_progress: "Completed",
    last_update_date: new Date(),
  };
  const response = await instance.post(
    "/onboarding/registration-completed",
    [payload],
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
      },
    }
  );
  return response;
};
// const validateCompanyUrl = (company_url) => {
//   console.log(company_url, "company_url");
//   return `https://${company_url}`;
// };
export const createAccount = async (accountData) => {
  // let { email_address } = JSON.parse(localStorage.getItem("lac-user-details"));
   
 
  const lastEmail = window.location.href.split('/')[4].split('%')[0];
  if (accountData.agencyName && accountData.agencyUrl) {
    accountData.agencyName = accountData.agencyName;
    accountData.agencyUrl = accountData.agencyUrl;
  } else if (accountData.companyName && accountData.companyUrl) {
    accountData.companyName = accountData.companyName;
    // accountData.companyUrl = validateCompanyUrl(accountData.companyUrl);
    accountData.companyUrl = accountData.companyUrl;
  }

  let payload = {
    email_address: lastEmail,
    organization_type: accountData.organisationType,
    role_in_organization: "Admin", // hardocoded
    agency_name: accountData.agencyName,
    agency_url: accountData.agencyUrl,
    company_name: accountData.companyName,
    // company_url: `${validateCompanyUrl(accountData.companyUrl)}`,
    company_url: accountData.companyUrl,
    company_business_type: accountData.businessType,
    company_platform: accountData.platform,
    company_traffic: accountData.traffic,
    company_country: accountData.country,
    last_update_date: new Date(),
  };

  const { data } = await instance.post("/onboarding/create-account", [payload]);

  return data.data[0].client_id;
};

export const sendRegistrationEmail = async (email) => {
  const { data } = await instance.post("/onboarding/send-reg-email", email, {
    // headers: {
    //   Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
    // },
  });
  return data;
};

export const testConnection = async (accountData) => {
  // let { email_address } = JSON.parse(localStorage.getItem("lac-user-details"));
  // if (accountData.agencyName && accountData.agencyUrl) {
  //   accountData.companyName = accountData.agencyName;
  //   accountData.companyUrl = validateCompanyUrl(accountData.companyUrl);
  // } else if (accountData.companyName && accountData.companyUrl) {
  //   accountData.agencyName = accountData.companyName;
  //   accountData.agencyUrl = accountData.companyUrl;
  // }
  const lastEmail = window.location.href.split('/')[4].split('%')[0];
  try {
    let payload = {
      email_address: lastEmail,
      organization_type: accountData.organisationType,
      role_in_organization: accountData.role, // hardocoded
      agency_name: accountData.agencyName,
      agency_url: accountData.agencyUrl,
      company_name: accountData.companyName,
      company_url: accountData.companyUrl,
      company_business_type: accountData.businessType,
      company_platform: accountData.platform,
      company_traffic: accountData.traffic,
      company_country: accountData.country,
      client_id: localStorage.getItem("lac-user-active-client_id"),
      last_update_date: new Date(),
    };
    const { data } = await instance.post(
      "/onboarding/test-connection",
      payload
    );
    const emailInfo = {
      to: payload.email_address,
      subject: "Installation guidance",
      text: "Ljc Script",
      // html: `<div>&lt;script &gt;
      // (function(l, a, c, r, i, t, z) {
      //     l[c] = l[c] || function() {
      //       (l[c].q = l[c].q || []).push(arguments)
      //     };
      //     t = a.createElement(r);
      //     t.async = 1;
      //     t.src = "https://85.214.144.66:8086/api/config/"+i;
      //     z = a.getElementsByTagName(r)[0];
      //     z.parentNode.insertBefore(t, z);
      //   })(window, document, "lacritz", "script", '${payload?.client_id}');
      //   &lt;script &gt; </div>`,
      html: `<html lang="en">

      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
          <title>Installation guidance </title>
          <style>
              html,
              body {
      
      
                  background: #f1f1f1;
              }
      
              .email-container {
                  font-family: Arial, sans-serif;
                  background-color: #FFFFFF;
                  margin: 3%;
                  padding: 3%;
              }
      
              .heading {
                  text-align: left;
      
              }
      
              .button-like-link {
      
                  padding: 10px 20px;
                  text-decoration: none;
                  /* Remove underline */
                  background-color: #437eeb;
                  color: #fff;
                  border: none;
                  border-radius: 5vw;
                  cursor: pointer;
                  width: 10vw;
                  height: 5vh;
                  text-align: centre;
              }
      
              /* Remove the default button styles */
              .button-like-link:focus,
              .button-like-link:hover {
                  outline: none;
                  text-decoration: none;
              }
      
              .icon-background {
                  background-color: #437eeb;
                  border-radius: 50%;
                  z-index: -1;
              }
          </style>
      </head>
      
      <body class="email-container">
          <header class="heading">
              <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="10%" height="5%" />
              <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                  growth</p>
          </header>
      
      
          <hr style="border: 1px solid red;">
          <h3 style="font-size: 18px;color:red;text-align: center;">Installation guidance</h3>
          <h2>Dear Sir/Mam</h2>
      
          <p style="font-size: 18px;">Please copy and paste the below code snippet into the header section of the website</p>
      
          <div style="text-align: center; margin:2%;border:2px solid black">
          <p style=" font-size: 18px;font-weight: bold;" >&lt;script &gt;
                  (function(f, l, a, b, t, i, c) {
                  f[a] = f[a] || function() {
                  (f[a].q = f[a].q || []).push(arguments)
                  };
                  i = l.createElement(b);
                  i.async = 1;
                  i.src ='${process.env.REACT_APP_LJS_URL}'/api/config/'+t;
                  c = l.getElementsByTagName(b)[0];
                  c.parentNode.insertBefore(i, c);
                  })(window, document, "flable", "script", '${payload?.client_id}');
                  &lt;/script &gt;</p>
          </div>
          <p style="font-size: 18px;">For any further assistance or inquiries, please don't hesitate to reach out to our
              dedicated support team at support@flable.ai </p>
          <p style="font-size: 16px;">Best Regards,</p>
          <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
          <hr style="border: 1px solid #fafafa;margin-top:3%">
          <div id="socialConnect" style="width: 100%;text-align: center;">
              <table style="display: inline-block; vertical-align: middle;" width="auto">
                  <tr>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                  style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
      
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://twitter.com/Flableai" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                  </tr>
              </table>
          </div>
          <hr style="border: 1px solid #fafafa;margin-bottom:2%">
          <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
              <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                  rights
                  reserved.</span>
          </div>
          <div style="width: 100%;  text-align: center; display: table;margin:3px">
              <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                  company</span>
          </div>
          <div id="disclaimer" style="width: 100%;text-align: center;">
              <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                  registered
                  to the Flable platform as a user.This
                  indicates your agreement with our Terms of Use and Privacy Policies.
              </p>
          </div>
          <div style="width: 100%;text-align: center;">
      
              <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                  <tr>
                      <td style="text-align: center; ">
      
                          <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                      </td>
                      </td>
                  </tr>
              </table>
      
          </div>
      </body>
      
      </html>`,
    };
    await sendRegistrationEmail(emailInfo);
    return data;
  } catch (error) {
    const emailInfo = {
      to: email_address,
      subject: "Test Connection Failed",
      text: "Failure",
      // html: `<div>{error}</div>`,
      html: `<html lang="en">

      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
          <title>Test Connection Failed </title>
          <style>
              html,
              body {
                  background: #f1f1f1;
              }
      
              .email-container {
                  font-family: Arial, sans-serif;
                  background-color: #FFFFFF;
                  margin: 3%;
                  padding: 3%;
              }
      
              .heading {
                  text-align: left;
      
              }
      
              .button-like-link {
      
                  padding: 10px 20px;
                  text-decoration: none;
                  /* Remove underline */
                  background-color: #437eeb;
                  color: #fff;
                  border: none;
                  border-radius: 5vw;
                  cursor: pointer;
                  width: 10vw;
                  height: 5vh;
                  text-align: centre;
              }
      
              /* Remove the default button styles */
              .button-like-link:focus,
              .button-like-link:hover {
                  outline: none;
                  text-decoration: none;
              }
      
              .icon-background {
                  background-color: #437eeb;
                  border-radius: 50%;
                  z-index: -1;
              }
          </style>
      </head>
      
      <body class="email-container">
          <header class="heading">
              <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="10%" height="5%" />
              <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                  growth</p>
          </header>
      
      
          <hr style="border: 1px solid red;">
          <h3 style="font-size: 18px;color:red;text-align: center;">Test Connection Failed</h3>
          <h2>Dear Sir/Mam</h2>
      
          <p style="font-size: 18px;">Test connection failed.Please contact support@flable.ai for further assistance.</p>
      
          <p style="font-size: 16px;">Best Regards,</p>
          <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
          <hr style="border: 1px solid #fafafa;margin-top:3%">
          <div id="socialConnect" style="width: 100%;text-align: center;">
              <table style="display: inline-block; vertical-align: middle;" width="auto">
                  <tr>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                  style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
      
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://twitter.com/Flableai" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                  </tr>
              </table>
          </div>
          <hr style="border: 1px solid #fafafa;margin-bottom:2%">
          <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
              <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                  rights
                  reserved.</span>
          </div>
          <div style="width: 100%;  text-align: center; display: table;margin:3px">
              <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                  company</span>
          </div>
          <div id="disclaimer" style="width: 100%;text-align: center;">
              <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                  registered
                  to the Flable platform as a user.This
                  indicates your agreement with our Terms of Use and Privacy Policies.
              </p>
          </div>
          <div style="width: 100%;text-align: center;">
      
              <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                  <tr>
                      <td style="text-align: center; ">
      
                          <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                      </td>
                      </td>
                  </tr>
              </table>
      
          </div>
      </body>
      
      </html>`,
    };
    await sendRegistrationEmail(emailInfo);
    console.log("error testConnection", error);
    }
};

export const getJSSnippet = async () => {
  let { email_address } = JSON.parse(localStorage.getItem("lac-user-details"));

  let payload = {
    client_id: localStorage.getItem("lac-user-active-client_id"),
    email_address: email_address,
  };
  const { data } = await instance.post("/onboarding/js-snippet", payload);
  return data?.data;
};
export const listAddedUser = async (email) => {
  let payload = {
    email,
  };
  const { data } = await instance.post("/Login/get-new-user", payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
    },
  });
  return data;
};
