import {createSlice} from '@reduxjs/toolkit';

const heatmapSlice = createSlice({
	name: 'heatmap',
	initialState: {
		loading: true
	},
	reducers: {
		setLoading: (state, action) => {
			console.log('setloading called');
			state.loading = action.payload;
		}
	}
});

export const {setLoading} = heatmapSlice.actions;
export default heatmapSlice.reducer;
