import React from 'react';
import KPIWidget from './components/widgets/KPIWidget';
import Chart from '../Charts/Chart';
import { Paper } from '@material-ui/core';

export class DragAndDropRegion extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={`drag-and-drop-region drag-and-drop-region-${this.props.region.type}`}
        draggable={this.props.draggable}
        onDragStart={this.props.onDragStart({ id: this.props.region.id })}
        onDragOver={this.props.onDragOver({ id: this.props.region.id })}
        onDrop={this.props.onDrop({ id: this.props.region.id })}
      >
        {this.props.region.type === 'KPI' && (
          <KPIWidget kpidata={this.props.region.data} deleteKPI={this.props.region.deleteKPI} />
        )}
        {this.props.region.type === 'CHARTS' && (
          <Paper className="drag-and-drop-chart-paper">
            <Chart
              type={this.props.region.data.type}
              data={this.props.region.data}
            ></Chart>
          </Paper>
        )}
      </div>
    );
  }
}

export class DragAndDropContainer extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      regions: props.regions && props.regions.length > 0
        ? props.regions[0].type === 'CHARTS'
          ? props.regions.filter((region) => {
            return region.data.series[0].data.length > 0;
          })
          : props.regions
        : [],
    };
  }
  componentDidUpdate(prevProps, prevState) {
    const nextProps = {
      regions: this.props.regions && this.props.regions.length > 0
        ? this.props.regions[0].type === 'CHARTS'
          ? this.props.regions.filter((region) => {
            return region.data.series[0].data.length > 0;
          })
          : this.props.regions
        : [],
    };
    if (
      JSON.stringify(nextProps.regions) !== JSON.stringify(this.state.regions)
    ) {
      this.setState(nextProps);
    }
  }
  swapRegions = (fromRegion, toRegion) => {
    let regions = this.state.regions.slice();
    let fromIndex = -1;
    let toIndex = -1;

    for (let i = 0; i < regions.length; i++) {
      if (regions[i].id === fromRegion.id) {
        fromIndex = i;
      }
      if (regions[i].id === toRegion.id) {
        toIndex = i;
      }
    }

    if (fromIndex != -1 && toIndex != -1) {
      let { fromId, ...fromRest } = regions[fromIndex];
      let { toId, ...toRest } = regions[toIndex];
      regions[fromIndex] = { id: fromRegion.id, ...toRest };
      regions[toIndex] = { id: toRegion.id, ...fromRest };
      this.setState({ regions: regions });
      if (this.props.onDrop) {
        this.props.onDrop(regions);
      }
    }
  };

  /* The dragstart event is fired when the user starts dragging an element or text selection */
  /* event.target is the source element : that is dragged */
  /* Firefox requires calling dataTransfer.setData for the drag to properly work */
  handleDragStart = (data) => (event) => {
    let fromRegion = JSON.stringify({ id: data.id });
    event.dataTransfer.setData('dragContent', fromRegion);
  };

  /* The dragover event is fired when an element or text selection is being dragged */
  /* over a valid drop target (every few hundred milliseconds) */
  /* The event is fired on the drop target(s) */
  handleDragOver = (data) => (event) => {
    event.preventDefault(); // Necessary. Allows us to drop.
    return false;
  };

  /* Fired when an element or text selection is dropped on a valid drop target */
  /* The event is fired on the drop target(s) */
  handleDrop = (data) => (event) => {
    event.preventDefault();

    let fromRegion = JSON.parse(event.dataTransfer.getData('dragContent'));
    let toRegion = { id: data.id };

    this.swapRegions(fromRegion, toRegion);
    return true;
  };

  makeRegions = () => {
    return this.state.regions.map((region) => (
      <DragAndDropRegion
        region={region}
        key={region.id}
        draggable="true"
        onDragStart={this.handleDragStart}
        onDragOver={this.handleDragOver}
        onDrop={this.handleDrop}
      />
    ));
  };

  render() {
    return (
      this.state.regions?.length > 0 && (
        <div className="drag-and-drop-container pb-32">
          {this.makeRegions()}
        </div>
      )
    );
  }
}
