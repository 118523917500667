import AddIcon from "@material-ui/icons/Add";
import { Button, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export const ActionBar = (props) => {
  const history = useHistory();

  const { content, onSearch } = props;
  return (
    <>
      <div
        style={{ flex: "1 1 50%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {content}
      </div>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => {
          history.push("/manage-users/add-user");
        }}
        disabled={false}
      >
        Add user
      </Button>
      <TextField
        className="ml-20"
        label="Search field"
        type="search"
        onChange={(e) => onSearch(e.target.value?.toLocaleLowerCase())}
      />
    </>
  );
};
