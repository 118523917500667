import { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Formsy from 'formsy-react';
import { SelectFormsy, TextFieldFormsy } from '@fuse/core/formsy';
import {
    MenuItem,
    Divider,
    Button,
    ListSubheader,
    Grid,
} from '@material-ui/core';
import '../chart-builder.css';

const useStyles = makeStyles({
    layoutRoot: {},
});

function ShareChartForm(props) {
    const { usersList, onCancel, onSubmit } = props;

    const classes = useStyles();
    const [selected_email, setEmail] = useState('');
    const formRef = useRef(null);

    const handleSelectUser = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (formValues) => {
        onSubmit(selected_email);
    };

    return (
        <>
            <Formsy
                onValidSubmit={handleSubmit}
                ref={formRef}
                className="w-5/5 flex flex flex-col"
            >
                <SelectFormsy
                    className={classes.root}
                    name="selected_email"
                    label="Share with"
                    onChange={handleSelectUser}
                >
                    <ListSubheader>
                        <em>Choose User</em>
                    </ListSubheader>
                    {usersList?.map((item) => {
                        return (
                            <MenuItem key={item.email_address} value={item.email_address}>
                                {item.full_name}
                            </MenuItem>
                        );
                    })}
                </SelectFormsy>
                <Grid container className="mt-20 mb-10" direction="row-reverse">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        type="submit"
                    >
                        Save
                    </Button>
                    <Button
                        color="primary"
                        size="small"
                        className="mr-10"
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Formsy>
        </>
    );
}

export default ShareChartForm;
