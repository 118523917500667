import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useState, useEffect } from 'react';
import { DashboardConstants } from 'app/main/app/dashboard/constants/dashboard.constants';
import { useHistory } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import axios from 'axios';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// import { submitRegister } from 'app/auth/store/registerSlice';
import * as yup from 'yup';
import _ from '@lodash';
import 'intro.js/introjs.css';
import introJs from 'intro.js';
// import GoogleSignin from './GoogleSignup';
// import GoogleSignup from './GoogleSignup';
// import { Label } from 'reactstrap';

import { openDialog } from 'app/store/fuse/dialogSlice';
import { UserExistsDialog } from './user-exists-dialog/UserExistsDialog';

import PasswordStrengthIndicator from './passwordStrengthIndicator';
import { getUsersList, sendRegistrationEmail } from "../services/register.service"
import { tr } from 'date-fns/locale';
const isNumberRegx = /\d/;
const specialCharacterRegx = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  fullName: yup.string().required('You must enter full name'),
  email: yup
    .string()
    .email('You must enter a valid email')
    .required('You must enter an email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
});

const defaultValues = {
  fullName: '',
  email: '' /*,
	password: ''*/,
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function JWTRegisterTab(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const authRegister = useSelector(({ auth }) => auth.register);
  const [hidePassword, togglePasswordType] = useState(true);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [passwordValidity, setPasswordValidity] = useState({
    minChar: false,
    number: false,
    specialChar: false,
  });

  const {
    register,
    formState,
    handleSubmit,
    reset,
    errors,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields } = formState;
  const intro = introJs();
  const productTour = (e) => {
    intro.setOptions({ steps });
    intro.start();
  };
  const steps = [
    {
      element: '#nameTxt',
      intro: ' Begin your registration by providing essential details. Enter your name ',
      position: 'top',
    },
    {
      element: '#emailTxt',
      intro: ' Enter your  Email id. ',
      position: 'top',
    },
    {
      element: '#passwordTxt',
      intro: ' Set up a password for your account here. ',
      position: 'top',
    },
    {
      element: '#registerButton',
      intro: ' Click Account creation button,to get your account created and you will receive an email  ',
      position: 'top',
    },
    {
      element: '#signinLink',
      intro: 'If you are registered user,click here to signin to the application ',
      position: 'top',
    },



  ];
  async function registerEmail(email) {
    // const url = window.location.href
    // const urlArray = url.split("/")
    // const lastEmail = urlArray[urlArray.length - 1]

    const emailInfo = {
      to: email,
      cc: '',
      // subject: 'Lacritz Registration',
      // text: 'Registration link for Lacritz',
      subject: 'Flable Registration',
      text: 'Registration link for Flable',
      html: `<html lang="en">

      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
          <title>Registration link for Flable </title>
          <style>
              html,
              body {
                  background: #f1f1f1;
              }
      
              .email-container {
                  font-family: Arial, sans-serif;
                  background-color: #FFFFFF;
                  margin: 3%;
                  padding: 3%;
              }
      
              .heading {
                  text-align: left;
      
              }
      
              .button-like-link {
      
                  padding: 10px 20px;
                  text-decoration: none;
                  /* Remove underline */
                  background-color: #437eeb;
                  color: #fff;
                  border: none;
                  border-radius: 5vw;
                  cursor: pointer;
                  width: 10vw;
                  height: 5vh;
                  text-align: centre;
              }
      
              /* Remove the default button styles */
              .button-like-link:focus,
              .button-like-link:hover {
                  outline: none;
                  text-decoration: none;
              }
      
              .icon-background {
                  background-color: #437eeb;
                  border-radius: 50%;
                  z-index: -1;
              }
          </style>
      </head>
      
      <body class="email-container">
          <header class="heading">
              <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="10%" height="5%" />
              <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                  growth</p>
          </header>
      
      
          <hr style="border: 1px solid red;">
      
          <h3 style="font-size: 18px;color:red;text-align: center;">Registration link for Flable </h3>
      
          <p style="font-size: 18px;">To register please click on the link below.</p>
      
          <div style="text-align: center; margin:2%">
              <a style="text-align:center;color:white" href='${process.env.REACT_APP_DASHBOARD_URL}/account-setup/${email}}'
                  class="button-like-link">Registration Link</a>
          </div>
          <p style="font-size: 18px;">For any further assistance or inquiries, please don't hesitate to reach out to our
              dedicated support team at support@flable.ai </p>
          <p style="font-size: 16px;">Best Regards,</p>
          <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
          <hr style="border: 1px solid #fafafa;margin-top:3%">
          <div id="socialConnect" style="width: 100%;text-align: center;">
              <table style="display: inline-block; vertical-align: middle;" width="auto">
                  <tr>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                  style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
      
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://twitter.com/Flableai" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                  </tr>
              </table>
          </div>
          <hr style="border: 1px solid #fafafa;margin-bottom:2%">
          <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
              <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                  rights
                  reserved.</span>
          </div>
          <div style="width: 100%;  text-align: center; display: table;margin:3px">
              <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                  company</span>
          </div>
          <div id="disclaimer" style="width: 100%;text-align: center;">
              <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                  registered
                  to the Flable platform as a user.This
                  indicates your agreement with our Terms of Use and Privacy Policies.
              </p>
          </div>
          <div style="width: 100%;text-align: center;">
      
              <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                  <tr>
                      <td style="text-align: center; ">
      
                          <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                      </td>
                      </td>
                  </tr>
              </table>
      
          </div>
      </body>
      
      </html>`,
    };
    await sendRegistrationEmail(emailInfo)
  }

  const registerUser = (payLoad) => {
    // e.preventDefault();
    console.log('registerUser triggered!!!!', payLoad);
    return new Promise((resolve, reject) => {
      axios
        .post(DashboardConstants.APIURL + '/Login/register', {
          email: payLoad.email,
          passkey: payLoad.password,
          /*client_user_id: 'A0100309',
        client_id: 'A01003',
        title: 'Mr',
        first_name: payLoad.fullName,
        last_name: payLoad.fullName,
        middle_name: payLoad.fullName,*/
          display_name: payLoad.fullName,
          terms_and_conditions: payLoad.terms_and_conditions || 'Y',
          cb_product_updates: payLoad.cb_product_updates || 'Y',
        })
        .then(function (response) {
          console.log(response);
          console.log('registerUser response!!!!', payLoad);

          if (response && response.data && response.data.errorMessage) {

            dispatch(
              openDialog({
                children: <UserExistsDialog email={payLoad.email} />,
              })
            );
          } else {
            registerEmail(payLoad.email)
            localStorage.setItem(
              'lac-user-details',
              JSON.stringify({ email_address: payLoad.email, product_register_progress: "Step 1" })
            );
            history.push(`/account-setup/${payLoad.email}`);
          }
        })
        .catch(function (error) {
          console.log(error);
          history.push('/account-setup');
        });
    });
  };

  useEffect(() => {
    authRegister.errors.forEach((error) => {
      setError(error.type, {
        type: 'manual',
        message: error.message,
      });
    });
  }, [authRegister.errors, setError]);
  // const emailVerifydata = async (email) => {
  //     const response = await getUserEmail(email);

  //   };
  // const emailVerifydata = async (email) => {
  //   try {
  //     const response = await getUsersList(email);
  //       if(response){
  //         return true;
  //       }else{
  //         return false;
  //       }
  //     }catch(e){
  //       console.log(e);
  //     }


  // };
  async function onSubmit(model) {
    localStorage.clear()
    registerUser(model);
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const onChangePassword = (password) => {
    setPassword(password);

    setPasswordValidity({
      minChar: password.length >= 8 ? true : false,
      number: isNumberRegx.test(password) ? true : false,
      specialChar: specialCharacterRegx.test(password) ? true : false,
    });
  };
  return (
    <div className="w-full">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <form
        className="flex flex-col justify-center w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TextField
          className="mb-16"
          type="text"
          name="fullName"
          label="Full Name"
          inputRef={register}
          id="nameTxt"
          error={!!errors.fullName}
          helperText={errors?.fullName?.message}
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20" color="action">
                  person
                </Icon>
              </InputAdornment>
            ),
          }}
          required
        />

        <TextField
          className="mb-16"
          type="text"
          name="email"
          inputRef={register}
          error={!!errors.email}
          helperText={errors?.email?.message}
          label="Email Id"
          id="emailTxt"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20" color="action">
                  email
                </Icon>
              </InputAdornment>
            ),
          }}
          required
        />

        <TextField
          className="mb-16"
          type={hidePassword ? 'password' : 'text'}
          label="Set Password"
          name="password"
          inputRef={register}
          id="passwordTxt"
          error={!!errors.password}
          helperText={errors?.password?.message}
          onFocus={() => setPasswordFocused(true)}
          onChange={(e) => onChangePassword(e.target.value)}
          autoComplete="new-password"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon
                  className="text-20"
                  color="action"
                  onClick={() => togglePasswordType(!hidePassword)}
                >
                  {hidePassword ? 'visibility_off' : 'visibility'}
                </Icon>
              </InputAdornment>
            ),
          }}
          required
        />

        {passwordFocused && (
          <PasswordStrengthIndicator validity={passwordValidity} />
        )}

        {/* <FormGroup>
          <FormControlLabel
            className="font-normal mr-8"
            control={<Checkbox />}
            label="I have read and agree to the Terms of Service and Privacy Policy"
          />
          <FormControlLabel
            className="font-normal mr-8"
            control={<Checkbox />}
            label="I want to receive product updates via email"
          />
        </FormGroup> */}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="REGISTER"
          id="registerButton"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          value="legacy"
        >
          Create Account
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          onClick={productTour}
        >
          Take a tour
        </Button>
        {/* <GoogleSignup /> */}
      </form>
    </div>
  );
}

export default JWTRegisterTab;
