import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { Grid, Box, Icon, Card, CardActions, CardContent, CardMedia, Button, ButtonGroup, Typography } from '@material-ui/core';
import './explore.css';
import 'intro.js/introjs.css';
import introJs from 'intro.js';
import { disableExploreTour } from "app/fuse-layouts/layout1/components/store/productTourSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles({
    layoutRoot: {}
});

function Explore() {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const startTour = useSelector((state) => state.dashboard.tourFlag.exploreTourFlag);
    const handleChartClick = (userPreference) => {
        //update the state     

        // navigate to chart builder
        history.push("/chartbuilder");
    };
    const handleKPIBuilderClick = (userPreference) => {
        //update the state     

        // navigate to kpi builder
        history.push("/kpibuilder");
    };
    const intro = introJs();
    const steps = [
        {
            element: '#cbBtn',
            intro: 'Click here to navigate to chart builder page',
            position: 'top',
        },
        {
            element: '#kpiBtn',
            intro: 'Click here to navigate to KPI builder page',
            position: 'top',
        }
    ];
    const productTour = (e) => {
        intro.setOptions({ steps });
        intro.start();
        dispatch(disableExploreTour());
    };
    useEffect(() => {
        if (startTour) {
            productTour();
        }
    }, [startTour])
    return (
        <>
            <Grid container
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid item>
                    <div className="explore-main">
                        <div className="explore-icon explore-icon-chart">
                            <Icon>addchart</Icon>
                        </div>
                        <div className="explore-sub-section">
                            <Typography component="span" className="text-16 font-700 flex">
                                Chart Builder
                            </Typography>
                            <Typography
                                className="text-12 font-500  explore-description"
                                color="textSecondary"
                            >
                                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet non integer condimentum nunc, augue a, dolor in semper. Facilisis senectus netus diam sagittis, augue blandit dui et consequat. Risus risus a non. */}
                                Chart builder is the most powerful and professional analysis tool which helps the client to customise, visualise and analyse the required parameters of business.
                                Using this tool will make you feel confident and predict the future of your bussiness.
                                Easily make impressive customised charts and pin the charts for future reference.
                            </Typography>
                            <ButtonGroup size="small" variant="contained" aria-label="contained button group" style={{ boxShadow: 'none' }}>
                                <Button className="text-11" id="cbBtn" onClick={() => handleChartClick(1) }>Explore Users</Button>
                                {/*<Button className="text-11" onClick={() => handleChartClick(1)} disabled = {true}>Explore Sessions</Button>
                                <Button className="text-11" onClick={() => handleChartClick(1)} disabled = {true}>Explore Events</Button>*/}
                            </ButtonGroup>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid item>
                    <div className="explore-main">
                        {/* <div className="explore-icon explore-icon-chart"> */}
                        <div className="explore-icon explore-icon-kpi">

                            <Icon>addchart</Icon>
                        </div>
                        <div className="explore-sub-section">
                            <Typography component="span" className="text-16 font-700 flex">
                                KPI Builder
                            </Typography>
                            <Typography
                                className="text-12 font-400   explore-description"
                                color="textSecondary"
                            >
                                {/* KPI builder tool of Lacritz helps to build dashboard quickly and easily to view your companys Key Perforance Indicators. */}
                                KPI builder tool of Flable helps to build dashboard quickly and easily to view your companys Key Perforance Indicators.
                                You can build your own customised dashboard with required indicators, which saves your time and helps you to achieve your business goals quickly.
                            </Typography>
                            <ButtonGroup size="small" variant="contained" aria-label="contained button group" style={{ boxShadow: 'none' }}>
                                <Button className="text-11" id="kpiBtn" onClick={() => handleKPIBuilderClick(1)}>Explore Users</Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {/* // Commeting for MVP-1 */}
            {/* <Grid container
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid item>
                    <div className="explore-main">
                        <div className="explore-icon explore-icon-funnel">
                            <Icon>addchart</Icon>
                        </div>
                        <div className="explore-sub-section">
                            <Typography component="span" className="font-semibold flex">
                                Funnel
                            </Typography>
                            <Typography
                                className="text-11 font-medium capitalize explore-description"
                                color="textSecondary"
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet non integer condimentum nunc, augue a, dolor in semper. Facilisis senectus netus diam sagittis, augue blandit dui et consequat. Risus risus a non.
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container
                justifyContent="flex-start"
                alignItems="flex-start">

                <Grid item>
                    <div className="explore-main">
                        <div className="explore-icon explore-icon-kpi">
                            <Icon>addchart</Icon>
                        </div>
                        <div className="explore-sub-section">
                            <Typography component="span" className="font-semibold flex">
                                KPI
                            </Typography>
                            <Typography
                                className="text-11 font-medium capitalize explore-description"
                                color="textSecondary"
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet non integer condimentum nunc, augue a, dolor in semper. Facilisis senectus netus diam sagittis, augue blandit dui et consequat. Risus risus a non.
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container
                justifyContent="flex-start"
                alignItems="flex-start">

                <Grid item>
                    <div className="explore-main">
                        <div className="explore-icon explore-icon-cohort">
                            <Icon>addchart</Icon>
                        </div>
                        <div className="explore-sub-section">
                            <Typography component="span" className="font-semibold flex">
                                Cohort
                            </Typography>
                            <Typography
                                className="text-11 font-medium capitalize explore-description"
                                color="textSecondary"
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet non integer condimentum nunc, augue a, dolor in semper. Facilisis senectus netus diam sagittis, augue blandit dui et consequat. Risus risus a non.
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid container
                justifyContent="flex-start"
                alignItems="flex-start">

                <Grid item>
                    <div className="explore-main">
                        <div className="explore-icon explore-icon-user-journey">
                            <Icon>addchart</Icon>
                        </div>
                        <div className="explore-sub-section">
                            <Typography component="span" className="font-semibold flex">
                                User Journey
                            </Typography>
                            <Typography
                                className="text-11 font-medium capitalize explore-description"
                                color="textSecondary"
                            >
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquet non integer condimentum nunc, augue a, dolor in semper. Facilisis senectus netus diam sagittis, augue blandit dui et consequat. Risus risus a non.
                            </Typography>
                        </div>
                    </div>
                </Grid>
            </Grid> */}
        </>
    );
}

export default Explore;
