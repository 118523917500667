import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { getFiltersById } from '../services/chart-builder.service';
import { DashboardConstants } from '../../dashboard/constants/dashboard.constants';


const namespace = 'chartbuilder/filter';
const filterAdapter = createEntityAdapter({});

const initialState = filterAdapter.getInitialState({
    loading: DashboardConstants.HTTPSTATUS.FINISHED,
    entities: {},
    currentRequestId: "",
    error: ''
});

export const fetchFiltersById = async (id) => {
    try {
        const { data } = await getFiltersById(id);
        return data;
    } catch (err) {
        return null;
    }
};


const { actions, reducer } = createSlice({
    name: namespace,
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchFiltersById.fulfilled]: (state, { meta, payload }) => {
            if (meta.requestId === state.currentRequestId.requestId) {
                state.currentRequestId = '';
                state.entities = payload;
                state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
            }
        },
        [fetchFiltersById.pending]: (state, { meta }) => {
            state.loading = DashboardConstants.HTTPSTATUS.PENDING;
            state.currentRequestId = meta;
        },
        [fetchFiltersById.rejected]: (state, { meta, payload, error }) => {
            if (state.currentRequestId === meta) {
                state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
                state.entities = payload;
                state.error = error;
                state.currentRequestId = meta;
            }
        },
    }
});

export const { clear_state } = actions;

export const { selectEntities: selectFiltersEntities } = filterAdapter.getSelectors(
    state => {
        // console.log(state);
        return state.chartbuilder.filter;
    }
);

export default reducer;
