import { combineReducers } from '@reduxjs/toolkit';
import meta from './metaSlice';
import details from './detailsSlice';
import filter from './filterSlice';
import data from './dataSlice';

const reducer = combineReducers({
    meta,
    details,
    filter,
    data
});

export default reducer;
