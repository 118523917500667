import Typography from "@material-ui/core/Typography";
export const Title = (props) => {
  const { title } = props;
  return (
    <Typography
      style={{ flex: "1 1 100%"}}
      variant="h6"
      id="tableTitle"
      component="div"
    >
      {title}
    </Typography>
  );
};
