import { yupResolver } from '@hookform/resolvers/yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
// import jwt from "jsonwebtoken";
//import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
//import { submitLogin } from 'app/auth/store/loginSlice';
import { setUserDetails } from 'app/auth/store/userSlice';
import * as yup from 'yup';
import _ from '@lodash';
//import GoogleSignin from './GoogleSignin';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { DashboardConstants } from 'app/main/app/dashboard/constants/dashboard.constants';
import { ContinueRegistrationPopup } from './ContinueRegistrationPopup';
import { openDialog } from 'app/store/fuse/dialogSlice';
import { makeStyles } from "@material-ui/core/styles";
// import jwt from 'jwt-decode';

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required(),
  password: yup
    .string()
    .required()
    .min(4, 'Password is too short - should be 4 chars minimum.'),
});

const defaultValues = {
  email: '',
  password: '',
};
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useStyles = makeStyles((theme) => ({
  adornedEnd: {
    paddingRight:"2em"
  }
}))
function JWTLoginTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const login = useSelector(({ auth }) => auth.login);
  const {
    register,
    setValue,
    formState,
    handleSubmit,
    reset,
    trigger,
    errors,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const history = useHistory();

  const { isValid, dirtyFields } = formState;
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  useEffect(() => {
    console.log('Login Cleared Cache');
    localStorage.removeItem('lac-key');
    localStorage.removeItem('lac-user-details');
    localStorage.removeItem('lac-user-client-details');
    localStorage.removeItem('lac-user');
    localStorage.removeItem('lac-user-active-client_id')

  }, []);

  useEffect(() => {
    setValue('email', '', { shouldDirty: true, shouldValidate: false });
    setValue('password', '', { shouldDirty: true, shouldValidate: false });
  }, [reset, setValue, trigger]);

  useEffect(() => {
    login.errors.forEach((error) => {
      setError(error.type, {
        type: 'manual',
        message: error.message,
      });
    });
  }, [login.errors, setError]);

  // function onSubmit(model) {
  //   dispatch(submitLogin(model));
  // }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const checkLogin = (e) => {
    e.preventDefault();
    localStorage.clear();
    console.log('Login_1 triggered!!!!');
    axios
      .post(DashboardConstants.APIURL + '/Login/log-in', {
        email: email,
        passkey: password,
        /*client_user_id: 'A0100309',
        client_id: 'A01003',
         title: 'Mr',
        first_name: 'asd',
        last_name: 'asd',
        middle_name: 'asd',
        display_name: 'asdasd',*/
      })
      .then(function (response) {
        console.log('Login_1 responded!!!!');
    
        const availableClients = response.data.data;
        const activeClientDetails = availableClients[0];
        const mergeData = response.data.data;

        mergeData.forEach((item) => {
          item.currentProductName =
            activeClientDetails && availableClients.length > 0
              ? activeClientDetails.company_name
              : 'TODO';
          item.availableProducts =
            availableClients && availableClients.length > 0
              ? availableClients.map((item) => {
                  return {
                    agency_name: item.agency_name,
                    agency_url: item.agency_url,
                    client_id: item.client_id,
                    company_name: item.company_name,
                    company_url: item.company_url,
                    organization_type: item.organization_type,
                    user_role: item.user_role,
                    product_register_progress: item.product_register_progress ,
  
                  };
                })
              : [];
        });
        localStorage.setItem('lac-key', response.data.token);
        localStorage.setItem('lac-user-details', JSON.stringify(mergeData[0]));
        localStorage.setItem(
          'lac-user-client-details',
          JSON.stringify(mergeData)
        );
        localStorage.setItem('lac-user', true);
        // localStorage.setItem('lac-individual', availableClients[0].organization_type);
        dispatch(setUserDetails(response.data));
        // console.log(localStorage.getItem('lac-key'))
      const data =  response.data?.data.filter((item) => item.product_register_progress === 'Completed');
        if (data[0]?.product_register_progress === 'Completed') {
          localStorage.setItem('lac-user-details',JSON.stringify(mergeData[0]))
        history.push('/dashboard/default');
        } else {
          dispatch(
            openDialog({
              children: (
                <ContinueRegistrationPopup
                  callback={() => {
                    history.push('/account-setup');
                  }}
                />
              ),
            })
          );
        }
      })
      .catch(function (error) {
        if(error.response && error.response.data){
          if(error.response.data.message == 'Internal server error'){
            setAlertMessage('You are not assign to any clinet/website . Please contact your admin.')
          }else if(error.response.data.message == 'Login Failed as user is not Active'){
            setAlertMessage(`${error.response.data.message}  please contact your admin`)
          }
          else if(error.response.data.message === 'Wrong credentials provided' || error.response.data.message === 'Forbidden resource'){
            setAlertMessage(` Wrong credentials provided`)
          }
          else{
          setAlertMessage(error.response.data.message);
          }
        }
        else{setAlertMessage('Internal server error');}
        setAlertType('error');
        setOpen(true);
        setValue('password', '', { shouldDirty: true, shouldValidate: false });
        console.log(error);
      });
  };

  return (
    <div className="w-full">
      <form
        className="flex flex-col justify-center w-full"
        onSubmit={checkLogin}
      >
        <TextField
          className="mb-16"
          type="text"
          name="email"
          inputRef={register}
          error={!!errors.email}
          helperText={errors?.email?.message}
          label="Email"
          //value={email}
          onChange={(e) => setEmail(e.target.value)}
          id="emailTxt"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon className="text-20" color="action">
                  user
                </Icon>
              </InputAdornment>
            ),
            classes: {
              adornedEnd: classes.adornedEnd
            }
          }}
          variant="outlined"
        />
        <TextField
          className="mb-16"
          label="Password"
          type="password"
          name="password"
          inputRef={register}
          //value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password}
          helperText={errors?.password?.message}
          variant="outlined"
          id="passwordTxt"
          InputProps={{
            className: 'pr-2',
            type: showPassword ? 'text' : 'password',
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  <Icon className="text-20" color="action">
                    {showPassword ? 'visibility' : 'visibility_off'}
                  </Icon>
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertType}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full mx-auto mt-16"
          aria-label="LOG IN"
          disabled={_.isEmpty(dirtyFields) || !isValid}
          value="legacy"
          id="loginButton"
        >
          Login
        </Button>
       
        {/* <GoogleSignin /> */}
      </form>

      {/* <table className="w-full mt-32 text-center'>
				<thead className='mb-4'>
					<tr>
						<th>
							<Typography className='font-semibold text-11' color='textSecondary'>
								Role
							</Typography>
						</th>
						<th>
							<Typography className='font-semibold text-11' color='textSecondary'>
								Email
							</Typography>
						</th>
						<th>
							<Typography className='font-semibold text-11' color='textSecondary'>
								Password
							</Typography>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<Typography className='font-medium text-11' color='textSecondary'>
								Admin
							</Typography>
						</td>
						<td>
							<Typography className='text-11'>admin@fusetheme.com</Typography>
						</td>
						<td>
							<Typography className='text-11'>admin</Typography>
						</td>
					</tr>
					<tr>
						<td>
							<Typography className='font-medium text-11' color='textSecondary'>
								Staff
							</Typography>
						</td>
						<td>
							<Typography className='text-11'>staff@fusetheme.com</Typography>
						</td>
						<td>
							<Typography className='text-11'>staff</Typography>
						</td>
					</tr>
				</tbody>
			</table> */}
    </div>
  );
}

export default JWTLoginTab;
