import { heatmapTypes } from './heatmapTypes';

const INITIAL_STATE = {
    toggleFlag: 'false',
    loading: false
};

const heatmapReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case heatmapTypes.SET_LOADING: return {...state, loading: action.payload}
        case heatmapTypes.SET_TOGGLEFLAG:
            return {
                ...state,
                toggleFlag: action.payload
            };
        default:
            return state;
    }
};

export default heatmapReducer;
