import i18next from 'i18next';
import Explore from './Explore';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';
import { TextareaAutosize } from "@material-ui/core";

i18next.addResourceBundle('en', 'examplePage', en);
i18next.addResourceBundle('tr', 'examplePage', tr);
i18next.addResourceBundle('ar', 'examplePage', ar);

/**
 * Lazy load Example
 */

import React from 'react';

const ExploreConfig = {
    settings: {
        layout: {
            config: {
                toolbar: {
                     display: true,
                     style: "fixed",
                     position: "below",
                     title: "",
                   }
            }
        },
        
    },
    
    routes: [
        {
            path : '/explore',
            component: React.lazy(() => import('./Explore'))
        }
    ]
};

export default ExploreConfig;


