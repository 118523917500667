import { List, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  li: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
}));

export const UserGridColumn = (props) => {
  const classes = useStyles();
  const { data } = props;
  return (
    <List dense={false}>
      {data.map((item) => {
        const { primary, secondary, render } = item;
        return (
          <ListItem className={classes.li}>
            {(primary || (primary && seconday)) && (
              <ListItemText
                primary={primary}
                secondary={secondary ? secondary : null}
              />
            )}
            {render && render()}
          </ListItem>
        );
      })}
    </List>
  );
};
