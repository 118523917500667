import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';

const namespace = 'dashboard/page';
const dashboardAdapter = createEntityAdapter({});

const initialState = dashboardAdapter.getInitialState({
    hasKPIOrderChanged: false,
    hasChartsOrderChanged: false,
    // dateRange: '45D',
    dateRange: '7D',
    dateFrequency: 'daily',
    updatedKPIList: [],
    updatedChartList: []
});

const { actions, reducer } = createSlice({
    name: namespace,
    initialState,
    reducers: {
        setDashboardDateRange: (state, action) => {
            return {
                ...state,
                dateRange: action.payload.dateRange
            };
        },
        setDashboardDateFrequency: (state, action) => {
            return {
                ...state,
                dateFrequency: action.payload.dateFrequency
            };
        },        
        setKPIOrderChanged: (state, action) => {
            return {
                ...state,
                hasKPIOrderChanged: action.payload.flag,
                updatedKPIList: action.payload.list
            };
        },
        setChartsOrderChanged: (state, action) => {
            return {
                ...state,
                hasChartsOrderChanged: action.payload.flag,
                updatedChartList: action.payload.list
            };
        }
    }
});

/*
export const { selectEntities: selectDashboardPageEntities } = dashboardAdapter.getSelectors(
    state => {
        console.log(state.dashboard.page);
        return state.dashboard.page;
    }
);
*/

export const { setKPIOrderChanged, setChartsOrderChanged, setDashboardDateRange, setDashboardDateFrequency } = actions;

export default reducer;
