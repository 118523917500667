import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { structuredData } from "../dynamic-insight/store/dynamicSlice";
import {
  getDiTimeStamp,
  dynamicInsight,
} from "../dynamic-insight/services/dynamicinsight.service";
import TabEffect from "./components/Tabs/TabEffect";
import InfoIcon from "@material-ui/icons/Info";
import {
  Tooltip,
  Typography,
  Toolbar,
  Grid,
  ButtonGroup,
  Button,
} from "@material-ui/core";
import introJs from 'intro.js';
import { disableDITour } from "app/fuse-layouts/layout1/components/store/productTourSlice";
import { useSelector } from "react-redux";

export default function DynamicInsightMain() {
  const dispatch = useDispatch();
  const [diTimeStamp, setDiTimeStamp] = useState("");
  const [allData, setAllData] = useState(null);
  const [dateRange,setDateRange] = useState("7");
  

  const startTour = useSelector((state) => state.dashboard.tourFlag.DITourFlag);
  const intro = introJs();
  const steps = [
        {
            element: '#dateRange',
            intro: 'Choose the desired time-frame to view  your data.',
            position: 'top',
        },
        {
          element: '#insightType',
          intro: 'Click on the type of insight you are looking for ',
          position: 'top',
      },
        {
            element: '#insights',
            intro:'Here is your overall summary about the event, but if you want to go in detail,click on individual insights. ',
            position: 'top',
        },
        {
            element: '#businessType',
            intro: 'Click here to choose the business type ',
            position: 'top',
        },
                  
    ];
  const productTour = (e) => {
        intro.setOptions({ steps });
        intro.start();
    dispatch(disableDITour());
    };
    useEffect(()=>{
      if(startTour)
      {
     productTour();
      }
    },[startTour])
  useEffect(async () => {
    await getDynamicInsideData();
  }, []);
  const getDynamicInsideData = async () => {
    const clientId = localStorage.getItem("lac-user-active-client_id");
    try {
      let data = await dynamicInsight(clientId);
      if (data?.data?.message == "Success")
        setAllData(data?.data?.dynamicInsights);
    } catch (err) {
      console.log(err);
      setAllData(null);
    }

    try {
      const timeStamp = await getDiTimeStamp(clientId);
      const date = new Date(timeStamp?.data);
      const localDate = date?.toDateString();
      setDiTimeStamp(localDate);
    } catch (err) {
      setDiTimeStamp("No Date Found");
    }
  };
  const handleDateRange =(date)=>{
    setDateRange(date)
    if (allData?.length > 0) {
      let DynamicEventData = [];
      let DynamicTrafficData = [];
      let business_categoryTraffic = {};
      let business_categoryEvent = {};
      let insightTimeValueInDays = "";
      allData?.map((datas, index) => {
        if (
          datas.category == "traffic" &&
          datas.insight_time_value_in_days == date
        ) {
          DynamicTrafficData.push(datas);
          business_categoryTraffic[datas.business_category] = true;
          insightTimeValueInDays = datas.insight_time_value_in_days;
        } else if (
          datas.category == "event" &&
          datas.insight_time_value_in_days == date
        ) {
          DynamicEventData.push(datas);
          business_categoryEvent[datas.business_category] = true;
          insightTimeValueInDays = datas.insight_time_value_in_days;
        }
      });
      dispatch(
        structuredData({
          allData: allData,
          DynamicEventData: DynamicEventData,
          DynamicTrafficData: DynamicTrafficData,
          business_categoryTraffic: Object.keys(business_categoryTraffic).sort(),
          business_categoryEvent: Object.keys(business_categoryEvent).sort(),
          insightTimeValueInDays: date,
        })
      );
    }
  }

  useEffect(() => {
    if (allData?.length > 0) {
      let DynamicEventData = [];
      let DynamicTrafficData = [];
      let business_categoryTraffic = {};
      let business_categoryEvent = {};
      let insightTimeValueInDays = "";
      allData?.map((datas, index) => {
        if (
          datas.category == "traffic" &&
          datas.insight_time_value_in_days == dateRange && datas?.business_category !== "0"
        ) {
          DynamicTrafficData.push(datas);
          business_categoryTraffic[datas.business_category] = true;
          insightTimeValueInDays = datas.insight_time_value_in_days;
        } else if (
          datas.category == "event" &&
          datas.insight_time_value_in_days == dateRange
          && datas?.business_category !== "0"
        ) {
          DynamicEventData.push(datas);
          business_categoryEvent[datas.business_category] = true;
          insightTimeValueInDays = datas.insight_time_value_in_days;
        }
      });
      dispatch(
        structuredData({
          allData: allData,
          DynamicEventData: DynamicEventData,
          DynamicTrafficData: DynamicTrafficData,
          business_categoryTraffic: Object.keys(business_categoryTraffic).sort(),
          business_categoryEvent: Object.keys(business_categoryEvent).sort(),
          insightTimeValueInDays: dateRange,
        })
      );
    }
  }, [allData]);
  return (
    <div>
      <Toolbar className="pt-12 min-h-48 md:min-h-48">
        <Grid container >
          <Grid >
            <div className="dynamicTitle ">
              <Typography
                variant="h1"
                component="h1"
                className=" text-16 font-500"
              >
                <span className=" text-24 font-800">
                  Dynamic Insights
                </span>{" "}
                &nbsp;&nbsp;
                <span style={{ fontSize: "12px" }} className="pt-0">
                  {`${diTimeStamp}  `}
                  <Tooltip className="pb-5" title="The Insights are based on last captured Data as of this Date">
                    <InfoIcon style={{ color: "#3B6FCF" }} />
                  </Tooltip>
                </span>
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            style={{
              float: "right",
              width: "73%",
              display: "flex",  
              justifyContent: "end",
            }}
          >
            <ButtonGroup
            id="dateRange"
              size="small"
              variant="outlined"
              aria-label="contained button group"
              style={{
                boxShadow: "none",
                height: "3.5rem",
                paddingTop: "0.5%"
              }}
            >
              <Button
                value="today"
                className={`text-11 ${dateRange === '1' ? 'btn-primary' : ''
                  }`}
                onClick={() => handleDateRange('1')}
              >
                1D
              </Button>
              <Button
                value="1D"
                className={`text-11 ${dateRange === '3' ? 'btn-primary' : ''
                  }`}
                onClick={() => handleDateRange('3')}
              >
                3D
              </Button>
              <Button
                value="3D"
                className={`text-11 ${dateRange === '7' ? 'btn-primary' : ''
                  }`}
                onClick={() => handleDateRange('7')}
              >
                7D
              </Button>
              <Button
                value="7D"
                className={`text-11 ${dateRange === '15' ? 'btn-primary' : ''
                  }`}
                onClick={() => handleDateRange('15')}
              >
                15D
              </Button>
              <Button
                value="15D"
                className={`text-11 ${dateRange === '30' ? 'btn-primary' : ''
                  }`}
                onClick={() => handleDateRange('30')}
              >
                30D
              </Button>
              <Button
                value="30D"
                className={`text-11 ${dateRange === '60' ? 'btn-primary' : ''
                  }`}
                onClick={() => handleDateRange('60')}
              >
                60D
              </Button>
              <Button
                value="90D"
                className={`text-11 ${dateRange === '90' ? 'btn-primary' : ''
                  }`}
                onClick={() => handleDateRange('90')}
              >
                90D
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </Toolbar>
      {<TabEffect
      dateRange={dateRange} />}
    </div>
  );
}
