import i18next from "i18next";
import DynamicInsightMain from "./DynamicInsightMain";
import en from "./i18n/en";
import tr from "./i18n/tr";
import ar from "./i18n/ar";
import { TextareaAutosize,  } from "@material-ui/core";

i18next.addResourceBundle("en", "examplePage", en);
i18next.addResourceBundle("tr", "examplePage", tr);
i18next.addResourceBundle("ar", "examplePage", ar);

/**
 * Lazy load Example
 */

import React from "react";



const DynamicinsightConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          display: TextareaAutosize,
          // style: "fixed",
          // position: "below",
          // title: `Dynamic Insights`,
        },
      },
    },
  },

  routes: [
    {
      path: "/dynamic-Insights",
      component: React.lazy(() => import("./DynamicInsightMain")),
    },
  ],
};

export default DynamicinsightConfig;
