import axios from 'axios';
import { DashboardConstants } from '../../dashboard/constants/dashboard.constants';

const token = localStorage.getItem('lac-key');
const instance = axios.create({
  baseURL: DashboardConstants.APIURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const dynamicInsight= async (client_id) => {
  const payload = {
    client_id:client_id,
  }
  const {data} = await instance.post('/dashboard/dynamic-insides',payload, {
    headers: {  
      Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
      });
  return data;
}
export const getDiTimeStamp = async (client_id) => {
  const payload = {
    client_id:client_id,
  }
  const {data} = await instance.post('/dashboard/dynamic-insides-timeStamp',payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
      });
  return data;
}
// export const dynamicInsightUpdateEveryDay= async (client_id) => {
//   const payload = {
//     client_id:client_id,
//   }
//   const {data} = await instance.post('/dashboard/dynamic-insides-addDay',payload, {
//     headers: {  
//       Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
//       },
//       });
//   return true;
// }

export const dynamicInsightUpdate= async (insideData) => {
  // console.log('insideData', insideData);
  const payload = {
    client_id:insideData?.client_Id,
    insight_text:insideData?.insight_text,
    user_input:insideData?.user_input,
  }
  // console.log("payload Dashboard js",payload)
  const {data} = await instance.post('/dashboard/dynamic-insides-update',payload, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
      });
  return data;
}


export const getKpisList = async (dateRange) => {
  const { data } = await instance.post('/dashboard/all-kpis', dateRange, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
    },
  });
  return data;
};

export const getDashboards = async (requestParams) => {
  const { data } = await instance.post('/dashboard/all', requestParams, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
    },
  });
  return data;
};

export const updateUserKpi = async (kpiSortedArray) => {
  const { data } = await instance.post(
    '/dashboard/kpis-user-update',
    kpiSortedArray,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};

export const getKpiMasterList = async () => {
  const { data } = await instance.post('/dashboard/kpis-masterlist');
  return data;
};
export const updateKpi = async (kpiSortedArray) => {
  const { data } = await instance.post(
    '/dashboard/kpis-update',
    kpiSortedArray,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};

export const getChartsList = async (dateRange) => {
  // console.log('test')
  // console.log(dateRange);
  const { data } = await instance.post(
    '/dashboard/all-interactive-charts',
    dateRange,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};

export const updateUserChart = async (chartsSortedArray) => {
  const { data } = await instance.post(
    '/dashboard/charts-user-update',
    chartsSortedArray,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};

export const getChartsDetailsBySharedDashboard = async (chartSelection) => {
  const { data } = await instance.post(
    '/pinned-dashboard/details',
    chartSelection,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};

export const getChartsDetailsByPinnedDashboard = async (chartSelection) => {
  const { data } = await instance.post(
    '/pinned-dashboard/details',
    chartSelection,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};

export const saveSharedDashboardService = async (params) => {
  const { data } = await instance.post(
    '/pinned-dashboard/save-share-dashboard',
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};

export const deleteDashboardService = async (params) => {
  const { data } = await instance.post(
    '/pinned-dashboard/delete-dashboard',
    params,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  );
  return data;
};


export const getRealtime = async (clientID) => {
  const { data } = await instance.post(
    '/real-time/realtimedata',
    clientID,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    }
  )
  // console.log(data,"service realtime")
  return data
}
export const getAllRealTime = async (clientID)=>{
  const {data} = await instance.post(
    'real-time/allrealtimedata',
    clientID,
    {
      headers:{
        Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      }
    }
  )
  return data
}
// export const getWidgetsList = async () => {
//   const { data } = await instance.get("/dashboard/all-widgets");
//   return data;
// };
