import axios from 'axios';
import { DashboardConstants } from 'app/main/app/dashboard/constants/dashboard.constants';
// const token = localStorage.getItem('lac-key');
const instance = axios.create({
  baseURL: DashboardConstants.APIURL,
//   headers: {
//     Authorization: `Bearer ${token}`,
//   },
});

export const sendResetPassword = async (email) => {
    // console.log("emailsendResetPassword",email);
    const { data } = await instance.post('/onboarding/send-reg-email', email, {
      headers: {
    //     Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
      },
    });
    // console.log("data",data);
    return data;
  };

export const getUserEmail = async (email) => {
  const  data = await instance.post('/onboarding/verify-email', {
    email,
  });
  return data;
}
export const resetPassword = async (resetdata) => {
  const  data = await instance.post('/onboarding/reset-password', {
    resetdata,
  });
  return data;
}
export const updateTokenTable = async (tokenData) => {

  const  data = await instance.post('/onboarding/update-token-table', {
    tokenData,
  });
  return data;
}
export const sendResetPasswordConfirmation = async (email) => {
  const { data } = await instance.post('/onboarding/send-reg-email', email, {
    headers: {
 
    },
  });
  return data;
};