import axios from 'axios';
import { DashboardConstants } from 'app/main/app/dashboard/constants/dashboard.constants';
import {sendRegistrationEmail} from 'app/main/register/services/register.service';
const token = localStorage.getItem('lac-key');
const instance = axios.create({
  baseURL: DashboardConstants.APIURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getUsers = async (email) => {
  const {
    data: { data: userData },
  } = await instance.post('/users/all', {
    email,
  });
  const { users } = userData;
  let allUsers = Object.values(users);
  let activeUsers = allUsers.filter((i) => i.user_active != 'N');

  return activeUsers;
};
export const getUsersList = async (email) => {
  const {
    data: { data: userData },
  } = await instance.post('/users/all', {
    email,
  });
  //const { usersList } = userData;

  return userData.usersList;
};

export const createUser = async (userForm) => {
  try{
  const { fullname, email, country, language, password, businessInfo,pageTitle } =
    userForm;
  const {email_address,company_name,agency_name  } = JSON.parse(localStorage.getItem('lac-user-details'));
  let newUserArray =await businessInfo?.map((item) => {

    return {
      email_address: email,
      full_name: fullname,
      user_role: item?.access_role,
      password: password, // TO be removed from here, will be generated on the server
      user_active: 'Y',
      cb_product_updates: 'Y',
      client_id: item?.client_id,
      country: country,
      language: language,
      create_date: new Date(),// to local time
      agency_url: item.agency_url,
      company_name: item.company_name,
      company_url: item.company_url,
      last_update_date: new Date(),// to local time
    };
  });
  let dataArray = newUserArray?.filter((item) => {
    return item.user_role?.length > 0; /// Iflter to rmeove those clients with no role selected
  });

  const {data}  = await instance.post('/users', dataArray);
  if(data && pageTitle === 'Add User'){
    const emailInfo ={
      to: email,
      cc: email_address,
      subject: 'New User Added Successfully',
      text: 'Successfully Added New User',
      html:` <html lang="en">

      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
          <title>User added successfully</title>
          <style>
              html,
              body {
                  background: #f1f1f1;
              }
      
              .email-container {
                  font-family: Arial, sans-serif;
                  background-color: #FFFFFF;
                  margin: 3%;
                  padding: 3%;
              }
      
              .heading {
                  text-align: left;
      
              }
      
              .button-like-link {
      
                  padding: 10px 20px;
                  text-decoration: none;
                  /* Remove underline */
                  background-color: #437eeb;
                  color: #fff;
                  border: none;
                  border-radius: 5vw;
                  cursor: pointer;
                  width: 10vw;
                  height: 5vh;
                  text-align: centre;
              }
      
              /* Remove the default button styles */
              .button-like-link:focus,
              .button-like-link:hover {
                  outline: none;
                  text-decoration: none;
              }
      
              .icon-background {
                  background-color: #437eeb;
                  border-radius: 50%;
                  z-index: -1;
              }
          </style>
      </head>
      
      <body class="email-container">
          <header class="heading">
              <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="10%" height="5%" />
              <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                  growth</p>
          </header>
      
      
          <hr style="border: 1px solid red;">
      
          <h3 style="font-size: 18px;color:red;text-align: center;">Successfully added user</h3>
      
          <p style="font-size: 18px;">This mail is to notify that ${email} has been successfully added to the ${company_name
              }.</p>
      
          <p style="font-size: 18px;">For any further assistance or inquiries, please don't hesitate to reach out to our
              dedicated support team at support@flable.ai </p>
          <p style="font-size: 16px;">Best Regards,</p>
          <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
          <hr style="border: 1px solid #fafafa;margin-top:3%">
          <div id="socialConnect" style="width: 100%;text-align: center;">
              <table style="display: inline-block; vertical-align: middle;" width="auto">
                  <tr>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                  style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
      
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://twitter.com/Flableai" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                  </tr>
              </table>
          </div>
          <hr style="border: 1px solid #fafafa;margin-bottom:2%">
          <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
              <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                  rights
                  reserved.</span>
          </div>
          <div style="width: 100%;  text-align: center; display: table;margin:3px">
              <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                  company</span>
          </div>
          <div id="disclaimer" style="width: 100%;text-align: center;">
              <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                  registered
                  to the Flable platform as a user.This
                  indicates your agreement with our Terms of Use and Privacy Policies.
              </p>
          </div>
      
          <div style="width: 100%;text-align: center;">
      
              <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                  <tr>
                      <td style="text-align: center; ">
      
                          <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                      </td>
                      </td>
                  </tr>
              </table>
      
          </div>
      </body>
      
      </html>`
  
    }
  
    await sendRegistrationEmail(emailInfo)
    return data;
  }else if (data && pageTitle === 'Edit User'){
    const emailInfo ={
      to: email,
      cc: email_address,
      subject: 'User Edited Successfully',
      text: 'Successfully Edited New User',
      html:`<html lang="en">

      <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
          <title>User edited successfully</title>
          <style>
              html,
              body {
                  background: #f1f1f1;
              }
      
              .email-container {
                  font-family: Arial, sans-serif;
                  background-color: #FFFFFF;
                  margin: 3%;
                  padding: 3%;
              }
      
              .heading {
                  text-align: left;
      
              }
      
              .button-like-link {
      
                  padding: 10px 20px;
                  text-decoration: none;
                  /* Remove underline */
                  background-color: #437eeb;
                  color: #fff;
                  border: none;
                  border-radius: 5vw;
                  cursor: pointer;
                  width: 10vw;
                  height: 5vh;
                  text-align: centre;
              }
      
              /* Remove the default button styles */
              .button-like-link:focus,
              .button-like-link:hover {
                  outline: none;
                  text-decoration: none;
              }
      
              .icon-background {
                  background-color: #437eeb;
                  border-radius: 50%;
                  z-index: -1;
              }
          </style>
      </head>
      
      <body class="email-container">
          <header class="heading">
              <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="10%" height="5%" />
              <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                  growth</p>
          </header>
      
      
          <hr style="border: 1px solid red;">
      
          <h3 style="font-size: 18px;color:red;text-align: center;">Successfully edited user</h3>
      
          <p style="font-size: 18px;">This mail is to notify that ${email} has been successfully edited to the ${company_name
              }.</p>
      
          <p style="font-size: 18px;">For any further assistance or inquiries, please don't hesitate to reach out to our
              dedicated support team at support@flable.ai </p>
          <p style="font-size: 16px;">Best Regards,</p>
          <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
          <hr style="border: 1px solid #fafafa;margin-top:3%">
          <div id="socialConnect" style="width: 100%;text-align: center;">
              <table style="display: inline-block; vertical-align: middle;" width="auto">
                  <tr>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                  style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
      
                          <a href="https://www.linkedin.com/company/flable/" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                      <td class="icon-background" style="width:25%">
                          <a href="https://twitter.com/Flableai" target="_blank">
                              <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                  height="50%" style="margin-top:25%" />
                          </a>
      
                      </td>
                  </tr>
              </table>
          </div>
          <hr style="border: 1px solid #fafafa;margin-bottom:2%">
          <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
              <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                  rights
                  reserved.</span>
          </div>
          <div style="width: 100%;  text-align: center; display: table;margin:3px">
              <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                  company</span>
          </div>
          <div id="disclaimer" style="width: 100%;text-align: center;">
              <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                  registered
                  to the Flable platform as a user.This
                  indicates your agreement with our Terms of Use and Privacy Policies.</p>
          </div>
      
          <div style="width: 100%;text-align: center;">
      
              <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                  <tr>
                      <td style="text-align: center; ">
      
                          <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                      </td>
                      <td style="text-align: center; ">
                          <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                      </td>
                      </td>
                  </tr>
              </table>
      
          </div>
      </body>
      
      </html>`
  
    }
    await sendRegistrationEmail(emailInfo)
    return data;
  }
}catch(err){
  console.log(err,"error")
}
  }

export const deleteUser = async (userInfo) => {
  try{
  const {
    fullname,
    email,
    country,
    language,
    password,
    businessAccountInfo: businessInfo,
  } = userInfo;
  const {email_address} = JSON.parse(localStorage.getItem('lac-user-details'));

  let newUserArray = businessInfo
    .filter((i) => i.assignedBusiness)
    .map((item) => {
      return {
        email_address: email,
        full_name: fullname,
        user_role: item.access_role,
        // password: password, // TO be removed from here, will be generated on the server
        user_active: 'N',
        cb_product_updates: 'Y',
        client_id: item.client_id,
        country: country,
        language: language,
        create_date: item.create_date,
        agency_url: item.assignedBusiness,
        company_name: item.company_name,
        company_url: item.assignedBusiness,
        last_update_date: new Date(),// to local time
      };
    });
  const { data } = await instance.post('/users', newUserArray);
  const emailInfo ={
    to: email_address,
    cc:email,
    subject: 'User deleted Successfully',
    text: 'Successfully deleted  User',
    html: `<html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
        <title>User added successfully</title>
        <style>
            html,
            body {
                background: #f1f1f1;
            }
    
            .email-container {
                font-family: Arial, sans-serif;
                background-color: #FFFFFF;
                margin: 3%;
                padding: 3%;
            }
    
            .heading {
                text-align: left;
    
            }
    
            .button-like-link {
    
                padding: 10px 20px;
                text-decoration: none;
                /* Remove underline */
                background-color: #437eeb;
                color: #fff;
                border: none;
                border-radius: 5vw;
                cursor: pointer;
                width: 10vw;
                height: 5vh;
                text-align: centre;
            }
    
            /* Remove the default button styles */
            .button-like-link:focus,
            .button-like-link:hover {
                outline: none;
                text-decoration: none;
            }
    
            .icon-background {
                background-color: #437eeb;
                border-radius: 50%;
                z-index: -1;
            }
        </style>
    </head>
    
    <body class="email-container">
        <header class="heading">
            <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="10%" height="5%" />
            <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                growth</p>
        </header>
    
    
        <hr style="border: 1px solid red;">
    
        <h3 style="font-size: 18px;color:red;text-align: center;">Successfully deleted user</h3>
    
        <p style="font-size: 18px;">This mail is to notify that ${email} has been successfully deleted from 
            ${company_name}.</p>
    
        <p style="font-size: 18px;">For any further assistance or inquiries, please don't hesitate to reach out to our
            dedicated support team at support@flable.ai </p>
        <p style="font-size: 16px;">Best Regards,</p>
        <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
        <hr style="border: 1px solid #fafafa;margin-top:3%">
        <div id="socialConnect" style="width: 100%;text-align: center;">
            <table style="display: inline-block; vertical-align: middle;" width="auto">
                <tr>
                    <td class="icon-background" style="width:25%">
                        <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                            <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                height="50%" style="margin-top:25%" />
                        </a>
    
                    </td>
                    <td class="icon-background" style="width:25%">
                        <a href="https://www.linkedin.com/company/flable/" target="_blank">
                            <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                style="margin-top:25%" />
                        </a>
    
                    </td>
                    <td class="icon-background" style="width:25%">
    
                        <a href="https://www.linkedin.com/company/flable/" target="_blank">
                            <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                height="50%" style="margin-top:25%" />
                        </a>
    
                    </td>
                    <td class="icon-background" style="width:25%">
                        <a href="https://twitter.com/Flableai" target="_blank">
                            <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                height="50%" style="margin-top:25%" />
                        </a>
    
                    </td>
                </tr>
            </table>
        </div>
        <hr style="border: 1px solid #fafafa;margin-bottom:2%">
        <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
            <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                rights
                reserved.</span>
        </div>
        <div style="width: 100%;  text-align: center; display: table;margin:3px">
            <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                company</span>
        </div>
        <div id="disclaimer" style="width: 100%;text-align: center;">
            <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                registered
                to the Flable platform as a user.This
                indicates your agreement with our Terms of Use and Privacy Policies.
            </p>
        </div>
    
        <div style="width: 100%;text-align: center;">
    
            <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                <tr>
                    <td style="text-align: center; ">
    
                        <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                    </td>
                    <td style="text-align: center; ">
                        <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                    </td>
                    <td style="text-align: center; ">
                        <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                    </td>
                    </td>
                </tr>
            </table>
    
        </div>
    
    </body>
    
    </html>`,

  }

  await sendRegistrationEmail(emailInfo)
  return data;
}catch(err){
  console.log(err,"error")
}
};

export const removeUserRole = async (userInfo) => {
  try{
  const {
    fullname,
    email,
    country,
    language,
    password,
    businessAccountInfo: businessInfo,
  } = userInfo;
  const {email_address} = JSON.parse(localStorage.getItem('lac-user-details'));

  let newUserArray = businessInfo
    .filter((i) => i.assignedBusiness)
    .map((item) => {
      return {
        email_address: email,
        full_name: fullname,
        user_role: item.access_role,
        //  password: password, // TO be removed from here, will be generated on the server
        user_active: 'Y',
        cb_product_updates: 'Y',
        client_id: item.client_id,
        country: country,
        language: language,
        create_date: item.create_date,
        agency_url: item.assignedBusiness,
        company_name: item.company_name,
        company_url: item.assignedBusiness,
        last_update_date: new Date(),// to local time
      };
    });
  const { data } = await instance.post('/users', newUserArray);
  const emailInfo ={
    to: email_address,
    subject: 'Remove User Role Successfully',
    text: 'Remove User Role Successfully',
    html:`<html lang="en">

    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
        <title>User role removed successfully</title>
        <style>
            html,
            body {
                background: #f1f1f1;
            }
    
            .email-container {
                font-family: Arial, sans-serif;
                background-color: #FFFFFF;
                margin: 3%;
                padding: 3%;
            }
    
            .heading {
                text-align: left;
    
            }
    
            .button-like-link {
    
                padding: 10px 20px;
                text-decoration: none;
                /* Remove underline */
                background-color: #437eeb;
                color: #fff;
                border: none;
                border-radius: 5vw;
                cursor: pointer;
                width: 10vw;
                height: 5vh;
                text-align: centre;
            }
    
            /* Remove the default button styles */
            .button-like-link:focus,
            .button-like-link:hover {
                outline: none;
                text-decoration: none;
            }
    
            .icon-background {
                background-color: #437eeb;
                border-radius: 50%;
                z-index: -1;
            }
        </style>
    </head>
    
    <body class="email-container">
        <header class="heading">
            <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="10%" height="5%" />
            <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                growth</p>
        </header>
    
    
        <hr style="border: 1px solid red;">
    
        <h3 style="font-size: 18px;color:red;text-align: center;"> Removed user role successfully </h3>
    
    
        <p style="font-size: 18px;"> This mail is to notify you that ${email} has been successfully removed from
            ${company_name}</p>
        <p style="font-size: 18px;">For any further assistance or inquiries, please don't hesitate to reach out to our
            dedicated support team at support@flable.ai </p>
        <p style="font-size: 16px;">Best Regards,</p>
        <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
        <hr style="border: 1px solid #fafafa;margin-top:3%">
        <div id="socialConnect" style="width: 100%;text-align: center;">
            <table style="display: inline-block; vertical-align: middle;" width="auto">
                <tr>
                    <td class="icon-background" style="width:25%">
                        <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                            <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                height="50%" style="margin-top:25%" />
                        </a>
    
                    </td>
                    <td class="icon-background" style="width:25%">
                        <a href="https://www.linkedin.com/company/flable/" target="_blank">
                            <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                style="margin-top:25%" />
                        </a>
    
                    </td>
                    <td class="icon-background" style="width:25%">
    
                        <a href="https://www.linkedin.com/company/flable/" target="_blank">
                            <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                height="50%" style="margin-top:25%" />
                        </a>
    
                    </td>
                    <td class="icon-background" style="width:25%">
                        <a href="https://twitter.com/Flableai" target="_blank">
                            <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                height="50%" style="margin-top:25%" />
                        </a>
    
                    </td>
                </tr>
            </table>
        </div>
        <hr style="border: 1px solid #fafafa;margin-bottom:2%">
        <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
            <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                rights
                reserved.</span>
        </div>
        <div style="width: 100%;  text-align: center; display: table;margin:3px">
            <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                company</span>
        </div>
        <div id="disclaimer" style="width: 100%;text-align: center;">
            <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                registered
                to the Flable platform as a user.This
                indicates your agreement with our Terms of Use and Privacy Policies.
            </p>
        </div>
    
        <div style="width: 100%;text-align: center;">
    
            <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                <tr>
                    <td style="text-align: center; ">
    
                        <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                    </td>
                    <td style="text-align: center; ">
                        <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                    </td>
                    <td style="text-align: center; ">
                        <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                    </td>
                    </td>
                </tr>
            </table>
    
        </div>
    </body>
    
    </html>`,

  }

  await sendRegistrationEmail(emailInfo)
  return data;
}catch(err){
  console.log(err,"error")
}
};

export const fetchMasterData = async () => {
  const {
    data: { data: masterData },
  } = await instance.get('/users/user-master-data');
  let countryList, languageList, roleList;
  if (masterData) {
    countryList = masterData.Country;
    languageList = masterData['Language Preference'];
    roleList = masterData.Role;
    // businessListOptions = masterData.clients;
  }

  return { countryList, languageList, roleList };
};

export const getUserById = async (userEmail, email) => {
  const {
    data: { data: userData },
  } = await instance.get(`/users/user/${userEmail}`, {
    email,
  });

  return userData;
};
