import React from "react";
import {
  Grid,
  Paper,
  Typography,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  Button,
} from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import ThumbDownAltIcon from "@material-ui/icons/ThumbDownAlt";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import Tooltip from "@material-ui/core/Tooltip";
import { dynamicInsightUpdate } from "./services/dynamicinsight.service"
import { updateDynamicInsight, paginationDynamicInsight, selectDynamicEntities } from './store/dynamicSlice'
// import CircularProgress from "@material-ui/core/CircularProgress";
// import InfoIcon from "@material-ui/icons/Info";
//import dispatch
import { useDispatch, useSelector } from "react-redux";
import "./dynamicinsight.css";
const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    marginLeft: "0.5%",
    padding: "0.5rem",
    width: "99%",
  },
  root1: {
    maxWidth: "1400%",
    margin: theme.spacing(1),
    borderColor: "green"
  },
  root4: {
    maxWidth: "1400%",
    margin: theme.spacing(1),
    marginTop: "-1.5rem",
    border: "1px solid green"

  },
  root5: {
    maxWidth: "1400%",
    margin: theme.spacing(1),
    marginTop: "-1.5rem",
    border: "1px solid red"

  },
  root11: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  root3: {
    marginLeft: "35%",
    // marginTop: "10%",
    marginRight: "25%",
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  avatar: {
    fontSize: 16,
    background: "none",
    color: "black",
    fontWeight: "bold",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  thumbUpColor: {
    color: "green",
  },
  thumbDownColor: {
    color: "red",
  },
}));
const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);


const handleNullValue = (value) => {
  if (value == "Null" || value == "null") {
    return ""
  }
  return value
};
const DynamicInsights = (props) => {
  const classes = useStyles();
  const [pageData, setPageData] = React.useState(null);
  const [page, setPage] = React.useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(6);
  const [isPage, setIsPage] = React.useState(false);
  const dispatch = useDispatch();
  const pageCount = useSelector(selectDynamicEntities);
  React.useEffect(() => {
    if (props?.originalData?.length > 0) {
      if (pageCount?.pageCount > 0 && isPage === false) {
        setPageData(
          props.originalData?.slice(
            (pageCount?.pageCount - 1) * 6,
            pageCount?.pageCount * 6
          )
        );
      } else {
        setPageData(props?.originalData?.slice(0, 6));
        setIsPage(false);
      }
    } else {
      // setOriginalData(props?.dynamicData?.data?.dynamicInsights);
      if (pageCount?.pageCount > 0 && isPage === false) {
        setPageData(
          props.originalData?.slice(
            (pageCount?.pageCount - 1) * 6,
            pageCount?.pageCount * 6
          )
        );
      } else {
        setPageData(props?.originalData?.slice(0, 6));
        setIsPage(false);
      }
    }
    if (pageData !== undefined && pageData?.length > 0) {
      let count = 0;
      if (props.originalData > 0) {
        count = Math.ceil(props?.originalData?.length / 6);
      } else {
        count = Math.ceil(props?.originalData?.length / 6);
      }

      setPage(parseInt(count) + 1);
    }
  }, [props.originalData, pageCount?.pageCount]);

  const handleChangePage = (event, newPage) => {
    dispatch(
      paginationDynamicInsight({
        page: newPage,
      })
    );
    setPageData(props.originalData?.slice((newPage - 1) * 6, newPage * 6));
  };

  const handleCountUp = async (event, data, reaction) => {
    try {
      const payload = {
        client_Id: localStorage.getItem("lac-user-active-client_id"),
        insight_text: data?.insight_text,
        user_input: reaction,
        insight_time_value_in_days: data?.insight_time_value_in_days,
        insight_type: data?.insight_type,
      };
      dispatch(
        updateDynamicInsight({
          client_id: data?.client_id,
          insight_text: data?.insight_text,
          user_input: reaction,
          insight_time_value_in_days: data?.insight_time_value_in_days,
          insight_type: data?.insight_type,
          category: data?.category
        })
      );
      await dynamicInsightUpdate(payload);
    } catch (err) {
      console.log(err);
    }
  };
  const handleCountDown = async (event, data, reaction) => {
    try {
      const payload = {
        client_Id: localStorage.getItem("lac-user-active-client_id"),
        insight_text: data?.insight_text,
        user_input: reaction,
        insight_time_value_in_days: data?.insight_time_value_in_days,
        insight_type: data?.insight_type,

      };
      dispatch(
        updateDynamicInsight({
          client_id: data?.client_id,
          insight_text: data?.insight_text,
          user_input: reaction,
          insight_time_value_in_days: data?.insight_time_value_in_days,
          insight_type: data?.insight_type,
          category: data?.category

        })
      );
      await dynamicInsightUpdate(payload);
    } catch (err) {
      console.log(err);
    }
  };
  let handleInsightType = (insight_value, value_type) => {
    if (value_type == "Null" || value_type == "null" || value_type == null || value_type == undefined || value_type == "" || value_type == " ") {
      return ""
    }
    else if (value_type == "percentage") {
      return Math.round(parseFloat(insight_value)) + "%"
    }
    else if ((value_type == "Time" && insight_value !=="Null") || (value_type == "Count" && insight_value !=="Null") ) {
      // insight_value is less then 100
      if (Number(insight_value) < 100) {
        return insight_value
      }
      else if (Number(insight_value) < 1000) {
        return insight_value.slice(0, 2)

      }
      else if (Number(insight_value) > 1000 && Number(insight_value) < 10000) {
        return insight_value.slice(0, 1) + "K"
      }
      else if (Number(insight_value) > 10000 && Number(insight_value) < 100000) {
        return insight_value.slice(0, 2) + "K"
      }
      else if (Number(insight_value) > 100000 && Number(insight_value) < 1000000) {
        return insight_value.slice(0, 1) + "L"
      }
    }else if(insight_value ==="Null"){
      return ""
    }

  }


  return (
    <>
      <div className={classes.root}>
        <br />
        <br />
        <Grid container spacing={3}>
          {
            props.originalData?.length > 0 &&
            pageData?.map((item, index) => {
              return (
                <Grid item xs={6} key={index}>
                  {
                    item.stats == "increasing" && (
                      <div style={{ position: "relative", top: "35%", left: "-.5%", background: 'white', width: "10%" }}>
                        <ArrowUpwardIcon style={{ color: "green" }} />
                      </div >
                    )
                  }
                  {
                    item.stats == "decreasing" && (
                      <div style={{ position: "relative", top: "35%", left: "-.5%", background: 'white', width: "10%" }}>
                        <ArrowDownwardIcon style={{ color: "red" }} />
                      </div >
                    )
                  }

                  {
                    item.stats === "increasing" && (
                      <LightTooltip
                        title="Was this insight useful?"
                        placement="top"
                      >
                        <Card className={classes.root4}  >

                          <CardHeader
                            avatar={
                              <div
                                aria-label="recipe"
                                className={classes.avatar}
                              >
                                {
                                  // if function return null then it will not show anything
                                  item?.insight_value === "Null" ? "" : handleInsightType(item?.insight_value, item?.value_type)
                                }
                              </div>
                            }
                            action={
                              <>
                                {" "}
                                <IconButton aria-label="ThumbDownAltIcon">
                                  {item?.user_input === "yes" ? (
                                    <ThumbUpAltIcon
                                      onClick={(event) =>
                                        handleCountUp(event, item, "")
                                      }
                                      className={
                                        item?.user_input == "yes"
                                          ? classes.thumbUpColor
                                          : null
                                      }
                                    />
                                  ) : (
                                    <ThumbUpAltIcon
                                      onClick={(event) =>
                                        handleCountUp(event, item, "yes")
                                      }
                                      className={
                                        item?.user_input == "yes"
                                          ? classes.thumbUpColor
                                          : null
                                      }
                                    />
                                  )}
                                </IconButton>
                                <IconButton aria-label="ThumbUpAltIcon">
                                  {item?.user_input === "no" ? (
                                    <ThumbDownAltIcon
                                      onClick={(event) =>
                                        handleCountDown(event, item, "")
                                      }
                                      className={
                                        item?.user_input == "no"
                                          ? classes.thumbDownColor
                                          : null
                                      }
                                    />
                                  ) : (
                                    <ThumbDownAltIcon
                                      onClick={(event) =>
                                        handleCountDown(event, item, "no")
                                      }
                                      className={
                                        item?.user_input == "no"
                                          ? classes.thumbDownColor
                                          : null
                                      }
                                    />
                                  )}
                                </IconButton>
                              </>
                            }
                            title={item?.insight_text}
                          />
                        </Card>
                      </LightTooltip>
                    )
                  }
                  {
                    item.stats === "decreasing" && (
                      <LightTooltip
                        title="Was this insight useful?"
                        placement="top"
                      >
                        <Card className={classes.root5}  >

                          <CardHeader
                            avatar={
                              <div
                                aria-label="recipe"
                                className={classes.avatar}
                              >
                                {item?.insight_value ==="Null" ? "" : handleInsightType(item?.insight_value, item?.value_type)}
                              </div>
                            }
                            action={
                              <>
                                {" "}
                                <IconButton aria-label="ThumbDownAltIcon">
                                  {item?.user_input === "yes" ? (
                                    <ThumbUpAltIcon
                                      onClick={(event) =>
                                        handleCountUp(event, item, "")
                                      }
                                      className={
                                        item?.user_input == "yes"
                                          ? classes.thumbUpColor
                                          : null
                                      }
                                    />
                                  ) : (
                                    <ThumbUpAltIcon
                                      onClick={(event) =>
                                        handleCountUp(event, item, "yes")
                                      }
                                      className={
                                        item?.user_input == "yes"
                                          ? classes.thumbUpColor
                                          : null
                                      }
                                    />
                                  )}
                                </IconButton>
                                <IconButton aria-label="ThumbUpAltIcon">
                                  {item?.user_input === "no" ? (
                                    <ThumbDownAltIcon
                                      onClick={(event) =>
                                        handleCountDown(event, item, "")
                                      }
                                      className={
                                        item?.user_input == "no"
                                          ? classes.thumbDownColor
                                          : null
                                      }
                                    />
                                  ) : (
                                    <ThumbDownAltIcon
                                      onClick={(event) =>
                                        handleCountDown(event, item, "no")
                                      }
                                      className={
                                        item?.user_input == "no"
                                          ? classes.thumbDownColor
                                          : null
                                      }
                                    />
                                  )}
                                </IconButton>
                              </>
                            }
                            title={item?.insight_text}
                          />
                        </Card>
                      </LightTooltip>
                    )
                  }
                  {
                    item.stats !== "decreasing" && item.stats !== "increasing" ? (
                      <LightTooltip
                        title="Was this insight useful?"
                        placement="top"
                      >
                        <Card className={classes.root1}  >

                          <CardHeader
                            avatar={
                              <div
                                aria-label="recipe"
                                className={classes.avatar}
                              >
                                {
                                  // print the function data return
                                  item?.insight_value ==="Null" ? "" : handleInsightType(item?.insight_value, item?.value_type)

                                }
                              </div>
                            }
                            action={
                              <>
                                {" "}
                                <IconButton aria-label="ThumbDownAltIcon">
                                  {item?.user_input === "yes" ? (
                                    <ThumbUpAltIcon
                                      onClick={(event) =>
                                        handleCountUp(event, item, "")
                                      }
                                      className={
                                        item?.user_input == "yes"
                                          ? classes.thumbUpColor
                                          : null
                                      }
                                    />
                                  ) : (
                                    <ThumbUpAltIcon
                                      onClick={(event) =>
                                        handleCountUp(event, item, "yes")
                                      }
                                      className={
                                        item?.user_input == "yes"
                                          ? classes.thumbUpColor
                                          : null
                                      }
                                    />
                                  )}
                                </IconButton>
                                <IconButton aria-label="ThumbUpAltIcon">
                                  {item?.user_input === "no" ? (
                                    <ThumbDownAltIcon
                                      onClick={(event) =>
                                        handleCountDown(event, item, "")
                                      }
                                      className={
                                        item?.user_input == "no"
                                          ? classes.thumbDownColor
                                          : null
                                      }
                                    />
                                  ) : (
                                    <ThumbDownAltIcon
                                      onClick={(event) =>
                                        handleCountDown(event, item, "no")
                                      }
                                      className={
                                        item?.user_input == "no"
                                          ? classes.thumbDownColor
                                          : null
                                      }
                                    />
                                  )}
                                </IconButton>
                              </>
                            }
                            title={item?.insight_text}
                          />
                        </Card>
                      </LightTooltip>) : null}
                </Grid>
              );
            }) // <h1 className="loader">No Data Found</h1>
          }
          {/* {pageData === null && (
            <div className={classes.root11}>
              <CircularProgress disableShrink />
            </div>
          )} */}
        </Grid>
        <div className={classes.root3}>
          {props.originalData?.length > 0 && (
            <Pagination
              count={
                page ? page - 1 : Math.ceil(props.originalData?.length / 6)
              }
              onChange={(event, pageCount) =>
                handleChangePage(event, pageCount)
              }
              variant="outlined"
              shape="rounded"
            />
          )}
        </div>
      </div>
    </>
  );
};
export default DynamicInsights;
