import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { FilterContext, FilterRowContext } from './Context';
import FilterCell from './FilterCell';
import _ from '@lodash';
import {
  IconButton, 
  Icon,  
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  layoutRoot: {},
});

const selectStyles = {
  control: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
  }),

  valueContainer: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
    position: 'initial',
    'padding-left': '4px',
  }),
  indicatorContainer: (base, state) => ({
    ...base,
    padding: '0',
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    height: '14px',
    'min-height': '14px',
    'margin-top': '2px',
    'margin-bottom': '2px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '0 2px',
  }),
  input: (base, state) => ({
    ...base,
    margin: '0',
    'padding-top': '0',
  }),
};

const groupBy = (objectArray, property) => {
  return objectArray.reduce(function (acc, obj) {
    var key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

const getDefaultEventType = (fieldNames) => {
  const groupByType = groupBy(fieldNames, 'eventType');
 return Object.entries(groupByType)?.map(([key, value]) => (
    { label: key, options: value }
  ));
};

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  fontSize: 11,
  justifyContent: 'space-between',
};
const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 11,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const FilterRowPerform = (props) => {
  const context = useContext(FilterContext);

  const updateState = (filterRows, changeType) => {
    if (context?.onChange) {
      context.onChange(
        props.filterIndex,
        { filterRows: filterRows },
        changeType
      );
    }
  };

  const handleJoinOperatorChange = (event) => {
    const joinOperator = event.target.value;
    const filterRows = [...context.filterRows];

    //update the state only if it changed
    if (joinOperator !== filterRows[props.rowIndex].joinOperator) {
      filterRows[props.rowIndex].joinOperator = joinOperator;

      updateState(filterRows, 'JOIN_OPERATOR_CHANGE');
    }
  };

  const handleActionNameChange = (selectedOption) => {
    const action = selectedOption?.value;
    const filterRows = [...context.filterRows];

    //update the state only if it changed
    if (action !== filterRows[props.rowIndex].action) {
      filterRows[props.rowIndex].action = action;
      filterRows[props.rowIndex].field = undefined;
      filterRows[props.rowIndex].operator = undefined;
      filterRows[props.rowIndex].values = [];

      updateState(filterRows, 'ACTION_NAME_CHANGE');
    }
  };

  const handleFieldNameChange = (selectedOption) => {
    const field = selectedOption?.value;
    const filterRows = [...context.filterRows];

    //update the state only if it changed
    if (field !== filterRows[props.rowIndex].field) {
      filterRows[props.rowIndex].field = field;
      filterRows[props.rowIndex].operator = undefined;
      filterRows[props.rowIndex].values = [];

      updateState(filterRows, 'FIELD_NAME_CHANGE');
      handleOperatorAndFieldValue();
    }
  };

  const handleOperatorChange = (selectedOption) => {
    const operator = selectedOption?.value;
    const filterRows = [...context.filterRows];

    //update the state only if it changed
    if (operator !== filterRows[props.rowIndex].operator) {
      filterRows[props.rowIndex].operator = operator;
      filterRows[props.rowIndex].values = [];

      updateState(filterRows, 'OPERATOR_CHANGE');
    }
  };

  const handleFieldValueChange = (values) => {
    const filterRows = [...context.filterRows];
    filterRows[props.rowIndex].values = values;
    updateState(filterRows, 'FIELD_VALUE_CHANGE');
  };


  const handleOperatorAndFieldValue = () => {
    const filterRows = [...context.filterRows];
    filterRows[props.rowIndex].operator = 'EQ';
    updateState(filterRows, 'OPERATOR_CHANGE');
    filterRows[props.rowIndex].values = [{value: '1', label: '1'}];
    updateState(filterRows, 'FIELD_VALUE_CHANGE');
  };

  const deleteRow = () => {
    if (context) {
      const filterRows = [...context.filterRows];
      filterRows.splice(props.rowIndex, 1);

      updateState(filterRows, 'DELETE_ROW');
    }
  };

  const action = props.actionNames?.find((column) => {
    return column.value === props.action;
  });

  const field = action?.fieldNames?.find((column) => {
    return column.value === props.field;
  });

  return (
    <>
      <FilterRowContext.Provider
        value={{
          filterIndex: props.filterIndex,
          rowIndex: props.filterIndex,
          onFieldValueChange: handleFieldValueChange,
        }}
      >
        <div
          className="filter-row-perform"
          id={`filter-row-${props.filterIndex}-${props.rowIndex}`}
        >
          <div className="filter-row-joinOperator-permormed pr-10">
            {props.showJoinOperatorLabel && (
              <Typography variant="caption">
                {
                  props.joinOperatorOptions?.find(
                    (o) => o.value === props.rowJoinOperator
                  )?.label
                }
              </Typography>
            )}
            <Typography variant="caption">{` who performed`}</Typography>
          </div>
          <div className="filter-row-action pr-10">
            <Select
              id={`filter-row-action-name-${props.filterIndex}-${props.rowIndex}`}
              disabled={props.disabled}
              className="text-11 filter-row-fieldName-select"
              label="Select action"
              value={props.actionNames?.find(
                (o) => o.value === (props.action || '')
              )}
              options={props.actionNames}
              onChange={handleActionNameChange}
              styles={selectStyles}
            ></Select>
          </div>
          {action && (
            <div className="filter-row-fieldName pr-10">
              <Select
                id={`filter-row-field-name-${props.filterIndex}-${props.rowIndex}`}
                disabled={props.disabled}
                className="text-11 filter-row-fieldName-perform-select"
                label="Select field"
                value={
                  props.field
                    ? action.fieldNames?.find(
                      (o) => o.value === (props.field || '')
                    )
                    : ''
                }
                options={props.action === 'default' ? getDefaultEventType(action.fieldNames) : action.fieldNames}
                formatGroupLabel={formatGroupLabel}
                onChange={handleFieldNameChange}
                styles={selectStyles}
              />
            </div>
          )}
          {false && props.field && (
            <div className="filter-row-operator pr-10">
              <Select
                id={`filter-row-operator-${props.filterIndex}-${props.rowIndex}`}
                disabled={props.disabled}
                className="text-11 filter-row-joinOperator-select"
                isSearchable={false}
                placeholder={null}
                value={
                  props.operator
                    ? field?.operatorOptions?.find(
                      (o) => o.value === props.operator || ''
                    )
                    : ''
                }
                options={field.operatorOptions}
                onChange={handleOperatorChange}
                styles={selectStyles}
              ></Select>
            </div>
          )}
          {false && props.operator && (
            <div className="filter-row-fieldValue">
              <FilterCell
                {...field}
                disabled={props.disabled}
                operator={props.operator}
                values={props.values}
              />{' '}
              times
            </div>
          )}
          <div className="filter-row-removebtn">
            {props.renderDelete && (
              <IconButton
                disabled={props.disabled}
                size={'small'}
                onClick={deleteRow}
              >
                <Icon>delete</Icon>
              </IconButton>
            )}
          </div>
        </div>
      </FilterRowContext.Provider>
    </>
  );
};

export default FilterRowPerform;
