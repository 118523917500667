import axios from "axios";
import { DashboardConstants } from "../../dashboard/constants/dashboard.constants";
const token = localStorage.getItem("lac-key");
const instance = axios.create({
  baseURL: DashboardConstants.APIURL,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const dynamicInsight = async (client_id) => {
  try {
    const payload = {
      client_id: client_id,
    };
    const { data } = await instance.post(
      "/dashboard/dynamic-insides",
      payload,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getDiTimeStamp = async (client_id) => {
  const payload = {
    client_id: client_id,
  };
  const { data } = await instance.post(
    "/dashboard/dynamic-insides-timeStamp",
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
      },
    }
  );
  return data;
};
// export const dynamicInsightUpdateEveryDay= async (client_id) => {
//   const payload = {
//     client_id:client_id,
//   }
//   const {data} = await instance.post('/dashboard/dynamic-insides-addDay',payload, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem('lac-key')}`,
//       },
//       });
//   return true;
// }

export const dynamicInsightUpdate = async (insideData) => {
  // console.log('insideData', insideData);
  const payload = {
    client_id: insideData?.client_Id,
    insight_text: insideData?.insight_text,
    user_input: insideData?.user_input,
    insight_time_value_in_days: insideData?.insight_time_value_in_days,
    insight_type: insideData?.insight_type,

  };
  // console.log("payload Dashboard js",payload)
  const { data } = await instance.post(
    "/dashboard/dynamic-insides-update",
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
      },
    }
  );
  return data;
};

export const dynamicInsightEmailUpdate = async (insideData) => {
  if (insideData?.frequency === "After 1 Days") {
    // add current date plus one day in new Date 
    let date = new Date();
    date.setDate(date.getDate() + 1);
    insideData.emailStarttime = date;
  }
  else if (insideData?.frequency === "After 3 Days") {
    //  add three day in new Date  convert new Date into  give format 2023-07-02 23:10
    let date = new Date();
    date.setDate(date.getDate() + 3);
    insideData.emailStarttime = date;
  }
  else if (insideData?.frequency === "After 7 Days") {
    //  add seven day in new Date  convert new Date into  give format 2023-07-02 23:10
    let date = new Date();
    date.setDate(date.getDate() + 7);
    insideData.emailStarttime = date;
  }
  else if (insideData?.frequency === "After 9 Days") {
    //  add nine day in new Date  convert new Date into  give format 2023-07-02 23:10
    let date = new Date();
    date.setDate(date.getDate() + 9);
    insideData.emailStarttime = date;
  }





  const payload = {
    client_id: insideData?.client_id,
    to_email: `${JSON.parse(localStorage.getItem("lac-user-client-details"))[0].email_address},${insideData?.cc_email}`,
    email_frequency: insideData?.frequency,
    email_frequency_time: insideData?.time,
    email_timezone: insideData?.timeZone,
    event_insight: insideData?.eventInsight,
    traffic_insight: insideData?.trafficInsight,
    email_starttime: insideData?.emailStarttime,
    create_by: insideData?.createBy,
    create_date: insideData?.createDate,
  };
  const { data } = await instance.post(
    "/dashboard/dynamic-insides-email-update",
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
      },
    }
  );
  return data;
};

export const getDynamicInsightEmail = async (client_id) => {
  const payload = {
    client_id: localStorage.getItem("lac-user-active-client_id"),
    dashboard_login_user_id: JSON.parse(localStorage.getItem("lac-user-client-details"))[0].email_address,
  };
  const { data } = await instance.post(
    "/dashboard/dynamic-email-table-data",
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("lac-key")}`,
      },
    }
  );
  return data;
}

