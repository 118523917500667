import config from './ChartConfig';
import _ from '@lodash';

function getChartConfig(data, type, groupThenBy, multipleSegment, dateRange) {
  switch (type) {
    case 'area': {
      return getAreaChartConfig(data, type);
    }
    case 'line': {
      return getLineChartConfig(data, type, groupThenBy, multipleSegment, dateRange);
    }
    case 'bubble': {
      return getBubbleChartConfig(data, type);
    }
    case 'pie': {
      return getPieChartConfig(data, type);
    }
    case 'treemap': {
      return getTreeMapChartConfig(data, type);
    }
    case 'histogram': {
      return getBarHistogramChartConfig(data, type);
    }
    case 'donut': {
      return getDonutChartConfig(data, type);
    }
    case 'bar': {
      return getBarChartConfig(data, type);
    }
    case 'stacked': {
      return getStackedBarChartConfig(data, type);
    }
  }
}

function getAreaChartConfig(data, type) {
  const state = config[type];
  const series = [];
  let categories = [];

  data.series.forEach((item) => {
    const seriesdata = [];
    categories = [];
    item.data.forEach((seriesData) => {
      categories.push(seriesData.xaxis);
      seriesdata.push(parseInt(parseInt(seriesData.yaxis, 10)));
    });
    series.push({ name: item.name, data: seriesdata });
  });

  state.config.series = series;
  state.config.options.xaxis.categories = categories;
  state.config.options.title.text = data.name;

  return _.cloneDeep(state);
}

function getTreeMapChartConfig(data, type) {
  const state = config[type];

  const colours_array = [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#3F51B5',
    '#03A9F4',
    '#4CAF50',
    '#F9CE1D',
    '#FF9800',
    '#33B2DF',
    '#546E7A',
    '#D4526E',
    '#13D8AA',
    '#A5978B',
    '#4ECDC4',
    '#C7F464',
    '#81D4FA',
    '#546E7A',
    '#FD6A6A',
    '#2B908F',
    '#F9A3A4',
    '#90EE7E',
    '#FA4443',
    '#69D2E7',
    '#449DD1',
    '#F86624',
    '#EA3546',
    '#662E9B',
    '#C5D86D',
    '#D7263D',
    '#1B998B',
    '#2E294E',
    '#F46036',
    '#E2C044',
    '#662E9B',
    '#F86624',
    '#F9C80E',
    '#EA3546',
    '#43BCCD',
    '#5C4742',
    '#A5978B',
    '#8D5B4C',
    '#5A2A27',
    '#C4BBAF',
    '#A300D6',
    '#7D02EB',
    '#5653FE',
    '#2983FF',
    '#00B1F2',
  ];

  const series = [];

  if (data.series.length == 0) {
    data.series.push({
      name: 'series 1',
      data: [
        { label: 'No data is available for the given Daterange ', count: '1' },
      ],
    });
  }

  data.series.forEach((item) => {
    const seriesdata = [];
    item.data.forEach((seriesData) => {
      var colr =
        colours_array[Math.floor(Math.random() * colours_array.length)];
      seriesdata.push({
        x: seriesData.xaxis || 'NA',
        y: parseInt(seriesData.yaxis),
        fillColor: colr,
      });
    });
    series.push({ name: item.name, data: seriesdata });
  });

  state.config.series = series;
  state.config.options.title.text = data.name;

  return _.cloneDeep(state);
}

function getBarHistogramChartConfig(data, type) {
  const state = config[type];
  const series = [];
  let categories = [];

  data.series.forEach((item) => {
    const seriesdata = [];
    categories = [];
    item.data.forEach((seriesData) => {
      categories.push(seriesData.xaxis);
      seriesdata.push(parseInt(seriesData.yaxis));
    });
    series.push({ name: item.name, data: seriesdata });
  });

  state.config.series = series;
  state.config.options.xaxis.categories = categories;
  state.config.options.title.text = data.name;

  return _.cloneDeep(state);
}

function getBarChartConfig(data, type) {
  const state = config[type];
  const series = [];
  let categories = [];

  const colours_set = new Set();

  // generate unique color codes for series
  for(var i = 0; data.series.length > colours_set.size; i++) {
    colours_set.add("#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase());
  }

  const colours_array = Array.from(colours_set);

  data.series.forEach((item, index) => {
    const seriesdata = [];
    categories = [];
    item.data.forEach((seriesData) => {
      categories.push(seriesData.xaxis);
      seriesdata.push(parseInt(parseInt(seriesData.yaxis, 10)));
    });
    series.push({ name: item.name, data: seriesdata, color: colours_array[index] });
  });

  state.config.series = series;
  state.config.options.xaxis.categories = categories;
  state.config.options.title.text = data.name;

  return _.cloneDeep(state);
}

function getStackedBarChartConfig(data, type) {
  const state = config[type];
  const series = [];
  let categories = [];
  data.series.forEach((item) => {
    const seriesdata = [];
    categories = [];
    item.data.forEach((seriesData) => {
      categories.push(seriesData.xaxis);
      seriesdata.push(seriesData.yaxis);
    });
    series.push({ name: item.name, data: seriesdata });
  });
  state.config.series = series;
  state.config.options.xaxis.categories = categories;
  state.config.options.title.text = data.name;

  return _.cloneDeep(state);
}

function getDonutChartConfig(data, type) {
  const state = config[type];
  const series = [];
  let categories = [];
  data.series.forEach((item) => {
    categories = [];
    item.data.forEach((seriesData) => {
      categories.push(seriesData.xaxis);
      series.push(parseInt(seriesData.yaxis, 10));
    });
  });

  state.config.series = series;
  state.config.options.labels = categories;
  state.config.options.legend.formatter = function (val, opts) {
    return (
      categories[opts.seriesIndex] +
      ' - ' +
      opts.w.globals.series[opts.seriesIndex]
    );
  };
  state.config.options.title.text = data.name;

  return _.cloneDeep(state);
}

function getLineChartConfig(data, type, groupThenBy, multipleSegment, dateRange) {
  const state = config[type];
  const series = [];

  if (!data.series || (data.series && data.series.length == 0)) {
    data.series = [];
    data.series.push({
      name: 'series 1',
      data: [
        {
          label: 'No data is available for the given Daterange ',
          count: '1',
        },
      ],
      color: '#008FFB'
    });
  }

  const colours_set = new Set();

  // generate unique color codes for series
  for(var i = 0; data.series.length > colours_set.size; i++) {
    colours_set.add("#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase());
  }

  const colours_array = Array.from(colours_set);

  data?.series.forEach((item, index) => {
    const seriesdata = [];
    item.data.forEach((seriesData) => {
      seriesdata.push({
        x: seriesData.xaxis || seriesData.xaxis == 0 ? seriesData.xaxis.toString() : 'NA',
        y: parseInt(seriesData.yaxis),
      });
    });
    series.push({ name: item.name, data: seriesdata, color: colours_array[index] });
  });

  state.config.series = series;
  state.config.options.title.text = data.name;
  if (!groupThenBy && !multipleSegment && ['today','7D','30D','45D'].indexOf(dateRange) >= 0) {
    state.config.options.stroke = {
      width: [3, 5],
      curve: 'straight',
      dashArray: [8, 0]
    }
    state.config.series[0].name = 'Previous '
    state.config.series[1].name = 'Current '
    if(dateRange) {
      state.config.series[0].name = state.config.series[0].name + dateRange
      state.config.series[1].name = state.config.series[1].name + dateRange
    }
  } else if (state?.config?.options?.stroke) {
    delete state?.config?.options?.stroke
  }

  return _.cloneDeep(state);
}
function getPieChartConfig(data, type) {
  const state = config[type];
  const colours_array = [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#3F51B5',
    '#03A9F4',
    '#4CAF50',
    '#F9CE1D',
    '#FF9800',
    '#33B2DF',
    '#546E7A',
    '#D4526E',
    '#13D8AA',
    '#A5978B',
    '#4ECDC4',
    '#C7F464',
    '#81D4FA',
    '#546E7A',
    '#FD6A6A',
    '#2B908F',
    '#F9A3A4',
    '#90EE7E',
    '#FA4443',
    '#69D2E7',
    '#449DD1',
    '#F86624',
    '#EA3546',
    '#662E9B',
    '#C5D86D',
    '#D7263D',
    '#1B998B',
    '#2E294E',
    '#F46036',
    '#E2C044',
    '#662E9B',
    '#F86624',
    '#F9C80E',
    '#EA3546',
    '#43BCCD',
    '#5C4742',
    '#A5978B',
    '#8D5B4C',
    '#5A2A27',
    '#C4BBAF',
    '#A300D6',
    '#7D02EB',
    '#5653FE',
    '#2983FF',
    '#00B1F2',
  ];
  const series = [];
  let categories = [];
  data.series.forEach((item) => {
    categories = [];
    item.data.forEach((seriesData) => {
      categories.push(seriesData.xaxis);
      series.push(parseInt(seriesData.yaxis, 10));
    });
  });


  state.config.series = series;
  state.config.options.labels = categories;
  state.config.options.colors = colours_array;
  state.config.options.title.text = data.name;

  return _.cloneDeep(state);
}

function getBubbleChartConfig(data, type) {
  const state = config[type];

  const colours_array = [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#3F51B5',
    '#03A9F4',
    '#4CAF50',
    '#F9CE1D',
    '#FF9800',
    '#33B2DF',
    '#546E7A',
    '#D4526E',
    '#13D8AA',
    '#A5978B',
    '#4ECDC4',
    '#C7F464',
    '#81D4FA',
    '#546E7A',
    '#FD6A6A',
    '#2B908F',
    '#F9A3A4',
    '#90EE7E',
    '#FA4443',
    '#69D2E7',
    '#449DD1',
    '#F86624',
    '#EA3546',
    '#662E9B',
    '#C5D86D',
    '#D7263D',
    '#1B998B',
    '#2E294E',
    '#F46036',
    '#E2C044',
    '#662E9B',
    '#F86624',
    '#F9C80E',
    '#EA3546',
    '#43BCCD',
    '#5C4742',
    '#A5978B',
    '#8D5B4C',
    '#5A2A27',
    '#C4BBAF',
    '#A300D6',
    '#7D02EB',
    '#5653FE',
    '#2983FF',
    '#00B1F2',
  ];

  const series = [];

  if (data.series.length == 0) {
    data.series.push({
      name: 'series 1',
      data: [
        { label: 'No data is available for the given Daterange ', count: '1' },
      ],
    });
  }

  data.series.forEach((item) => {
    const seriesdata = [];
    item.data.forEach((seriesData) => {
      var colr =
        colours_array[Math.floor(Math.random() * colours_array.length)];
      seriesdata.push({
        x: seriesData.xaxis || 'NA',
        y: parseInt(seriesData.yaxis),
        r: parseInt(seriesData.size),
        fillColor: colr,
      });
    });
    series.push({ name: item.name, data: seriesdata });
  });

  state.config.series = series;
  state.config.options.title.text = data.name;

  return _.cloneDeep(state);
}

export default getChartConfig;
