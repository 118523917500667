import { EnhancedToolbar } from "./Toolbar";
import { Title } from "./Title";

export const Header = (props) => {
  const {onSearch}=props;
  return (
    <div>
      <Title title="Users" />
      <EnhancedToolbar onSearch={onSearch}/>
    </div>
  );
};
