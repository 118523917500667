import FusePageSimple from '@fuse/core/FusePageSimple';
import { makeStyles } from '@material-ui/core/styles';
import UserJourneySankey from './UserJourneySankey'
const useStyles = makeStyles({
	layoutRoot: {}
});

function UserJourney() {
	const classes = useStyles();

	return (
		<FusePageSimple
			classes={{
				root: classes.layoutRoot
			}}
			// header={
			// 	<div className="p-4">
			// 		<h4>UserJourney Header</h4>
			// 	</div>
			// }
			// contentToolbar={
			// 	<div className="px-4">
			// 		<h4>UserJourney Content Toolbar</h4>
			// 	</div>
			// }
			content={
				 <div className="p-4">
				<UserJourneySankey /> 
				// 	<h4>UserJourney Content</h4>
				// 	<br />
				 </div>
				
			}
		/>
	);
}

export default UserJourney;
