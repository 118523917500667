import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SubTab from "../SubTabs/SubTab";
import CircularUnderLoad from '../UiSpinner/UiSpinner'
import Swal from "sweetalert2";
import { sendRegistrationEmail } from '../../../../../main/register/services/register.service'
import {
  selectDynamicEntities
} from '../../store/dynamicSlice'
// css
import "./TabEffect.css";
import { Dialog, DialogActions, Grid } from "@material-ui/core";
import { dynamicInsightEmailUpdate, getDynamicInsightEmail } from '../../services/dynamicinsight.service'
import { da } from "date-fns/locale";
import { set } from "lodash";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    margin: "2rem",
  },
  tabs: {
    padding: ".5rem"
  },
  indicator: {
    backgroundColor: "transparent",

  },
  // selected: {
  //   backgroundColor: theme.palette.secondary.main,
  //   color: theme.palette.secondary.contrastText,
  //   borderRadius: "25px",
  // },
}));
const tabStyle = {
  default_tab: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    fontSize: 15
  },
  active_tab: {
    background: " #437EEB",
    borderRadius: "9px",
    color: " #ffffff"
  }
};
export default function TabEffect(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0)
  const dynamicData = useSelector(selectDynamicEntities);
  const [emailForm, setEmailForm] = useState({
    to_email: "",
    cc_email: "",
    frequency: "",
    time: "",
    timeZone: "",
    eventInsight: "",
    trafficInsight: "",
    client_id: "",
    createDate: "",
    createBy: "",
    emailStarttime: ""
  })
  const [emailDataTraffic, setEmailDataTraffic] = useState([])
  const [emailDataEvent, setEmailDataEvent] = useState([])





  const [trafficInsightBusinessCategory, setTrafficInsightBusinessCategory] = useState([])
  const [eventInsightBusinessCategory, setEventInsightBusinessCategory] = useState([])
  const handleChange = (event, newValue) => {
    setActiveIndex(newValue)
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getStyle = (isActive) => {
    return isActive ? tabStyle.active_tab : tabStyle.default_tab
  }
  // useEffect filter event with business_category in array and traffic with category in array
  useEffect(() => {
    if (dynamicData?.eventsInsight?.data?.length > 0) {
      // 
      // push all business_category in array 
      let emailDataEvent = []
      let newArray = []
      dynamicData?.eventsInsight?.data?.map((item) => {
        newArray.push(item.business_category)
        if (item?.insight_type === "Summary") {
          emailDataEvent.push({
            business_category: item?.business_category,
            insight_text: item?.insight_text,
          }
          )
        }
      }
      )
      setEmailDataEvent(emailDataEvent)
      // remove duplicate value from array
      let removeDuplicate = [...new Set(newArray)]
      console.log("removeDuplicate", removeDuplicate)
      setEventInsightBusinessCategory(removeDuplicate)
    }

    if (dynamicData?.trafficInsight?.data?.length > 0) {
      // push all business_category in array
      let newArray = []
      let emailDataTraffic = []
      dynamicData?.trafficInsight?.data?.map((item) => {
        newArray.push(item.business_category)
        if (item?.insight_type === "Summary") {
          emailDataTraffic.push({
            business_category: item?.business_category,
            insight_text: item?.insight_text,
          }
          )
        }
      }
      )
      // remove duplicate value from array
      setEmailDataTraffic(emailDataTraffic)
      let removeDuplicate = [...new Set(newArray)]
      console.log("removeDuplicate", removeDuplicate)
      setTrafficInsightBusinessCategory(removeDuplicate)

    }
  }, [dynamicData?.eventsInsight?.data, dynamicData?.trafficInsight?.data])
  const handleChangeEmailForm = (e) => {
    // convert new Date into  give format 2023-07-02 23:10
    //  take company name from local storage lac-user-client-details 
    let company_name = JSON.parse(localStorage.getItem("lac-user-client-details"))[0].company_name
    console.log("company_name", company_name)
    setEmailForm({
      ...emailForm,
      client_id: localStorage.getItem("lac-user-active-client_id"),
      // create date based on Time zone code 
      // createBy:localStorage.getItem("lac-user-client-details")?.company_name,
      //  take company name from local storage lac-user-client-details and set in createBy
      createBy: company_name,
      // set 2023-07-02 23:10 
      createDate: new Date(),
      emailStarttime: "",
      [e.target.name]: e.target.value
    })
  }

  console.log("emailForm", emailForm)
  const handleSendEmail = async () => {
    try {
      const data = await dynamicInsightEmailUpdate(emailForm);
      console.log(data, "json data di")
      if (data?.data == "DB update successfully") {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Email sent successfully on given time and frequency',
        })
        setOpen(false)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
        setOpen(false)

      }
    } catch (err) {
      console.log(err)
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!',
      })
      setOpen(false)

    }
  }

  // useEffect(() => {
  //   // getDynamicInsightEmail
  //   const getDynamicInsightEmailData = async () => {

  //     try {
  //       const data = await getDynamicInsightEmail();
  //       console.log("data", data)
  //       // form data email_starttime and email_frequency_time  and set email api on that time 
  //       if (data?.data?.length > 0) {
  //         data.map((item) => {
  //           if (data?.to_email === JSON.parse(localStorage.getItem("lac-user-client-details"))[0].email_address) {
  //             // get only year month and date from email_starttime
  //             let email_starttime = new Date(item?.email_starttime).toISOString().slice(0, 10)
  //             // add email_frequency_time in email_starttime
  //             let combineDateAndtime = email_starttime + " " + item?.email_frequency_time
  //             // convert 2023-07-13 12:00 am into new Date
  //             let date = new Date(combineDateAndtime)
  //             // get current date
  //             let currentDate = new Date()
  //             // compare current date and email_starttime

  //             let emailDataTrafficth = ""
  //             let emailDataTraffictd = ""

  //             emailDataTraffic.map((item) => {
  //               // add th  
  //               emailDataTrafficth += `<tr>
  //               <th>${item?.business_category}</th>
  //           </tr>`
  //               // add td
  //               emailDataTraffictd += `<tr>
  //               <td>${item?.insight_text}</td>
  //           </tr>`
  //             })
  //             // event data
  //             let emailDataEventth = ""
  //             let emailDataEventtd = ""
  //             emailDataEvent.map((item) => {
  //               // add th
  //               emailDataEventth += `<tr>
  //               <th>${item?.business_category}</th>
  //           </tr>`
  //               // add td
  //               emailDataEventtd += `<tr>
  //               <td>${item?.insight_text}</td>
  //           </tr>`
  //             })
  //             // console.log("emailDataTrafficth",emailDataTrafficth)

  //             if (date.getTime() === currentDate.getTime()) {
  //               // call email api
  //               const emailinfo = {
  //                 to: item?.to_email,
  //                 cc: item?.cc_email,
  //                 subject: "Dynamic Insight",
  //                 text: "Dynamic Insight data",
  //                 html: `

  //               <head>
  //               <meta charset="UTF-8">
  //               <meta name="viewport" content="width=device-width, initial-scale=1.0">
  //               <title>Di Email</title>
  //               </head>
  //               <style>
  //               table, th, td {
  //               border: 1px solid black;
  //               border-collapse: collapse;
  //               padding: 10px;
  //               text-align: left;

  //               }
  //               /* divided table into two part
  //               one as  th and one as td */
  //               th{
  //               width: 50%;

  //               }


  //               </style>
  //               <body>
  //               <h1>Event insights</h1>
  //               <!-- table with 2 column -->
  //               <!-- style table with border for every column and row  -->
  //               <table>
  //               `+ emailDataEventth + `
  //               `+ emailDataEventtd + `

  //               </table>

  //               <h1> Traffic Insights</h1>

  //               <table>
  //               `+ emailDataTrafficth + `
  //               `+ emailDataTraffictd + `

  //               </table>

  //               <h2><strong>For in detail analyisis, check out the dashboard</strong></h2>

  //               <button 
  //               style="background-color: #437EEB; /* Green */
  //               border: none;
  //               color: white;
  //               padding: 15px 32px;
  //               text-align: center;
  //               text-decoration: none;
  //               border-radius: 12px;
  //               font-size: 16px;
  //               margin: 4px 2px;
  //               cursor: pointer;"
  //               >
  //               <a 
  //               // add href link of dashboard from env file
  //               href=${process.env.REACT_APP_DASHBOARD_URL}/dynamic-insights
  //               >
  //               open dashboard
  //               </a>
  //               </button>


  //               </body>
  //               `
  //               }
  //               sendRegistrationEmail(emailinfo)


  //             }
  //           }
  //         })
  //       }
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   }
  //   // call every 1 minute
  //   setInterval(() => {
  //     getDynamicInsightEmailData()
  //   }, 60000)
  //   // clearInterval()

  // }, [])
  // get email_address from local storage lac-user-client-details
  const email_address = JSON.parse(localStorage.getItem("lac-user-client-details"))[0].email_address
  return (
    <div className={classes.root}>
      {
        // dailog box with email form 
        // <Dialog open={open} onClose={() => {
        //   console.log("close")
        //   setOpen(false)

        // }} aria-labelledby="form-dialog-title" fullWidth>
        //   <div style={{ padding: "2rem" }}>
        //     <Typography variant="h6" component="h6" align="left" className="font-800">Send Email</Typography>
        //     <div style={{ display: "flex", flexDirection: "column", marginTop: "2rem" }}>
        //       <Typography variant="h6" component="h6" align="left" className="font-800">To Email</Typography>
        //       <input type="text" disabled onChange={handleChangeEmailForm} name="to_email" value={email_address} placeholder="Enter email address" style={{ padding: ".5rem", border: "1px solid #A0A4A8", borderRadius: "5px", marginTop: ".5rem" }} />
        //     </div>
        //     <div style={{ display: "flex", flexDirection: "column", marginTop: "2rem" }}>
        //       <Typography variant="h6" component="h6" align="left" className="font-800">CC Email</Typography>
        //       <input type="text" onChange={handleChangeEmailForm} name="cc_email" value={emailForm.cc_email} placeholder="Enter email address" style={{ padding: ".5rem", border: "1px solid #A0A4A8", borderRadius: "5px", marginTop: ".5rem" }} />
        //     </div>
        //   </div>
        //   <Grid container justify="center" style={{ padding: "2rem" }}>
        //     {/* divided into three */}
        //     <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
        //       <div style={{ display: "flex", flexDirection: "column", }}>
        //         {/* dropdown */}
        //         <Typography variant="h6" component="h6" className="font-800">Select frequency</Typography>
        //         <select style={{ padding: ".5rem", border: "1px solid #A0A4A8", borderRadius: "5px", marginTop: ".5rem" }}
        //           name="frequency" onChange={handleChangeEmailForm} value={emailForm.frequency}
        //         >
        //           {/* tell to select in ui */}
        //           <option value="">Select</option>
        //           <option value="After 1 Days">After 1 Days</option>
        //           <option value="After 3 Days">After 3 Days</option>
        //           <option value="After 7 Days">After 7 Days</option>
        //           <option value="After 9 Days">After 9 Days</option>
        //         </select>
        //       </div>
        //     </Grid>
        //     <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
        //       <div style={{ display: "flex", flexDirection: "column" }}>
        //         {/* time dropdown am and pm */}
        //         <Typography variant="h6" component="h6" className="font-800">Select time</Typography>
        //         <select style={{ padding: ".5rem", border: "1px solid #A0A4A8", borderRadius: "5px", marginTop: ".5rem" }}
        //           name="time" onChange={handleChangeEmailForm} value={emailForm.time}
        //         >
        //           {/* tell to select in ui */}
        //           <option value="">Select</option>
        //           <option value="12:00 am">12:00 am</option>
        //           <option value="01:00 pm">01:00 pm</option>
        //           <option value="02:00 am">02:00 am</option>
        //           <option value="06:00 pm">06:00 pm</option>
        //         </select>
        //       </div>
        //     </Grid>
        //     <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
        //       <div style={{ display: "flex", flexDirection: "column" }}>
        //         {/* dropdown for different different time zone */}
        //         <Typography variant="h6" component="h6" align="center" className="font-800">Time zone</Typography>
        //         <select style={{ padding: ".5rem", border: "1px solid #A0A4A8", borderRadius: "5px", marginTop: ".5rem" }}
        //           name="timeZone" onChange={handleChangeEmailForm} value={emailForm.timeZone}
        //         >
        //           {/* tell to select in ui */}
        //           <option value="">Select</option>
        //           <option value="IST">IST</option>
        //           {/* <option value="CET"> CET</option> */}
        //         </select>
        //       </div>
        //     </Grid>
        //   </Grid>
        //   {/* dropdown for event */}
        //   <Grid container style={{ padding: "2rem" }} fullWidth>
        //     <Typography variant="h6" component="h6" align="left" className="font-800">Event Insights</Typography>

        //     <Grid item xs={12} >
        //       <select style={{ padding: ".5rem", border: "1px solid #A0A4A8", borderRadius: "5px", marginTop: ".5rem", width: "-webkit-fill-available" }}
        //         name="eventInsight" onChange={handleChangeEmailForm} value={emailForm.eventInsight}
        //       >
        //         <option value="">Select</option>
        //         {
        //           eventInsightBusinessCategory?.length > 0 && eventInsightBusinessCategory.map((item, index) => {
        //             // tell to select in ui
        //             return (
        //               <option key={index} value={item}>{item}</option>
        //             )
        //             // return (
        //             //   <option key={index} value={item}>{item}</option>
        //             // )
        //           }
        //           )
        //         }
        //       </select>
        //     </Grid>
        //   </Grid>
        //   <Grid container style={{ padding: "2rem" }} fullWidth>
        //     <Typography variant="h6" component="h6" align="left" className="font-800">Traffic Insights</Typography>
        //     <Grid item xs={12}>

        //       <select style={{ padding: ".5rem", border: "1px solid #A0A4A8", borderRadius: "5px", marginTop: ".5rem", width: "-webkit-fill-available" }}
        //         name="trafficInsight" onChange={handleChangeEmailForm} value={emailForm.trafficInsight}
        //       >
        //         <option value="">Select</option>
        //         {
        //           trafficInsightBusinessCategory?.length > 0 && trafficInsightBusinessCategory.map((item, index) => {
        //             return (

        //               <option key={index} value={item}>{item}</option>

        //             )
        //           }
        //           )
        //         }
        //       </select>
        //     </Grid>
        //   </Grid>

        //   <DialogActions>
        //     <div style={{ display: "flex", justifyContent: "end", padding: "2rem" }}>
        //       <button onClick={() => {
        //         setOpen(false)
        //       }} style={{ padding: ".5rem 1rem", border: "1px solid #A0A4A8", borderRadius: "5px", marginTop: ".5rem", marginRight: "1rem" }}>Cancel</button>
        //       <button onClick={() => {
        //         handleSendEmail()
        //         // add primary color
        //       }} style={{ padding: ".5rem 1rem", border: "1px solid #A0A4A8", borderRadius: "5px", marginTop: ".5rem", color: "#fff", backgroundColor: "#437EEB" }}
        //       // add [rimary color to button

        //       >Send</button>
        //     </div>
        //   </DialogActions>



        // </Dialog>

      }
      <AppBar
        position="static"
        elevation={0}
        style={{ background: "none", border: "1px solid #A0A4A8" }}
      >
        <Tabs id="insightType"
          TabIndicatorProps={{ className: classes.indicator }}
          value={value}
          onChange={handleChange}
          aria-label="full width tabs example"
          className={classes.tabs}
        >
          <Tab style={getStyle(activeIndex === 0)} label="Event Insights" {...a11yProps(0)} />
          <Tab style={getStyle(activeIndex === 1)} label="Traffic Insights" {...a11yProps(1)} />
          {/* <div
            //send email button style in end of tab
            onClick={() => {
              setOpen(true)
            }}

            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "2rem",
              cursor: "pointer",
            }}
          >
            <MailOutlineIcon style={{ color: "#242424", fontSize: "1.5rem" }} className="font-800" />
            <Typography className="font-800" component={`span`} style={{ color: "#242424", fontSize: "1rem" }}>Send Email</Typography>
          </div>*/}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {
            dynamicData?.eventsInsight?.data?.length > 0 ?
              (<SubTab dateRange={props.dateRange} category="event" />) : (dynamicData?.eventsInsight?.data?.length > 0 ? (<Typography align="center">No Event Found</Typography>) : <CircularUnderLoad />)
          }

        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {
            dynamicData?.trafficInsight?.data?.length > 0 ?
              (<SubTab dateRange={props.dateRange} category="traffic" />) : (dynamicData?.trafficInsight?.data?.length > 0 ? (<Typography align="center">No Event Found</Typography>) : <CircularUnderLoad />)
          }
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
