import React, { useEffect, useState } from "react";
import { Link as RouteLink } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Grid, Link } from "@material-ui/core";
import { DataTable } from "../components/data-table/DataTable";
import { UserGridColumn } from "../components/user-grid-column/UserGridColumn";
import { GridDeleteRole } from "../components/user-grid-column/GridDeleteRole";
import { DeleteUserDialog } from "../components/delete-dialog/DeleteUserDialog";
import { useDispatch } from "react-redux";
import { openDialog } from "app/store/fuse/dialogSlice";
import { getUsers, deleteUser, removeUserRole } from "../services/users";
function Alert(props) {
  return <MuiAlert elevation={6000} variant="filled" {...props} />;
}
const columns = [
  {
    label: "Email",
    accessor: "email",
  },
  {
    label: "Name",
    accessor: "fullname",
    render: (col) => {
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <div>{col}</div>
        </Grid>
      );
    },
  },

  {
    label: "Assigned Business",
    accessor: "businessAccountInfo",
    render: (col) => {
      const assignedBusinessList = col
        .filter((i) => i.role)
        .map((item) => {
          return {
            primary: item.assignedBusiness,
          };
        });

      return <UserGridColumn data={assignedBusinessList} />;
    },
  },
  {
    label: "Access Role",
    accessor: "businessAccountInfo",
    render: (col) => {
      const roleList = col.map((item) => {
        return {
          primary: item.role,
        };
      });
      return <UserGridColumn data={roleList} />;
    },
  },
  {
    label: " ",
    accessor: "businessAccountInfo",
    width: "1%",
  },
];

export default function ListUsersTab(props) {
  const { searchText } = props;
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gridReload, setGridReload] = useState(0);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const dispatch = useDispatch();

  useEffect(async () => {
    setLoading(true);
    const { email_address } = JSON.parse(
      localStorage.getItem("lac-user-details")
    );
    try {
      let data = await getUsers(email_address);
      setUsers(data);
    } catch {
    } finally {
      setLoading(false);
    }
  }, [gridReload]);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  useEffect(() => {
    let filteredData;
    if (searchText) {
      filteredData = users.filter((item) => {
        return item.fullname.toLowerCase().indexOf(searchText) > -1;
      });
      setFilteredUsers(filteredData);
    }
  }, [searchText]);

  columns[0].render = (col, row) => {
    const { email_address } = JSON.parse(
      localStorage.getItem("lac-user-details")
    );
    return (
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <div>{col}</div>
        <RouteLink to={`/manage-users/edit-user/${col}`}>
          <Link
            component="button"
            variant="body2"
            style={{ textDecoration: "underline",color:"black" }}
          >
            Edit profile
          </Link>
        </RouteLink>
        {col !== email_address && (
          <Link
            component="button"
            variant="body2"
            style={{ textDecoration: "underline", color: "#CC3737" }}
            className="mt-20"
            onClick={() =>
              dispatch(
                openDialog({
                  children: (
                    <DeleteUserDialog
                      email={col}
                      deleteUser={() => {
                        deleteUser(row).then(() => {
                          setOpen(true);
                          setAlertMessage("User deleted successfully");
                          setAlertType("success");
                          setGridReload((prevState) => prevState + 1);
                        });
                      
                      }}
                    />
                  ),
                })
              )
            }
          >
            Remove from Organization{" "}
          </Link>
        )}
      </Grid>
    );
  };

  columns[4].render = (col, rowData) => {
    const roleList = col.map((item, index) => {
      const { email } = rowData;
      return {
        render: () => {
          return (
            <GridDeleteRole
              item={item}
              email={email}
              role={item.role}
              deleteUserRole={() => {
                let businessAccountInfo = rowData.businessAccountInfo.map(
                  (i, _index) => {
                    if (index == _index) {
                      delete i.role;
                      i.access_role = "";
                    } else {
                      i.access_role = i.role;
                    }
                    return i;
                  }
                );

                removeUserRole({
                  ...rowData,
                  businessAccountInfo: businessAccountInfo,
                }).then(() => {
                  setGridReload((prevState) => prevState + 1);
                  setAlertMessage("User deleted successfully");
                  setAlertType("success");
                  
                });
              }}
            />
          );
        },
      };
    });
    return <UserGridColumn data={roleList} />;
  };

  return (
    <>
     <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
    <DataTable
      data={searchText ? filteredUsers : users}
      columns={columns}
      loading={loading}
      isRowStyled={true}
    />
    </>
  );
}
