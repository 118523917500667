import withReducer from 'app/store/withReducer';
import reducer from './store';
import AppBar from '@material-ui/core/AppBar';
import { Grid, Button, ButtonGroup, NativeSelect } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';

import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useDispatch } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import {
  selectKpisEntities,
  fetchKpis,
  updateUserKpis,
} from 'app/main/app/dashboard/store/kpisSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import {
  fetchCharts
} from '../../../main/app/dashboard/store/chartsSlice';
import {
  setKPIOrderChanged,
  setChartsOrderChanged,
  setDashboardDateRange,
  setDashboardDateFrequency
} from '../../../main/app/dashboard/store/dashboardSlice';
import SearchBar from 'material-ui-search-bar';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
  fetchUrlValue,
} from "../../../main/app/behaviour-analysis/heatmap/store/url2Slice";
import {
  fetchDateValue,
} from "../../../main/app/behaviour-analysis/heatmap/store/dateSlice";
import moment from "moment/moment";
import FormHelperText from '@mui/material/FormHelperText';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#fff',
  },
  btn: {
    margin: `2px ${theme.spacing(2)}px`,
  },
  searchInput: {
    width: '40%',
    left: '20px',
  },
  newButton: {
    position: 'absolute',
    right: '60px',
  },
  button: {
    position: 'absolute',
    right: '100px',
    padding: '20px',
  },
  power: {
    position: 'absolute',
    right: '5px',
  },
  headHeader: {
    border :'1px solid #EBEBEB'
    //boxShadow: '0px 2px 5px #ccc',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  largeBtn: {
    width: '140px',
    height: '50px',
    marginLeft: '20px',
  },
  revealActive: {
    opacity: "1",
    maxHeight: "100px",
    overflow: "visible",
    transform: "scale(1)",
  },
  revealInactive: {
    opacity: "0",
    maxHeight: "0",
    overflow: "hidden",
    transform: "scale(0.8)",
    transition: "0.5s",
  },
  formroot: {
    '& .MuiFormControl-root': {
      width: '80%',
      margin: theme.spacing(1),
    },
  },
  selectedButton: {
    backgroundColor: ' #25303E',
    color: 'white',
    '&:hover': {
      backgroundColor: ' #25303E', // "#D3D3D3"
      color: 'white',
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const SHOW_RESET_BUTTON = false;
function ToolbarLayout1(props) {
  const [userInput, setUserInput] = useState("");  
  const dashboardEntities = useSelector((state) => state.dashboard.page);
  const [dateRange, setDateRange] = useState(dashboardEntities.dateRange);  
  const [dateFrequency, setDateFrequency] = useState(dashboardEntities.dateFrequency);
  const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
  const navbar = useSelector(({ fuse }) => fuse.navbar);
  const toolbarTheme = useSelector(selectToolbarTheme);
  const dispatch = useDispatch();
  const title = props.title;
  const classes = useStyles();

  let pastDate = new Date();
  pastDate.setDate(pastDate.getDate() - 7);
  const [open, setOpen] = useState(false);

  const [charttitle, setChartTitle] = useState('');
  const [chartType, setChartType] = useState('');
  const dashboardPageState = useSelector((state) => state.dashboard.page);
  const user = useSelector((state) => state.auth.user);
  const [snakBaropen, setSnakBarOpen] = useState(false);
  const today = new Date();
  const todayForm = today.toISOString().substring(0, 10);
  const [datevalue, setdateValue] = useState("Change3Months");
  const [enableDate, setenableDate] = useState(false);

  const [toDate, setToDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [fromDate, setFromDate] = useState(
    new Date().toISOString().substring(0, 10)
  );

  let yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayForm = yesterday.toISOString().substring(0, 10);
  let dayBefore = new Date();
  dayBefore.setDate(dayBefore.getDate() - 1);
  const dayBeforeForm = dayBefore.toISOString().substring(0, 10);

  let week = new Date();
  week.setDate(week.getDate() - 7);
  const weekForm = week.toISOString().substring(0, 10);

  const [selectedDateStart, setSelectedStartDate] = useState(
    'Thu Apr 22 2021 19:39:00 GMT+0200 (Central European Summer Time)'
  );
  const [selectedDateEnd, setSelectedEndDate] = useState(today);
  const [selectedTodayButtonFlag, setselectedTodayButtonFlag] = useState(false);
  const [
    selectedYesterdayButtonFlag,
    setselectedYesterdayButtonFlag,
  ] = useState(false);
  const [selectedWeekButtonFlag, setselectedWeekButtonFlag] = useState(false);
  let changeToolbarDisplay = useSelector(state => {
    return state.toolbarlayout1.toggleFlagToolBar.toggleFlag
  });
  const isHeatmapLoading = useSelector(state => state.heatmap.loading)
  const [displayFlag, setDisplayFlag] = useState(false);
  useEffect(() => {
    if (changeToolbarDisplay === true || changeToolbarDisplay === "true")
      setDisplayFlag(true);
    else
      setDisplayFlag(false);
  }, [changeToolbarDisplay]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDateRange = (newDateRange) => {
    setDateRange(newDateRange);
    dispatch(setDashboardDateRange({ dateRange: newDateRange }));
  };

  const handleDateFrequency = (event) => {    
    setDateFrequency(event.target.value);
    dispatch(setDashboardDateFrequency({ dateFrequency: event.target.value }));
  };

  const handleStartDateChange = (e) => {
    setselectedWeekButtonFlag(false);
    setselectedYesterdayButtonFlag(false);
    setselectedTodayButtonFlag(false);
    setFromDate(e.toISOString().substring(0, 10));
    setSelectedStartDate(e);
    if (title === "Footprint Map") {
      dispatch(fetchDateValue({ fromDate: e.toISOString().substring(0, 10), toDate }));
    }
    else {
      dispatch(
        fetchKpis({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate: e.toISOString().substring(0, 10),
          toDate,
        })
      );
      dispatch(
        fetchCharts({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate: e.toISOString().substring(0, 10),
          toDate,
        })
      );
    }
  };

  const handleEndDateChange = (e) => {
    setToDate(e.toISOString().substring(0, 10));
    setSelectedEndDate(e);
    console.log(toDate);
    if (title === "Footprint Map") {
      dispatch(fetchDateValue({ fromDate, toDate: e.toISOString().substring(0, 10) }));
    }
    else {
      dispatch(
        fetchKpis({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate,
          toDate: e.toISOString().substring(0, 10),
        })
      );
      dispatch(
        fetchCharts({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate,
          toDate: e.toISOString().substring(0, 10),
        })
      );
    }
  };

  const changeToday = () => {
    setselectedTodayButtonFlag(true);
    setselectedYesterdayButtonFlag(false);
    setselectedWeekButtonFlag(false);
    if (title === "Footprint Map") {
      dispatch(fetchDateValue({ fromDate: yesterdayForm, toDate: todayForm }));
    }
    else {
      dispatch(
        fetchKpis({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate: todayForm,
          toDate: todayForm,
        })
      );
      dispatch(
        fetchCharts({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate: todayForm,
          toDate: todayForm,
        })
      );
    }
  };
  const changeYesterday = () => {
    setselectedYesterdayButtonFlag(true);
    setselectedTodayButtonFlag(false);
    setselectedWeekButtonFlag(false);
    if (title === "Footprint Map") {
      dispatch(
        fetchDateValue({ fromDate: dayBeforeForm, toDate: yesterdayForm })
      );
    }
    else {
      dispatch(
        fetchKpis({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate: yesterdayForm,
          toDate: yesterdayForm,
        })
      );
      dispatch(
        fetchCharts({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate: yesterdayForm,
          toDate: yesterdayForm,
        })
      );
    }
  };
  const changeWeek = () => {
    setselectedWeekButtonFlag(true);
    setselectedYesterdayButtonFlag(false);
    setselectedTodayButtonFlag(false);
    if (title === "Footprint Map") {
      dispatch(fetchDateValue({ fromDate: weekForm, toDate: todayForm }));
    }
    else {
      dispatch(
        fetchKpis({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate: weekForm,
          toDate: todayForm,
        })
      );
      dispatch(
        fetchCharts({
          userId: user.data.email || user.data.email_address,
          clientId: user.data.client_id,
          fromDate: weekForm,
          toDate: todayForm,
        })
      );
    }
  };

  const handleChangeRadio = (event) => {
    console.log("Date Value", event.target.value);
    if (event.target.value === "Select Dates") {
      setenableDate(true);
    } else {
      setenableDate(false);
    }
    setdateValue(event.target.value);
  };
  const handleChangeSearch = (value) => {

    console.log("value", value);
    setUserInput(value);
  };

  const handleSearchClick = (event) => {
    console.log("djsfhks");
    // let dateRange ={fromDate: "2019-03-03",
    // toDate: new Date().toISOString().substring(0, 10)};
    // let device="Mobile";
    // let url="https://www.healthurwealth.com/home/cart";
    // const data = {dateRange, device };
    console.log("keyEvent..", event.which);
    if (event.which == 13) {
      // console.log("value inside click",userInput);
      // ls.set("searchInfo",userInput);
      // dispatch(fetchDomData(data));
      //  dispatch(setUrl({urlValue:userInput}));
      let searchvalue = userInput.toLowerCase();
      console.log("searchvalue", searchvalue);
      dispatch(fetchUrlValue(searchvalue));
    }
  };
  const handleReset = () => {
    setOpen(false);
    let fromDate = "2019-03-03";
    let toDate = new Date().toISOString().substring(0, 10);
    dispatch(fetchDateValue({ fromDate: fromDate, toDate: toDate }));
    setdateValue("");
    setSelectedStartDate('Thu Apr 22 2021 19:39:00 GMT+0200 (Central European Summer Time)');
    setSelectedEndDate(today);
  };
  const handleSave = (value) => {
    console.log("Save function");
    setOpen(false);

    if (datevalue === "Today") {
      console.log("Save Today");
      dispatch(fetchDateValue({ fromDate: yesterdayForm, toDate: todayForm }));
    } else if (datevalue === "Yesterday") {
      console.log("Save Yesterday");
      dispatch(
        fetchDateValue({ fromDate: dayBeforeForm, toDate: yesterdayForm })
      );
    } else if (datevalue === "ChangeWeek") {
      console.log("Save ChangeWeek");
      dispatch(fetchDateValue({ fromDate: weekForm, toDate: todayForm }));
    } else if(datevalue === "ChangeMonth") {
      const oneMonthAgo = moment().subtract(1, 'months').format('YYYY-MM-DD');
      dispatch(fetchDateValue({
        fromDate: oneMonthAgo,
        toDate: new Date().toISOString().substring(0, 10)
      }))
    } else if(datevalue === "Change45Days") {
      const FortyFiveDaysAgo = moment().subtract(45, 'days').format('YYYY-MM-DD');
      // toDate: "2022-08-27"
      dispatch(fetchDateValue({
        fromDate: FortyFiveDaysAgo,
        toDate: new Date().toISOString().substring(0, 10)
      }))
    } else if(datevalue === "Change2Months") {
      const twoMonthsAgo = moment().subtract(2, 'months').format('YYYY-MM-DD');
      // toDate: "2022-08-27"
      dispatch(fetchDateValue({
        fromDate: twoMonthsAgo,
        toDate: new Date().toISOString().substring(0, 10)
      }))
    } else if(datevalue === "Change3Months") {
      const threeMonthsAgo = moment().subtract(3, 'months').format('YYYY-MM-DD');
      // toDate: "2022-08-27"
      dispatch(fetchDateValue({
        fromDate: threeMonthsAgo,
        toDate: new Date().toISOString().substring(0, 10)
      }))
    }
    else {
      console.log("Selected dates");
      dispatch(fetchDateValue({ fromDate: fromDate, toDate: toDate }));
    }

    //  ls.set("searchInfo",userInput);
  };
  const handleSavePreferences = () => {
    if (dashboardPageState.hasKPIOrderChanged) {
      dispatch(updateUserKpis(dashboardPageState.updatedKPIList))
        .then((unwrapResult) => {
          dispatch(setKPIOrderChanged({ flag: false, list: [] }));
          setSnakBarOpen(true);
        })
        .catch(() => { });
    }
    if (dashboardPageState.hasChartsOrderChanged) {
      // TODO: uncomment this code after implement the sevice api
      /*
      dispatch(updateUserCharts(dashboardPageState.updatedChartList))
        .then((unwrapResult) => {
          dispatch(setChartsOrderChanged({ flag: false, list: [] }));
          setSnakBarOpen(true);
        })
        .catch(() => { });
        */

      // comment below lines after service api implementation
      dispatch(setChartsOrderChanged({ flag: false, list: [] }));
      setSnakBarOpen(true);
    }
  };

  const handleChartDataCreation = {
    series: [
      {
        name: 'Desktops',
        data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: chartType,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      title: {
        text: charttitle,
        align: 'left',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: [
          'Jan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Jun',
          'Jul',
          'Aug',
          'Sep',
        ],
      },
    },
  };

  const handleSnakBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnakBarOpen(false);
  };

  return (
    <ThemeProvider theme={toolbarTheme}>
      <AppBar
        id="fuse-toolbar"
        className={clsx(
          classes.root,
          'flex relative z-20 shadow-md',
          props.className
        )}
        color="default"
        style={{ backgroundColor: toolbarTheme.palette.background.paper }}
        position="static"
      >
        <Toolbar
          className={clsx(classes.headHeader, 'p-0 min-h-48 md:min-h-64')}
        >
          <div className="flex flex-1 px-16">
            {config.navbar.display && config.navbar.position === 'left' && (
              <>
                <Hidden mdDown>
                  {(config.navbar.style === 'style-3' ||
                    config.navbar.style === 'style-3-dense') && (
                      <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                    )}

                  {config.navbar.style === 'style-1' && !navbar.open && (
                    <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                  )}
                </Hidden>

                <Hidden lgUp>
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
                </Hidden>
              </>
            )}

          </div>

          <div className="flex items-center px-8 h-full overflow-x-auto">

            <UserMenu />
          </div>

          {config.navbar.display && config.navbar.position === 'right' && (
            <>
              <Hidden mdDown>
                {!navbar.open && (
                  <NavbarToggleButton className="w-40 h-40 p-0 mx-0" />
                )}
              </Hidden>

              <Hidden lgUp>
                <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8" />
              </Hidden>
            </>
          )}
        </Toolbar>

        {title === 'Footprint Map' ? (
          <Toolbar className="p-0 min-h-48 md:min-h-48">
            <Grid container>
              <Grid item className=" flex w-full sm:w-1/2 p-20">
                <h3>{title}</h3>
              </Grid>
              <Grid className="flex p-10 sm:w-1/2 "  item  style={{justifyContent: "flex-end"}}>
                <Grid    direction="column" >
                <SearchBar disabled={displayFlag || isHeatmapLoading}
                  placeholder="Search Url"
                  value={userInput}
                  onChange={(query) => {
                    handleChangeSearch(query);
                  }}
                  onKeyPress={(event) => {
                    handleSearchClick(event);
                  }}
                />
                 <FormHelperText style={{fontSize:"12px",paddingLeft:"1rem"}}> Please enter the correct URL starting with https://</FormHelperText>
                 <FormHelperText style={{fontSize:"12px",paddingLeft:"1rem"}}> ex:https://example.com/</FormHelperText>
                 </Grid>
                <div class="justify-content-end">
                  <Button disabled={displayFlag || isHeatmapLoading}
                    className={classes.largeBtn}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<FontAwesomeIcon icon={faFilter} />}
                    onClick={handleClickOpen}
                  >
                    <h4>Filter</h4>
                  </Button>
                </div>
               
              </Grid>
              <Dialog
                fullWidth={true}
                className="w-full"
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Add Filters</DialogTitle>
                <DialogContent>
                  <div className="w-2/5 flex flex flex-col">
                    <div dialogwidth>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="gender"
                          name="gender1"
                          value={datevalue}
                          onChange={handleChangeRadio}
                        >
                          <FormControlLabel
                            value="Today"
                            control={<Radio />}
                            label="Today"
                          />
                          <FormControlLabel
                            value="ChangeWeek"
                            control={<Radio />}
                            label="Last 7 days"
                          />
                          <FormControlLabel
                            value="ChangeMonth"
                            control={<Radio />}
                            label="Last 30 days"
                          />
                          <FormControlLabel
                            value="Change45Days"
                            control={<Radio />}
                            label="Last 45 days"
                          />
                          <FormControlLabel
                            value="Change2Months"
                            control={<Radio />}
                            label="Last 2 Months"
                          />
                          <FormControlLabel
                            value="Change3Months"
                            control={<Radio />}
                            label="Last 3 Months"
                          />
                        </RadioGroup>
                          <br></br>
                      </FormControl>
                    </div>

                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} variant="contained">
                    Cancel
                  </Button>
                  <Button onClick={handleSave} variant="contained" >
                    Save
                  </Button>
                  {SHOW_RESET_BUTTON && <Button onClick={handleReset} variant="contained" >
                    Reset
                  </Button>}
                </DialogActions>
              </Dialog>
            </Grid>
          </Toolbar>
        ) : (
          title && (
            <Toolbar className="p-0 min-h-48 md:min-h-48">
              <Grid container   style={{float:'left',display:'flex'}}>
                <Grid item className="p-20 flex w-full sm:w-1/4 p-19 font-700">
                  <h3>{title}</h3>
                </Grid>

                <Grid item className="p-10 flex  " style={{float: 'right',width: '74%',display: 'flex',justifyContent:'end' }}>
                  {/* <NativeSelect
                    defaultValue="daily"
                    className="mr-10"
                    value={dateFrequency}
                    onChange={handleDateFrequency}
                  >
                    <option value="hourly">Hourly</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </NativeSelect> */}
                  <ButtonGroup id="dateRange"
                    size="small"
                    variant="outlined"
                    aria-label="contained button group"
                    style={{boxShadow:"none",height:"3.5rem",paddingTop:"0.5%"}}
                  >
                    <Button
                      value="today"
                      className={`text-11 ${dateRange === 'today' ? 'btn-primary' : ''
                        }`}
                      onClick={() => handleDateRange('today')}
                    >
                      Today
                    </Button>
                    <Button
                      value="7D"
                      className={`text-11 ${dateRange === '7D' ? 'btn-primary' : ''
                        }`}
                      onClick={() => handleDateRange('7D')}
                    >
                      7D
                    </Button>
                    <Button
                      value="30D"
                      className={`text-11 ${dateRange === '30D' ? 'btn-primary' : ''
                        }`}
                      onClick={() => handleDateRange('30D')}
                    >
                      30D
                    </Button>
                    <Button
                      value="45D"
                      className={`text-11 ${dateRange === '45D' ? 'btn-primary' : ''
                        }`}
                      onClick={() => handleDateRange('45D')}
                    >
                      45D
                    </Button>
                    <Button
                      disabled={true}
                      value="2M"
                      className={`text-11 ${dateRange === '2M' ? 'btn-primary' : ''
                        }`}
                      onClick={() => handleDateRange('2M')}
                    >
                      2M
                    </Button>
                    <Button
                      disabled={true}
                      value="3M"
                      className={`text-11 ${dateRange === '3M' ? 'btn-primary' : ''
                        }`}
                      onClick={() => handleDateRange('3M')}
                    >
                      3M
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>

              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snakBaropen}
                autoHideDuration={1500}
                onClose={handleSnakBarClose}
              >
                <Alert onClose={handleSnakBarClose} severity="success">
                  Preferences Save Successfully.
                </Alert>
              </Snackbar>
            </Toolbar>
          )
        )}
      </AppBar>
    </ThemeProvider>
  );
}

export default withReducer('toolbarlayout1', reducer)(ToolbarLayout1);
