import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { getKpisList, updateUserKpi } from '../services/dashboard.service';
import { DashboardConstants } from '../../dashboard/constants/dashboard.constants';

const namespace = 'dashboard/kpis';
const kpisAdapter = createEntityAdapter({});

const initialState = kpisAdapter.getInitialState({
  loading: DashboardConstants.HTTPSTATUS.FINISHED,
  entities: {},
  currentRequestId: "",
  error: ''
});

export const fetchKpis = createAsyncThunk(
  `${namespace}/fetchKpis`,
  async (dateRange, { rejectWithValue }) => {
    try {
      const list = await getKpisList(dateRange);
      return list;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const updateUserKpis = createAsyncThunk(
  `${namespace}/updateUserKpisList`,
  async (kpiSortedArray, { rejectWithValue }) => {

    try {
      const list = await updateUserKpi(kpiSortedArray);
      return list;
    } catch (err) {
      return rejectWithValue([], err);
    }

  });

const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {
    clear_state: (state, action) => {
      console.log('hi');
      state = initialState;
      return state;
    },
    update_kpidata: (state, action) => {
      const objIndex = state.entities.data.dashboard_data.findIndex(
        (obj) => obj.chartId == (action.payload.chartId)
      );
      state.entities.data.dashboard_data.splice(objIndex, 1);
    }
  },
  extraReducers: {
    [fetchKpis.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = '';
        state.entities = payload;
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
      }
    },
    [fetchKpis.pending]: (state, { meta }) => {
      state.loading = DashboardConstants.HTTPSTATUS.PENDING;
      state.currentRequestId = meta;
    },
    [fetchKpis.rejected]: (state, { meta, payload, error }) => {
      if (state.currentRequestId === meta) {
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
        state.entities = payload;
        state.error = error;
        state.currentRequestId = meta;
      }
    },
    [updateUserKpis.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = '';
        state.entities = payload;
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
      }
    },
  }
});

export const { clear_state, update_kpidata } = actions;

export const { selectEntities: selectKpisEntities } = kpisAdapter.getSelectors(
  state => {
    // console.log(state.dashboard.kpis);
    return state.dashboard.kpis;
  }
);

export default reducer;
