import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { DashboardConstants } from '../../../dashboard/constants/dashboard.constants';

const dateAdapter = createEntityAdapter({});
const initialState = dateAdapter.getInitialState({
	loading: DashboardConstants.HTTPSTATUS.FINISHED,
	entities: {},
	currentRequestId: "",
	error: ''
});
export const fetchDateValue = createAsyncThunk(
  'todoApp/dateValue/fetchDateValue',
  async (date, { rejectWithValue }) => {
    try {
      console.log("inside date slice",date)
    const list = date//await getDomData(dateRange,{url:baseUrl}, null);
    return list;
    } catch (err) {
    return rejectWithValue([], err);
    }
  }
  );
  const { actions, reducer } = createSlice({
    name: 'todoApp/dateValue',
    initialState,
    reducers: {
      
    },
    extraReducers: {
      [fetchDateValue.fulfilled]: (state, { meta, payload }) => {
        if (meta.requestId === state.currentRequestId.requestId) {
          state.currentRequestId = '';
          state.entities = payload;
          state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
        }
      },
      [fetchDateValue.pending]: (state, { meta }) => {
        state.loading = DashboardConstants.HTTPSTATUS.PENDING;
        
  state.currentRequestId = meta;
      },
      [fetchDateValue.rejected]: (state, { meta, payload, error }) => {
        if (state.currentRequestId === meta) {
          state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
          state.entities = payload;
          state.error = error;
          state.currentRequestId = meta;
        }
      }
    }
  });

  export const { selectEntities: selectDateEntities } = dateAdapter.getSelectors(
    state => {
      return state.todoApp.dateValue;
      }
  );

  export default reducer;