import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import withReducer from 'app/store/withReducer';
import { showMessage } from 'app/store/fuse/messageSlice';
import reducer from './store';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { selectMetaEntities, fetchMeta } from './store/metaSlice';
import { fetchDetailsById } from './store/detailsSlice';
import { v1 as uuid } from "uuid";
import {
  fetchAllDashboardsForUser,
  fetchChartDataBySelection,
  fetchDashboardMetaByID,
  saveChartBySelection,
  fetchAllUsers,
} from './store/dataSlice';

import FilterSet from './components/FilterSet';
import Chart from '../Charts/Chart';
import AddToPhotosOutlinedIcon from '@material-ui/icons/AddToPhotosOutlined';
import ShareIcon from '@material-ui/icons/Share';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import SaveChartForm from './components/SaveChartForm';
import ShareChartForm from './components/ShareChartForm';
import ChartLegendTable from './components/ChartLegendTable';
import {
  FormControl,
  IconButton,
  Icon,
  Divider,
  Paper,
  Select as UISelect,
  NativeSelect,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  ButtonGroup,
  Typography,
  TextField,
  InputLabel,
  ListSubheader,
  Grid
} from '@material-ui/core';
import './chart-builder.css';
import 'intro.js/introjs.css';
import introJs from 'intro.js';
import { disableCBTour } from "app/fuse-layouts/layout1/components/store/productTourSlice";

const useStyles = makeStyles({
  layoutRoot: {},
});

const selectStyles = {
  control: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
  }),

  valueContainer: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
    position: 'initial',
    'padding-left': '4px',
  }),
  indicatorsContainer: (base, state) => ({
    ...base,
    '& div': {
      padding: '0',
    },
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    height: '14px',
    'min-height': '14px',
    'margin-top': '2px',
    'margin-bottom': '2px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '0 2px',
  }),
  input: (base, state) => ({
    ...base,
    margin: '0',
    'padding-top': '0',
  }),
};

function ChartBuilder() {
  const classes = useStyles();
  const params = useParams();
  const dispatch = useDispatch();
  const [maximizeClass, setMaximizeClass] = useState('');
  const [exploreType, setExploreType] = useState(1);
  const [chartId, setChartId] = useState(-1);
  const meta = useSelector(selectMetaEntities);
  const [saveOpen, setSaveOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);

  const [dateRange, setDateRange] = useState('30D');
  const [dateFrequency, setDateFrequency] = useState('daily');
  const [groupBy, setGroupBy] = useState(null);
  const [thenBy, setThenBy] = useState(null);
  const [compareBy, setCompareBy] = useState(null);
  const [chartType, setChartType] = useState('line');
  const [dataFilterType, setDataFilterType] = useState('top10');
  const [dataSet, setDataSet] = useState(null);
  const [makeChartCall, setMakeChartCall] = useState(params?.chartId ? false : true);

  const [dashboardFullList, setdashboardFullList] = useState([]);
  const [dashboardList, setdashboardList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [chartInfo, setChartInfo] = useState({
    name: 'Untitled',
    description: 'No Description',
    dashboard_id: null,
  });
  const [showDataFilterType, setShowDataFilterType] = useState(false);

  const [chartData, setChartData] = useState({});
  const history = useHistory();
  const [toolTipInfo, setToolTipInfo] = useState([]);
  const user = useSelector((state) => state.auth.user);
  const startTour = useSelector((state) => state.dashboard.tourFlag.chartBuilderTourFlag);
  const intro = introJs();
  const steps = [
    {
      element: '#chart-builder-explore-type',
      intro: 'Select the explore type here—it\'s all about the number of users.',
      position: 'top',
    },
    {
      element: '#filters',
      intro: 'You can add your filters here.Based on the filters chosen,the chart will be generated',
      position: 'top',
    },
    {
      element: '#additionalFilters',
      intro: 'For grouped data and additional chart customization, simply click on the grouped icon and choose the desired parameter from the drop-down menu.',
      position: 'top',
    },
  
    {
      element: '#dateSelection',
      intro: 'you can zoom in on hours, days, weeks, or months, the chart will adapt accordingly to provide you with the perfect view of your data. To do so, Click on the icon highlighted in the image below!',
      position: 'top',
    },
    {
      element: '#chartTypeSelection',
      intro: 'Click here to effortlessly switch between three popular chart options: bar chart, pie chart, and line chart.',
      position: 'top',
    },
    {
      element: '#generatedChart',
      intro: 'This section holds the generated chart based on your inputs',
      position: 'top',
    },
    {
      element: '#maximizeBtn',
      intro: 'Click here to maximize the generated chart',
      position: 'top',
    },
    // {
    //   element: '#downloadBtn',
    //   intro: 'Click here to download the generated chart',
    //   position: 'top',
    // },
    {
      element: '#saveBtn',
      intro: 'Save your Chart for future reference and share it with other stakeholders!.The saved charts can be accessed from the pinned dashboard in the navigation menu',
      position: 'top',
    }
  ];
  const productTour = (e) => {
    intro.setOptions({ steps });
    intro.start();
    dispatch(disableCBTour());
  };
  useEffect(() => {
    if (startTour) {
      productTour();
    }
  }, [startTour])

  useEffect(() => {
    // TODO check if the token is expired, if yes then push to login
    if (!localStorage.getItem('lac-user')) {
      history.push('/login');
    }
  });
  useEffect(() => {
    dispatch(fetchMeta(user.data.client_id));
  }, [dispatch]);

  const getDataSet = (details) => {
    // check if the filters are passed
    if (!details.filters) {
      const filter = {
        filterIndex: 1,
        isValid: false,
        rowJoinOperator: "and",
        setJoinOperator: "and",
        validationMessage: "",
        filterRows: []
      };
      details.filters = { data: [filter] }
    }

    // check if the filters data has value or not
    if (!details.filters.data) {
      details.filters.data = [
        {
          filterRows: [],
          rowJoinOperator: 'and',
          setJoinOperator: 'and',
          isValid: false,
          validationMessage: '',
          filterIndex: 1,
        },
      ];
    }

    return details.filters;
  };

  useEffect(async () => {
    console.log('params', params);
    const dashboardListdetails = await fetchAllDashboardsForUser({
      client_id: user.data.client_id,
      email_address: user.data.email || user.data.email_address,
    });

    if (dashboardListdetails) {
      setdashboardFullList(dashboardListdetails);
      setdashboardList(dashboardListdetails?.filter(dashboard => dashboard.is_default === 'N'));
    }

    const usersListDetails = await fetchAllUsers({
      client_id: user.data.client_id,
      email_address: user.data.email || user.data.email_address,
    });
    console.log('usersListDetails');
    console.log(usersListDetails);
    if (usersListDetails) setUsersList(usersListDetails);

    if (params.dashboardId) {
      const details = await fetchDetailsById(
        params.dashboardId,
        parseInt(params?.chartId)
      ); // TODO get the chart data by chart id from dashboard or take from cache

      if (details) {
        setChartId(parseInt(params.chartId));
        setExploreType(details.exploreType);
        setDataSet(getDataSet(details));
        setGroupBy(details.groupBy);
        setThenBy(details.thenBy);
        setCompareBy(details.compareBy);
        if (details.groupBy || details.thenBy || details.compareBy) {
          setShowDataFilterType(true)
        }
        else {
          setShowDataFilterType(false);
        }
        setChartInfo({
          name: details.chartTitle,
          description: details.chartDescription,
          dashboard_id: details.dashboard_id,
        });
        setDateFrequency(details.dateFrequency);
        setDateRange(details.dateRange);
        setChartType(details.chartType || details.type);
        setDataFilterType(details.dataFilterType || 'top10');
        setChartData(
          details.chartData || {
            series: details.series,
          }
        );
        setShowChart(true);
      }
    }

    setMakeChartCall(true);
  }, []);

  useEffect(async () => {
    // console.log('rendering chart', makeChartCall);
    if (makeChartCall) {
      // console.log('rendering chart');
      renderChart();
    }
  }, [
    dateRange,
    dateFrequency,
    groupBy,
    thenBy,
    compareBy,
    chartType,
    dataFilterType,
    dataSet,
  ]);
  const data = {
    series: chartData.series,
    // name: chartInfo.name,
    description: chartInfo.description,
    type: chartType,
  };
  const renderChart = async () => {

    if (chartType != '' && chartType != '10') {
      const chartSelection = {
        exploreType: exploreType,
        filters: dataSet,
        groupBy: groupBy,
        thenBy: thenBy,
        compareBy: compareBy,
        dateFrequency: dateFrequency,
        dateRange: dateRange,
        type: chartType,
        dataFilterType: dataFilterType,
        client_id: user.data.client_id,
        email_address: user.data.email || user.data.email_address,
      };

      const details = await fetchChartDataBySelection(chartSelection);

      if (details) {
        //setChartType(chartType);
        setChartData(details.chartData);
      }
      //setting tooltip info
      if (!dataSet?.data) {
        let info=[];
        let defaultCondition="All -Users";
        if(chartSelection.groupBy||chartSelection.compareBy||chartSelection.thenBy)
        {
          if(chartSelection.groupBy)
          {
            defaultCondition+=" - "+" Group by : "+chartSelection.groupBy;
          }
          if(chartSelection.thenBy)
          { 
            defaultCondition+=" - "+" Then by : "+chartSelection. thenBy;
          }
          if(chartSelection.compareBy)
          {
            defaultCondition+=" - "+" Compare by : "+ chartSelection.compareBy;
          }
        }
        info.push(defaultCondition);
        info.push(defaultCondition);
        setToolTipInfo(info);
      }
      else {
        setToolTipInfo(null);
        let info = [];
        dataSet?.data?.forEach(element => {
          let filterInfo = "All -users - ";
          element.filterRows?.forEach(filter => {
            let fieldName = "";
            let fieldValue = "";
            fieldName = filter.field;
            filter.values?.forEach(fVal => {
              fieldValue += ":" + fVal.value;
            })
            filterInfo += fieldName + "  : " + filter.operator + " " + fieldValue;
          })
          //Checking for groupby ,compare by and then by values,adding if any
          if(chartSelection.groupBy||chartSelection.compareBy||chartSelection.thenBy)
          {
            if(chartSelection.groupBy)
            {
              filterInfo+=" - "+" Group by : "+chartSelection.groupBy;
            }
            if(chartSelection.thenBy)
            {
              filterInfo+=" - "+" Then by : "+chartSelection. thenBy;
            }
            if(chartSelection.compareBy)
            {
              filterInfo+=" - "+" Compare by : "+ chartSelection.compareBy;
            }
          }
                    info.push(filterInfo);
        });
        //Pushing filters info for current and previous records
        if (dataSet?.data?.length === 1) {
          let filters = info[0];
          info.push(filters);
        }
        setToolTipInfo(info);
      }
      console.log('chartType')
      console.log(chartType)
      setShowChart(true);
    }
  };

  const handleClose = () => {
    setSaveOpen(false);
    setShareOpen(false);
  };

  const handleFilterSetChange = async (dataset, changeType) => {
    setShowChart(false);
    setDataSet(dataset);
  };

  const handleExploreTypeChange = (selectedOption) => {
    const value = selectedOption?.value;
    setShowChart(false);
    setExploreType(value);
  };

  const handleGroupByChange = (selectedOption) => {
    const value = selectedOption?.value;
    setShowChart(false);
    setChartType('bar');
    setGroupBy(value);
    if (value || thenBy || compareBy) {
      setShowDataFilterType(true)
    }
    else {
      setShowDataFilterType(false);
    }
  };

  const handleThenByChange = (selectedOption) => {
    const value = selectedOption?.value;
    setShowChart(false);
    setChartType('bar');
    setThenBy(value);
    if (groupBy || value || compareBy) {
      setShowDataFilterType(true)
    }
    else {
      setShowDataFilterType(false);
    }
  };

  const handleCompareByChange = (selectedOption) => {
    const value = selectedOption?.value;
    setShowChart(false);
    setChartType('bar');
    setCompareBy(value);
    if (groupBy || thenBy || value) {
      setShowDataFilterType(true)
    }
    else {
      setShowDataFilterType(false);
    }
  };

  const handleDateFrequency = (event) => {
    setShowChart(false);
    setDateFrequency(event.target.value);
  };

  const handleDateRange = (newDateRange) => {
    setShowChart(false);
    setDateRange(newDateRange);
  };
  const handleShare = async (email_address) => {
    // TODO make api call to save the share dashboard user
    console.log(email_address);
  };
  const handleSave = async (pchartinfo) => {
    let chart_id = 1;
    const saveDetails = {
      dashboard_id: pchartinfo.dashboard_id,
      dashboard_name: pchartinfo.dashboard_name,
      email_address: user.data.email || user.data.email_address,
      client_id: user.data.client_id,
    };
    if (saveDetails.dashboard_id == -1) {
      //New chart case
      saveDetails.dashboard_id =
        dashboardFullList && dashboardFullList.length > 0
          ? Math.max.apply(
            Math,
            dashboardFullList.map(function (o) {
              return o.id;
            })
          ) + 1
          : 1;

      saveDetails.dashboard_data = [
        {
          chartId: chart_id,
          chartTitle: pchartinfo.name,
          chartDescription: pchartinfo.description,
          exploreType: exploreType,
          filters: Object.assign({}, dataSet, { exploreType: exploreType }),
          type: chartType,
          dataFilterType: dataFilterType,
          groupBy: groupBy,
          thenBy: thenBy,
          compareBy: compareBy,
          dateFrequency: dateFrequency,
          dateRange: dateRange,
          is_default: 'N'
        },
      ];
    } else {
      //Get the existing chart details and add thos t the end
      const chartSelection = {
        userId: user.data.email || user.data.email_address,
        dashboard_id: saveDetails.dashboard_id,
        dateRange: dateRange,
        client_id: user.data.client_id,
        email_address: user.data.email || user.data.email_address,
      };

      const details = await fetchDashboardMetaByID(chartSelection);

      console.log('fetchDashboardMetaByID');
      console.log(details, params);
      saveDetails.dashboard_data = JSON.parse(details.dashboard_data);
      saveDetails.dashboard_name = details.dashboard_name;
      if (params.chartId) {
        const objIndex = saveDetails.dashboard_data.findIndex(
          (obj) => obj.chartId == params?.chartId
        );
        console.log('Index matched');
        chart_id = params.chartId ? parseInt(params?.chartId) : saveDetails.dashboard_data.length + 1; //Should be max + 1 chart number of all existing charts in the same dashboardd
        saveDetails.dashboard_data[objIndex] = {
          chartId: chart_id,
          chartTitle: pchartinfo.name,
          chartDescription: pchartinfo.description,
          exploreType: exploreType,
          filters: Object.assign({}, dataSet, { exploreType: exploreType }),
          type: chartType,
          dataFilterType: dataFilterType,
          groupBy: groupBy,
          thenBy: thenBy,
          compareBy: compareBy,
          dateFrequency: dateFrequency,
          dateRange: dateRange,
          is_default: 'N'
        };
      } else {
        if (saveDetails.dashboard_data.length == 4) {
          return dispatch(
              showMessage({
                  message: `Maximum charts reached in the dashboard ${saveDetails.dashboard_name}, delete a chart or add in a different dashboard.`,
                  autoHideDuration: 6000,
                  anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center'
                  },
                  variant: 'error'
              }));
        }
        chart_id = params.chartId ? parseInt(params.chartId) : saveDetails.dashboard_data.length + 1; //Should be max + 1 chart number of all existing charts in the same dashboardd
        saveDetails.dashboard_data.push({
          chartId: chart_id,
          chartTitle: pchartinfo.name,
          chartDescription: pchartinfo.description,
          exploreType: exploreType,
          filters: Object.assign({}, dataSet, { exploreType: exploreType }),
          type: chartType,
          dataFilterType: dataFilterType,
          groupBy: groupBy,
          thenBy: thenBy,
          compareBy: compareBy,
          dateFrequency: dateFrequency,
          dateRange: dateRange,
          is_default: 'N'
        });
      }
    }

    const response = await saveChartBySelection(saveDetails);

    setSaveOpen(false);
    dispatch(
      showMessage({
        message: 'Chart is saved successfully.',
        autoHideDuration: 6000,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        variant: 'success'
      }));
    localStorage.setItem("reload-menu-items", "true");
    // history.push("/dashboard/" + uuid());
    history.push("/chartbuilder/" + saveDetails.dashboard_id + "/" + chart_id);
  };

  const openSaveForm = (e) => {
    setSaveOpen(true);
  };
  const openShareForm = (e) => {
    setShareOpen(true);
  };
  const onTypeOfChartChange = (event) => {
    const type = event.target.value;
    if (type != 10) {
      setChartType(type);
      setShowChart(true);
    } else {
      setChartType(type);
      setShowChart(false);
    }
    setShowChart(false);
  };

  const onDataFilterChange = (event) => {
    const value = event.target.value;
    setDataFilterType(value);
  };


  const onMaximize = (event) => {
    setMaximizeClass(maximizeClass === '' ? 'maximize' : '');
  };

  const options = [
    { value: 1, label: 'Explore Users' },
    // { value: 2, label: 'Explore Sessions', isDisabled: true },
    // { value: 3, label: 'Explore Events', isDisabled: true },
  ];

  return (
    <>
      <div className="chart-builder-main">
        <div className="chart-builder-left">
          <div>
            <FormControl>
              <Select
                id={`chart-builder-explore-type`}
                className="text-11 chart-builder-explore-type-select"
                label="Select Type"
                isSearchable={false}
                value={options?.find((o) => o.value == exploreType)}
                options={options}
                onChange={handleExploreTypeChange}
                styles={selectStyles}
              ></Select>
            </FormControl>
          </div>
          <div className="chart-builder-divider">
            <Divider />
          </div>
          <div className="chart-builder-users" id="filters">
            <FilterSet
              fieldNames={meta.fieldNames}
              fieldValues={meta.fieldValues}
              actionNames={meta.actionNames}
              dataset={dataSet}
              canAddSegment={groupBy || thenBy || compareBy || chartType == 'pie'}
              onChange={handleFilterSetChange}
              
            />
            <div id="additionalFilters">
            <div className="chart-builder-users-bottom" >
              <Typography variant="caption" className="mr-20 text-12 font-400">
                grouped by
              </Typography>
              <FormControl>
                <Select
                  id={`chart-builder-group-by`}
                  className="text-11 chart-builder-explore-type-select"
                  label="Select group by"
                  isSearchable={true}
                  isClearable={true}
                  value={meta.groupByNames?.find((o) => o.value == groupBy)}
                  options={meta.groupByNames}
                  onChange={handleGroupByChange}
                  styles={selectStyles}
                  isDisabled={dataSet?.data?.length > 1}
                ></Select>
              </FormControl>
            </div>
            <div className="chart-builder-users-bottom">
              <Typography variant="caption" className="mr-20 text-12 font-400">
                and then by
              </Typography>
              <FormControl>
                <Select
                  id={`chart-builder_then-group-by`}
                  className="text-11 chart-builder-explore-type-select"
                  label="Select then by"
                  isSearchable={true}
                  isClearable={true}
                  value={meta.groupByNames?.find((o) => o.value == thenBy)}
                  options={meta.groupByNames}
                  onChange={handleThenByChange}
                  styles={selectStyles}
                  isDisabled={dataSet?.data?.length > 1}
                ></Select>
              </FormControl>
            </div>
            <div className="chart-builder-users-footer">
              <Typography variant="caption" className="mr-20 text-12 font-400">
                compare by
              </Typography>
              <FormControl>
                <Select
                  id={`chart-builder-compare-by`}
                  className="text-11 chart-builder-explore-type-select"
                  label="Select compare by"
                  isSearchable={true}
                  isClearable={true}
                  value={meta.compareByNames?.find((o) => o.value == compareBy)}
                  options={meta.compareByNames}
                  onChange={handleCompareByChange}
                  styles={selectStyles}
                  isDisabled={dataSet?.data?.length > 1}
                ></Select>
              </FormControl>
            </div>
            </div>
          </div>
        </div>
        <div className="chart-builder-right">
          <div class="chart-builder-right-header">
            {chartId !== -1 && (
              <div class="chart-builder-right-header-title">
                <Typography component="span" className="font-semibold flex">
                  {chartInfo.name}
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  {chartInfo.description}
                </Typography>
              </div>
            )}
            {/* <div class="chart-builder-right-header-share mr-20">
              <Button
                size="small"
                variant="contained"
                color="primary"
                startIcon={<ShareIcon />}
                onClick={openShareForm}
              >
                Share
              </Button>
            </div> */}
            {/* <div class="chart-builder-right-header-add  mr-20">
              <Button
                size="small"
                disabled
                variant="text"
                color="secondary"
                startIcon={<AddToPhotosOutlinedIcon />}
              >
                Add to
              </Button>
            </div>
            <div class="chart-builder-right-header-export  mr-20">
              <Button
                size="small"
                disabled
                variant="text"
                color="secondary"
                startIcon={<ImportExportIcon />}
              >
                Export
              </Button>
            </div> */}
            <Grid container={true} justify="flex-end">
              <div class="chart-builder-right-header-save">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  startIcon={<SaveOutlinedIcon />}
                  onClick={openSaveForm}
                  className="pr-10 pl-12"
                  id='saveBtn'
                >
                  Save
                </Button>
              </div>
            </Grid>
          </div>
          <Paper elevation={3} className="chart-builder-card">
            <div class="chart-builder-right-top" id="dateSelection">
              <div className="mr-20">
                <NativeSelect 
                  defaultValue="daily"
                  value={dateFrequency}
                  onChange={handleDateFrequency}
                >
                  <option value="hourly">Hourly</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </NativeSelect>
              </div>
              <div className="mr-20">
                <ButtonGroup
                  size="small"
                  variant="outlined"

                  aria-label="outlined button group"
                >
                  {/* <Button
                    value="custom"
                    className={`text-11 ${
                      dateRange === 'custom' ? 'btn-primary' : ''
                    }`}
                    onClick={() => handleDateRange('custom')}
                  >
                    Custom
                  </Button> */}
                  <Button
                    value="today"
                    className={`text-11 ${dateRange === 'today' ? 'btn-primary' : ''
                      }`}
                    onClick={() => handleDateRange('today')}
                  >
                    Today
                  </Button>
                  <Button
                    value="7D"
                    className={`text-11 ${dateRange === '7D' ? 'btn-primary' : ''
                      }`}
                    onClick={() => handleDateRange('7D')}
                  >
                    7D
                  </Button>
                  <Button
                    value="30D"
                    className={`text-11 ${dateRange === '30D' ? 'btn-primary' : ''
                      }`}
                    onClick={() => handleDateRange('30D')}
                  >
                    30D
                  </Button>
                  <Button
                    value="45D"
                    className={`text-11 ${dateRange === '45D' ? 'btn-primary' : ''
                      }`}
                    onClick={() => handleDateRange('45D')}
                  >
                    45D
                  </Button>
                  <Button
                    value="2M"
                    className={`text-11 ${dateRange === '2M' ? 'btn-primary' : ''
                      }`}
                    onClick={() => handleDateRange('2M')}
                  >
                    2M
                  </Button>
                  <Button
                    value="3M"
                    className={`text-11 ${dateRange === '3M' ? 'btn-primary' : ''
                      }`}
                    onClick={() => handleDateRange('3M')}
                  >
                    3M
                  </Button>
                  {/* <Button
                    value="12M"
                    className={`text-11 ${
                      dateRange === '12M' ? 'btn-primary' : ''
                    }`}
                    onClick={() => handleDateRange('12M')}
                  >
                    12M
                  </Button> */}
                </ButtonGroup>
              </div>
              {/* <div className="mr-20 hide">
                <NativeSelect defaultValue={10}>
                  <option value={10}>Uniques</option>
                  <option value={20}>Monthly</option>
                  <option value={30}>Yearly</option>
                </NativeSelect>
              </div> */}
            </div>
          </Paper>
          <Paper  elevation={3} className={`chart-builder-card flex-grow-1 ${maximizeClass}`}>
            <div class="chart-builder-right-middle" >
              <div class="chart-builder-right-middle-header" >
                <div className="mr-auto">
                  <NativeSelect id="chartTypeSelection"
                    defaultValue={'line'}
                    onChange={onTypeOfChartChange}
                    value={chartType || 'line'}
                  >
                    <option value={10}>Type of chart</option>
                    <option value="line">Linear Chart</option>
                    {dataSet?.data?.length > 1 ? <></> : <option value="pie">Pie Chart</option>}
                    <option value="bar">Bar Chart</option>
                  </NativeSelect>
                  {showDataFilterType && <> &nbsp;&nbsp;&nbsp;
                    <NativeSelect id="dataSelection"
                      defaultValue={'top10'}
                      onChange={onDataFilterChange}
                      value={dataFilterType || 'top10'}
                    >
                      <option value="top10">Top 10</option>
                      <option value="top20">Top 20</option>
                      <option value="top30">Top 30</option>
                      <option value="all">All</option>
                    </NativeSelect>
                  </>
                  }
                </div>
                <div>
                  <IconButton id="maximizeBtn" size={'small'} title="Maximize window" onClick={onMaximize} className="chart-builder-right-middle-resize">
                    <Icon>{maximizeClass === '' ? 'call_made' : 'call_received'}</Icon>
                  </IconButton>
                </div>
              </div>
              {!showChart && (
                <div class="chart-builder-right-middle-content">
                  <div className="chart-builder-right-middle-content-icon">
                    <TimelineIcon />
                  </div>
                  <div>
                    <Typography variant="caption" display="block" gutterBottom>
                      {(chartType == '' || chartType == '10') ? 'Start by selecting an event and Chart Type' : 'Please wait while the chart data is being fetched.'}
                    </Typography>
                  </div>
                </div>
              )}
              {showChart && data && data.series && data.series.length > 0 && data.series.some((item) => {
                return item.data && item.data.length > 0;
              }) && chartType != '10' && (
                  <div class="chart-builder-right-middle-chart-content" id="generatedChart">
                    <Chart 
                      type={chartType}
                      data={data}
                      groupThenBy={groupBy || thenBy || compareBy}
                      multipleSegment={dataSet?.data?.length > 1}
                      dateRange={dateRange}
                      height={'500px'}
                      width={'100%'}
                    ></Chart>
                  </div>
                )}

              {showChart && (!data || !data.series || data.series.length == 0 || data.series.every((item) => {
                return item.data && item.data.length == 0;
              })
              ) && (
                  <Typography component="span" className="font-semibold flex">
                    No Data Available
                  </Typography>
                )}
              {showChart && data && data.series && data.series.length > 0 && data.series.some((item) => {
                return item.data && item.data.length > 0;
              }) && (
                  <div class="chart-builder-right-middle-chart-content">
                    <ChartLegendTable
                      type={chartType}
                      data={data}
                      toolTipInfo={toolTipInfo}
                    ></ChartLegendTable>
                  </div>
                )}
            </div>
          </Paper>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        className="w-full"
        open={saveOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create New</DialogTitle>
        <DialogContent>
          <SaveChartForm
            chartInfo={chartId !== -1 ? chartInfo : null}
            dashboardList={dashboardList}
            dashboardId={params.dashboardId}
            onCancel={handleClose}
            onSubmit={handleSave}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        className="w-full"
        open={shareOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create New</DialogTitle>
        <DialogContent>
          <ShareChartForm
            usersList={usersList}
            onCancel={handleClose}
            onSubmit={handleShare}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default withReducer('chartbuilder', reducer)(ChartBuilder);
