import { createSlice, createSelector } from '@reduxjs/toolkit';
import _ from '@lodash';


const initialState = {
  toDate: 'todfays date',
  fromDate: 'from Date',
};


const dateRangeSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setToday: (state, action) => {

      return {
        ...state,
        toDate: 'todaysasdasd',
      };
    },
    setYesterDay: (state, action) => {

      return {
        ...state,
        setYesterDay: 'the is the day',
      };
    },
    setLast7Days: (state, action) => {
      return {
        ...state,
        setYesterDay: 'the is thasdasdade day',
      };
    }
  }
});


export const { setToday, setYesterDay, setLast7Days } = dateRangeSlice.actions;

export default dateRangeSlice.reducer;
