import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  "MuiCollapse-entered-*": {
    borderTop: `1px solid #E0E0E0`,
  },
}));

const Aprops = {
  accordionItems: [
    {
      summary: "General settings",
      summaryRenderer: () => {
        return (
          <>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>
              General settings
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              I am an accordion
            </Typography>
          </>
        );
      },
      details: "",
      detailsRenderer: () => {
        return (
          <Typography>
            Nulla facilisi. Phasellus sollicitudin nulla et quam mattis feugiat.
            Aliquam eget maximus est, id dignissim quam.
          </Typography>
        );
      },
    },
    {
      summary: "General settings",
      summaryRenderer: () => {
        return (
          <>
            <Typography sx={{ width: "33%", flexShrink: 0 }}>Users</Typography>
            <Typography sx={{ color: "text.secondary" }}>
              You are currently not an owner
            </Typography>
          </>
        );
      },
      details: "",
      detailsRenderer: () => {
        return (
          <Typography>
            Donec placerat, lectus sed mattis semper, neque lectus feugiat
            lectus, varius pulvinar diam eros in elit. Pellentesque convallis
            laoreet laoreet.
          </Typography>
        );
      },
    },
  ],
  defaultExpanded: 1,
};

export const AppAccordion = (props) => {
  const classes = useStyles();
  const { accordionItems, defaultExpanded } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      {accordionItems.map((item, panelIndex) => {
        return (
          <Accordion
            expanded={expanded === panelIndex}
            onChange={handleChange(panelIndex)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {item.summaryRenderer()}
            </AccordionSummary>
            <AccordionDetails className={classes["MuiCollapse-entered-*"]}>
              {item.detailsRenderer()}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};
