import { Button } from "@material-ui/core";

export const UploadButton = (props) => {
  const { onImageSelected } = props;
  return (
    <Button type="upload" variant="outlined" size="medium" component="label">
      Choose Image
      <input
        type="file"
        accept="image/*"
        hidden
        id="contained-button-file"
        onChange={onImageSelected}
      />
    </Button>
  );
};
