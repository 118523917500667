import _ from '@lodash';

const validateFilter = (filter, setIndex) => {
    filter.isValid = true;
    filter.validationMessage = '';

    // validate for required values
    if (!filter.filterRows || filter.filterRows.some((row) => !row.field)) {
        filter.isValid = false;
        filter.validationMessage = 'Enter all mandatory fields for filter set ' + (setIndex.currentFilterIndex);
    }
};

export const arrayMoveMutate = (array, from, to) => {
    array = [...array];
    const startIndex = from < 0 ? array.length + from : from;
    if (startIndex >= 0 && startIndex < array.length) {

        const endIndex = to < 0 ? array.length + to : to;

        const [item] = array.splice(from, 1);
        array.splice(endIndex, 0, item);
    }
    return array;
};



export const arrangeDataSetWithGroupFilterIndex = (dataset, setIndex) => {
    dataset?.data?.forEach((filterOrGroup) => {
        if (filterOrGroup.hasOwnProperty('data')) {
            filterOrGroup.groupIndex = setIndex.currentGroupIndex;
            setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
            arrangeDataSetWithGroupFilterIndex(filterOrGroup, setIndex);
        }
        else {
            filterOrGroup.filterIndex = setIndex.currentFilterIndex;
            setIndex.currentFilterIndex = setIndex.currentFilterIndex + 1;
        }
    });

};

export const addNewGroupOrSet = (dataset, spliceIndex, filter, setIndex) => {
    return dataset?.data?.some((filterOrGroup, idx) => {
        if (filterOrGroup.hasOwnProperty('data')) {
            if (setIndex.hasOwnProperty('currentGroupIndex')) {
                if (setIndex.currentGroupIndex === spliceIndex) {
                    dataset.data.splice(idx + 1, 0, filter);
                    return true;
                }
                setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
            }
            return addNewGroupOrSet(filterOrGroup, spliceIndex, filter, setIndex);
        }
        else {
            if (setIndex.hasOwnProperty('currentFilterIndex')) {
                if (setIndex.currentFilterIndex === spliceIndex) {
                    dataset.data.splice(idx + 1, 0, filter);
                    return true;
                }
                setIndex.currentFilterIndex = setIndex.currentFilterIndex + 1;
            }
        }
        return false;
    });
};

export const deleteGroupOrSet = (dataset, spliceIndex, filter, setIndex) => {

    return dataset?.data?.some((filterOrGroup, idx) => {
        if (filterOrGroup.hasOwnProperty('data')) {
            if (setIndex.hasOwnProperty('currentGroupIndex')) {
                if (setIndex.currentGroupIndex === spliceIndex) {
                    if (filter) {
                        dataset.data.splice(idx, 1, filter);
                    }
                    else {
                        dataset.data.splice(idx, 1);
                    }
                    return true;
                }
                setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
            }
            return deleteGroupOrSet(filterOrGroup, spliceIndex, filter, setIndex);
        }
        else {
            if (setIndex.hasOwnProperty('currentFilterIndex')) {
                if (setIndex.currentFilterIndex === spliceIndex) {
                    if (filter) {
                        dataset.data.splice(idx, 1, filter);
                    }
                    else {
                        dataset.data.splice(idx, 1);
                    }
                    return true;
                }
                setIndex.currentFilterIndex = setIndex.currentFilterIndex + 1;
            }
        }
        return false;
    });
};



export const editGroupOrSet = (dataset, editIndex, filter, setIndex) => {

    return dataset?.data?.some((filterOrGroup) => {
        if (filterOrGroup.hasOwnProperty('data')) {
            if (setIndex.hasOwnProperty('currentGroupIndex')) {
                if (setIndex.currentGroupIndex === editIndex) {
                    filterOrGroup.setJoinOperator = filter.setJoinOperator;
                    return true;
                }
                setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
            }
            return editGroupOrSet(filterOrGroup, editIndex, filter, setIndex);
        }
        else {
            if (setIndex.hasOwnProperty('currentFilterIndex')) {
                if (setIndex.currentFilterIndex === editIndex) {
                    if (filter.hasOwnProperty('filterRows')) {
                        filterOrGroup.filterRows = filter.filterRows;
                    }
                    if (filter.hasOwnProperty('setJoinOperator')) {
                        filterOrGroup.setJoinOperator = filter.setJoinOperator;
                    }
                    if (filter.hasOwnProperty('rowJoinOperator')) {
                        filterOrGroup.rowJoinOperator = filter.rowJoinOperator;
                    }
                    return true;
                }
                setIndex.currentFilterIndex = setIndex.currentFilterIndex + 1;
            }
        }
        return false;
    });
};

export const moveGroupOrSet = (dataset, filterIndex, upOrDown, setIndex) => {

    return dataset?.data?.some((filterOrGroup, idx) => {
        if (filterOrGroup.hasOwnProperty('data')) {
            if (setIndex.hasOwnProperty('currentGroupIndex')) {
                if (setIndex.currentGroupIndex === filterIndex) {
                    dataset.data = arrayMoveMutate(dataset.data, idx, idx + upOrDown);
                    return true;
                }
                setIndex.currentGroupIndex = setIndex.currentGroupIndex + 1;
            }
            return moveGroupOrSet(filterOrGroup, filterIndex, upOrDown, setIndex);
        }
        else {
            if (setIndex.hasOwnProperty('currentFilterIndex')) {
                if (setIndex.currentFilterIndex === filterIndex) {
                    dataset.data = arrayMoveMutate(dataset.data, idx, idx + upOrDown);
                    return true;
                }
                setIndex.currentFilterIndex = setIndex.currentFilterIndex + 1;
            }
        }
        return false;
    });
};

export const validateFilters = (dataset, setIndex) => {
    dataset.data.forEach((filterOrGroup) => {
        if (filterOrGroup.hasOwnProperty('data')) {
            validateFilters(filterOrGroup, setIndex);
            filterOrGroup.isValid = filterOrGroup.data.every((item) => item.isValid);
            filterOrGroup.validationMessage = filterOrGroup.data.filter((item) => !item.isValid).map((item) => item.validationMessage).join(',');
        }
        else {
            validateFilter(filterOrGroup, setIndex);
            setIndex.currentFilterIndex = setIndex.currentFilterIndex + 1;
        }
    });
};