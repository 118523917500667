import { useState, useEffect } from 'react';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import FilterRow from './FilterRow';
import FilterRowPerform from './FilterRowPerform';
import _ from '@lodash';
import {
  FormControl,
  IconButton,
  Icon,
  CardContent,
  CardMedia,
  Button,
  ButtonGroup,
  Typography,
} from '@material-ui/core';
import { FilterContext } from './Context';

const useStyles = makeStyles({
  layoutRoot: {},
});

const customStyles = {
  control: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
  }),

  valueContainer: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
    position: 'initial',
    'padding-left': '4px',
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    height: '14px',
    'min-height': '14px',
    'margin-top': '2px',
    'margin-bottom': '2px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '0 2px',
  }),
  input: (base, state) => ({
    ...base,
    margin: '0',
    'padding-top': '0',
  }),
};

const selectStyles = {
  control: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
  }),

  valueContainer: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
    position: 'initial',
    'padding-left': '4px',
  }),
  indicatorContainer: (base, state) => ({
    ...base,
    padding: '0',
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    height: '14px',
    'min-height': '14px',
    'margin-top': '2px',
    'margin-bottom': '2px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '0 2px',
  }),
  input: (base, state) => ({
    ...base,
    margin: '0',
    'padding-top': '0',
  }),
};

const onAddNewFilter = (props) => {
  if (props.onChange) {
    props.onChange(
      props.filterIndex,
      {
        filterRows: null,
        rowJoinOperator: 'and',
        setJoinOperator: 'and',
        isValid: false,
        validationMessage: '',
      },
      'ADD_NEW_RULE_TO_SET'
    );
  }
};

const onDeleteFilter = (props) => {
  let filter = null;
  if (props.isSingleSet) {
    filter = {
      filterRows: [
        // {
        //   field: undefined,
        //   operator: undefined,
        //   values: [{ value: undefined, label: undefined }],
        // },
      ],
      rowJoinOperator: 'and',
      setJoinOperator: 'and',
      isValid: false,
      validationMessage: '',
    };
  }
  if (props.onChange) {
    props.onChange(props.filterIndex, filter, 'DELETE_RULE_FROM_SET');
  }
};

const onRowsJoinOperatorChange = (selectedOption, props) => {
  const rowJoinOperator = selectedOption?.value;

  if (props.onChange) {
    props.onChange(
      props.filterIndex,
      { rowJoinOperator: rowJoinOperator },
      'BETWEEN_ROWS_JOIN_OPERATOR'
    );
  }
};

const onAddNewRow = (props) => {
  const filterRows = [...props.filterRows];
  filterRows.push({
    field: undefined,
    operator: undefined,
    values: [{ value: undefined, label: undefined }],
    joinOperator: 'and',
    isPerform: false,
  });
  if (props.onChange) {
    props.onChange(
      props.filterIndex,
      { filterRows: filterRows },
      'ADD_NEW_ROW'
    );
  }
};

const onAddNewPerform = (props) => {
  const filterRows = [...props.filterRows];
  filterRows.push({
    field: undefined,
    operator: undefined,
    values: [{ value: undefined, label: undefined }],
    joinOperator: 'and',
    isPerform: true,
  });
  if (props.onChange) {
    props.onChange(
      props.filterIndex,
      { filterRows: filterRows },
      'ADD_NEW_ROW'
    );
  }
};

const Filter = (props) => {
  return (
    <>
      <FilterContext.Provider
        value={{
          filterRows: props.filterRows,
          onChange: props.onChange,
        }}
      >
        <div>
          <FilterHeader {...props} />
        </div>
        <div>
          <FilterRows {...props} />
        </div>
        {props.renderAddSegment && (
          <div className="chart-builder-users-middle">
            <Button
              variant="text"
              disabled={props.disabled || !!props.canAddSegment}
              startIcon={<AddIcon />}
              className="text-12 font-700"
              onClick={() => {
                onAddNewFilter(props);
              }}
            >
              Add Segment
            </Button>
          </div>
        )}
      </FilterContext.Provider>
    </>
  );
};

export const FilterHeader = (props) => {
  const options = [
    { value: 1, label: 'All' },
    { value: 2, label: 'Active', isDisabled: true },
    { value: 3, label: 'New', isDisabled: true },
    { value: 4, label: 'Unique', isDisabled: true },
  ];
  return (
    <div className="chart-builder-users-header">
      <Typography component="span" variant="caption" className="mr-5">
        {props.filterIndex} .
      </Typography>
      <Select
        className="text-11 chart-builder-users-select mr-10"
        label="Select event"
        isSearchable={false}
        defaultValue={{ label: 'All', value: 1 }}
        options={options}
        styles={customStyles}
      ></Select>
      <Typography
        component="span"
        className="flex chart-builder-users-header-title mr-5"
      >
        Users
      </Typography>
      <Select
        id={`filter-header-join-operator-${props.filterIndex}`}
        disabled={props.disabled}
        className="text-11 filter-header-joinOperator-select mr-5"
        label="Select condition"
        isSearchable={false}
        value={props.joinOperatorOptions?.find(
          (o) => o.value === props.rowJoinOperator
        )}
        options={props.joinOperatorOptions}
        onChange={(selectedOption) =>
          onRowsJoinOperatorChange(selectedOption, props)
        }
        styles={selectStyles}
      ></Select>
      <Typography variant="caption" className="mr-5 ml-auto">
        <Button
          variant="text"
          size="small"
          disabled={props.disabled}
          className="text-12 font-700"
          onClick={() => {
            onAddNewRow(props);
          }}
        >
          + where
        </Button>
      </Typography>
      <FormControl>
        <Button
          variant="text"
          size="small"
          disabled={props.disabled}
          className="text-12 font-700"
          onClick={() => {
            onAddNewPerform(props);
          }}
        >
          + perform
        </Button>
      </FormControl>
       {/* Enabling deletion for Segments other than default */}
      {(props.filterIndex!==1)?<IconButton
        disabled={props.disabled}
        size={'small'}
        onClick={() => {
          onDeleteFilter(props);
        }}
      >
        <Icon>delete</Icon>
      </IconButton>:" "}
    </div>
  );
};

export const FilterRows = (props) => {
  const filterRows = [...props.filterRows];

  return (
    <>
      {filterRows.map((row, idx) => {
        console.log('filterRows.map');
        console.log(row);
        if (!row.isPerform) {
          return (
            <FilterRow
              key={`filterrow_${idx}`}
              filterIndex={props.filterIndex}
              rowIndex={idx}
              fieldNames={props.fieldNames}
              fieldValues={props.fieldValues}
              operatorOptions={
                props.fieldNames?.find(
                  (fieldName) => fieldName.value === row.field
                )?.operatorOptions || []
              }
              joinOperatorOptions={props.joinOperatorOptions}
              rowJoinOperator={props.rowJoinOperator}
              renderJoinOperator={idx > 0}
              showJoinOperatorLabel={true}
              renderDelete={true}
              {...row}
            />
          );
        } else {
          return (
            <FilterRowPerform
              key={`filterrow_${idx}`}
              filterIndex={props.filterIndex}
              rowIndex={idx}
              fieldNames={props.fieldNames}
              actionNames={props.actionNames}
              operatorOptions={
                props.fieldNames?.find(
                  (fieldName) => fieldName.value === row.field
                )?.operatorOptions || []
              }
              joinOperatorOptions={props.joinOperatorOptions}
              rowJoinOperator={props.rowJoinOperator}
              renderJoinOperator={idx > 0}
              showJoinOperatorLabel={true}
              renderDelete={true}
              {...row}
            />
          );
        }
      })}
    </>
  );
};

FilterRows.defaultProps = {
  filterRows: [],
};

Filter.defaultProps = {
  filterRows: [],
};

Filter.displayName = 'Filter';
export default Filter;
