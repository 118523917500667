import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Formsy from 'formsy-react';
import { fetchDashboardMetaByID } from '../../chart-builder/store/dataSlice';
import { SelectFormsy, TextFieldFormsy } from '@fuse/core/formsy';
import {
  MenuItem,
  Divider,
  Button,
  ListSubheader,
  Grid,
} from '@material-ui/core';
import '../../chart-builder/chart-builder.css';

const useStyles = makeStyles({
  layoutRoot: {},
});

const MAX_KPI_COUNT = 8;

function SaveChartForm(props) {
  console.log('SaveChartForm', props);
  const { chartInfo, dashboardList, onCancel, onSubmit, dashboardId } = props;
  const user = useSelector((state) => state.auth.user);
  const classes = useStyles();
  const [isFormValid, setIsFormValid] = useState(false);
  const [dashboard_id, setDashboardId] = useState(0);
  const [chartId, setChartId] = useState(props.chartId);
  const formRef = useRef(null);
  const [chartsList, setChartsList] = useState([]);

  let initialValueProps = {
    name: {
      value: '',
    },
    description: {
      value: '',
    },
    dashboard_id: {
      value: '',
    },
    dashboard_name: {
      value: 'Default Dashboard',
    },
  };

  if (chartInfo) {
    const { name, description, dashboard_id } = chartInfo;
    initialValueProps = {
      name: {
        value: name,
      },
      description: {
        value: description,
      },
      dashboard_id: {
        value: dashboard_id,
      },
    };
  }

  useEffect(async () => {
    if (chartId === 0) {
      const chartSelection = {
        userId: user.data.email || user.data.email_address,
        dashboard_id: dashboardId,
        client_id: user.data.client_id,
        email_address: user.data.email || user.data.email_address,
      };

      const details = await fetchDashboardMetaByID(chartSelection);
      if (details) {
        const dashboard_data = JSON.parse(details.dashboard_data);
        setChartsList(dashboard_data);
      }
    }
  }, [dashboardId]);

  const handleCreateDashboard = (event) => {
    setDashboardId(event.target.value);
  };

  const handleChartSelect = (event) => {
    setChartId(event.target.value);
  };

  const disableButton = () => {
    setIsFormValid(false);
  };

  const enableButton = () => {
    setIsFormValid(true);
  };

  const handleSubmit = (formValues) => {
    onSubmit({ ...formValues });
  };

  return (
    <>
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="w-5/5 flex flex flex-col"
      >
        <TextFieldFormsy
          className={classes.root}
          type="text"
          name="name"
          label="Name"
          required
          {...initialValueProps.name}
        />
        <TextFieldFormsy
          className={classes.root}
          type="text"
          name="description"
          label="Description"
          {...initialValueProps.description}
        />
        <SelectFormsy
          className={classes.root}
          name="dashboard_id"
          label="Add to"
          required
          onChange={handleCreateDashboard}
          {...initialValueProps.dashboard_id}
          value={dashboardId}
        >
          {dashboardList?.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id} selected={item.id == dashboardId}>
                {item.title}
              </MenuItem>
            );
          })}
          {dashboardList?.length === 0 && <MenuItem value={-1}>+ Create a new default dashboard</MenuItem>}
        </SelectFormsy>
        {dashboard_id === -1 && (
          <TextFieldFormsy
            className={classes.root}
            type="text"
            name="dashboard_name"
            label="New Default Dashboard Name"
            inputProps={{							
							readOnly: true
						}}
            required            
            {...initialValueProps.dashboard_name}
          />
        )}
        {!chartInfo && (MAX_KPI_COUNT - chartsList?.length <= 0) &&
          <>
            <div style={{ color: "red", paddingTop: "10px", paddingBottom: "1px" }}>Max limit reached, please unpin to proceed</div>
            <SelectFormsy
              className={classes.root}
              name="chartId"
              label="All KPIS"
              required
              onChange={handleChartSelect}
              value={chartId}
            >
              {chartsList?.map((item) => {
                return (
                  <MenuItem key={item.chartId} value={item.chartId} selected={item.chartId == chartId}>
                    {item.chartTitle}
                  </MenuItem>
                );
              })}
            </SelectFormsy>
          </>
        }
        <Grid container className="mt-20 mb-10" direction="row-reverse">
          <Button
            variant="contained"
            color="primary"
            size="small"
            disabled={!isFormValid}
            type="submit"
          >
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="mr-10"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Grid>
      </Formsy>
    </>
  );
}

export default SaveChartForm;
