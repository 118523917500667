import React, { useEffect, useState } from "react";
import "./SubTab.css";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TabEffectTwo from "../TabEffectTwo/TabEffectTwo";
import { useSelector, useDispatch } from "react-redux";
import {
  selectDynamicEntities,
  filterDynamicInsight
} from '../../store/dynamicSlice'
const tabStyle = {
  default_tab: {
    color: "#000000",
    fontSize: 15,
  },
  active_tab: {
    // background:" #437EEB",
    borderRadius: "5px",
    color: " #437EEB",
    border: "1px solid #437EEB ",
    paddingLeft: '.5rem'
  },
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div 
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      // style={{ }}
      style={{ width: "-webkit-fill-available", overflow: "none" }}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    // paddingLeft: "2rem",
    overflow: "visible",
  },
  indicator: {
    backgroundColor: "#437EEB",
    height: "10px",
    top: "45px",
    overflow: "inherit !important",
    backgroundColor: "transparent",
    // border:"1px solid #437EEB"
  },
}));

export default function SubTab(props) {
  const classes = useStyles();
  const dispatch = useDispatch()
  const dynamicData = useSelector(selectDynamicEntities);
  const [value, setValue] = useState(0);
  const [sidenavDynamic, setSidenavDynamic] = useState(null);
  const [selectedBusinessTraffic, setSelectedBusinessTraffic] = useState(dynamicData?.trafficInsight?.business_category[0]);
  const [selectedBusinessEvent, SetSelectedBusinessEvent] = useState(dynamicData?.eventsInsight?.business_category[0])
  const [activeIndex, setActiveIndex] = useState(0);
  const [originalData, setOriginalData] = useState(null);
  const [summary, setSummary] = useState(null)
  // const dispatch = useDispatch();


  useEffect(() => {
    if (dynamicData?.eventsInsight?.data?.length > 0 && props.category == "event") {
      if(dynamicData?.eventsInsight?.business_category?.includes(selectedBusinessEvent)){
        // console.log(selectedBusinessEvent,"selectedBusinessEvent &####")
        setValue(0)
        setActiveIndex(0)
        SetSelectedBusinessEvent(dynamicData?.eventsInsight?.business_category[0])

      }
      SetSelectedBusinessEvent(dynamicData?.eventsInsight?.business_category[0])
      dynamicData?.eventsInsight?.data?.map((data) => {
        if (data?.business_category == selectedBusinessEvent && data?.insight_time_value_in_days == props.dateRange && data?.insight_type == "Summary" && props.category == "event") {
          // console.log(data.insight_text,"data.insight_text event")
          setSummary(data.insight_text)
        }
      }
      )
      setSidenavDynamic(dynamicData?.eventsInsight?.business_category)
    } else if (dynamicData?.trafficInsight?.data?.length > 0 && props.category == "traffic") {
      if(dynamicData?.trafficInsight?.business_category?.includes(selectedBusinessTraffic)){
        setValue(0)
        setActiveIndex(0)
        setSelectedBusinessTraffic(dynamicData?.trafficInsight?.business_category[0])
      }
      setSelectedBusinessTraffic(dynamicData?.trafficInsight?.business_category[0])
      dynamicData?.trafficInsight?.data?.map((data) => {
        if (data?.business_category == selectedBusinessTraffic && data?.insight_time_value_in_days == props.dateRange && data?.insight_type == "Summary" && props.category == "traffic") {
          // console.log( data.insight_text,"data.insight_text traffic")
          setSummary(data.insight_text)
        }
      })
      setSidenavDynamic(dynamicData?.trafficInsight?.business_category)

    }
    if (dynamicData?.trafficInsight?.business_category?.length > 0 || dynamicData?.eventsInsight?.data?.length > 0) {

      dispatch(filterDynamicInsight({
        selectedBusinessTraffic: selectedBusinessTraffic,
        selectedBusinessEvent: selectedBusinessEvent,
        insight_time_value_in_days: props.dateRange,
        category: props.category
      }))
    }

  }, [dynamicData?.trafficInsight?.business_category, dynamicData?.trafficInsight?.data, dynamicData?.eventsInsight?.business_category, dynamicData?.eventsInsight?.data, props.dateRange])

  useEffect(() => {
    if (dynamicData?.eventsInsight?.filterData?.length > 0 && props.category == "event") {
      // console.log(dynamicData?.eventsInsight?.filterData,"dynamicData?.eventsInsight?.filterData")
      setOriginalData(dynamicData?.eventsInsight?.filterData)
    } else if (dynamicData?.trafficInsight?.filterData?.length > 0 && props.category == "traffic") {
      setOriginalData(dynamicData?.trafficInsight?.filterData)
      // console.log(dynamicData?.trafficInsight?.filterData,"dynamicData?.trafficInsight?.filterData")

    }

  }, [dynamicData?.trafficInsight?.filterData, dynamicData?.eventsInsight?.filterData, dynamicData?.trafficInsight?.data, dynamicData?.eventsInsight?.data, props.category])
  const handleChange = (event, newValue) => {

    // console.log(newValue,"newValue &##")
    setActiveIndex(newValue);
    setValue(newValue);
    setSelectedBusinessTraffic(event.target.innerText);
    dynamicData?.trafficInsight?.data?.map((data) => {
      if (data?.business_category == event.target.innerText && data?.insight_time_value_in_days == props.dateRange && data?.insight_type == "Summary" && props.category == "traffic") {
        setSummary(data.insight_text)
      }
    })

    dynamicData?.eventsInsight?.data?.map((data) => {
      if (data?.business_category == event.target.innerText && data?.insight_time_value_in_days == props.dateRange && data?.insight_type == "Summary" && props.category == "event") {
        setSummary(data.insight_text)
      }
    })
    dispatch(filterDynamicInsight({
      selectedBusinessTraffic: event.target.innerText,
      selectedBusinessEvent: event.target.innerText,
      insight_time_value_in_days: props.dateRange,
      category: props.category
    }))

  };

  const getStyle = (isActive) => {
    return isActive ? tabStyle.active_tab : tabStyle.default_tab;
  };
  // console.log(summary, originalData,"summary, originalData")
  // console.log(selectedBusinessEvent,"selectedBusinessEvent &####")

  return (
    <div className="subTabContainer" >
      <div className={classes.root} >
        <Tabs id="businessType"
          TabIndicatorProps={{ className: classes.indicator }}
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          {sidenavDynamic &&
            sidenavDynamic?.map((data, index) => {
              return <Tab label={data} {...a11yProps(index)} style={getStyle(activeIndex === index)} />;
            })}
        </Tabs>
        {sidenavDynamic && summary && originalData &&
          sidenavDynamic?.map((data, index) => {
            return (
              <TabPanel value={value} index={index} style={{ width: "-webkit-fill-available" }}>
                <TabEffectTwo
                  summary={summary}
                  originalData={originalData}
                />
              </TabPanel>
            );
          })}
      </div>
    </div>
  );
}
