import i18next from 'i18next';
import KpiBuilder from './KpiBuilder';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';

i18next.addResourceBundle('en', 'examplePage', en);
i18next.addResourceBundle('tr', 'examplePage', tr);
i18next.addResourceBundle('ar', 'examplePage', ar);

/**
 * Lazy load Example
 */

import React from 'react';

const KpiBuilderConfig = {
  settings: {
    layout: {
      config: {
        toolbar: {
          display: true,
          style: 'fixed',
          position: 'below',
          title: '',
        },
      },
    },
  },

  routes: [
    {
      path: '/kpibuilder',
      exact: true,
      component: React.lazy(() => import('./KpiBuilder')),
    },
    {
      path: '/kpibuilder/:dashboardId/:chartId',
      component: React.lazy(() => import('./KpiBuilder')),
    }
  ],
};

export default KpiBuilderConfig;
