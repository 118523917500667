const lightTheme = {
    50:'#242424',
    100:'#FAFAFA',
	500: '#EBEBEB',
    700: '#437EEb',
    
    
	
	
};

export default lightTheme;