import React from "react";
import "./TabEffectTwo.css";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { Tabs, Button } from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DynamicInsights from "../../DynamicInsights";
import { CSVLink } from "react-csv";

const headers = [
  { label: "Business Category", key: "business_category" },
  { label: "Effective Date", key: "eff_date" },
  { label: "Index", key: "index" },
  { label: "Insight Text", key: "insight_text" },
  { label: "Insight Type", key: "insight_type" },
  { label: "Insight", key: "insight_value" },
  { label: "Priority", key: "priority" },
  { label: "Value Type", key: "value_type" },
];
const tabStyle = {
  default_tab: {
    color: "#000000",
    // backgroundColor: '#FFFFFF',
    fontSize: 15,
  },
  active_tab: {
    // background:" #437EEB",
    borderRadius: "9px",
    color: " #437EEB",
  },
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "-webkit-fill-available",
    marginLeft: "1rem",
  },
  indicator: {
    backgroundColor: "#437EEB",
    height: "10px",
    top: "45px",
  },
  selected: {},
}));

const downloadcsv = (e, originalData) => {
  return (
    <CSVLink
      data={originalData?.length > 0 ? originalData : ""}
      headers={headers}
      filename={"DynamicInsights.csv"}
      style={{ textDecoration: "none" }}
    >
      <Button
        variant="outlined"
        id="exportBtn"
        className="button"
        style={{ textDecoration: "none" }}
        disabled={originalData?.length > 0 ? false : true}
      >
        Export as
      </Button>
    </CSVLink>
  );
};

export default function TabEffectTwo(props) {
  const { originalData } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [activeIndex, setActiveIndex] = React.useState(0);
  // const dynamicData = useSelector(selectDynamicEntities);
  const handleChange = (event, newValue) => {
    setActiveIndex(newValue);
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const getStyle = (isActive) => {
    return isActive ? tabStyle.active_tab : tabStyle.default_tab;
  };
  React.useEffect(() => {}, [props.summary, props.originalData]);
  return (
    <div className="tab-effect-two">
      <div className={classes.root}>
        <AppBar
          position="static"
          elevation={0}
          style={{ background: "none", borderBottom: "1px solid #A0A4A8" }}
        >
          <Tabs
            id="insights"
            value={value}
            // classes={{ indicator: classes.indicator }}
            TabIndicatorProps={{ className: classes.indicator }}
            onChange={handleChange}
            onClick={(e, originalData) => downloadcsv(e.originalData)}
            aria-label="full width tabs example"
          >
            <Tab
              style={getStyle(activeIndex === 0)}
              // classes={{ selected: classes.selected }}
              label="Summary"
              {...a11yProps(0)}
            />
            <Tab
              style={getStyle(activeIndex === 1)}
              // classes={{ selected: classes.selected }}
              label={`Individual Insights (${originalData?.length})`}
              {...a11yProps(1)}
            />

            <div className="csvLink">
              <CSVLink
                data={originalData?.length > 0 ? originalData : ""}
                headers={headers}
                filename={
                  "DynamicInsights-" +
                  originalData[0].business_category +
                  ".csv"
                }
                style={{ textDecoration: "none" }}
              >
                <Button
                  variant="outlined"
                  className="button"
                  style={{ textDecoration: "none" }}
                  disabled={originalData?.length > 0 ? false : true}
                >
                  Export as
                </Button>
              </CSVLink>
            </div>
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            {
              // props.summary
              // split the summary into paragraphs and display them in separate divs with ul and li
              // props.summary.split(".").map((item, key) => {
              //   return <div key={key}>{item}</div>
              // })
              // check sentence length and split accordingly to display in separate divs with ul and li and don't display empty divs or don't split decimal numbers into separate divs
              props.summary.split(". ").map((item, key) => {
                if (item.length > 1 && item.indexOf(".") === -1) {
                  return <Typography key={key} component={'li'}
                  >{item + "."}</Typography>;
                } else if (item.length > 1 && item.indexOf(".") > -1) {
                  return <Typography
                  key={key} component={'li'} >{item + "."}</Typography>;
                }
              })
            }
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <>
              <DynamicInsights originalData={originalData} />
            </>
          </TabPanel>
        </SwipeableViews>
      </div>
    </div>
  );
}
