import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Paper,
  Snackbar
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { setUserDetails } from 'app/auth/store/userSlice';


import { AppAccordion } from "app/shared-components/app-accordion/AppAccordion";

import {
  CodeIntegrateSectionOneSummary,
  CodeIntegrateSectionOneDetails,
  CodeIntegrateSectionTwoSummary,
  CodeIntegrateSectionTwoDetails,
} from "./CodeIntegrateSections";

import { logoutUser } from 'app/auth/store/userSlice'
import { testConnection, makeRegistrationComplete, sendRegistrationEmail } from "../services/register.service";
import { useDispatch } from "react-redux";
import 'intro.js/introjs.css';
import introJs from 'intro.js';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function CodeIntegrate(props) {
  const { jsSnippet, accountSetupData, listAddedUser } = props;
  const history = useHistory();
  const [testConnectionLoading, setTestConnectionLoading] = useState(false);
  const [testConnectionText, setTestConnectionText] = useState("");
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const dispatch = useDispatch();
  const intro = introJs();
  const productTour = (e) => {
    intro.setOptions({ steps });
    intro.start();
  };
  const steps = [

    {
      element: '#sendSnippet',
      intro: 'To share the code with your teammate or software engineer, simply enter their email address in the designated field. ',
      position: 'top',
    },
    {
      element: '#copyCodeBtn',
      intro: 'To install the snippet yourself, simply copy the code displayed  below and paste it into the head tag of your website. This will enable flable .ai to track and gather data from your website seamlessly.Ensure that the code is placed in the correct location for optimal functionality. ',
      position: 'top',
    },
    {
      element: '#testConnectionBtn',
      intro: 'You can test the connection by clicking on the displayed icon. This will initiate a connection test to ensure that the integration between your website and flable .ai is functioning properly.',
      position: 'top',
    },

  ];

  const handleEmailMaual = async (email) => {
    try {
      const { email_address } = localStorage.getItem('lac-user-details') ? JSON.parse(localStorage.getItem('lac-user-details')) : {};
      const client_id = localStorage.getItem("lac-user-active-client_id");
      const emailInfo = {
        cc: email_address,
        to: email,
        subject: 'Installation guidance',
        text: 'Ljc Script',
        // html: `<div>&lt;script &gt;
        // (function(l, a, c, r, i, t, z) {
        //     l[c] = l[c] || function() {
        //       (l[c].q = l[c].q || []).push(arguments)
        //     };
        //     t = a.createElement(r);
        //     t.async = 1;
        //     t.src = "https://85.214.144.66:8086/api/config/"+i;
        //     z = a.getElementsByTagName(r)[0];
        //     z.parentNode.insertBefore(t, z);
        //   })(window, document, "lacritz", "script", '${client_id}');
        //   &lt;/script &gt;</div>`,
        html: `<html lang="en">

        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
            <title>Registration link for Flable </title>
            <style>
                html,
                body {
                    background: #f1f1f1;
                }
        
                .email-container {
                    font-family: Arial, sans-serif;
                    background-color: #FFFFFF;
                    margin: 3%;
                    padding: 3%;
                }
        
                .heading {
                    text-align: left;
        
                }
        
                .button-like-link {
        
                    padding: 10px 20px;
                    text-decoration: none;
                    /* Remove underline */
                    background-color: #437eeb;
                    color: #fff;
                    border: none;
                    border-radius: 5vw;
                    cursor: pointer;
                    width: 10vw;
                    height: 5vh;
                    text-align: centre;
                }
        
                /* Remove the default button styles */
                .button-like-link:focus,
                .button-like-link:hover {
                    outline: none;
                    text-decoration: none;
                }
        
                .icon-background {
                    background-color: #437eeb;
                    border-radius: 50%;
                    z-index: -1;
                }
            </style>
        </head>
        
        <body class="email-container">
            <header class="heading">
                <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="10%" height="5%" />
                <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                    growth</p>
            </header>
        
        
            <hr style="border: 1px solid red;">
        
            <p style="font-size: 18px;">Please copy and paste the below code snippet into the header section of the website</p>
        
            <div style="text-align: center; margin:2%;border:2px solid black">
                           <p style=" font-size: 18px;font-weight: bold;" id="script_main">&lt;script &gt;
                (function(f, l, a, b, t, i, c) {
                f[a] = f[a] || function() {
                (f[a].q = f[a].q || []).push(arguments)
                };
                i = l.createElement(b);
                i.async = 1;
                i.src ='${process.env.REACT_APP_LJS_URL}'/api/config/'+t;
                c = l.getElementsByTagName(b)[0];
                c.parentNode.insertBefore(i, c);
                })(window, document, "flable", "script", '${client_id}');
                &lt;/script &gt;</p>
            </div>
            <p style="font-size: 18px;">For any further assistance or inquiries, please don't hesitate to reach out to our
                dedicated support team at support@flable.ai </p>
            <p style="font-size: 16px;">Best Regards,</p>
            <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
            <hr style="border: 1px solid #fafafa;margin-top:3%">
            <div id="socialConnect" style="width: 100%;text-align: center;">
                <table style="display: inline-block; vertical-align: middle;" width="auto">
                    <tr>
                        <td class="icon-background" style="width:25%">
                            <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                                <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                    height="50%" style="margin-top:25%" />
                            </a>
        
                        </td>
                        <td class="icon-background" style="width:25%">
                            <a href="https://www.linkedin.com/company/flable/" target="_blank">
                                <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                    style="margin-top:25%" />
                            </a>
        
                        </td>
                        <td class="icon-background" style="width:25%">
        
                            <a href="https://www.linkedin.com/company/flable/" target="_blank">
                                <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                    height="50%" style="margin-top:25%" />
                            </a>
        
                        </td>
                        <td class="icon-background" style="width:25%">
                            <a href="https://twitter.com/Flableai" target="_blank">
                                <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                    height="50%" style="margin-top:25%" />
                            </a>
        
                        </td>
                    </tr>
                </table>
            </div>
            <hr style="border: 1px solid #fafafa;margin-bottom:2%">
            <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
                <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                    rights
                    reserved.</span>
            </div>
            <div style="width: 100%;  text-align: center; display: table;margin:3px">
                <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                    company</span>
            </div>
            <div id="disclaimer" style="width: 100%;text-align: center;">
                <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                    registered
                    to the Flable platform as a user.This
                    indicates your agreement with our Terms of Use and Privacy Policies.
                </p>
            </div>
            <div style="width: 100%;text-align: center;">
        
                <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                    <tr>
                        <td style="text-align: center; ">
        
                            <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                        </td>
                        <td style="text-align: center; ">
                            <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                        </td>
                        <td style="text-align: center; ">
                            <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                        </td>
                        </td>
                    </tr>
                </table>
        
            </div>
        </body>
        
        </html>`
      };
      await sendRegistrationEmail(emailInfo)
    } catch (err) {
      console.log(err, "error")
    }
  }
  // console.log(accountSetupData,"accountSetupData starting codeintegrate")
  const accordionItems = [
    {
      summary: "",
      summaryRenderer: () => {
        return <CodeIntegrateSectionOneSummary />;
      },
      details: "",
      detailsRenderer: () => {
        return <CodeIntegrateSectionOneDetails handleEmailMaual={handleEmailMaual} />;
      },
    },
    {
      summary: "General settings",
      summaryRenderer: () => {
        return <CodeIntegrateSectionTwoSummary />;
      },
      details: "",
      detailsRenderer: () => {
        return <CodeIntegrateSectionTwoDetails jsSnippet={jsSnippet} />;
      },
    },
  ];
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const performTestConnection = async () => {
    try {
      const { email_address } = JSON.parse(localStorage.getItem('lac-user-details'));
      let getListData
      if (email_address) {
        getListData = await listAddedUser(email_address)

      }

      const availableClients = getListData
      const activeClientDetails = availableClients[0];
      const mergeData = getListData

      mergeData.forEach((item) => {
        item.currentProductName =
          activeClientDetails && availableClients.length > 0
            ? activeClientDetails.company_name
            : 'TODO';
        item.availableProducts =
          availableClients && availableClients.length > 0
            ? availableClients.map((item) => {
              return {
                agency_name: item.agency_name,
                agency_url: item.agency_url,
                client_id: item.client_id,
                company_name: item.company_name,
                company_url: item.company_url,
                organization_type: item.organization_type,
                user_role: item.user_role,
                product_register_progress: item.product_register_progress,

              };
            })
            : [];
      });
      localStorage.setItem(
        'lac-user-client-details',
        JSON.stringify(mergeData)
      );
      const response = {
        data: mergeData,
        token: localStorage.getItem('lac-user-token')
      }
      dispatch(setUserDetails(response));
      console.log(accountSetupData, "accountSetupData codeintegrate")
      const result = await testConnection(accountSetupData);
      if (result?.data?.status !== 'Script Not Authenticated') {
        setTestConnectionText(result.data.status);
        setTestConnectionLoading(false);
        setAlertType('success');
        setAlertMessage('Connection Successful');
        setOpen(true);
        await makeRegistrationComplete(accountSetupData);
        dispatch(logoutUser());
        setTimeout(() => {
          history.push("/login");
        }, 2000);
      } else {
        setTestConnectionText(result?.data?.status);
        setTestConnectionLoading(false);
        setAlertType('error');
        setAlertMessage("please contact support@flable.ai");
        setOpen(true);
      }
    } catch (err) {
      // setTestConnectionText(err);
      setTestConnectionLoading(false);
      setAlertType('error');
      setAlertMessage("Couldn't process your request.Please contact support@flable.ai");
      setOpen(true);

    }

  };
  return (
    <div>
      <AppAccordion accordionItems={accordionItems} defaultExpanded={1} />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-end"
      >
        <Grid
          item
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Grid
            container
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-end"
          >
            <Grid item>
              {testConnectionLoading && (
                <CircularProgress size={20} style={{ marginBottom: "7px" }} />
              )}
            </Grid>
            <Grid item>
              {testConnectionText && (
                <Typography
                  color="textSecondary"
                  variant="caption"
                  display="block"
                  style={{
                    lineHeight: "35px",
                    marginLeft: "9px",
                    marginRight: "17px",
                  }}
                >
                  {testConnectionText}
                </Typography>
              )}
            </Grid>
            <Grid item>
              <Button
                style={{ marginTop: "22px" }}
                variant="contained"
                disableElevation
                color="primary"
                size="lg"
                id="testConnectionBtn"
                onClick={performTestConnection}
              >
                Test Connection
              </Button>
              <Button
                style={{ marginTop: "22px" }}
                className="ml-10"
                variant="contained"
                color="primary"
                onClick={productTour}
              >
                Take a tour
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        style={{ marginTop: "20px" }}
      >
        <Typography variant="subtitle1" gutterBottom component="div">
          Installed but not receiving data?
        </Typography>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          style={{ marginTop: "4px" }}
        >
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            color="textSecondary"
          >
            {`Check out the `}
          </Typography>
          <Typography variant="caption" display="block" className="pl-2 pr-2">
            {` documentation`}
          </Typography>
          <Typography
            variant="caption"
            display="block"
            gutterBottom
            color="textSecondary"
          >
            {` or if you are still having trouble, contact us at `}
          </Typography>
          <Typography variant="caption" display="block" className="pl-2">
            {/* support@lacritz.com */}
            support@flable.ai
          </Typography>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default CodeIntegrate;
