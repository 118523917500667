import { TableRow } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const AppTableRow = (props) => {
  const { isRowStyled = false, children } = props;
  if (isRowStyled) {
    return <StyledTableRow>{children}</StyledTableRow>;
  } else {
    return <TableRow>{children}</TableRow>;
  }
};
