import i18next from "i18next";
import en from "./i18n/en";
import tr from "./i18n/tr";
import ar from "./i18n/ar";

i18next.addResourceBundle("en", "customEventMappingPage", en);
i18next.addResourceBundle("tr", "customEventMappingPage", tr);
i18next.addResourceBundle("ar", "customEventMappingPage", ar);

import React from "react";

const CustomEventMappingConfig = {
    settings: {
        layout: {
            style: "layoutInspector",
            config: {
                scroll: "content",
                navbar: {
                    display: false,
                    folded: false,
                },
                toolbar: {
                    display: false,
                },
                settingsPanel: {
                    display: false
                },

                mode: "container",
            },
        },
    },
    routes: [
        {
            path: ["/custom-event-mapping"],
            component: React.lazy(() => import("./CustomEventMapping")),
        },
    ],
};
// https://cors-anywhere.herokuapp.com/corsdemo
export default CustomEventMappingConfig;
