import { Redirect } from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import appsConfigs from 'app/main/app/appsConfigs';
import LoginConfig from 'app/main/login/LoginConfig';
import LogoutConfig from 'app/main/logout/LogoutConfig';
import RegisterConfig from 'app/main/register/RegisterConfig';
import AccountSetupConfig from 'app/main/register/AccountSetupConfig';
import ProfileConfig from 'app/main/profile/ProfileConfig';
import UserManagementConfig from 'app/main/user-management/UserManagementConfig';
import ResetPasswordConfig from 'app/main/reset-password/ResetPasswordConfig';
import NewPasswordConfig from 'app/main/reset-password/NewPasswordConfig';
import DynamicInsightConfig from 'app/main/app/dynamic-insight/DynamicInsightConfig';
const routeConfigs = [
  ...appsConfigs,
  AccountSetupConfig,
  LogoutConfig,
  LoginConfig,
  RegisterConfig,
  ProfileConfig,
  UserManagementConfig,
  LogoutConfig,
  ResetPasswordConfig,
  NewPasswordConfig,
  DynamicInsightConfig
];

const routes = [
  // if you want to make whole app auth protected by default change defaultAuth for example:
  // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
  // The individual route configs which has auth option won't be overridden.
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
  {
    path: '/',
    component: () => <Redirect to="/dashboard/dummy" />,
  },
];

export default routes;
