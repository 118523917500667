import { useEffect, useState } from 'react';
import { Box, Typography, Divider, TextField, Button, Snackbar } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import Logo from './image/lacritz.svg'
import Logo from './image/flable.png'
import { resetPassword, sendResetPasswordConfirmation } from './services/newPassword.service';
import { useParams } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import CryptoJS  from 'crypto-js';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const NewPassword = props => {
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [isToken, setIsToken] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [decodedToken, setDecodedToken] = useState(null);
    const [passwordDetails, setPasswordDetails] = useState({
        password: '',
        confirmPassword: '',
    });
   
    const emailDetails = {
        to: decodedToken?.email,
        subject: "Password reset confirmation for your Flable account",
        text: "Your password has been reset",
        // html: `<a href='${process.env.REACT_APP_DASHBOARD_URL}/new-password/${isTokenValid?.token}}}'>Reset Password</a>`,
        html: `<html lang="en">

        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
            <title>Reset password link </title>
            <style>
                html,
                body {
        
        
                    background: #f1f1f1;
                }
        
                .email-container {
                    font-family: Arial, sans-serif;
                    background-color: #FFFFFF;
                    margin: 3%;
                    padding: 3%;
                }
        
                .heading {
                    text-align: left;
        
                }
        
                .button-like-link {
        
                    padding: 10px 20px;
                    text-decoration: none;
                    /* Remove underline */
                    background-color: #437eeb;
                    color: #fff;
                    border: none;
                    border-radius: 5vw;
                    cursor: pointer;
                    width: 10vw;
                    height: 5vh;
                    text-align: centre;
                }
        
                /* Remove the default button styles */
                .button-like-link:focus,
                .button-like-link:hover {
                    outline: none;
                    text-decoration: none;
                }
        
                .icon-background {
                    background-color: #437eeb;
                    border-radius: 50%;
                    z-index: -1;
                    text-align: center;
                    vertical-align: middle;
                }
            </style>
        </head>
        
        <body class="email-container">
            <header class="heading">
                <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="10%" height="5%" />
                <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                    growth</p>
            </header>
        
        
            <hr style="border: 1px solid red;">
        
            <h2>Hi &nbsp;</h2>
        
            <p style="font-size: 18px;">We hope this message finds you well.</p>
            <p style="font-size: 18px;">Your password for Flable account has been reset successfully.</p>
        
            <p style="font-size: 18px;">For any further assistance or inquiries, please don't hesitate to reach out to our
                dedicated support team at support@flable.ai </p>
            <p style="font-size: 16px;">Best Regards,</p>
            <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
            <hr style="border: 1px solid #fafafa;margin-top:3%">
            <div id="socialConnect" style="width: 100%;text-align: center;">
                <table style="display: inline-block; vertical-align: middle;" width="auto">
                    <tr>
                        <td class="icon-background" style="width:25%">
                            <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                                <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                    height="50%" style="margin-top:25%" />
                            </a>
        
                        </td>
                        <td class="icon-background" style="width:25%">
                            <a href="https://www.linkedin.com/company/flable/" target="_blank">
                                <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                    style="margin-top:25%" />
                            </a>
        
                        </td>
                        <td class="icon-background" style="width:25%">
        
                            <a href="https://www.linkedin.com/company/flable/" target="_blank">
                                <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                    height="50%" style="margin-top:25%" />
                            </a>
        
                        </td>
                        <td class="icon-background" style="width:25%">
                            <a href="https://twitter.com/Flableai" target="_blank">
                                <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                    height="50%" style="margin-top:25%" />
                            </a>
        
                        </td>
                    </tr>
                </table>
            </div>
            <hr style="border: 1px solid #fafafa;margin-bottom:2%">
            <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
                <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                    rights
                    reserved.</span>
            </div>
            <div style="width: 100%;  text-align: center; display: table;margin:3px">
                <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                    company</span>
        
            </div>
            <div id="disclaimer" style="width: 100%;text-align: center;">
                <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                    registered
                    to the Flable platform as a user.This
                    indicates your agreement with our Terms of Use and Privacy Policies.
                </p>
            </div>
            <div style="width: 100%;text-align: center;">
        
                <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                    <tr>
                        <td style="text-align: center; ">
        
                            <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                        </td>
                        <td style="text-align: center; ">
                            <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                        </td>
                        <td style="text-align: center; ">
                            <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                        </td>
                        </td>
                    </tr>
                </table>
        
            </div>
        </body>
        
        </html>`,
      };
    const { token } = useParams();
    useEffect(() => {
        if (token) {
           const url = window.location.href.split('/');
        //    console.log(url,"url");
           //join with / to get the url after  4 index
              const urlAfter4Index = url.slice(4).join('/');
          //decrypt the token
          var bytes = CryptoJS?.AES?.decrypt(urlAfter4Index, 'my-secret-key@123');
          var decryptedData = JSON.parse(bytes?.toString(CryptoJS.enc.Utf8));
        //   console.log(decryptedData)
          //remove last 6 char
          setDecodedToken(decryptedData)
            const token = urlAfter4Index?.slice(0, -6);
            setIsToken(token);
        }
    }
        , [isToken]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleChange = (e) => {
        setPasswordDetails({
            ...passwordDetails,
            [e.target.name]: e.target.value,
        });
        // var passw = /^[A-Za-z]\w{7,20}$/;
        // if (e.target.value.match(passw)) {
        //     setError('')
        //     return true;
        // } else {
        //     setError('Password must be 8 , one number and one special character')
        //     return false;
        // }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        // const verifyEmailData  = JSON.parse(localStorage.getItem("verifyEmail"))
        const data = {
            email: decodedToken.email,
            password: passwordDetails.password,
            token: isToken
        }
        try {
            if (passwordDetails.password === passwordDetails.confirmPassword) {
                const response = await resetPassword(data);
                if (response?.data?.data === "Password reset successfully") {
                    setOpen(true);
                    setAlertMessage("Password reset successfully");
                    setAlertType("success");
                   await sendResetPasswordConfirmation(emailDetails);
                    
                } else {
                    setOpen(true);
                    setAlertMessage("token expired please try again");
                    setAlertType("error");
                }

            } else {
                alert("Password and Confirm Password does not match");
            }
        } catch (error) {
            console.log("error", error);
            setOpen(true);
            setAlertMessage("Password reset failed");
            setAlertType("error");
        }
    }
    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Box className="flex flex-col items-center mt-24">
                <img className="logo-icon w-100 mb-8" src={Logo} alt="logo svg" />
            </Box>
            <Divider />

            <div className="flex flex-col flex-auto items-center mt-64 p-16 md:p-24">

                <Typography variant="h4" className="mb-24">
                    Reset Password
                </Typography>
                <Typography variant="p" className="mb-32">
                    Provide new password to continue password reset
                </Typography>
                <form className="flex flex-col items-center justify-center w-full max-w-400">
                    <TextField
                        className='mt-16 mb-16'
                        label="New Password"
                        variant="standard"
                        fullWidth
                        name='password'
                        value={passwordDetails?.password}
                        type="password"
                        onChange={handleChange}
                        // error={error ? true : false}
                        // helperText={error}
                        InputProps={{
                            className: 'pr-2',
                            type: showPassword ? 'text' : 'password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        <Icon className="text-20" color="action">
                                            {showPassword ? 'visibility' : 'visibility_off'}
                                        </Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        className='mt-16 mb-16'
                        label="Confirm Password"
                        variant="standard"
                        fullWidth
                        name='confirmPassword'
                        value={passwordDetails?.confirmPassword}
                        onChange={handleChange}
                        type="password"
                        // error={error ? true : false}
                        // helperText={error}
                        InputProps={{
                            className: 'pr-2',
                            type: showPassword ? 'text' : 'password',
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)}>
                                        <Icon className="text-20" color="action">
                                            {showPassword ? 'visibility' : 'visibility_off'}
                                        </Icon>
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
                <Button style={{ "backgroundColor": "#3F3D56", "color": "white" }} className="mt-16  pr-64 pl-64" variant='contained' onClick={handleSubmit}>
                    Reset
                </Button>
                <Box className="flex flex-row items-center justify-center w-full max-w-400 mt-64">
                    <Typography className=" font-500" color="textSecondary" >
                        <Link to="/login" style={{ "cursor": "pointer", "color": "#3F3D56" }}> Want to return ? <Typography variant="span" className="font-700">Login</Typography></Link>
                    </Typography>
                </Box>
            </div>

        </div>
    )
}
export default NewPassword;
