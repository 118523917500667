import { SelectFormsy, TextFieldFormsy } from "@fuse/core/formsy";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  MenuItem,
  Button,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import Formsy from "formsy-react";
import { useRef, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import FormsyPassword from "app/shared-components/formsy-password/FormsyPassword";
import { addValidationRule } from "formsy-react";
import { BusinessInfoList } from "./BusinessInfoList";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { DeleteUserDialog } from "../delete-dialog/DeleteUserDialog";
import { useDispatch } from "react-redux";
import { openDialog } from "app/store/fuse/dialogSlice";
import { getUsersList } from "../../services/users";
const passwordCriteria = [
  { regex: /\d/, text: "Minimum 1 Digit" },
  { regex: /^(?=.*[a-z])(?=.*[A-Z])/, text: "Minimum 1 Upper and Lower Case" },
  { regex: /(?=^.{8,}$)/, text: "Minimum 8 Characters" },
  { regex: /[#$@!%&*?]/, text: "Minimum 1 Special Character" },
];
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const useUserStyles = makeStyles({
  root: {
    width: "100%",
  },
  email: {
    width: "98%",
  },
});

export const UserForm = (props) => {
  const {
    userInfo,
    onSubmit,
    countryList,
    languageList,
    roleList,
    businessListOptions,
    deleteUser,
    loggedInUserEmail,
    pageTitle,
  } = props;
  const [isFormValid, setIsFormValid] = useState(false);
  const formRef = useRef(null);
  const history = useHistory();
  const classes = useUserStyles();
  const [addedBusiness, setAddedBusiness] = useState();
  const [countrySh, setCountrySh] = useState(userInfo?.country);
  const [validateEmail, setValidateEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const dispatch = useDispatch();
  console.log("pageTitle########", pageTitle);
  let initialValueProps = {
    fullname: {
      value: "",
    },
    email: {
      value: "",
    },
    country: {
      value: "",
    },
    language: {
      value: "",
    },
    password: {
      value: "",
    },
  };

  if (userInfo) {
    const { fullname, email, country, language, password } = userInfo;
    initialValueProps = {
      fullname: {
        value: fullname,
        // disabled: true,
      },
      email: {
        value: email,
        disabled: true,
      },
      country: {
        value: countrySh,
      },
      language: {
        value: language,
      },
      password: {
        value: password,
        disabled: true,
      },
    };
  }

  const getAddedBusiness = (_businessList) => {
    setAddedBusiness(_businessList);
  };

  function disableButton() {
    setIsFormValid(false);
  }

  function enableButton() {
    setIsFormValid(true);
  }

  addValidationRule("isValidPassword", function (_, value) {
    if (userInfo && !value) {
      return true;
    } else {
      return !passwordCriteria.some(({ regex }) => !regex.test(value));
    }
  });
  const fetchEmail = async (email) => {
    try {
      const response = await getUsersList(email);
      return response;
    } catch (error) {
      return null;
    }
  };

  async function handleSubmit(formValues) {
    const { email } = formValues;
    const emailRespose = await fetchEmail(email);
    if (emailRespose == null && pageTitle === "Add User") {
      setValidateEmail(true);
      onSubmit({
        ...formValues,
        businessInfo: addedBusiness,
        country: countrySh,
      });
    } else if (
      pageTitle === "Edit user profile" ||
      pageTitle === "Profile Settings"
    ) {
      setValidateEmail(true);
      onSubmit({
        ...formValues,
        businessInfo: addedBusiness,
        country: countrySh,
      });
    } else {
      // setAlertMessage("Email already exists please contact Lacritz Admin");
      setAlertMessage("Email already exists please contact Flable Admin");
      setAlertType("error");
      setOpen(true);
    }
  }
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const onDeleteUser = () => {
    let businessAccountInfo = userInfo.businessAccountInfo?.map((i) => {
      i.assignedBusiness = i.current_business;
      return i;
    });
    deleteUser?.({
      ...userInfo,
      businessAccountInfo: businessAccountInfo,
    }).then(() => {
      history.push("/manage-users");
    });
  };
  // const {user_role}= localStorage.getItem('lac-user-details')?JSON.parse(localStorage.getItem('lac-user-details')):{};
  return (
    <div>
      <>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={alertType}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </>
      <Formsy
        onValidSubmit={handleSubmit}
        onValid={enableButton}
        onInvalid={disableButton}
        ref={formRef}
        className="flex flex-col justify-center mt-20"
        // disabled={true}
      >
        <Grid container spacing={2} direction="column">
          <Grid item xs={12} md={5}>
            <TextFieldFormsy
              className={classes.root}
              type="text"
              name="fullname"
              label="Full Name"
              validations={{
                minLength: 4,
              }}
              validationErrors={{
                minLength: "Min character length is 4",
              }}
              {...initialValueProps.fullname}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <Grid container xs={12} md={12}>
              <Grid md={5}>
                <TextFieldFormsy
                  className={classes.email}
                  type="text"
                  name="email"
                  label="Email"
                  validations={{
                    isEmail: true,
                  }}
                  validationErrors={{
                    isEmail: "This is not a valid email",
                  }}
                  required
                  {...initialValueProps.email}
                />
              </Grid>
              <Grid md={7} style={{ position: "relative" }}>
                <Typography
                  variant="subtitle2"
                  style={{ position: "absolute", bottom: 0 }}
                >
                  {pageTitle === "Profile Settings" ||
                  pageTitle === "Edit user profile"
                    ? "Email cannot be changed"
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5}>
            {
              <Autocomplete
                id="combo-box-demo"
                options={countryList}
                value={countrySh || initialValueProps.country.value}
                onChange={(event, value) => {
                  setCountrySh(value);
                }}
                required
                renderInput={(params) => (
                  <TextField {...params} label="Country" variant="standard" />
                )}
              />
            }
          </Grid>
          <Grid item xs={12} md={5}>
            <SelectFormsy
              className={classes.root}
              name="language"
              label="Language"
              value="none"
              {...initialValueProps.language}
            >
              <MenuItem value="none">
                <em>None</em>
              </MenuItem>
              {languageList?.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>;
              })}
            </SelectFormsy>
          </Grid>
          <Grid item xs={12} md={5}>
            {pageTitle === "Edit user profile" || pageTitle === "Profile Settings" ? null : (
              <FormsyPassword
                className={classes.root}
                passwordCriteria={passwordCriteria}
                showCriteriaList={true}
                required={!userInfo}
                label={"Password"}
                name="password"
                validations="isValidPassword"
                {...initialValueProps.password}
              />
            ) }
          </Grid>
          {businessListOptions && businessListOptions.length > 0 && (
            <Grid item xs={12} md={5}>
              <BusinessInfoList
                businessAccountInfo={userInfo?.businessAccountInfo}
                roleList={roleList}
                businessListOptions={businessListOptions}
                getAddedBusiness={getAddedBusiness}
                editProfile={props.editProfile}
              />
            </Grid>
          )}
          <Grid container className="mt-10 ">
            <Grid item>
              <Button
                className="mr-10"
                variant="contained"
                color="primary"
                size="lg"
                startIcon={
                  userInfo ? (
                    <IconButton
                      size="small"
                      aria-label="delete role"
                      component="span"
                    >
                      <EditIcon fontSize="inherit" style={{ color: "white" }} />
                    </IconButton>
                  ) : (
                    <AddIcon />
                  )
                }
                disabled={!isFormValid}
                type="submit"
              >
                {userInfo ? "Save" : "Create user"}
              </Button>
            </Grid>
            <Grid item xs={4}>
              {userInfo &&
              loggedInUserEmail != userInfo.email &&
              businessListOptions?.length > 0 &&
              props.editProfile === false ? (
                <Button
                  variant="outlined"
                  size="lg"
                  onClick={
                    userInfo && loggedInUserEmail != userInfo.email
                      ? () =>
                          dispatch(
                            openDialog({
                              children: (
                                <DeleteUserDialog
                                  email={userInfo.email}
                                  deleteUser={onDeleteUser}
                                />
                              ),
                            })
                          )
                      : () => {
                          history.push("/manage-users");
                        }
                  }
                >
                  Remove Users
                </Button>
              ) : null}
              {pageTitle === "Profile Settings" ? (
                <Button
                  className="ml-10"
                  variant="outlined"
                  size="lg"
                  onClick={() => {
                    history.push("/dashboard/default");
                  }}
                >
                  Discard
                </Button>
              ) : (
                <Button
                  className="ml-10"
                  variant="outlined"
                  size="lg"
                  onClick={() => {
                    history.push("/manage-users");
                  }}
                >
                  Discard
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Formsy>
    </div>
  );
};
