import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { StyledTypography } from "./StyledTypography";


export const Breadcrumb = (props) => {
  const { items, onChange } = props;
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
    >
      {items.map((item) => {
        const { isActive, name, key } = item;
        return (
          <StyledTypography
            isActive={isActive}
            name={name}
            key={key}
            itemKey={key}
            onChange={onChange}
          />
        );
      })}
    </Breadcrumbs>
  );
};
