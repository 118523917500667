import { Button, Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import CompanyDetails from "./CompanyDetails";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CodeIntegrate from "./CodeIntegrate";
import { Breadcrumb } from "app/shared-components/breadcrumb/Breadcrumb";
import {
  createAccount,
  getJSSnippet,
  getUserAccount,
  sendRegistrationEmail,
  listAddedUser,
} from "../services/register.service";
import { useSelector } from "react-redux";
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
const items = [
  {
    name: "Company Details",
    isActive: true,
    key: 0,
  },
  {
    name: "Code Integrate",
    isActive: false,
    key: 1,
  },
];

function AccountSetup(props) {
  const [selectedBreadcrumb, setSelectedBreadcrumb] = useState(0);
  const [breadcrumbItems, setBreadcrumbItems] = useState(items);
  const [jsSnippet, setJSSnippet] = useState(null);
  const [accountSetupDisabled, setAccountSetupDisabled] = useState(false);
  const [accountData, setAccountData] = useState(null);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const userState = useSelector(({ auth }) => {
    return auth.user.data;
  });

  useEffect(() => {
    var accountSetupData = {};
    let source = localStorage.getItem("lac-source-account-setup");
    if (source === "ADD_NEW_PRODUCT") {
      localStorage.setItem("lac-source-account-setup", "");
      let userClientDetails = JSON.parse(
        localStorage.getItem("lac-user-client-details")
      );
      accountSetupData.agencyName = userClientDetails[0].agency_name;
      accountSetupData.agencyUrl = userClientDetails[0].agency_url;
      accountSetupData.organisationType = "Marketing Agency";
    } else if (
      userState?.product_register_progress === "Step 1"
        ? userState.product_register_progress === "Step 1"
        : userState?.product_register_progress === "Step 2"
    ) {
      if (userState?.product_register_progress === "Step 2") {
        setAccountSetupDisabled(true);
        setSelectedBreadcrumb(1);
        accountSetupData.role = userState.user_role;
        if (userState.agency_name && userState.agency_url) {
          accountSetupData.agencyName = userState.agency_name;
          accountSetupData.agencyUrl = userState.agency_url;
          accountSetupData.companyUrl =
            userState.availableProducts[0].company_url;
          accountSetupData.companyName =
            userState.availableProducts[0].company_name;
          accountSetupData.organisationType = "Marketing Agency";
        } else if (userState.company_name && userState.company_url) {
          accountSetupData.companyUrl =
            userState.availableProducts[0].company_url;

          accountSetupData.companyName =
            userState.availableProducts[0].company_name;
          accountSetupData.organisationType = "Individual Business";
          accountSetupData.platform =
            userState.availableProducts[0].company_platform;
          accountSetupData.traffic =
            userState.availableProducts[0].company_traffic;
          accountSetupData.country =
            userState.availableProducts[0].company_country;
          accountSetupData.client_id = userState.availableProducts[0].client_id;
          accountSetupData.product_register_progress =
            userState.availableProducts[0].product_register_progress;
        }
      } else {
        accountSetupData.role = userState.user_role;
        setSelectedBreadcrumb(0);
      }
    } else {
      let userClientDetails = localStorage.getItem("lac-user-active-client_id");
      if (source === "STEP_2") {
        userState.availableProducts.map((item) => {
          if (item.client_id === userClientDetails) {
            setSelectedBreadcrumb(1);
            setAccountSetupDisabled(true);
            accountSetupData.role = item.user_role;
            if (item.agency_name && item.agency_url) {
              accountSetupData.agencyName = item.agency_name;
              accountSetupData.agencyUrl = item.agency_url;
              accountSetupData.companyName = item.company_name;
              accountSetupData.companyUrl = item.company_url;
              accountSetupData.organisationType = "Marketing Agency";
            } else if (item.company_name && item.company_url) {
              accountSetupData.companyName = item.company_name;
              accountSetupData.companyUrl = item.company_url;
              accountSetupData.organisationType = "Individual Business";
            }
            accountSetupData.platform = userState.company_platform;
            accountSetupData.traffic = userState.company_traffic;
            accountSetupData.country = userState.company_country;
            accountSetupData.client_id = item.client_id;
            accountSetupData.product_register_progress =
              item.product_register_progress;
          }
        });
      }
    }
    setAccountData(accountSetupData);
  }, []);
  useEffect(() => {
    let updatedBreadcrumbItems = breadcrumbItems.map((item, index) => {
      if (selectedBreadcrumb == index) {
        item.isActive = true;
      } else {
        item.isActive = false;
      }
      return item;
    });
    setBreadcrumbItems(updatedBreadcrumbItems);
  }, [selectedBreadcrumb]);

  useEffect(() => {
    if(localStorage.getItem("lac-user-details")){
      getJSSnippet().then((data) => {
        setJSSnippet(data);
      });
    }
  
  }, []);

  function handleBreadcrumbChange(selectedIndex) {
    if (selectedIndex == 1) {
      setSelectedBreadcrumb(selectedIndex);
    } else setSelectedBreadcrumb(selectedIndex);
  }

  const performAccountSetup = async (formValues) => {
    // console.log("formValues", formValues);
    if(accountData?.hasOwnProperty("companyUrl")){
      return true
    }else{
      setAccountData(formValues)
    }
    if (formValues.companyUrl) {
        const response = await getUserAccount({
        prm_company_url: `${formValues.companyUrl}`,
      });
      const companyValidateMessage =
        response?.data?.data[0]?.company_url_verification_status[0]
          ?.validation_message;
      if (
        companyValidateMessage ===
        //  "Client/Company Already Registered with Lacritz"
         "Client/Company Already Registered with Flable"
      ) {
        setAlertMessage(companyValidateMessage);
        setAlertType("error");
        setOpen(true);
      } else if (
        //  companyValidateMessage === "Client/Company Not Registered with Lacritz"
         companyValidateMessage === "Client/Company Not Registered with Flable"
      ) {
        let datainfo
        const lastEmail = window.location.href.split('/')[4].split('%')[0];
        if(localStorage.getItem("lac-user-details")){
           datainfo = JSON.parse(
            localStorage.getItem("lac-user-details")
          );
        }else if(lastEmail){
          datainfo={
            email_address:lastEmail
          }
        }
      

        try {
          // let email = userState
          let data = await createAccount(formValues);
          if (data) handleBreadcrumbChange(1);
          localStorage.setItem("lac-user-active-client_id", data);
          await getJSSnippet().then((data) => {
            setJSSnippet(data);
          });
          const emailInfo = {
            to: datainfo?.email_address,
            subject: "User Guidance",
            text: "User Js Script",
            html: `<html lang="en">

            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <script src="https://code.iconify.design/iconify-icon/1.0.7/iconify-icon.min.js"></script>
                <title>User guidance </title>
                <style>
                    html,
                    body {
                        background: #f1f1f1;
                    }
            
                    .email-container {
                        font-family: Arial, sans-serif;
                        background-color: #FFFFFF;
                        margin: 3%;
                        padding: 3%;
                    }
            
                    .heading {
                        text-align: left;
            
                    }
            
                    .button-like-link {
            
                        padding: 10px 20px;
                        text-decoration: none;
                        /* Remove underline */
                        background-color: #437eeb;
                        color: #fff;
                        border: none;
                        border-radius: 5vw;
                        cursor: pointer;
                        width: 10vw;
                        height: 5vh;
                        text-align: centre;
                    }
            
                    /* Remove the default button styles */
                    .button-like-link:focus,
                    .button-like-link:hover {
                        outline: none;
                        text-decoration: none;
                    }
            
                    .icon-background {
                        background-color: #437eeb;
                        border-radius: 50%;
                        z-index: -1;
                    }
                </style>
            </head>
            
            <body class="email-container">
                <header class="heading">
                    <img src="https://flable.ai/wp-content/uploads/2023/07/Group-146-1.png" width="150vw" height="50vh" />
                    <p style="font-size: 18px;"> Flable AI is a global analytics company offering insights to accelerate business
                        growth</p>
                </header>
            
            
                <hr style="border: 1px solid red;">
            
                <h2>Dear Sir/Mam</h2>
            
                <p style="font-size: 18px;">Please copy and paste the below code snippet into the header section of the website</p>
            
                <div style="text-align: center; margin:2%;border:2px solid black">
                <p style=" font-size: 18px;font-weight: bold;">&lt;script &gt;
                        (function(f, l, a, b, t, i, c) {
                        f[a] = f[a] || function() {
                        (f[a].q = f[a].q || []).push(arguments)
                        };
                        i = l.createElement(b);
                        i.async = 1;
                        i.src ='${process.env.REACT_APP_LJS_URL}'/api/config/'+t;
                        c = l.getElementsByTagName(b)[0];
                        c.parentNode.insertBefore(i, c);
                        })(window, document, "flable", "script", '${data}');
                        &lt;/script &gt;</p>
                </div>
                <p style="font-size: 18px;">For any further assistance or inquiries, please don't hesitate to reach out to our
                    dedicated support team at support@flable.ai </p>
                <p style="font-size: 16px;">Best Regards,</p>
                <p style="font-size: 16px;color:#437eeb">The Flable team.</p>
                <hr style="border: 1px solid #fafafa;margin-top:3%">
                <div id="socialConnect" style="width: 100%;text-align: center;">
                    <table style="display: inline-block; vertical-align: middle;" width="auto">
                        <tr>
                            <td class="icon-background" style="width:25%">
                                <a href="https://www.youtube.com/channel/UCfTiob-n1Lzjd9d1G4fDiLQ" target="_blank">
                                    <img src="https://flable.ai/wp-content/uploads/2024/01/Ant-Design-YouTube.png" width="100%"
                                        height="50%" style="margin-top:25%" />
                                </a>
            
                            </td>
                            <td class="icon-background" style="width:25%">
                                <a href="https://www.linkedin.com/company/flable/" target="_blank">
                                    <img src="https://flable.ai/wp-content/uploads/2024/01/B.png" width="100%" height="50%"
                                        style="margin-top:25%" />
                                </a>
            
                            </td>
                            <td class="icon-background" style="width:25%">
            
                                <a href="https://www.linkedin.com/company/flable/" target="_blank">
                                    <img src="https://flable.ai/wp-content/uploads/2024/01/lucide_instagram.png" width="100%"
                                        height="50%" style="margin-top:25%" />
                                </a>
            
                            </td>
                            <td class="icon-background" style="width:25%">
                                <a href="https://twitter.com/Flableai" target="_blank">
                                    <img src="https://flable.ai/wp-content/uploads/2024/01/iconoir_twitter.png" width="100%"
                                        height="50%" style="margin-top:25%" />
                                </a>
            
                            </td>
                        </tr>
                    </table>
                </div>
                <hr style="border: 1px solid #fafafa;margin-bottom:2%">
                <div style="width: 100%;  text-align: center; display: table;margin-left:3px">
                    <span style=" display: table-cell; vertical-align: middle; font-size: 16px;width:100%">&copy; 2024 Flable.All
                        rights
                        reserved.</span>
                </div>
                <div style="width: 100%;  text-align: center; display: table;margin:3px">
                    <span style=" display: table-cell; vertical-align: top; font-size: 16px;width:100%">Digitalization empowering
                        company</span>
                </div>
                <div id="disclaimer" style="width: 100%;text-align: center;">
                    <p style="display: inline-block; vertical-align: middle;width:80%"> You are receiving this email because you
                        registered
                        to the Flable platform as a user.This
                        indicates your agreement with our Terms of Use and Privacy Policies.
                    </p>
                </div>
                <div style="width: 100%;text-align: center;">
            
                    <table style="display: inline-block; vertical-align: middle;" cellspacing="3px" cellpadding="3px">
                        <tr>
                            <td style="text-align: center; ">
            
                                <a href="https://flable.ai/index.php/privacy-policy-2/" target="_blank">Privacy policy</a>
                            </td>
                            <td style="text-align: center; ">
                                <a href="https://flable.ai/index.php/terms-of-service/" target="_blank">Terms of service</a>
                            </td>
                            <td style="text-align: center; ">
                                <a href="https://flable.ai/index.php/contact-us/" target="_blank">Help center</a>
                            </td>
                            </td>
                        </tr>
                    </table>
            
                </div>
            </body>
            
            </html>`,
          };
          
          if(data){

          await sendRegistrationEmail(emailInfo);
         
          }
        } catch (err) {
          console.log("Something went wrong", err);
        }
      }
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Typography variant="h6" id="tableTitle" component="div">
        Account Setup
      </Typography>
      <Breadcrumb items={breadcrumbItems} onChange={handleBreadcrumbChange} />
      <Box pt={8}>
        {selectedBreadcrumb === 0 &&
          (userState?.product_register_progress === "Step 2"
            ? Boolean(accountData)
            : true) && (
            <>
              <CompanyDetails
                {...props}
                onFormSubmission={performAccountSetup}
                accountSetupData={accountData}
                accountSetupDisabled={accountSetupDisabled}
              />
            </>
          )}
        {selectedBreadcrumb === 1 && (
          <CodeIntegrate
            jsSnippet={jsSnippet}
            accountSetupData={accountData}
            listAddedUser={listAddedUser}
          />
        )}
      </Box>
    </div>
  );
}

export default AccountSetup;
