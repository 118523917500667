import React from 'react'
import { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import BorderLinearProgress from '@material-ui/core/LinearProgress'
import "./pagecount.css"
export default function Pagecount(props) {
    const [pagecount, setPagecount] = React.useState([])

    useEffect(() => {
        if (props?.realtimeData?.length > 0) {
            //    filter only page_count data from realtimeData array page_count should not be empaty obj
            let page_count = [];
            for (let i = 0; i < props?.realtimeData?.length; i++) {
                if (props?.realtimeData[i].page_count != undefined) {
                    let page_title = Object.keys(props?.realtimeData[i].page_count);
                    let count = 0
                    for (let j = 0; j < page_title.length; j++) {

                        if (page_count.length == 0) {
                            page_count.push({ page_title: page_title[j], count: 1 });
                        } else {
                            for (let k = 0; k < page_count.length; k++) {
                                if (page_count[k].page_title == page_title[j]) {
                                    count = 1;
                                    page_count[k].count = page_count[k].count + 1;
                                }
                            }
                            if (count == 0) {
                                page_count.push({ page_title: page_title[j], count: 1 });
                            }
                        }
                    }
                }
            }

            setPagecount(page_count);

        }

    }, [props?.realtimeData])
    return (
        <div className='pagecountCard '>
            <Typography className='font-500 text-4 mt-6' variant="h6" >
                Real time page views
            </Typography>
            <div className='flex flex-row items-center justify-between  mr-4'>
                <Typography className='font-400 text-5 ' variant='p'>
                    Page title
                </Typography>
                <Typography className='font-400 text-5' variant='p'>
                    views
                </Typography>

            </div>
            <div className='pageTitileView mb-4  mr-6 '>
                {
                    pagecount?.length > 0 && pagecount?.map((item, index) => {
                        return (
                            <>
                                <div className='flex flex-row  justify-between '>
                                    <Typography className='font-600 text-3 items-start' variant='p'>
                                        {item.page_title}
                                    </Typography>
                                    <Typography className='font-600 text-3 items-end' variant='p'>
                                        {item.count}
                                    </Typography>

                                </div>
                                <BorderLinearProgress variant="determinate"
                                    // color
                                    color='secondary'
                                    value={item.page_title.length} />

                            </>
                        )
                    }
                    )
                }
            </div>



        </div>
    )
}
