import { createSlice } from '@reduxjs/toolkit';
import store  from "../../../../main/app/behaviour-analysis/heatmap/store/heatmapStore.js";

 const initialState= {
	toggleFlag:false,
  }

   const  toolbarSlice  = createSlice({
    name: 'toolbarlayout1/toggleFlagToolBar',
	initialState:{toggleFlag:false},
    reducers:{
		getToolbarLayoutFlag: (state) => {
				return {
					...state,
					toggleFlag: false
				}
				
	  },
}
})

export const {getToolbarLayoutFlag,setToolbarLayoutFlag} = toolbarSlice.actions
export default toolbarSlice.reducer