import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import {
  getChartsList,
  getChartsDetailsBySharedDashboard,
  getChartsDetailsByPinnedDashboard,
  getDashboardMetaByID,
  updateUserChart,
  saveSharedDashboardService,
  deleteDashboardService,
} from '../services/dashboard.service';
import { DashboardConstants } from '../../dashboard/constants/dashboard.constants';
import { getUsersList } from '../../../user-management/services/users';

const namespace = 'dashboard/charts';
const chartsAdapter = createEntityAdapter({});

const initialState = chartsAdapter.getInitialState({
  loading: DashboardConstants.HTTPSTATUS.FINISHED,
  entities: {},
  currentRequestId: '',
  error: '',
});

export const fetchChartsDetailsBySharedDashboard = async (chartSelection) => {
  try {
    const { data } = await getChartsDetailsBySharedDashboard(chartSelection);
    return data;
  } catch (err) {
    return null;
  }
};

export const fetchChartsDetailsByPinnedDashboard = async (chartSelection) => {
  try {
    const { data } = await getChartsDetailsByPinnedDashboard(chartSelection);
    return data;
  } catch (err) {
    return null;
  }
};

export const fetchAllUsers = async (requestParams) => {
  try {
    const data = await getUsersList(requestParams.email_address);
    return data;
  } catch (err) {
    return null;
  }
};

export const saveSharedDashboard = async (chartSelection) => {
  try {
    const { data } = await saveSharedDashboardService(chartSelection);
    return data;
  } catch (err) {
    return null;
  }
};

export const deleteDashboard = async (chartSelection) => {
  try {
    const { data } = await deleteDashboardService(chartSelection);
    return data;
  } catch (err) {
    return null;
  }
};

export const fetchCharts = createAsyncThunk(
  `${namespace}/fetchCharts`,
  async (dateRange, { rejectWithValue }) => {
    try {
      const list = await getChartsList(dateRange);
      return list;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

export const updateUserCharts = createAsyncThunk(
  `${namespace}/updateUserChartsList`,
  async (chartsSortedArray, { rejectWithValue }) => {
    try {
      const list = await updateUserChart(chartsSortedArray);
      return list;
    } catch (err) {
      return rejectWithValue([], err);
    }
  }
);

const { actions, reducer } = createSlice({
  name: namespace,
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCharts.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = '';
        state.entities = payload;
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
      }
    },
    [fetchCharts.pending]: (state, { meta }) => {
      state.loading = DashboardConstants.HTTPSTATUS.PENDING;
      state.currentRequestId = meta;
    },
    [fetchCharts.rejected]: (state, { meta, payload, error }) => {
      if (state.currentRequestId === meta) {
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
        state.entities = payload;
        state.error = error;
        state.currentRequestId = meta;
      }
    },
    [updateUserCharts.fulfilled]: (state, { meta, payload }) => {
      if (meta.requestId === state.currentRequestId.requestId) {
        state.currentRequestId = '';
        state.entities = payload;
        state.loading = DashboardConstants.HTTPSTATUS.FINISHED;
      }
    },
  },
});

export const { selectEntities: selectChartsEntities } =
  chartsAdapter.getSelectors((state) => state.dashboard.charts);

export default reducer;
