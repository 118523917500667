import { TableBody, TableCell, TableRow } from "@material-ui/core";
import { AppTableRow } from "./AppTableRow";

export const AppTableBody = (props) => {
  const { data, additionalRowRender, isRowStyled, classes, columns } = props;
  return (
    <TableBody>
      {data.map((row, rowIndex) => (
        <AppTableRow key={row.id} isRowStyled={isRowStyled}>
          {columns.map((col) => {
            return (
              <TableCell
                scope="row"
                className={classes?.td}
                style={{ width: col?.width }}
              >
                {col.render
                  ? col.render(row[col.accessor], row, rowIndex)
                  : col.label}
              </TableCell>
            );
          })}
        </AppTableRow>
      ))}
      {additionalRowRender && (
        <TableRow className="ml-2 mb-12" style={{ display: "block" }}>
          {additionalRowRender()}
        </TableRow>
      )}
    </TableBody>
  );
};
