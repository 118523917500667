import ListUsersTab from "./ListUsersTab";
import { Header } from "../components/header/header";
import Box from "@material-ui/core/Box";
import { useState } from "react";

export const ListUsersTabContainer = () => {
  const [searchText, setSearchText] = useState();
  const onSearch = (_text) => {
    setSearchText(_text);
  };
  return (
    <Box style={{ width: "100%", height: "100%" }} p={10} pt={5}>
      <Header onSearch={onSearch} />
      <ListUsersTab searchText={searchText} />
    </Box>
  );
};
