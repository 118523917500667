import { authRoles } from "app/auth";
import UserManagement from "./UserManagement";
import { AddEditUserContainer } from "./tabs/AddEditUserContainer";

const UserManagementConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: true,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: true,
        },
        leftSidePanel: {
          display: true,
        },
        rightSidePanel: {
          display: true,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/manage-users",
      exact: true,
      component: UserManagement,
    },
    {
      path: "/manage-users/add-user",
      component: AddEditUserContainer,
    },
    {
      path: "/manage-users/edit-user/:userId",
      component: AddEditUserContainer,
    },
  ],
};

export default UserManagementConfig;
