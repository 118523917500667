import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';
import { TextareaAutosize } from "@material-ui/core";

i18next.addResourceBundle('en', 'chartBuilderPage', en);
i18next.addResourceBundle('tr', 'chartBuilderPage', tr);
i18next.addResourceBundle('ar', 'chartBuilderPage', ar);

/**
 * Lazy load Example
 */

import React from 'react';

const customizeConfig = {
    settings: {
        layout: {
            config: {
                toolbar: {
                    display: false,
                     style: "fixed",
                     position: "below",
                     title: "Chart Builder",
                   }
            }
        },
        
    },
    
    routes: [
        {
            path : '/Customize',
           component: React.lazy(() => import('./Customize'))
        }
    ]
};

export default customizeConfig;


