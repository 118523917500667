import {
  IconButton,
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import { closeDialog } from 'app/store/fuse/dialogSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export const UserExistsDialog = (props) => {
  const { email, step } = props;
  const history = useHistory();

  const dispatch = useDispatch();
  return (
    <>
      <DialogTitle id="alert-dialog-title">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            color="primary"
            aria-label="delete role"
            component="span"
            size="small"
          >
            <DeleteIcon fontSize="large" />
          </IconButton>
          <div>Email Already Exists</div>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle2">
          {'This email id "' +
            email +
            '" is already registered with us. Please click on "Sign in"'}
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', padding: '22px' }}>
        <Button
          variant="contained"
          color="primary"
          size="lg"
          disabled={false}
          onClick={() => {
            dispatch(closeDialog());
            history.push('/login');
          }}
        >
          Sign In
        </Button>
        <Button
          variant="outlined"
          size="medium"
          // component="label"
          disabled={false}
          onClick={() => {
            dispatch(closeDialog());
          }}
        >
          Change Email
        </Button>
      </DialogActions>
    </>
  );
};
