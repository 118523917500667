import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { makeStyles } from '@material-ui/core/styles';
import { FilterContext, FilterRowContext } from './Context';
import FilterCell from './FilterCell';
import _ from '@lodash';
import {
  IconButton,
  MenuItem,
  Divider,
  Icon,
  CardMedia,
  Button,
  NativeSelect,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles({
  layoutRoot: {},
});

const selectStyles = {
  control: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
  }),

  valueContainer: (base, state) => ({
    ...base,
    height: '20px',
    'min-height': '20px',
    position: 'initial',
    'padding-left': '4px',
  }),
  indicatorContainer: (base, state) => ({
    ...base,
    padding: '0',
  }),
  indicatorSeparator: (base, state) => ({
    ...base,
    height: '14px',
    'min-height': '14px',
    'margin-top': '2px',
    'margin-bottom': '2px',
  }),
  dropdownIndicator: (base, state) => ({
    ...base,
    padding: '0 2px',
  }),
  input: (base, state) => ({
    ...base,
    margin: '0',
    'padding-top': '0',
  }),
};

const FilterRow = (props) => {
  const context = useContext(FilterContext);

  const updateState = (filterRows, changeType) => {
    if (context?.onChange) {
      context.onChange(
        props.filterIndex,
        { filterRows: filterRows },
        changeType
      );
    }
  };

  const handleJoinOperatorChange = (selectedOption) => {
    const joinOperator = selectedOption?.value;
    const filterRows = [...context.filterRows];

    //update the state only if it changed
    if (joinOperator !== filterRows[props.rowIndex].joinOperator) {
      filterRows[props.rowIndex].joinOperator = joinOperator;

      updateState(filterRows, 'JOIN_OPERATOR_CHANGE');
    }
  };

  const handleFieldNameChange = (selectedOption) => {
    const field = selectedOption?.value;
    const filterRows = [...context.filterRows];

    //update the state only if it changed
    if (field !== filterRows[props.rowIndex].field) {
      filterRows[props.rowIndex].field = field;
      filterRows[props.rowIndex].operator = undefined;
      filterRows[props.rowIndex].values = [];

      updateState(filterRows, 'FIELD_NAME_CHANGE');
    }
  };

  const handleOperatorChange = (selectedOption) => {
    const operator = selectedOption?.value;
    const filterRows = [...context.filterRows];

    //update the state only if it changed
    if (operator !== filterRows[props.rowIndex].operator) {
      filterRows[props.rowIndex].operator = operator;
      filterRows[props.rowIndex].values = [];

      updateState(filterRows, 'OPERATOR_CHANGE');
    }
  };

  const handleFieldValueChange = (values) => {
    const filterRows = [...context.filterRows];
    filterRows[props.rowIndex].values = values;
    updateState(filterRows, 'FIELD_VALUE_CHANGE');
  };

  const deleteRow = () => {
    if (context) {
      const filterRows = [...context.filterRows];
      filterRows.splice(props.rowIndex, 1);

      updateState(filterRows, 'DELETE_ROW');
    }
  };

  const field = props.fieldNames?.find((column) => {
    return column.value === props.field;
  });

  return (
    <>
      <FilterRowContext.Provider
        value={{
          filterIndex: props.filterIndex,
          rowIndex: props.filterIndex,
          onFieldValueChange: handleFieldValueChange,
        }}
      >
        <div
          className="filter-row"
          id={`filter-row-${props.filterIndex}-${props.rowIndex}`}
        >
          <div className="filter-row-joinOperator pr-10">
            {props.renderJoinOperator && (
              <>
                {props.showJoinOperatorLabel && (
                  <Typography variant="caption">
                    {
                      props.joinOperatorOptions?.find(
                        (o) => o.value === props.rowJoinOperator
                      )?.label
                    }
                  </Typography>
                )}
                {!props.showJoinOperatorLabel && (
                  <Select
                    id={`filter-row-join-operator-${props.filterIndex}-${props.rowIndex}`}
                    disabled={props.disabled}
                    className="text-11 filter-row-joinOperator-select"
                    label="Select condition"
                    isSearchable={false}
                    value={props.joinOperatorOptions?.find(
                      (o) => o.value === props.joinOperator
                    )}
                    options={props.joinOperatorOptions}
                    onChange={handleJoinOperatorChange}
                    styles={selectStyles}
                  ></Select>
                )}
              </>
            )}
            {!props.renderJoinOperator && (
              <Typography variant="caption">where</Typography>
            )}
          </div>
          <div className="filter-row-fieldName pr-10">
            <Select
              id={`filter-row-field-name-${props.filterIndex}-${props.rowIndex}`}
              disabled={props.disabled}
              className="text-11 filter-row-fieldName-select"
              label="Select field"
              value={props.fieldNames?.find(
                (o) => o.value === (props.field || '')
              )}
              options={props.fieldNames}
              onChange={handleFieldNameChange}
              styles={selectStyles}
            ></Select>
          </div>
          <div className="filter-row-operator pr-10">
            <Select
              id={`filter-row-operator-${props.filterIndex}-${props.rowIndex}`}
              disabled={props.disabled}
              className="text-11 filter-row-joinOperator-select"
              isSearchable={false}
              placeholder={null}
              value={
                props.operator
                  ? props.operatorOptions?.find(
                      (o) => o.value === props.operator || ''
                    )
                  : ''
              }
              options={props.operatorOptions}
              onChange={handleOperatorChange}
              styles={selectStyles}
            ></Select>
          </div>
          <div className="filter-row-fieldValue">
            <FilterCell
              {...field}
              disabled={props.disabled}
              operator={props.operator}
              values={props.values}
              fieldValues={props.fieldValues}
            />
          </div>
          <div className="filter-row-removebtn">
            {props.renderDelete && (
              <IconButton
                disabled={props.disabled}
                size={'small'}
                onClick={deleteRow}
              >
                <Icon>delete</Icon>
              </IconButton>
            )}
          </div>
        </div>
      </FilterRowContext.Provider>
    </>
  );
};

export default FilterRow;
