import mock from '../mock';

const analyticsDashboardAppDB = {
  widgets: [
    {
      id: 'widget1',
      series: [
        {
          name: 'Average',
          description: 'Customer Acquisition Cost',
          value: '$0.412',
        },

        {
          name: 'Shopping',
          description: 'Cart Abandonment Rate',
          value: '0.6%',
        },
        {
          name: 'New Customer',
          description: 'Customer Conversion Rate',
          value: '9%',
        },
        {
          name: 'Sales',
          description: 'Total Orders from today',
          value: '4',
        },
        {
          name: 'Average',
          description: 'Session on Website',
          value: '362.5 sec',
        },
        {
          name: 'Average',
          description: 'Time to First Purchase',
          value: '22.8hrs',
        },

        {
          name: '',
          description: 'Bounce Rate',
          value: '2%',
        },

        {
          name: '',
          description: 'Active Users',
          value: '6',
        },
      ],
    },
    {
      id: 'widget2',
      series: [],
    },
    {
      id: 'widget3',
      series: [
        {
          name: 'New Customer',
          description: 'Customer Conversion Rate',
          value: '9%',
        },
      ],
    },
    {
      id: 'widget4',
      series: [
        {
          name: 'Sales',
          description: 'Total Orders from today',
          value: '4',
        },
      ],
    },
    {
      id: 'widget5',
      series: [
        {
          name: 'Average',
          description: 'Session on Website',
          value: '362.5 sec',
        },
      ],
    },
    {
      id: 'widget6',
      series: [
        {
          name: 'Average',
          description: 'Time to First Purchase',
          value: '22.8hrs',
        },
      ],
    },
    {
      id: 'widget7',
      series: [
        {
          name: '',
          description: 'Bounce Rate',
          value: '2%',
        },
      ],
    },
    {
      id: 'widget8',
      series: [
        {
          name: '',
          description: 'Active Users',
          value: '6',
        },
      ],
    },
    {
      id: 'widget9',
      series: {},
    },
    {
      id: 'widget10',
      series: [
        {
          Today: [
            {
              data: [92.8, 6.1, 1.1],
              change: [-0.6, 0.7, 0.1],
            },
          ],
          Yesterday: [
            {
              data: [77.2, 8.4, 14.4],
              change: [-2.3, 0.3, -0.2],
            },
          ],
          'Last 7 days': [
            {
              data: [88.2, 9.2, 2.6],
              change: [1.9, -0.4, 0.3],
            },
          ],
          'Last 28 days': [
            {
              data: [65.2, 2.6, 32.2],
              change: [-12.6, -0.7, 4.2],
            },
          ],
          'Last 90 days': [
            {
              data: [93.5, 4.2, 2.3],
              change: [2.6, -0.7, 2.1],
            },
          ],
          options: {
            chart: {
              height: '100%',
              type: 'donut',
            },
            labels: ['Desktop', 'Mobile', 'Tablet'],
            legend: {
              show: true,
            },
            dataLabels: {
              enabled: true,
            },
            stroke: {
              width: 2,
              colors: undefined,
            },
            fill: {
              opacity: 1,
            },
            theme: {
              monochrome: {
                enabled: true,
                shadeTo: 'light',
                shadeIntensity: 0.65,
              },
            },
            states: {
              hover: {
                filter: {
                  type: 'darken',
                },
              },
            },
          },
        },
        {
          years: {
            2019: [
              {
                name: 'Sales',
                data: [
                  1.9,
                  3,
                  3.4,
                  2.2,
                  2.9,
                  3.9,
                  2.5,
                  3.8,
                  4.1,
                  3.8,
                  3.2,
                  2.9,
                ],
                fill: 'start',
              },
            ],
            2020: [
              {
                name: 'Sales',
                data: [
                  2.2,
                  2.9,
                  3.9,
                  2.5,
                  3.8,
                  3.2,
                  2.9,
                  1.9,
                  3,
                  3.4,
                  4.1,
                  3.8,
                ],
                fill: 'start',
              },
            ],
            2021: [
              {
                name: 'Sales',
                data: [
                  3.9,
                  2.5,
                  3.8,
                  4.1,
                  1.9,
                  3,
                  3.8,
                  3.2,
                  2.9,
                  3.4,
                  2.2,
                  2.9,
                ],
                fill: 'start',
              },
            ],
          },
          options: {
            chart: {
              type: 'area',
              height: '100%',
              background: 'transparent',
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            theme: {
              mode: 'light',
            },
            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
              ],
              tooltip: {
                enabled: false,
              },
              axisBorder: {
                show: false,
              },
            },
            yaxis: {
              axisBorder: {
                show: false,
              },
            },
            markers: {
              size: 3,
              strokeWidth: 1.5,
              strokeOpacity: 1,
              strokeDashArray: 0,
              fillOpacity: 1,
              shape: 'circle',
              radius: 2,
              hover: {
                size: 5,
              },
            },
            fill: {
              type: 'solid',
              opacity: 0.7,
              gradient: {
                shadeIntensity: 0.4,
                opacityFrom: 1,
                opacityTo: 0.5,
                stops: [30, 100, 100],
              },
            },
            grid: {
              show: true,
              strokeDashArray: 3,
              position: 'back',
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
              padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
            },
            stroke: {
              show: true,
              curve: 'smooth',
              lineCap: 'butt',
              width: 1.5,
              dashArray: 0,
            },
          },
        },
      ],
    },
  ],
};

mock.onGet('/api/analytics-dashboard-app/widgets').reply((config) => {
  return [200, analyticsDashboardAppDB.widgets];
});
