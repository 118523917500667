import { ListUsersTabContainer } from "./tabs/ListUsersTabContainer";
import IdleTimer from "react-idle-timer";
import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
function Profile() {
  const IdleTime = 5;

  const [initState, setInitState] = useState(true);
  const IdleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);
  const history = useHistory();
  const onIdle = () => {
    console.log("user is idle");
    Swal.fire({
      title: "You've been idle for a while",
      text: "You will be logged out in 5 seconds",
      icon: "error",
      timer: 5000,
      allowOutsideClick: false,
      showDenyButton: true,
      denyButtonText: "Log me out",
      confirmButtonText: "Stay logged in",
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("user is active");
        stayActive();
      } else {
        console.log("Login Cleared Cache");
        localStorage.removeItem("lac-key");
        localStorage.removeItem("lac-user-details");
        localStorage.removeItem("lac-user-client-details");
        localStorage.removeItem("lac-user");
        localStorage.removeItem("lac-user-active-client_id");
        localStorage.clear();
        history.push("/login");
        // logout({ returnTo: window.location.origin });
      }
    });
    sessionTimeoutRef.current = setTimeout(setInitState, 5000);
  };
  const stayActive = () => {
    console.log("user is active");
    setInitState(true);
    clearTimeout(sessionTimeoutRef.current);
  };
  function appLogout() {
    console.log("Login Cleared Cache");
    localStorage.removeItem("lac-key");
    localStorage.removeItem("lac-user-details");
    localStorage.removeItem("lac-user-client-details");
    localStorage.removeItem("lac-user");
    localStorage.removeItem("lac-user-active-client_id");
    localStorage.clear();
    history.push("/login");
    // logout({ returnTo: window.location.origin });
  }
  return (
    <>
      {initState ? (
        <div>
          <ListUsersTabContainer />
        </div>
      ) : (
        appLogout()
      )}
      <IdleTimer
        ref={IdleTimerRef}
        onIdle={onIdle}
        timeout={IdleTime * 60 * 1000}
      ></IdleTimer>
    </>
  );
}

export default Profile;
