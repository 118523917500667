import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import JWTLoginTab from './tabs/JWTLoginTab';
import Button from '@material-ui/core/Button';
import 'intro.js/introjs.css';
import introJs from 'intro.js';
// this is a test


const useStyles = makeStyles(theme => ({
	root: {
		// background: `linear-gradient(to right, ${theme.palette.primary.dark} 0%, ${darken(
		// 	theme.palette.primary.dark,
		// 	0.5
		// )} 100%)`,
		background: '#FFFFFF',
		color: theme.palette.primary.contrastText
	},
	leftSection: {},
	rightSection: {

		background: `linear-gradient(to right, ${theme.palette.primary.main} 0%, ${darken(
			theme.palette.primary.main,
			0.5
		)} 100%)`,
		color: theme.palette.primary.contrastText
	}
}));

function Login() {
	const history = useHistory();

	const responseSuccessGoogle = (response) => {

	}

	const responseErrorGoogle = (response) => {

	}


	const classes = useStyles();
	const [selectedTab, setSelectedTab] = useState(0);

	function handleTabChange(event, value) {
		setSelectedTab(value);
	}
	const intro = introJs();
	const productTour = (e) => {
		intro.setOptions({ steps });
		intro.start();
	};
	const steps = [
		{
			element: '#emailTxt',
			intro: ' Enter your registered Email id. ',
			position: 'top',
		},
		{
			element: '#passwordTxt',
			intro: ' Enter your Password. ',
			position: 'top',
		},
		{
			element: '#loginButton',
			intro: ' You can sign in to your account using your registered credentials and start exploring the features and capabilities. ',
			position: 'top',
		},
		{
			element: '#forgotPasswordLink',
			intro: ' Click on it to reset your password. ',
			position: 'top',
		},
		{
			element: '#registerLink',
			intro: 'If you don\'t have an account, please click the \'Register\' button.',
			position: 'top',
		}



	];
	return (
		<div
			className={clsx(
				classes.root,
				'flex flex-col flex-auto items-center justify-center flex-shrink-0 p-16 md:p-24'
			)}
		>
			<motion.div
				initial={{ opacity: 0, scale: 0.6 }}
				animate={{ opacity: 1, scale: 1 }}
				className="flex w-full max-w-400 md:max-w-3xl rounded-20 shadow-2xl overflow-hidden"
			>
				<Card
					className={clsx(
						classes.leftSection,
						'flex flex-col w-full max-w-sm items-center justify-center shadow-0'
					)}
					square
				>
					<CardContent className="flex flex-col items-center justify-center w-full py-96 max-w-320">
						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
							<div className="flex items-center mb-48">
								<img className="logo-icon w-100" src="assets/images/logos/flableLogo.png" alt="logo" />
								{/* <div className="border-l-1 mr-4 w-1 h-40" /> */}
								<div>
									<Typography className="text-24 font-semibold logo-text" color="inherit">
										FLABLE
									</Typography>
									<Typography
										className="text-16 tracking-widest -mt-8 font-700"
										color="textSecondary"
									>
										CAS GLOBAL
									</Typography>
								</div>
							</div>
						</motion.div>

						<JWTLoginTab />
						<div className="flex flex-row items-right justify-right pt-32 ">

							<Link id='forgotPasswordLink'
								to="/reset-password"
							// onClick={ ()=>  history.push('/reset-password')}
							>
								<span style={{ 'color': "black", "fontSize": "14px", "paddingLeft": "16rem" }}>
									Forget password?
								</span>
							</Link>
						</div>


					</CardContent>

					<div className="flex flex-col items-center justify-center pb-32">
						<div>
							<span className="font-normal mr-8">Don't have an account?</span>

							<Link className="font-normal " to="/register" id="registerLink">
								Register
							</Link>
						</div>
						{/* <Link className="font-normal mt-8" to="/">
							Back to Dashboard
						</Link> */}
						<Button
							type="submit"
							variant="contained"
							color="primary"
							className="w-full mx-auto mt-16"
							onClick={productTour}
						>
							Take a tour
						</Button>
					</div>
				</Card>

				<div className={clsx(classes.rightSection, 'hidden md:flex flex-1 items-center justify-center p-64')}>
					<div className="max-w-320">
						<motion.div
							initial={{ opacity: 0, y: 40 }}
							animate={{ opacity: 1, y: 0, transition: { delay: 0.2 } }}
						>
							<Typography variant="h3" color="inherit" className="font-semibold leading-tight">
								Welcome <br />
								to the <br /> Flable!
							</Typography>
						</motion.div>

						<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.3 } }}>
							<Typography variant="subtitle1" color="inherit" className="mt-32">
								Powerful and professional admin template for Web Applications, CRM, CMS, Admin Panels
								and more.
							</Typography>
						</motion.div>
					</div>
				</div>
			</motion.div>
		</div>
	);
}

export default Login;
