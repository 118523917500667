import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from "@material-ui/core/Tooltip";
import InfoIcon from "@material-ui/icons/Info";

// const columns = [
//   'Header',
//   'Date 1',
//   'Date 2',
//   'Date 3',
//   'Date 4',
//   'Date 5',
//   'Date 6',
//   'Date 7',
//   'Date 8',
//   'Date 9',
//   'Date 10',
//   'Date 11',
//   'Date 12',
// ];

export default function ChartLegendTable(props) {
  function createData(series) {
    const data1 = { Header: series.name };
    series.data.forEach((item) => {
      data1[item.xaxis] = item.yaxis;
    });
    console.log(data1);
    return data1;
  }

  // function generateChartTable(pdata) {
  //   //return [createData(pdata.series[0])];
  //   console.log('generateChartTable', pdata);
  //   const dataSeries = pdata.series.map((series) => {
  //     return createData(series);
  //   });
  //   console.log(dataSeries);
  //   return dataSeries;
  // }

  // function generateColumns(pdata) {

  //   const columns = [];
  //    pdata.series.forEach((series) => {
  //      series.data.forEach((item) => {
  //       if(columns.indexOf(item.xaxis) == -1)
  //         columns.push(item.xaxis);
  //     })
  //   });
  //   return ['Header', ...columns];
  // }
  function generateChartTable(pdata) {
    //return [createData(pdata.series[0])];
    console.log('generateChartTable', pdata);
    return pdata.series.map((series) => {
      return createData(series);
    });
  }

  function generateColumns(pdata) {
    const columns = pdata.series[0].data.map((item) => {
      return item.xaxis;
    });
    return ['Header', ...columns];
  }
  const { data, chartType,toolTipInfo } = props;
  const columns = generateColumns(data);
  const rows = generateChartTable(data);
  // console.log('ChartLegendTable rows');
  // console.log(rows);
  // console.log('ChartLegendTable columns');
  // console.log(columns);

  return (
    <TableContainer component={Paper} style={{overflowY:'auto', maxHeight:'300px'}}>
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell align="right" style={{ whiteSpace: 'nowrap' }}>
                {col} 
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,rowIndex) => (
           <TableRow key={row.name}>
              {columns.map((col,index) => (
                index?<TableCell align="right">{row[col]}
                </TableCell>:<TableCell align="right">{row[col]}
                <Tooltip
                      className="pb-5"
                      title={toolTipInfo[rowIndex]} 
                    >
                      <InfoIcon style={{ color: "#3B6FCF" }} />
                    </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
